import { TRACK_RUNNER_LOCATION } from '../../core/constants/actions'
import { getNewState } from '../../core/utility'

const intialState = {
  isLoading: false,
}

export default function trackRunnerLocationReducer(state = intialState, action) {

  switch (action.type) {
    case TRACK_RUNNER_LOCATION.REQUEST:
      return getNewState(state, { isLoading: true })

    case TRACK_RUNNER_LOCATION.SUCCESS:
      const { locationPoints } = action.payload;
      return getNewState(state, { locationPoints})

    case TRACK_RUNNER_LOCATION.FAILURE:
      const { error } = action.payload
      return getNewState(state, { error, ...intialState, locationPoints:[] })

    case TRACK_RUNNER_LOCATION.RESET:
      return intialState

    default:
      return state
  }
}