import { PINCODE_WISE_VIEW,} from '../../core/constants/actions'
import { getNewState } from '../../core/utility'

const intialState = {
    pincodeWise: [],
    isLoading: false,
}
export default function pincodeWiseReducer(state = intialState, action) {

    switch (action.type) {
        case PINCODE_WISE_VIEW.REQUEST:
            return getNewState(state, { isLoading: true })

        case PINCODE_WISE_VIEW.SUCCESS:
            const { pincodeWise } = action.payload;
            return getNewState(state, { pincodeWise })

        case PINCODE_WISE_VIEW.FAILURE:
            const { error } = action.payload
            return getNewState(state, { error, ...intialState })

        case PINCODE_WISE_VIEW.RESET:
            return intialState

        default:
            return state
    }
}
