import { Card, CardActions, CardContent, CardHeader, Dialog, DialogContent } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Button } from 'reactstrap'
import './style.scss'
import { getAmount } from '../../../core/utility'
import { useDispatch } from "react-redux";
import { setNotification } from '../../../redux/actions/notificationAction'
import { onlyNumber } from '../../../core/utility/inputNormalizers'
import { updateCustomerDetails } from '../../../core/services/miscServices'
import { showLoader, hideLoader } from '../../../redux/actions/loaderAction'
import { Error } from '@material-ui/icons'
import { updateCityLead } from '../../../core/services/shdServices'
import DropDown from '../../../shared/components/form/DropDown'
import { getConfirmationList } from '../../../redux/actions/quoteConfirmationAction'
import { CloseIconBlack } from '../../../core/utility/iconHelper'


export default function index({leadCity, status, open, onClose, leadId, name, email, refreshPage, cityList, city, leadCityName, onChangeCity, hcityChange, leadCityId }) {

    // const [updatedName, setUpdatedName] = useState(name);
    // const [updatedEmail, setUpdatedEmail] = useState(email);
    const [isError, setIsError] = useState(false);
    const [errorText, setErrorText] = useState(false);
    const [citySelcted, setCitySelcted] = useState({});
    const dispatch = useDispatch();


    useEffect (()=>{

        if(leadCityName){
            const leadCity = cityList.filter(city => 
                {
                  if(city.label === leadCityName) return city
                })
            setCitySelcted(leadCity[0])
        }
    },[leadCityName])


    const handleAdd = () => {
        let payload = {}
        payload.status = status
        payload.leadId = leadId
        payload.cityId = leadCityId
        payload.cityName = leadCityName
        updateCityLead(payload)
            .then((apiResponse) => {
                if (apiResponse.isValid) {
                    dispatch(setNotification("success", "Success", apiResponse.message));
                      refreshPage();
                    onClose();
                      refreshPage();
                    setIsError(false);
                    setErrorText("");
                } else {
                    dispatch(setNotification("danger", "Error", apiResponse.message));
                }
            });
        // }
    };

   
    return (
        <div>


            <Dialog open={open} onClose={onClose} className="dialog dial-edit-city1">
                <DialogContent className="dialog dial-edit-city">
                    <Card className="innerCard">
                        <CardHeader 
                        
                        title={<div >
                            <p className="headerText" style={{width:'33em'}}>Select City Name  </p>
                            </div>}
                         />
                        
                        <CardContent >
                            <div className="row" style={{alignItems:'center'}}>
                                <div className="col-5 text-right citName" > City Name :</div>
                                <div className="filter-container d-down"  >
                                    <DropDown
                                        options={cityList}
                                        value={citySelcted}
                                        placeholder="Select City Name"
                                        onChange={hcityChange}
                                    />
                                </div>
                               
                            </div>
                            <br />
                           
                        </CardContent>
                        <div className='edit-update-btn'>
                            <Button
                                color="primary"
                                // className="addpricebtn"
                                onClick={handleAdd}
                            >
                                Update
                            </Button>
                        </div>
                    </Card>
                </DialogContent>
            </Dialog>
        </div>
    )
}
