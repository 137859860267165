import React from "react"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,FormControlLabel, Radio, RadioGroup
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { withStyles } from '@material-ui/core/styles';

const EachFilter=(props)=>{

const {optionList,value,valueDrop,setValue,setValueDrop,label}=props
const drawerWidth = 400;
const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex"
    },
    appBar: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: drawerWidth,
    },
    title: {
      flexGrow: 1,
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      borderRadius: "18px 0px 0px 18px",
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "space-between",
      backgroundColor: "#F0F7FE",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginRight: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    },
    mainHeading: {
      // fontFamily: "inherit",
      fontWeight: "500",
      fontSize: "16px",
      color: "#333333",
    },
    typography:{
      // fontFamily:"ProximaNovaRegular",
      fontWeight: "500",fontSize: "16px",color: "#6D6D6D",width:"100%"
    },
    radioGroup:{display: 'flex',flexDirection:"row-reverse",justifyContent: 'space-between',alignItems: 'center', width: '320px',margin:"0px"
    }
  }));

const classes = useStyles();


const CustomRadio = withStyles({
  root: {
    color: '',  // replace with your color
    '&$checked': {
      color: 'blue',  // replace with your color
    },
  },
  checked: {},
})(Radio);

const getLabel=()=>{
  return (optionList.filter((item)=>(item.value===value))[0] && optionList.filter((item)=>(item.value===value))[0].label)
}

return(<>
        <Typography style={{color:value?"#317A9A":"",fontSize:"12px",color:"#979797",marginLeft:"3px",fontFamily:"proximaNovaRegular",marginTop:value?"10px":"0px"}}>{getLabel()?label:""}</Typography>

        <Accordion className="advanced-dropdown"  style={{width:"100%",backgroundColor:valueDrop || value?"#F5F6F6":"",borderRadius:"10px"}} expanded={valueDrop}  onClick={()=>{setValueDrop(!valueDrop)}}>
            <AccordionSummary expandIcon={!value || valueDrop?<ExpandMoreIcon style={{width:"36px"}}/>:null} style={{height:"48px",width:"348px"}}>
                <Typography  className={classes.typography}>
                <div style={{ display: 'flex', alignItems: 'center',width:"100%",justifyContent:"space-between" }}>
                    <Typography style={{color:value?"#317A9A":""}}>{getLabel()?getLabel():label}</Typography>
                    <div style={{display:"flex",marginRight:"-5px"}}>
                    {value && 
                    <>
                    <IconButton  aria-label="Delete" onClick={(event)=>{event.stopPropagation();setValue(null);setValueDrop(false)}}>
                    <DeleteOutlineIcon />
                    </IconButton>
                    {!valueDrop  && <IconButton  aria-label="Delete" onClick={(event)=>{event.stopPropagation();setValueDrop(!valueDrop)}}>
                    <EditIcon style={{ color: '#2076DC' }}/>
                    </IconButton>}
                    </>
                    }
                    </div>
                </div> 
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <RadioGroup onChange={(event)=>{setValue(event.target.value);setValueDrop(false)}} style={{width:"100%"}} value={value}>
                {optionList.map((item,index)=>(<FormControlLabel key={index} value={item.value} control={<CustomRadio />} label={item.label}  className={classes.radioGroup}/>))}
                </RadioGroup>
            </AccordionDetails>
        </Accordion>
        </>
)}


export default EachFilter
