import { Dialog } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Cc, Km, Owner, Whatsapp, Yamaha } from "../../../../core/utility/iconHelper";
import filterImg from '../../../../shared/img/icons/filter-icon.svg'
import DropDown from '../../../../shared/components/form/DropDown';
import { DatePicker, Loader } from 'rsuite'
import { disableDates, getAmount } from '../../../../core/utility/stringUtility'
// import DeepFilters from "./DeepFilters"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getPremiumAccess } from '../../../../core/services/authenticationServices';
import { PriorityImage } from '../../../../core/utility/iconHelper';
import SearchField from '../../../../shared/components/Search'
import TablePagination from '@material-ui/core/TablePagination';
import { getBikeYears, getMakes, getModels, getVariant, getVariantById } from '../../../../core/services/inventoryServices';
import { getCityID, getUserID } from "../../../../core/services/rbacServices";
import { getC2cSuggestionList, updateC2CLeadStatus } from "../../../../core/services/C2cServices";
import { useDispatch } from "react-redux";
import { setNotification } from "../../../../redux/actions/notificationAction";
import { hideLoader, showLoader } from "../../../../redux/actions/loaderAction";
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import { interestedBuyerAction } from "../../../../redux/actions/interestedBuyersAction";
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      '& > *': {
        marginTop: theme.spacing(2),
      },
    },
  }),
);
const VehicleCard=({vehicleDetail,value,onSelectVehicle})=>{
  const suggestionListDummy= [
    {
        "cityName": "Bangalore",
        "address": "Domlur, Indira Nagar",
        "totalKm": "100000",
        "bikeCC": "109.15",
        "noOfOwner": 1,
        "listedPrice": "12345",
        "bikeImage": " https://s3.ap-south-1.amazonaws.com/preprod.documents/2023/Sep/1695792030041-CAP8752341929063358025.jpg",
        "make": "Hero Honda",
        "model": "Karizma",
        "variant": "R",
        "inventoryId": 263741,
        "mfgYear": 2022,
        "mmvy": "Hero Honda Karizma R 2022",
        "makeId": 9,
        "modelId": 43,
        "variantId": 133,
        "storeId": 5637
    }
  ]

  const {cityName,address,totalKm,bikeCC,noOfOwner,listedPrice,bikeImage,make,model,variant,inventoryId,mfgYear,mmvy,makeId,modelId,variantId,storeId}=vehicleDetail
    return(
        <div className="bg-conatiner-card" onClick={onSelectVehicle}>
        <input value={inventoryId} name="vehicleList" type="radio" className="select-input" checked={value==inventoryId}/>
        <img src={bikeImage} />
        <div className="details-section">
          <p className="bike-model">{mmvy}</p>
          <p className="location">{address?address.split(",")[1]:""}, {cityName}</p>
          <div className="minute-details-container">
            <div className="km">
              <img src={Km} className="minute-image" />
              {totalKm} Kms
            </div>
            <div className="cc">
              <img src={Cc} className="minute-image" />
              {bikeCC} cc
            </div>
            <div className="owner">
              <img src={Owner} className="minute-image" />
              {noOfOwner}st owner
            </div>
          </div>
          <p className="price">₹ {getAmount(listedPrice)}</p>
        </div>
      </div>
    )
}


const VehicleSuggestionDialog=({open,setOpenTranferDialog,c2cBuyerLeadId,leadData})=>{
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [makes,setMakes]=useState([])
  const [models,setModels]=useState([])
  const [variants,setVaraiants]=useState([])
  const [years,setYears]=useState([])
  const [kmRanges,setKmRanges]=useState([])
  const [makeId,setMakeId]=useState('')
  const [modelId,setModelId]=useState('')
  const [variantId,setVariantId]=useState("")
  const [yearsId,setYearId]=useState("")
  const [kmRangeId,setKmRangeId]=useState("")
  const [searchText,setSearchText]=useState("")
  const [vehicleSuggList,setVehicleSuggList]=useState([])
  const [selectedVehicle,setSelectedVehicle]=useState("")
  const [count,setCount]=useState(0)
  const dispatch=useDispatch()
  const classes = useStyles();

  const getSuggestionList=(payload)=>{
    getC2cSuggestionList(payload).then(
      (apiResponse)=>{
        if(apiResponse.isValid){
          setVehicleSuggList(apiResponse.suggestionList)
          setCount(apiResponse.count)
        }
        else{
          setVehicleSuggList([])
          setCount(0)
        }
      })
  }
  useEffect(()=>{
    const payload={
      pageNum:page
    }
    getSuggestionList(payload)

    getMakes(getCityID())
            .then(apiResponse => {
                if (apiResponse.isValid) {
                    let array = apiResponse.sellOnlyResponse.length && apiResponse.sellOnlyResponse.map((item) => {
                        return { label: item.make, value: item.makeId }
                    })
                    setMakes(array)
                    setModels('')
                    setVaraiants('')
                    setVariantId('')

                }
            })


            getBikeYears()
            .then(apiResponse => {
              if (apiResponse.isValid) {
                  let array = apiResponse.years.length && apiResponse.years.map((item) => {
                      return { label: item, value: item }
                  })
                  setYears(array)

              }
          })
              


              const getKmRanges = () => {
                const kmRanges = []
                let year = new Date()
                for (let i = 10000; i <= 200000; i=i+10000) {
                  kmRanges.push({ label: `Upto ${i.toString()}`, value: i.toString() });
                }
                return kmRanges
            }
                setKmRanges(getKmRanges())
  }
  
  ,[])


  useEffect(()=>{

    const payload={
      pageNum:page
    }
    getSuggestionList(payload)

  },[page])

  useEffect(()=>{
    getModels(makeId.label, getCityID())
                .then(apiResponse => {
                    if (apiResponse.isValid) {
                        let array = apiResponse.modelList.length && apiResponse.modelList.map((item) => {
                            return { label: item.model, value: item.modelId }
                        })
                        setModels(array)
                        setModelId('')
                        setVariantId('')
                    }
                })
  }
  ,[makeId])

  useEffect(()=>{
    getVariantById(modelId.value, getCityID())
    .then(apiResponse => {
        if (apiResponse.isValid) {
            let array = apiResponse.bikeVariantList.length && apiResponse.bikeVariantList.map((item) => {
                return { label: item.bikeVariantName, value: item.bikeVariantId }
            })
            setVaraiants(array)
            setVariantId('')
        }
    })
  },[modelId])

  const handleChangePage = (event, newPage) => {
    setPage(newPage+1);
  };

  const handleChangeRowsPerPage = (
    event
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const getPayload=()=>{
    const payload={
      pageNum:page,
      make: makeId.label,
      model:modelId.label,
      variant:variantId.label,
      mfgYear:yearsId.label,
      totalKm:kmRangeId.value,
      mmv:searchText
    }
    return payload
  }
  const onClickApply=()=>{
    const payload=getPayload()
    getSuggestionList(payload)
  }
  const onClickClear=()=>{
    const payload={
      pageNum:page,
    }
    getSuggestionList(payload)
    setMakeId("")
    setModelId("")
    setVariantId("")
    setYearId("")
    setKmRangeId("")
    setSearchText("")

  }

  const onSearch=(value)=>{
    setSearchText(value)
  }

  const onClearSearch=()=>{
    setSearchText("")
    const payload=getPayload()
    delete payload.mmv
    getSuggestionList(payload)
  }

  const onSearchOfKeyword=()=>{
    onClickApply()
  }

  const onClickSubmitVehicle=()=>{
    dispatch(showLoader());

    const filteredList=vehicleSuggList.filter((item)=>(item.inventoryId===selectedVehicle))
    if(filteredList.length){
      const selectedOneVechicle=filteredList[0]
      const {cityName,address,totalKm,bikeCC,noOfOwner,listedPrice,bikeImage,make,model,variant,inventoryId,mfgYear,mmvy,makeId,modelId,variantId,storeId}=selectedOneVechicle
      const payload={
        leadId:leadData.sellerLeadId,
        crmLeadId:c2cBuyerLeadId,
        status:"C2C_Transfer",
        userId:getUserID(),
        make:make,
        model:model,
        variant:variant,
        makeId:makeId,
        modelId:modelId,
        variantId:variantId,
        storeId:storeId,
        inventoryId:inventoryId
    }
    
    updateC2CLeadStatus(payload).then(
      (apiResponse)=>{
        if (apiResponse.isValid) {
              dispatch(setNotification('success', 'SUCCESS', apiResponse.message))
              dispatch(hideLoader());

          }
        else{
            dispatch(setNotification('danger', 'ERROR', apiResponse.message))
            dispatch(hideLoader());
        }
      }
    )
    const interestedBuyerPayload={
      inventoryId: leadData.inventoryId,
      pageNum:1
  }
    dispatch(interestedBuyerAction(interestedBuyerPayload))
    }
    setOpenTranferDialog(false)
  }

    return(
        <Dialog open={open}   maxWidth="xl" fullWidth onClose={()=>setOpenTranferDialog(false)} className="dialog-bg-container-sugg">
            <div className="bg-container-veh-sugg">
                <div className="sugg-header">
                    <p>Vehicle Suggestion Dialog</p>
                    <button><span onClick={()=>setOpenTranferDialog(false)}>&#10005;</span></button>
                </div>
                <div className='anti-body'>
    <div className="cpp-filter-container interested-filter" style={{ flexWrap: 'nowrap'}}>
      {/* {
        isFilterLoading &&
        <Loader
          backdrop
          vertical
          content="Filter Loading..."
          style={{ zIndex: 9999 }}
          size="md"
        />
      } */}
      <div className="filter-icon">
        <img src={filterImg} alt="Filter Icon" className="filter-img" />
        <p className="filter-name">Filters</p>
      </div>
      <div className="cpp-filter-options filterOptions">
        <div className="date-filter-container no-margin">
          
            <div className="filter-container">
              <DropDown
                placeholder="Select Make"
                value={makeId}
                options={makes}
                onChange={(value)=>setMakeId(value)}
              />
            </div>
          
          <div className="filter-container" style={{width:"200px"}} >
            <DropDown
              placeholder="Select Model"
              value={modelId}
              options={models}
              onChange={(value)=>setModelId(value)}
            />
          </div>
          <div className="filter-container" style={{width:"200px"}} >
            <DropDown
              placeholder="Select Variant"
              value={variantId}
              options={variants}
              onChange={(value)=>setVariantId(value)}
            />
          </div>
          <div className="filter-container" style={{width:"200px"}} >
            <DropDown
              placeholder="Select Year"
              value={yearsId}
              options={years}
              onChange={(value)=>setYearId(value)}
            />
          </div>
        
          <div className="filter-container">
            <DropDown
              placeholder="Select KM Range"
              value={kmRangeId}
              options={kmRanges}
              onChange={(value)=>setKmRangeId(value)}
            />
          </div>
          <div className="filter-container" style={{marginLeft:"18px"}}>
            <button className="font-regular" style={{ marginRight: 15, color: '#ffffff', padding: '8px 20px', borderRadius: '22px', backgroundColor: '#4DBD74', border: '1px solid #35AC5E' }} onClick={onClickApply}>Apply</button>
            <button className="font-regular"  style={{ border: '#DEDEDE', backgroundColor: '1px solid #E8E8E8', color: '#333333', padding: '8px 20px', borderRadius: '22px' }} onClick={onClickClear}>Clear</button>
          </div>
        </div>
      </div>
      
    </div>
    
    
    </div>
               
                <div className="search-container">
                      <div className="cpp-filter-container for-suggest" >
                        <SearchField 
                          style={{ marginTop: '10px', marginBottom: '10px' }}
                          placeholder="Search By MMV"
                          searchText={searchText}
                          onSearch={onSearch}
                          onEnter={onSearchOfKeyword}
                          onClearSearch={onClearSearch}
                          onSearchKeyword={onSearchOfKeyword}
                      />
                      
                        <div className="pagination-container">
                          <p>Section: </p>
                          {/* <div className={classes.root}>
                            <Pagination count={10} />
                            <Pagination count={count} color="primary" />
                            <Pagination count={10} color="secondary" />
                            <Pagination count={10} disabled />
                          </div> */}
                            <TablePagination
                              component="div"
                              count={count}
                              page={page-1}
                              onPageChange={handleChangePage}
                              rowsPerPage={10}
                              // labelDisplayedRows={({ from, to, count }) =>`${from}-${to} of ${count}`}
                              // onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                    </div>
                  </div>
                    
                    
                    
                </div>
                <div className="vehicle-selection-section">
                        {vehicleSuggList.map((item,i)=>(<VehicleCard key={i} vehicleDetail={item} onSelectVehicle={()=>setSelectedVehicle(item.inventoryId)} value={selectedVehicle}/>))}
                </div>
                <div className="filter-container sugg-filter-container">
                  <button className="font-regular clear-button" onClick={()=>setOpenTranferDialog(false)}>CLEAR</button>
                  <button className="font-regular send-button" onClick={onClickSubmitVehicle}>SEND <img src={Whatsapp}/></button>
                </div>
            </div>
            
        </Dialog>
    )
}

export default VehicleSuggestionDialog