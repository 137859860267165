import { Dialog } from "@material-ui/core"
import moment from "moment"
import React, { useState } from "react"
import { DatePicker } from "rsuite"

const RescheduleDialog=({open,onhandlelose,handleDate,handleTime,value,setRescheduleComments,onHandleSubmitReschedule,rescheduleDateTime})=>{


  const [error,setError]=useState(false)
  const onChangeDate=(value)=>{
    const formattedValue=value ?  moment(value).format("YYYY-MM-DD") : null;
    handleDate(formattedValue)
  }
  const onChangeTime=(value)=>{
    const formattedValue=value ? moment(value).format("HH:mm") : null;
    handleTime(formattedValue)
  }
 
  const onHandleSubmitRescheduleChild=()=>{
    if(!value || !rescheduleDateTime.date || !rescheduleDateTime.time)
      setError(true)
    else{
      onHandleSubmitReschedule()
    } 
   
  }
  const disabledDate = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return (date < today);
  };
    return (
      <Dialog open={open}>
        <div className="bg-container-dialog">
          
          <div className="container-datepicker">
            <p>
              <DatePicker disabledDate={disabledDate} oneTap onChange={onChangeDate} className="select-date" placeholder="Select Date" cleanable={false}/>
            </p>
            <p>
              <DatePicker  onChange={onChangeTime} format="HH:mm" placeholder="Select Time" cleanable={false}/>
            </p>
            <textarea value={value}  onChange={(e)=>setRescheduleComments(e.target.value.replace(/[^a-zA-Z0-9\s]/g, '').slice(0,200))}  placeholder="Please Leave a comment"/>
          </div>
          <div className="button-container">
            <button className="button cancel-button" onClick={onhandlelose}>Cancel</button>
            <button className="button reschedule-button" onClick={onHandleSubmitRescheduleChild}>Reschedule</button>
          </div>
          {error && !rescheduleDateTime.date && <p>Please enter Date</p>}
          {error && !rescheduleDateTime.time && <p>Please enter Time</p>}
          {error && !value && <p>Please enter comments</p>}
        </div>
      </Dialog>
    );
}

export default RescheduleDialog