import { LOGISTICS_RUNNER_DETAILS } from '../../core/constants/actions'
import { getNewState } from '../../core/utility'

const intialState = {
  allRunners:[],
  isLoading: false,
  totalRunners:0,
  allDocCollectors: [],runnersOnDuty:0,docCompletedCount:0,docCollections:0,logUnCount:0,logAvCount:0,docAvCount:0,unAssCount:0,totLeadMappDoc:0,totLeadMapLog:0,
  docRchdCount:0, docInTransCount:0,docLeadAssCount:0,logLeadAssCount:0,logInTransCount:0,logRchdCount:0
}
export default function LogisticsRunnerDetailsReducer(state = intialState, action) {

  switch (action.type) {
    case LOGISTICS_RUNNER_DETAILS.REQUEST:
      return getNewState(state, { isLoading: true })

    case LOGISTICS_RUNNER_DETAILS.SUCCESS:
   
      const { allRunners, totalRunners,runnersOnDuty,allDocCollectors,docCompletedCount,docCollections,logUnCount,logAvCount,docAvCount,unAssCount,totLeadMappDoc,totLeadMapLog,
        docRchdCount, docInTransCount,docLeadAssCount,logLeadAssCount,logInTransCount,logRchdCount,docCollDone,logCollDone} = action.payload;
      return getNewState(state, { allRunners, totalRunners,runnersOnDuty,allDocCollectors,docCompletedCount,docCollections,logUnCount,logAvCount,docAvCount,unAssCount,totLeadMappDoc,totLeadMapLog,
        docRchdCount,docInTransCount,docLeadAssCount,logLeadAssCount,logInTransCount,logRchdCount,docCollDone,logCollDone})

    case LOGISTICS_RUNNER_DETAILS.FAILURE:
      const { error,logUnnCount,logAvvCount,docAvvCount,unAsssCount,totLeadMapppDoc,totLeadMappLog,
        docRchddCount, docInTranssCount,docLeadAsssCount,logLeadAsssCount,logInTranssCount,logRchddCount } = action.payload
      console.log("console console",action)
      return getNewState(state,  { error,logUnnCount,logAvvCount,docAvvCount,unAsssCount,totLeadMapppDoc,totLeadMappLog,
        docRchddCount, docInTranssCount,docLeadAsssCount,logLeadAsssCount,logInTranssCount,logRchddCount, allRunners:[],  allDocCollectors: [] })

    case LOGISTICS_RUNNER_DETAILS.RESET:
      return intialState

    default:
      return state
  }
}