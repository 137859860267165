import filterIcon from '../../shared/img/icons/filter-icon.svg'
import calenderIcon from '../../shared/img/icons/calender-icon.svg'
import searchIcon from '../../shared/img/icons/search-icon.svg'
import searchIconWhite from '../../shared/img/icons/search-white.svg'
import searchIconBlack from '../../shared/img/icons/searchIconBlack.svg'
import inventoryIcon from '../../shared/img/icons/inventory-icon.svg'
import logisticsIcon from '../../shared/img/icons/logistics-icon.svg'
import valuatorIcon from '../../shared/img/icons/valuator-icon.svg'
import downloadIcon from '../../shared/img/icons/download-icon.svg'
import fhdIcon from '../../shared/img/icons/fhd-icon.svg'
import shdIcon from '../../shared/img/icons/shd-icon.svg'
import dashboardIcon from '../../shared/img/icons/dashboard-icon.svg'
import credrProIcon from '../../shared/img/logo/logo.svg'
import closeIcon from '../../shared/img/icons/close-icon.svg'
import documentQcIcon from '../../shared/img/icons/document-qc-icon.svg'
import closeIconBlack from '../../shared/img/icons/close-black.svg'
import showroomLeads from '../../shared/img/icons/showroom-leads.svg'
import showroomSales from '../../shared/img/icons/showroom-sales.svg'
import postSales from '../../shared/img/icons/post-sales.svg'
import paymentsIcon from '../../shared/img/icons/payments.svg'
import plusIcon from '../../shared/img/icons/plus-icon.svg'
import callIcon from '../../shared/img/icons/call-icon.svg'
import refurbIcon from '../../shared/img/icons/refurb-dashboard.svg'
import performanceDbIcon from '../../shared/img/icons/performance-dashboard.svg'
import walletIcon from '../../shared/img/icons/Mywalleticon.svg'
import assignedInventoryIcon from '../../shared/img/icons/AssignedInventoryIcon.svg'
import liveInventoryIcon from '../../shared/img/icons/LiveInventoryIcon.svg'
import leadsandInvoicesIcon from '../../shared/img/icons/LeadsandInvoicesIcon.svg'
import paymentModeIcon from '../../shared/img/icons/PaymentModeIcon.svg'
import paymentHistoryIcon from '../../shared/img/icons/PaymentHistoryIcon.svg'
import returnsIcon from '../../shared/img/icons/ReturnsIcon.svg'
import pendingReturns from '../../shared/img/icons/PendingReturns.svg'
import businessIcon from '../../shared/img/icons/BusinessIcon.svg'
import editIcon from '../../shared/img/icons/edit.svg'
import WhiteCall from '../../shared/img/icons/WhiteCall.svg'
import infoIcon from '../../shared/img/icons/ep_info-filled.svg'
import refreshIcon from '../../shared/img/icons/refreshing.svg'
import whiteEditIcon from '../../shared/img/icons/edit_square.png'
import alertIcon from "../../shared/img/icons/alert-icon.svg"
import infoOutlinedIconBank from "../../shared/img/icons/info-outlined-icon-bank.svg"
import walletLogo from "../../shared/img/icons/walletLogo.png"
import pdfIcon from "../../shared/img/icons/PDFIcon.svg"
import closeIconLedger from '../../shared/img/icons/close-icon.svg'
import exportToExcel from '../../shared/img/icons/exportToExcel.svg'
import currentcreditIcon from '../../shared/img/icons/CurrentCreditLimit.svg'
import availableCreditIcon from '../../shared/img/icons/availableCreditLimit.svg'
import alertIconRed from '../../shared/img/icons/alert-icon.svg'
import alertIconWhite from '../../shared/img/icons/alert-icon-white.svg'
import callIconGreen from '../../shared/img/icons/call-icon-green.svg'
import thumbUpIcon from '../../shared/img/icons/thumb-up.svg'
import thumbDownIcon from '../../shared/img/icons/thumb-down.svg'
import thumbDownCircleIcon from '../../shared/img/icons/thumbs-down.svg'
import thumbUpCircleIcon from '../../shared/img/icons/thumbs-up.svg'
import chatIconSVG from '../../shared/img/icons/chat.svg'
import advancedFilterIcon from "../../shared/img/icons/AdvancedFilterIcon.svg"
import noAudioAvailable from '../../shared/img/icons/NoAudio.png'
import chatIcon from '../../shared/img/icons/chat.png'
import uploadIcon from '../../shared/img/icons/16 size.svg'
import communicationIcon from '../../shared/img/icons/communication.png'
import redError from "../../shared/img/icons/red_pending.svg"
import timeHistory from "../../shared/img/icons/time_history.svg"
import bankIconBlue from "../../shared/img/icons/info-bank-icon-blue.svg"
import wishListIcon from '../../shared/img/icons/Wishlist.png'
import runnerProfile from "../../shared/img/icons/runnerProfile.svg"
import channelPartnerMarker from "../../shared/img/icons/channelPartnerM.svg"
import hubMarker from "../../shared/img/icons/hubM.svg"
import inspectionMarker from "../../shared/img/icons/inspectionM.svg"
import warehouseMarker from "../../shared/img/icons/warehouseM.svg"
import shiftStartMarker from "../../shared/img/icons/shiftStartM.svg"
import shiftEndMarker from "../../shared/img/icons/shiftEndM.svg"
import bikePickupMarker from "../../shared/img/icons/bikePickup.svg"
import docUploadedMarker from "../../shared/img/icons/docUploadedM.svg"
import rejectedMarker from "../../shared/img/icons/rejectedM.svg"
import rescheduledMarker from "../../shared/img/icons/rescheduledM.svg"
import nearMeMarker from "../../shared/img/icons/nearMe.svg"
import reachedMarker from "../../shared/img/icons/reachedM.svg"
import priorityImage from "../../shared/img/icons/priority-selector.svg"
import home from '../../shared/img/icons/Home.svg'
import locationIcon from '../../shared/img/icons/location-icon.svg'
import relocate from '../../shared/img/icons/relocate.svg'
import icSharperror from '../../shared/img/icons/ic_sharp-error.svg'
import direction from '../../shared/img/icons/Direction.svg'
import searchIconM from '../../shared/img/icons/Search Icon.svg'
import callIconOGreen from "../../shared/img/icons/call_iconOGreen.svg"
import directionIconBlue from "../../shared/img/icons/directionIconBlue.svg"
import noTaskIcon from "../../shared/img/icons/noTaskIcon.svg"
import leadAssignedIcon from "../../shared/img/icons/leadAssigned.svg"
import leadBikeIcon from "../../shared/img/icons/leadBike.svg"
import leadNameIcon from "../../shared/img/icons/leadName.svg"
import leadDateIcon from "../../shared/img/icons/leadSlotC.svg"
import leadTimeIcon from "../../shared/img/icons/leadSlotT.svg"
import refreshGray from "../../shared/img/icons/refreshGray.svg"
import gpsOnIcon from "../../shared/img/icons/gpsOnIcon.svg"
import gpsOffIcon from "../../shared/img/icons/gpsOffIcon.svg"
import cross from '../../shared/img/icons/crossIcon.svg'
import storeFront from "../../shared/img/icons/storeOne.svg"
import viewAeHistoryIcon from "../../shared/img/icons/viewHistoryIcon.svg"
import credrImage from "../../shared/img/brand/credrImage.svg"
import RadioIcons from '../../shared/img/icons/radio-tower.svg'
import labelImportant from '../../shared/img/icons/label-important.svg'
import undoIcon from '../../shared/img/icons/undo.svg'
import Bangalore from '../../shared/img/icons/bangalore-city 1.svg'
import redCross from "../../shared/img/icons/RedCross.svg"
import tickGreen from "../../shared/img/icons/TickGreen.svg"
import timeHistoryBlueIcon from "../../shared/img/icons/TimeHistoryBlueIcon.svg"
import Filter_List from '../../shared/img/icons/filter_list.svg'
import yamaha from "../../shared/img/icons/Yamaha.svg";
import km from "../../shared/img/icons/km.svg";
import cc from "../../shared/img/icons/cc.svg";
import owner from "../../shared/img/icons/owner.svg";
import whatsapp from "../../shared/img/icons/whatsapp.svg"
import lock from "../../shared/img/icons/lock.svg"
import notesIcon from "../../shared/img/icons/NotesIcon.svg"
import notesIconNew from "../../shared/img/icons/NotesIcon_new.svg"



export const BengaloreIcon = Bangalore
export const filterList = Filter_List
export const RadioIcon = RadioIcons
export const crossIcon = cross 
export const searchIconMap = searchIconM
export const Direction = direction
export const icsharperror = icSharperror
export const homeIcon=home
export const emojiIcon = relocate
export const LocationIcon = locationIcon
export const EditIcon=editIcon
export const FilterIcon = filterIcon
export const CalenderIcon = calenderIcon
export const SearchIcon = searchIcon
export const SearchIconWhite = searchIconWhite
export const SearchIconBlack = searchIconBlack
export const InventoryIcon = inventoryIcon
export const LogisticsIcon = logisticsIcon
export const ValuatorIcon = valuatorIcon
export const DownloadIcon = downloadIcon
export const FHDIcon = fhdIcon
export const SHDIcon = shdIcon
export const DashboardIcon = dashboardIcon
export const CredRProLogo = credrProIcon
export const CloseIcon = closeIcon
export const DocumentQcIcon = documentQcIcon
export const CloseIconBlack = closeIconBlack
export const ShowroomLeads = showroomLeads
export const ShowroomSales = showroomSales
export const PostSales = postSales
export const PaymentsIcon = paymentsIcon
export const PlusIcon = plusIcon
export const CallIcon = callIcon
export const PerformanceDBIcon = performanceDbIcon
export const RefurbIcon = refurbIcon
export const WalletIcon = walletIcon
export const AssignedInventoryIcon = assignedInventoryIcon
export const LiveInventoryIcon = liveInventoryIcon
export const LeadsandInvoicesIcon = leadsandInvoicesIcon
export const PaymentModeIcon = paymentModeIcon
export const PaymentHistoryIcon = paymentHistoryIcon
export const ReturnsIcon = returnsIcon
export const PendingReturns = pendingReturns
export const BusinessIcon = businessIcon
export const WhiteCallIcon = WhiteCall
export const InfoIcon = infoIcon
export const RefreshIconLocal = refreshIcon
export const WhiteEditIcon = whiteEditIcon
export const AlertIconRed=alertIcon
export const InfoOutlinedIconBank=infoOutlinedIconBank
export const WalletLogo=walletLogo
export const PdfIcon = pdfIcon;
export const CloseIconLedger = closeIconLedger
export const ExportToExcel = exportToExcel
export const CurrentCreditIcon = currentcreditIcon
export const AvailableCreditIcon = availableCreditIcon
export const AlertIconWhite = alertIconWhite
export const CallIconGreen = callIconGreen
export const ThumbUpIcon = thumbUpIcon
export const ThumbDownIcon = thumbDownIcon
export const ThumbDownCircleIcon = thumbDownCircleIcon
export const ThumbUpCircleIcon = thumbUpCircleIcon
export const ChatIcon = chatIconSVG
export const AdvancedFilterIcon= advancedFilterIcon
export const NoAudioAvailable = noAudioAvailable
export const UploadIcon = uploadIcon
export const CommunicationIcon = communicationIcon
export const RedError=redError
export const TimeHistory=timeHistory
export const BankIconBlue=bankIconBlue
export const RunnerProfile=runnerProfile
export const ChannelPartner_Marker=channelPartnerMarker
export const Hub_Marker=hubMarker
export const Inspection_Marker=inspectionMarker
export const Warehouse_Marker=warehouseMarker
export const ShiftStart_Marker=shiftStartMarker
export const ShiftEnd_Marker=shiftEndMarker
export const BikePickup_Marker=bikePickupMarker
export const Rescheduled_Marker=rescheduledMarker
export const DocUploaded_Marker=docUploadedMarker
export const Rejected_Marker=rejectedMarker
export const NearMe_Marker=nearMeMarker
export const WishListIcon = wishListIcon
export const CallIconOGreen = callIconOGreen
export const DirectionIconBlue = directionIconBlue
export const NoTaskIcon = noTaskIcon
export const LeadAssignedIcon = leadAssignedIcon
export const LeadNameIcon = leadNameIcon
export const LeadBikeIcon = leadBikeIcon
export const LeadDateIcon = leadDateIcon 
export const LeadTimeIcon = leadTimeIcon
export const RefreshGray = refreshGray
export const GpsOnIcon = gpsOnIcon
export const GpsOffIcon = gpsOffIcon
export const Reached_Marker = reachedMarker
export const PriorityImage = priorityImage
export const StoreFront= storeFront
export const ViewAeHistoryIcon=viewAeHistoryIcon
export const CredrImage = credrImage
export const LabelImportant = labelImportant
export const UndoIcon = undoIcon
export const RedCross=redCross
export const TickGreen=tickGreen
export const TimeHistoryBlueIcon=timeHistoryBlueIcon
export const Yamaha = yamaha;
export const Cc = cc;
export const Owner = owner;
export const Km = km;
export const Whatsapp=whatsapp
export const Lock=lock
export const NotesIcon=notesIcon
export const NotesIconNew=notesIconNew
