import React, { useState, FC, useEffect, useRef } from 'react'
import { Modal, Grid, Row, Col, Form } from 'rsuite'
import Select from '../../../../../shared/components/redux-form-new/Select'
import TextArea from '../../../../../shared/components/redux-form-new/TextArea'
import { reduxForm, InjectedFormProps, Field, getFormValues, SubmissionError } from 'redux-form'
import { connect, ConnectedProps } from 'react-redux'
import validate from './validate'
// import { getReasonsQuote, updateStatus } from '../../../../../core/services/quoteConfServices'
import { getUserID } from '../../../../../core/services/rbacServices'
import { useDispatch } from "react-redux"
import { setNotification } from "../../../../../redux/actions/notificationAction"
import { reset } from 'redux-form';
import { getBuyerStatus, updateStatusC2CBuyer } from '../../../../../core/services/C2cServices'
import { capaitalize } from '../../../../../core/utility'
import { interestedBuyerAction } from '../../../../../redux/actions/interestedBuyersAction'
import { getShdOrderDeduction } from '../../../../../redux/actions/shdOrderDeductionAction'


const connector = connect()


function UpdateDropStatus({ onClose, open, handleSubmit, rowInfo, onRefreshRow,forInterestedTab,setOpenTranferDialog,statusList,leadData }) {
    // const [activeClose, setActiveClose] = useState(false)
    // const [comments, setComments] = useState('')
    // const [reasonList, setReasonList] = useState(statusList)
    const [reason, setReason] = useState('')
    const [error,setError]=useState(false)
    const dispatch = useDispatch()
    
    // useEffect(() => {
    //     if(forInterestedTab){
    //             // Run only during the initial render (form initialization)
    //             // console.log('Initialization logic here');
    //             getBuyerStatus()
    //             .then(apiRes => {
    //                 if (apiRes.isValid) {
    //                     const reasonList = apiRes.buyersStatusList.map(reason => {
    //                         return {
    //                             value: reason.csId,
    //                             label: reason.status
    //                         }
    //                     })
    //                     console.log("reasonList",reasonList)
    //                     setReasonList(reasonList)
    //                 }
    //             })
                
    //             console.log(forInterestedTab,"forInterestedTab aakash")
    //             // initialized.current = true;
    // }
            
            
    //     // dispatch(reset('quote-drop-status'))
    //     // console.log(rowInfo,"rowInfo aakash update")
    // }, [forInterestedTab])

    const getDelta = (formData) => {
        const delta = {}
        if (formData.status) {
            delta.status = formData.status
        }
        if (formData.leadId) {
            delta.leadId = formData.leadId
        }
        // if (formData.comments) {
        //     delta.comments = formData.comments
        // }
        // if (formData.reasonId) {
        //     delta.reasonId = formData.reasonId
        // }

        return delta
    }

    const closePopUp = () => {
        // setActiveClose(true)
        onClose()
        dispatch(reset('quote-drop-status'));
    }

    const handleUpdate = handleSubmit(formData => handleSubmitDetails(formData))

    const labelReason = () => {
        return (
            <>
                Select Reason <span style={{ color: 'red' }}>*</span>
            </>
        )
    }

    // const handleCommentChange = (e) => {
    //     setComments(e)
    // }

    const handleReasonChange = (reason) => {
        setReason(reason)
    }

    const handleSubmitDetails = (data) => {
        let delta = getDelta(data)
        if (
          ["Under_Negotitation", "Deal_Locked"].includes(delta.status) &&
          (!Boolean(leadData.buyerIntrestedPrice))
        ) {
          if (!Boolean(leadData.buyerIntrestedPrice)) {
            setError("Please Enter Interested Price");
          } 
        } 
        else if(delta.status==="Deal_Locked" && !Boolean(leadData.buyerEmailId && leadData.buyerEmailId.length)){
            setError("Please Enter Buyer Email Id");
        }
        else {
          delta.userId = getUserID();
          delta.crmLeadId = leadData.buyerLeadId;
          delta.leadId = rowInfo.leadId;
          // delta.status = 'DROP'
          if (delta.status === "C2C_Transfer") {
            setOpenTranferDialog({ c2cBuyerLeadId: leadData.buyerLeadId });
          } else if (Object.keys(delta).length) {
            if (delta.status !== null) {
              updateStatusC2CBuyer(delta).then((apiRes) => {
                if (apiRes.isValid) {
                  dispatch(
                    setNotification(
                      "success",
                      "Status Updated Successfully",
                      "Success"
                    )
                  );
                  onClose();
                  // onRefreshRow()
                  getDelta({});
                  dispatch(reset("quote-drop-status"));
                  const interestedBuyerPayload = {
                    inventoryId: rowInfo.inventoryId,
                    pageNum: 1,
                  };
                  dispatch(interestedBuyerAction(interestedBuyerPayload));
                  if(delta.status==="Deal_Locked"){
                    dispatch(getShdOrderDeduction(rowInfo.leadId))
                  }
                   
                  
                } else {
                  dispatch(setNotification("danger", apiRes.message,"Error"));
                  console.log("c2c row lead=",rowInfo.leadId)
                }
              });
            }
          }
        }
        
    }

    return (
        <Form onSubmit={handleSubmit(formData => handleSubmitDetails(formData))}>
            <Modal backdrop={true} keyboard={false} onHide={closePopUp} show={open} size="xs">
                <Modal.Header closeButton style={{ textAlign: 'center' }}>
                    <Modal.Title style={{ color: '#333333', fontFamily: 'ProximaNovaSemibold' }}>Update Status for<br/>{capaitalize(leadData.buyerName)} </Modal.Title>
                    <Modal.Title style={{ color: '#333333', fontFamily: 'ProximaNovaSemibold' }}>Buyer Lead ID: {leadData.buyerLeadId} </Modal.Title>
                </Modal.Header>
                <hr style={{ marginBottom: '10px' }} />
                <Modal.Body style={{ paddingBottom: 0, marginTop: '20px' }}>
                    <Grid fluid>
                        <Row style={{ marginLeft: '' }}>
                            <Col style={{ paddingLeft: '20px', paddingRight: '20px', marginTop: '15px' }}>
                                <Field
                                    name="status"
                                    component={Select}
                                    cleanable={false}
                                    options={statusList}
                                    searchable={false}
                                    label={labelReason()}
                                    placeholder="Select Status"
                                    onChange={handleReasonChange}
                                    value={reason}
                                />
                            </Col>
                            {/* <Col sm={12} style={{ paddingLeft: '20px', paddingRight: '20px', marginTop: '15px' }}>
                                <Field
                                    style={{ width: '320px' }}
                                    name="comments"
                                    component={TextArea}
                                    label="Comments"
                                    placeholder="Enter Message"
                                    onChange={handleCommentChange}
                                    className="commentError"
                                />
                            </Col> */}
                            <Col style={{ paddingLeft: '20px', paddingRight: '20px', marginTop: '15px' }}>
                                {error && <p style={{color:"red"}}><b>{error}</b></p>}

                            </Col>
                        </Row>
                    </Grid>
                </Modal.Body>
                <br />

                <Modal.Footer>
                    
                    <button style={{ marginRight: 15, color: '#ffffff', padding: '8px 20px', borderRadius: '22px', backgroundColor: '#4DBD74', border: '1px solid #35AC5E' }} onClick={handleUpdate}>Update</button>
                </Modal.Footer>
            </Modal>
        </Form>
    )
}

const ReduxForm = reduxForm({
    form: 'quote-drop-status',
    validate,
})(UpdateDropStatus)

export default connector(ReduxForm)
