import React ,{useState}from 'react'
import {Box, Card, CardActions, CardContent, makeStyles, Table, TableCell, TableContainer, TableHead, TableRow, Typography} from '@material-ui/core';
import {useStyles} from '../LeadDetails/index'
import { getDate, renderDate, renderDateSlot, renderString } from '../../../../../core/utility';
import './style.scss'
import { renderDateTime, renderTime } from '../../../../../core/utility/stringUtility';
import CollectDocModel from './CollectDocModel.jsx'
import ImageViewer from '../../../../../shared/components/ImageViewer';
import Viewer from 'react-viewer';


export default function FhdStoreDetails({customerDocuments, LeadInfoCustomer, setCollectModel, collectModel }) {
    const {storeDetails,userBankDocs,storeAccountDetails} = LeadInfoCustomer
if(customerDocuments) var {bankDetails, collectDocumentList, documents, leadExceptions} = customerDocuments

    const [imageOpen, setImageOpen] = useState(false);
    const [imageUrlView, setImageUrlView] = useState([{ src: null }]);

     const useStyles = makeStyles({
        root: {
            // width: '29rem',
            marginBottom: '2rem',
            marginRight: '2rem',
            borderRadius: '0.5rem'
        },
        table: {
            '& .MuiTableCell-root': {
                borderBottom: 'none',

                whiteSpace: 'nowrap',
            },
        },
        cell: {
            color: 'rgba(0, 0, 0, 0.5)',
            padding: '0px 30px 0px 0px',
        },
    })

    const classes = useStyles()

    console.log(customerDocuments,'customerDocuments')

    const closeImageViewer = () => {
        setImageOpen(false);
        setImageUrlView([{ src: null }])
      };

      const renderStatus = (status)=> {
        if(status ==="PENDING") return (
            <span style={{color:'#D78101'}}>Pending</span>
        )
        else
        return (
            <span style={{color:'#47B26C'}}>Completed</span>
        )
      }
    return (
        <Box style={{display:"flex", justifyContent:'start', overflowX:'auto'}}>
        <div>
        {
        collectDocumentList && collectDocumentList[0] 
        && 
        <Card className={classes.root} >
            <CardContent>
                <Typography variant='h6' component='h2'>Details </Typography>
                <Table className={classes.table}>
                    <TableContainer >
                        <TableHead >
                            <TableRow>
                                <TableCell className={classes.cell}>Requested Date</TableCell>
                                <TableCell >{(collectDocumentList[0] && collectDocumentList[0].requestedDate) ? renderDateTime(collectDocumentList[0].requestedDate) : 'NA'}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.cell}>Status</TableCell>
                                <TableCell >{(collectDocumentList[0] && collectDocumentList[0].status) ? renderStatus(collectDocumentList[0].status) : 'NA'}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.cell}>Completed Date</TableCell>
                                <TableCell >{(collectDocumentList[0] && collectDocumentList[0].completedDate) ? renderDateTime(collectDocumentList[0].completedDate): 'NA'}</TableCell>
                            </TableRow>
                        </TableHead>
                    </TableContainer>
                </Table>
            </CardContent>
        </Card>
        }
        {
            (bankDetails && bankDetails[0] && bankDetails[0].accountType === "BANK_ACCOUNT" )
            && 
            <Card className={classes.root} >
            <CardContent>
                <Typography variant='h6' component='h2'>KYC & Account Details </Typography>
                <Table className={classes.table}>
                    <TableContainer >
                        <TableHead >
                            <TableRow>
                                <TableCell className={classes.cell}>RC Name</TableCell>
                                <TableCell >{renderString(bankDetails[0].rcName)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.cell}>Beneficiary Name</TableCell>
                                <TableCell style={{maxWidth:'150px'}}>{ renderString(bankDetails[0].razorPayName)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.cell}>Bank Name</TableCell>
                                <TableCell >{ renderString(bankDetails[0].bankName)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.cell}>Branch Name</TableCell>
                                <TableCell >{ renderString(bankDetails[0].branchName)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.cell}>Account Number</TableCell>
                                <TableCell >{ renderString(bankDetails[0].accountNumber)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.cell}>IFSC Code</TableCell>
                                <TableCell >{ renderString(bankDetails[0].ifscCode)}</TableCell>
                            </TableRow>
                        </TableHead>
                    </TableContainer>
                </Table>
            </CardContent>
            </Card>
        }
        {
             (bankDetails && bankDetails[0] && bankDetails[0].accountType === "VPA" )
             &&
             <Card className={classes.root} >
             <CardContent>
                 <Typography variant='h6' component='h2'>KYC & Account Details </Typography>
                 <Table className={classes.table}>
                     <TableContainer >
                         <TableHead >
                             <TableRow>
                                 <TableCell className={classes.cell}>RC Name</TableCell>
                                 <TableCell >{renderString(bankDetails[0].rcName)}</TableCell>
                             </TableRow>
                             <TableRow>
                                 <TableCell className={classes.cell}>UPI Address</TableCell>
                                 <TableCell >{renderString(bankDetails[0].upi)}</TableCell>
                             </TableRow>
                             <TableRow>
                                 <TableCell className={classes.cell}> UPI Namew</TableCell>
                                 <TableCell >{renderString(bankDetails[0].razorPayName)}</TableCell>
                             </TableRow>
                         </TableHead>
                     </TableContainer>
                 </Table>
             </CardContent>
         </Card>
        }
        </div>
        
       {documents && documents.length>0  && <div style={{minWidth:'300px'}}>
        <Card className={classes.root} >
            <CardContent>
                <Typography variant='h6' component='h2'>{`Documents\u00a0&\u00a0Images`}</Typography>

                { documents.map((doc, index) => {

                    return <div className="doc-container" key={index+100}>
                        <div style={{display:'flex', alignItems:'center'}}>

                    <Typography style={{ color: '#757575', fontWeight:500}} variant='body1'>{doc.documentName}</Typography>
                   {
                       doc.documentType &&  <span style={{background: '#D5D3D3', borderRadius: '12px', padding: '4px 8px', fontSize:'9px', fontWeight:500, marginLeft:'10px'}}>{doc.documentType}</span>
                    }
                    </div>
                    <div style={{
                        display: 'flex',
                        flexWrap: 'wrap'
                    }}>

                    {
                        !doc.images ? 
                        
                        doc.imageUrl.map(imgUrl =>{
                            return  <div className='doc-preview'
                            style={{ padding: '0.5rem', cursor: 'pointer', height: '5rem', width: '6rem' }}
                            onClick={() => {
                                setImageUrlView([{ src: doc.imageUrl}])
                                 setImageOpen(true)}
                            }
                        >
                            <img
                                src={imgUrl}
                                alt={doc.documentName}
                                style={{ width: '100%', height: '100%', borderRadius: '0.5rem' }}
                            />
                        </div>
                        })
                       
                    :
                    doc.images.map(img =>{
                        return   <div className='doc-preview'
                        style={{ padding: '0.5rem', cursor: 'pointer', height: '5rem', width: '6rem', display:'inline-block' }}
                        key={(301+index)*Math.random(3)}
                        onClick={() => {
                            setImageUrlView([{ src: img.leadParameterValue}])
                             setImageOpen(true)}
                        }
                    >
                        <img
                            src={img.leadParameterValue}
                    alt={doc.documentName}
                            style={{ width: '100%', height: '100%', borderRadius: '0.5rem' }}
                        />
                    </div>
                    })
                }
                
                    </div>
                </div>
                })
              
}
            </CardContent>
        </Card>
        {imageOpen && (
            <Viewer
        visible={imageOpen}
        images={imageUrlView}
        activeIndex={0}
        onClose={closeImageViewer}
        changeable = {false}
      />
          )}
        </div>
        }
        
            <div>
            {
            leadExceptions && leadExceptions[0] && 
            <Card className={classes.root} >
                <CardContent>
                    <Typography variant='h6' component='h2'>Approved Exception </Typography>
                    <Table className={classes.table}>
                        <TableContainer >
                            <TableHead >
                                <TableRow>
                                    <TableCell className={classes.cell}>Date</TableCell>
                                    <TableCell style={{maxWidth:'150px'}}>{renderDateTime(leadExceptions[0].createdDate)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.cell}>Approved By</TableCell>
                                    <TableCell >{renderString(leadExceptions[0].approvedBy)}</TableCell>
                                </TableRow>
                                <br />
                                <h6>Exception</h6>
    
                            {
                                leadExceptions[0].leadException.map((exception,i) =>{
    
                                    return <TableRow key={i+50}>
                                    <TableCell className={classes.cell}>{exception}</TableCell>
                                </TableRow>
                                })
                            }
                                 {/* <br/> */}
                                 {/* <h6>Comments</h6> */}
    
                                {/* <TableRow> */}
                                    {/* <TableCell className={classes.cell}> */}
                                        {/* {leadExceptions[0].comments} */}
                                        {/* </TableCell> */}
                                       {/* <p> {leadExceptions[0].comments}</p>  */}
                                {/* </TableRow> */}
                                
                            </TableHead>
                        </TableContainer>
                    </Table>
                    <br/>
                    <h6>Comments</h6>
                    <p style={{opacity:0.4,  fontWeight: 600}}> {leadExceptions[0].comments}</p> 
                </CardContent>
            </Card>
              }
            </div>
        </Box>
    )
}