import {
  Chip,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import ViewBreakup from "./ViewBreakup";
import {
  getDate,
  renderString,
} from "../../../../../core/utility/stringUtility";
import ViewHistoryDrawer from "./Drawer";
import ViewReason from "../../../../DocumentQC-CV/components/ViewReason";
import { EditIcon } from "../../../../../core/utility/iconHelper";
import RescheduleDialog from "./rescheduleDialog";
import { getUserID } from "../../../../../core/services/rbacServices";
import { runnerRescheduleDateTime } from "../../../../../core/services/logisticServices";
import { useDispatch } from "react-redux";
import "./refundTable.scss";
import { setNotification } from "../../../../../redux/actions/notificationAction";
import {
  hideLoader,
  showLoader,
} from "../../../../../redux/actions/loaderAction";
import { leadLifecycleDetails } from "../../../../../redux/actions/biddingLifecycleAction";
import { getLogisticsHistory } from "../../../../../core/services/logisticServices";
import DeliveryTimeHistory from "../../../../Logistics/VehicleStatus/components/LogisticsHistory";
import { TimeHistory } from "../../../../../core/utility/iconHelper";

import {
  LabelImportant,
  UndoIcon,
} from "../../../../../core/utility/iconHelper";
import { setPriority } from "../../../../../core/services/miscServices";

export default function LeadLifecycle({
  leadLifeInfoCustomer,
  rowInfo,
  status,
  showStore,
  paymentData,
}) {
  const {
    channelPartnerResponseBean,
    documentsResponse,
    logisticsResponseBean,
    payoutDetailsResponseBean,
    soldDataResponseBean,
    holdBean,
    docCollectorResponse,
  } = leadLifeInfoCustomer;

  const useStyles = makeStyles((theme) => ({
    tableHeader: {
      height: "2rem",
      backgroundColor: "#333333",
      color: "white",
      fontWeight: "500",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    approvechip: {
      backgroundColor: "#DAF2DD",
      color: "#196834",
      height: "1.5rem",
      width: "8rem",
      fontWeight: "500",
      borderRadius: "5px",
    },
    pendingchip: {
      backgroundColor: "#FFE1C5",
      color: "#B96716",
      height: "1.5rem",
      width: "8rem",
      fontWeight: "500",
      borderRadius: "5px",
    },
    modalButton: {
      textAlign: "right",
      borderBottom: "1px solid #87AACA",
      color: "#87AACA",
      fontSize: "smaller",
      fontWeight: "600",
      cursor: "pointer",
      position: "relative",
      left: "130%",
    },
    reasonButton: {
      textAlign: "right",
      borderBottom: "1px solid #87AACA",
      color: "#87AACA",
      fontSize: "smaller",
      fontWeight: "600",
      cursor: "pointer",
      position: "relative",
      // left: "130%",
    },
    container: {
      display: "flex",
      justifyContent: "flex-start",
      flexWrap: "wrap",
      gap: "2rem",
      "& .MuiTableCell-root": {
        borderBottom: "none",
      },
    },
    leftCell: {
      color: "rgba(0, 0, 0, 0.5)",
      fontWeight: "500",
      width: "50%",
    },
    delayChip: {
      backgroundColor: "#FFD4D6",
      color: "#DA2128",
      borderRadius: "2px",
      width: "7.2rem",
      fontSize: "smaller",
      fontWeight: "500",
      height: "1.1rem",
      position: "relative",
      // top: '1rem',
      right: "-0.2rem",
      // marginBottom:'2px'
    },
  }));
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  // const [openRefundToWallet,setOpenRefundToWallet]=React.useState(false);
  const [openRefundHistory, setOpenRefundHistory] = React.useState(false);

  const [isResonOpen, setIsReasonOpen] = useState(false)
  const [reasonData, setReasonData] = useState({})
  const [reasonsDocQC, setReasonDocQC] = useState({})
  const [reasonLogistic, setReasonLogistic] = useState({})
  const [openTimeDialog, setOpenTimeDialog] = useState(false)
  const [rescheduleDateTime, setRescheduleDateTime] = useState({ date: "", time: "" })
  const [rescheduleComments, setRescheduleComments] = useState("")
  const dispatch = useDispatch()
  const [openHistory, setOpenHistory] = useState(false)
  const [historyData, setHistoryData] = useState([])
  const [show, setShow] = useState(
    soldDataResponseBean && soldDataResponseBean.priorityLead
      ? soldDataResponseBean.priorityLead === "YES"
        ? true
        : false
      : false
  );


  useEffect(() => {
    if (documentsResponse && (documentsResponse.docStatus === "DISPUTE" || documentsResponse.docStatus === "REJECTED")) {
      setReasonDocQC(documentsResponse)
    }
  }, [documentsResponse]);

  useEffect(() => {
    if (logisticsResponseBean && (logisticsResponseBean.logisticsStatus === "REJECTED" || logisticsResponseBean.logisticsStatus === "RESCHEDULED")) {
      setReasonLogistic({
        reasonForRejected: logisticsResponseBean.logReason ? logisticsResponseBean.logReason : '',
        remarks: logisticsResponseBean.logMessage ? logisticsResponseBean.logMessage : '',
      })
    }
  }, [logisticsResponseBean]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenHistory = () => {
    setOpenRefundHistory(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseRefundHistory = () => {
    setOpenRefundHistory(false);
  };

  const onHandleSubmitReschedule = () => {

    const payload = {
      leadId: rowInfo.leadId,
      runnerId: logisticsResponseBean.runnerId,
      reasonName: "Reschedule",
      scheduleTiming: rescheduleDateTime.date + " " + rescheduleDateTime.time,
      comments: rescheduleComments,
      updatedBy: getUserID()
    }
    dispatch(showLoader())
    runnerRescheduleDateTime(payload).then(
      (apiResonse => {
        if (apiResonse.isValid) {
          dispatch(setNotification('success', 'SUCCESS', apiResonse.message))
          dispatch(hideLoader())
          dispatch(leadLifecycleDetails(rowInfo.leadId))
        }
        else {
          dispatch(setNotification('danger', 'ERROR', apiResonse.message))
          dispatch(hideLoader())
        }
      })
    )
    setOpenTimeDialog(false)
    setRescheduleDateTime({ date: "", time: "" })
    setRescheduleComments("")

  }

  const onUndoPriority = (data) => {
    const payload = {
      leadId: data.leadId,
      undoPriority: "TRUE",
    };
    setPriority(payload).then((apiResponse) => {
      if (apiResponse.isValid) {
        setShow(false);
        dispatch(setNotification("success", "SUCCESS", apiResponse.message));
      }
    });
  };

  const onSetPriority = (data) => {
    const payload = {
      leadId: data.leadId,
      makePriority: "TRUE",
    };
    setPriority(payload).then((apiResponse) => {
      if (apiResponse.isValid) {
        setShow(true);
        dispatch(setNotification("success", "SUCCESS", apiResponse.message));
      }
    });
  };

  const getStatus = (lifecycleStatus) => {
    if (lifecycleStatus === "APPROVED") {
      return (
        <span
          style={{
            backgroundColor: "#DAF2DD",
            color: "#196834",
            padding: "1px 15px",
            borderRadius: "4px",
            fontWeight: "500",
          }}
        >
          Approved
        </span>
      );
    }
    if (lifecycleStatus === "PENDING") {
      return (
        <span
          style={{
            backgroundColor: "#FFE1C5",
            color: "#B96716",
            padding: "1px 15px",
            borderRadius: "4px",
            fontWeight: "500",
          }}
        >
          Pending
        </span>
      );
    }
    if (lifecycleStatus === "REJECTED") {
      return (
        <span
          style={{
            backgroundColor: "#FFD4D6",
            color: "#DA2128",
            padding: "1px 15px",
            borderRadius: "4px",
            fontWeight: "500",
          }}
        >
          Rejected
        </span>
      );
    }
    if (lifecycleStatus === "SELL") {
      return (
        <span
          style={{
            backgroundColor: "#DAF2DD",
            color: "#196834",
            padding: "1px 15px",
            borderRadius: "4px",
            fontWeight: "500",
          }}
        >
          Sell
        </span>
      );
    }
    if (lifecycleStatus === "EXCHANGE") {
      return (
        <span
          style={{
            backgroundColor: "#DAF2DD",
            color: "#196834",
            padding: "1px 15px",
            borderRadius: "4px",
            fontWeight: "500",
          }}
        >
          Exchange
        </span>
      );
    }
    if (lifecycleStatus === "ONGOING") {
      return (
        <span
          style={{
            backgroundColor: "#E9F4FF",
            color: "#366896",
            padding: "1px 15px",
            borderRadius: "4px",
            fontWeight: "500",
          }}
        >
          {" "}
          Ongoing{" "}
        </span>
      );
    } else {
      return (
        <span
          style={{
            backgroundColor: "#E9F4FF",
            color: "#366896",
            padding: "1px 15px",
            borderRadius: "4px",
            fontWeight: "500",
          }}
        >
          {" "}
          {lifecycleStatus}{" "}
        </span>
      );
    }
  };

  const getStatusDoc = (lifecycleStatus) => {
    if (lifecycleStatus === "INTRANSIT") {
      return (
        <span
          style={{
            backgroundColor: "#D8EAFF",
            color: "#366896",
            padding: "1px 15px",
            borderRadius: "4px",
            fontWeight: "500",
          }}
        >
          Intranist
        </span>
      );
    }
    if (lifecycleStatus === "DELIVERED") {
      return (
        <span
          style={{
            backgroundColor: "#D3FFE2",
            color: "#0CBA47",
            padding: "1px 15px",
            borderRadius: "4px",
            fontWeight: "500",
          }}
        >
          Delivered
        </span>
      );
    }
    if (lifecycleStatus === "ASSIGNED") {
      return (
        <span
          style={{
            backgroundColor: "#E9CDFF",
            color: "#9036D6",
            padding: "1px 15px",
            borderRadius: "4px",
            fontWeight: "500",
          }}
        >
          Assigned
        </span>
      );
    }
    if (lifecycleStatus === "PENDING_PICKUP") {
      return (
        <span
          style={{
            backgroundColor: "#FFEED1",
            color: "#F49F0A",
            padding: "1px 15px",
            borderRadius: "4px",
            fontWeight: "500",
          }}
        >
          Pending Pickup
        </span>
      );
    }
    if (lifecycleStatus === "RESCHEDULE") {
      return (
        <span
          style={{
            backgroundColor: "#D9DCFF",
            color: "#3B429F",
            padding: "1px 15px",
            borderRadius: "4px",
            fontWeight: "500",
          }}
        >
          Reschedule
        </span>
      );
    }
    if (lifecycleStatus === "DELIVERY_CONFIRM_PENDING") {
      return (
        <span
          style={{
            backgroundColor: "#FFDDCF",
            color: "#FD7A42",
            padding: "1px 15px",
            borderRadius: "4px",
            fontWeight: "500",
          }}
        >
          {" "}
          Delivery Confirm Pending{" "}
        </span>
      );
    }
    if (lifecycleStatus === "ARRIVED") {
      return (
        <span
          style={{
            backgroundColor: "#CAFFCF",
            color: "#12A020",
            padding: "1px 15px",
            borderRadius: "4px",
            fontWeight: "500",
          }}
        >
          {" "}
          Arrived{" "}
        </span>
      );
    }
    if (lifecycleStatus === "PENDING") {
      return (
        <span
          style={{
            backgroundColor: "#FFEED1",
            color: "#F49F0A",
            padding: "1px 15px",
            borderRadius: "4px",
            fontWeight: "500",
          }}
        >
          {" "}
          Pending{" "}
        </span>
      );
    } else {
      return (
        <span
          style={{
            backgroundColor: "#E9F4FF",
            color: "#366896",
            padding: "1px 15px",
            borderRadius: "4px",
            fontWeight: "500",
          }}
        >
          {" "}
          {lifecycleStatus}{" "}
        </span>
      );
    }
  };

  const showHistoryPopup = (info) => {
    getLogisticsHistory(info.leadId).
      then(apiResponse => {
        if (apiResponse.isValid) {
          setOpenHistory(true)
          setHistoryData(apiResponse.historyResp)
        }
        else {
          dispatch(setNotification('danger', 'ERROR', apiResponse.message))
        }
      })
  }

  const onCloseHistory = () => {
    setOpenHistory(false)
  }

  return (
    <>
      <div className={classes.container}>
        <div style={{ display: "flex", gap: "2rem" }}>
          <TableContainer
            component={Paper}
            style={{ width: "25rem", borderRadius: "13px" }}
          >
            <Typography variant="subtitle1" className={classes.tableHeader}>
              DOC QC
            </Typography>
            <Table size="small">
              <TableBody>
                {documentsResponse && (
                  <>
                    <TableRow>
                      <TableCell className={classes.leftCell}>
                        DOC QC Status
                      </TableCell>
                      <TableCell>
                        {documentsResponse &&
                          documentsResponse.docStatus &&
                          getStatus(documentsResponse.docStatus)}
                      </TableCell>
                    </TableRow>
                    {((documentsResponse &&
                      documentsResponse.docStatus === "DISPUTE") ||
                      (documentsResponse &&
                        documentsResponse.docStatus === "REJECTED")) && (
                        <TableRow>
                          <TableCell className={classes.leftCell}>
                            Reason
                          </TableCell>
                          <TableCell>
                            <span
                              className={classes.reasonButton}
                              onClick={() => {
                                setReasonData(reasonsDocQC);
                                setIsReasonOpen(true);
                              }}
                            >
                              View Reason
                            </span>
                          </TableCell>
                        </TableRow>
                      )}
                    <Typography
                      variant="subtitle2"
                      style={{ margin: "0 1rem" }}
                    >
                      DOC QC1
                    </Typography>
                    <TableRow>
                      <TableCell className={classes.leftCell}>
                        Agent Name
                      </TableCell>
                      <TableCell>
                        {renderString(documentsResponse.docQc1DoneBy)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.leftCell}>
                        Approval Date
                      </TableCell>
                      <TableCell>
                        {getDate(documentsResponse.docQc1DoneDate)}
                      </TableCell>
                    </TableRow>
                    <Typography
                      variant="subtitle2"
                      style={{ margin: "0 1rem" }}
                    >
                      DOC QC2
                    </Typography>
                    <TableRow>
                      <TableCell className={classes.leftCell}>
                        Agent Name
                      </TableCell>
                      <TableCell>
                        {renderString(documentsResponse.docQc2DoneBy)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.leftCell}>
                        Approval Date
                      </TableCell>
                      <TableCell>
                        {getDate(documentsResponse.docQc2DoneDate)}
                      </TableCell>
                    </TableRow>
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer
            component={Paper}
            style={{ width: "25rem", borderRadius: "13px" }}
          >
            <Typography variant="subtitle1" className={classes.tableHeader}>
              PAYOUT
            </Typography>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell className={classes.leftCell}>
                    Payout Status
                  </TableCell>
                  <TableCell>
                    {payoutDetailsResponseBean &&
                      payoutDetailsResponseBean.payoutStatus &&
                      getStatus(payoutDetailsResponseBean.payoutStatus)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.leftCell}>
                    Payment Mode
                  </TableCell>
                  <TableCell>
                    {payoutDetailsResponseBean &&
                      payoutDetailsResponseBean.paymentMode
                      ? renderString(payoutDetailsResponseBean.paymentMode)
                      : "-"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.leftCell}>User Name</TableCell>
                  <TableCell>
                    {payoutDetailsResponseBean &&
                      payoutDetailsResponseBean.payoutDoneBy
                      ? renderString(payoutDetailsResponseBean.payoutDoneBy)
                      : "-"}
                  </TableCell>
                </TableRow>
                <Typography variant="subtitle2" style={{ margin: "0 1rem" }}>
                  Payout
                </Typography>
                <TableRow>
                  <TableCell className={classes.leftCell}>
                    Initiation Date
                  </TableCell>
                  <TableCell>
                    {payoutDetailsResponseBean &&
                      payoutDetailsResponseBean.payoutRequestedDate
                      ? getDate(payoutDetailsResponseBean.payoutRequestedDate)
                      : "-"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.leftCell}>
                    Completion Date
                  </TableCell>
                  <TableCell>
                    {payoutDetailsResponseBean &&
                      payoutDetailsResponseBean.paymentDoneDate
                      ? getDate(payoutDetailsResponseBean.paymentDoneDate)
                      : "-"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.leftCell}>UTR</TableCell>
                  <TableCell>
                    {payoutDetailsResponseBean && payoutDetailsResponseBean.utr
                      ? renderString(payoutDetailsResponseBean.utr)
                      : "-"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div style={{ display: "flex", gap: "2rem" }}>
          <TableContainer
            component={Paper}
            style={{ width: "25rem", borderRadius: "13px" }}
          >
            <Typography variant="subtitle1" className={classes.tableHeader}>
              LOGISTICS
            </Typography>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell className={classes.leftCell}>
                    Logistics Status
                  </TableCell>
                  <TableCell>
                    {logisticsResponseBean &&
                      logisticsResponseBean.logisticsStatus &&
                      getStatus(logisticsResponseBean.logisticsStatus)}
                  </TableCell>
                </TableRow>
                {logisticsResponseBean &&
                  (logisticsResponseBean.logisticsStatus === "REJECTED" ||
                    logisticsResponseBean.logisticsStatus ===
                    "RESCHEDULED") && (
                    <TableRow>
                      <TableCell className={classes.leftCell}>Reason</TableCell>
                      <TableCell>
                        <span
                          className={classes.reasonButton}
                          onClick={() => {
                            setReasonData(reasonLogistic);
                            setIsReasonOpen(true);
                          }}
                        >
                          View Reason
                        </span>
                      </TableCell>
                    </TableRow>
                  )}
                <TableRow>
                  <TableCell className={classes.leftCell}>
                    In-Custody Status
                  </TableCell>
                  <TableCell>
                    {logisticsResponseBean &&
                      logisticsResponseBean.inCustodyStatus &&
                      getStatus(logisticsResponseBean.inCustodyStatus)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.leftCell}>
                    Logistics Coordinator
                  </TableCell>
                  <TableCell>
                    {logisticsResponseBean &&
                      logisticsResponseBean.coordinatorName
                      ? renderString(
                        logisticsResponseBean &&
                        logisticsResponseBean.coordinatorName
                      )
                      : "-"}
                  </TableCell>
                </TableRow>
                {/* <TableRow>
                  <TableCell className={classes.leftCell}>
                    Runner Name
                  </TableCell>
                  <TableCell>
                    {logisticsResponseBean && logisticsResponseBean.runnerName
                      ? renderString(
                          logisticsResponseBean &&
                            logisticsResponseBean.runnerName
                        )
                      : "-"}
                  </TableCell>
                </TableRow> */}
                <TableRow>
                  <TableCell className={classes.leftCell}>
                    Schedule Date
                  </TableCell>
                  <TableCell style={{ display: "flex" }}>
                    <div>
                      {logisticsResponseBean &&
                        logisticsResponseBean.scheduledDate
                        ? getDate(
                          logisticsResponseBean &&
                          logisticsResponseBean.scheduledDate
                        )
                        : "-"}
                      {logisticsResponseBean &&
                        logisticsResponseBean.delayedDays && (
                          <Chip
                            className={classes.delayChip}
                            label={`Delayed by ${renderString(
                              logisticsResponseBean &&
                              logisticsResponseBean.delayedDays
                            )}d`}
                          />
                        )}
                    </div>
                    {logisticsResponseBean &&
                      logisticsResponseBean.scheduledDate && <span style={{ cursor: "pointer" }}>
                        <img
                          src={EditIcon}
                          height="15px"
                          style={{ width: "2rem" }}
                          onClick={() => setOpenTimeDialog(true)}
                        />{" "}
                      </span>}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.leftCell}>
                    In-transit Date
                  </TableCell>
                  <TableCell>
                    {logisticsResponseBean && logisticsResponseBean.pickedUpDate
                      ? getDate(
                        logisticsResponseBean &&
                        logisticsResponseBean.pickedUpDate
                      )
                      : "-"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.leftCell}>
                    Delivery Date
                  </TableCell>
                  <TableCell>
                    {logisticsResponseBean && logisticsResponseBean.deliverdDate
                      ? getDate(
                        logisticsResponseBean &&
                        logisticsResponseBean.deliverdDate
                      )
                      : "-"}
                  </TableCell>
                </TableRow>
                {
                  status === 'SOLD' &&
                  <TableRow>
                    <TableCell className={classes.leftCell}>
                      Lead History
                    </TableCell>
                    <TableCell>
                      <button
                        className="btn-outline--small blue time-history"
                        onClick={() => showHistoryPopup(rowInfo)}
                      >
                        <img style={{ width: "16px", marginRight: "10px", display: "inline-flex" }} src={TimeHistory} />History
                      </button>
                    </TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
          {!showStore && (
            <TableContainer
              component={Paper}
              style={{ width: "25rem", borderRadius: "13px" }}
            >
              <Typography variant="subtitle1" className={classes.tableHeader}>
                CHANNEL PARTNER
              </Typography>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.leftCell}>CP Name</TableCell>
                    <TableCell>
                      {channelPartnerResponseBean &&
                        channelPartnerResponseBean.shdName &&
                        renderString(channelPartnerResponseBean.shdName)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.leftCell}>
                      Store Name
                    </TableCell>
                    <TableCell>
                      {channelPartnerResponseBean &&
                        channelPartnerResponseBean.shdStoreName &&
                        renderString(channelPartnerResponseBean.shdStoreName)}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className={classes.leftCell}>
                      Payment Status
                    </TableCell>
                    <TableCell>
                      {channelPartnerResponseBean &&
                        channelPartnerResponseBean.paymentStatus &&
                        getStatus(channelPartnerResponseBean.paymentStatus)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.leftCell}>
                      Fin Verification Status
                    </TableCell>
                    <TableCell>
                      {channelPartnerResponseBean &&
                        channelPartnerResponseBean.finVerificationStatus &&
                        getStatus(
                          channelPartnerResponseBean.finVerificationStatus
                        )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.leftCell}>
                      Payment Mode
                    </TableCell>
                    <TableCell>
                      {channelPartnerResponseBean &&
                        channelPartnerResponseBean.paymentMode
                        ? renderString(
                          channelPartnerResponseBean &&
                          channelPartnerResponseBean.paymentMode
                        )
                        : "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.leftCell}>
                      Payment Date
                    </TableCell>
                    <TableCell>
                      {channelPartnerResponseBean &&
                        channelPartnerResponseBean.paymentDate
                        ? getDate(
                          channelPartnerResponseBean &&
                          channelPartnerResponseBean.paymentDate
                        )
                        : "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.leftCell}>
                      Fin Verification Date
                    </TableCell>
                    <TableCell>
                      {channelPartnerResponseBean &&
                        getDate(
                          channelPartnerResponseBean &&
                          channelPartnerResponseBean.finVerificationDate
                        )}
                    </TableCell>
                  </TableRow>

                  {channelPartnerResponseBean &&
                    channelPartnerResponseBean.paymentMode && (
                      <span
                        className={classes.modalButton}
                        onClick={handleClickOpen}
                      >
                        View Breakup
                      </span>
                    )}
                  <br />
                  {channelPartnerResponseBean &&
                    channelPartnerResponseBean.paymentMode && (
                      <span
                        style={{ color: "#D000A2", borderColor: "#D000A2" }}
                        className={classes.modalButton}
                        onClick={handleClickOpenHistory}
                      >
                        View Refund History
                      </span>
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
        {/* new code */}
        {docCollectorResponse && (
          <div style={{ display: "flex", gap: "2rem" }}>
            <TableContainer
              component={Paper}
              style={{ width: "25rem", borderRadius: "13px" }}
            >
              <Typography variant="subtitle1" className={classes.tableHeader}>
                DOC - COLLECTOR
              </Typography>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.leftCell}>
                      Doc-collector Status
                    </TableCell>
                    <TableCell>
                      {docCollectorResponse &&
                        docCollectorResponse.docStatus &&
                        getStatusDoc(docCollectorResponse.docStatus)}
                    </TableCell>
                  </TableRow>
                  {docCollectorResponse &&
                    (docCollectorResponse.logisticsStatus === "REJECTED" ||
                      docCollectorResponse.logisticsStatus ===
                        "RESCHEDULED") && (
                      <TableRow>
                        <TableCell className={classes.leftCell}>
                          Reason
                        </TableCell>
                        <TableCell>
                          <span
                            className={classes.reasonButton}
                            onClick={() => {
                              setReasonData(reasonLogistic);
                              setIsReasonOpen(true);
                            }}
                          >
                            View Reason
                          </span>
                        </TableCell>
                      </TableRow>
                    )}
                  <TableRow>
                    <TableCell className={classes.leftCell}>
                      In-Custody Status
                    </TableCell>
                    <TableCell>
                      {docCollectorResponse &&
                        docCollectorResponse.docIncustudyStatus &&
                        getStatusDoc(docCollectorResponse.docIncustudyStatus)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.leftCell}>
                      Doc-collector Coordinator
                    </TableCell>
                    <TableCell>
                      {docCollectorResponse &&
                      docCollectorResponse.docCollectorName
                      ? renderString(
                        docCollectorResponse &&
                        docCollectorResponse.docCollectorName
                      )
                      : "-"}
                  </TableCell>
                </TableRow>
                {/* <TableRow>
                  <TableCell className={classes.leftCell}>
                    Runner Name
                  </TableCell>
                  <TableCell>
                    {docCollectorResponse && docCollectorResponse.runnerName
                      ? renderString(
                          docCollectorResponse &&
                            docCollectorResponse.runnerName
                        )
                      : "-"}
                  </TableCell>
                </TableRow> */}
                <TableRow>
                  <TableCell className={classes.leftCell}>
                    Schedule Date
                  </TableCell>
                  <TableCell style={{ display: "flex" }}>
                    <div>
                      {docCollectorResponse &&
                        docCollectorResponse.assignedDate
                        ? getDate(
                          docCollectorResponse &&
                          docCollectorResponse.assignedDate
                        )
                        : "-"}
                      {docCollectorResponse &&
                        docCollectorResponse.delayedDays && (
                          <Chip
                            className={classes.delayChip}
                            label={`Delayed by ${renderString(
                              docCollectorResponse &&
                              docCollectorResponse.delayedDays
                            )}d`}
                          />
                        )}
                    </div>
                    {/* {docCollectorResponse &&
                    docCollectorResponse.assignedDate && <span style={{ cursor: "pointer" }}>
                      <img
                        src={EditIcon}
                        height="15px"
                        style={{ width: "2rem" }}
                        onClick={() => setOpenTimeDialog(true)}
                      />{" "}
                    </span>} */}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.leftCell}>
                      In-transit Date
                    </TableCell>
                    <TableCell>
                      {docCollectorResponse &&
                      docCollectorResponse.intransitDate
                        ? getDate(
                            docCollectorResponse &&
                              docCollectorResponse.intransitDate
                          )
                        : "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.leftCell}>
                      Delivery Date
                    </TableCell>
                    <TableCell>
                      {docCollectorResponse && docCollectorResponse.deliverdDate
                        ? getDate(
                            docCollectorResponse &&
                              docCollectorResponse.deliverdDate
                          )
                        : "-"}
                    </TableCell>
                  </TableRow>
                  {/* <TableRow>
                      <TableCell colSpan="2" style={{ textAlign: 'center' }}>
                      <button className="priorityButton">Priority  <img src={LabelImportant} /></button>
                      </TableCell>
                </TableRow> */}
                  {status === "SOLD" && (
                    <TableRow>
                      <TableCell colSpan="2" style={{ textAlign: "center" }}>
                        {show ? (
                          <>
                            <button
                              className="prioritiedButton"
                              // onClick={() => onSetPriority(rowInfo)}
                            >
                              <span
                                style={{
                                  display: "inline-flex",
                                  alignItems: "center",
                                }}
                              >
                                Prioritised
                                <span style={{ marginLeft: "12px" }}>
                                  <img src={LabelImportant} alt="Important" />
                                </span>
                              </span>
                            </button>
                            <br />
                            <button
                              className="undoButton"
                              onClick={() => onUndoPriority(rowInfo)}
                            >
                              <span
                                style={{
                                  display: "inline-flex",
                                  alignItems: "center",
                                }}
                              >
                                Undo
                                <span style={{ marginLeft: "12px" }}>
                                  <img src={UndoIcon} alt="Important" />
                                </span>
                              </span>
                            </button>
                          </>
                        ) : (
                          <button
                            className="priorityButton"
                            onClick={() => onSetPriority(rowInfo)}
                          >
                            <span
                              style={{
                                display: "inline-flex",
                                alignItems: "center",
                              }}
                            >
                              Priority
                              <span style={{ marginLeft: "12px" }}>
                                <img src={LabelImportant} alt="Important" />
                              </span>
                            </span>
                          </button>
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
        {/* <div style={{ display: "flex", gap: "2rem" }}>
          
        </div> */}
        <div style={{ display: "flex", gap: "2rem" }}>
          {holdBean && holdBean.onHoldBy && (
            <TableContainer
              component={Paper}
              style={{ width: "25rem", borderRadius: "13px" }}
            >
              <Typography variant="subtitle1" className={classes.tableHeader}>
                HOLD
              </Typography>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.leftCell}>
                      Payout Status
                    </TableCell>
                    <TableCell>
                      {holdBean &&
                        holdBean.paymentStatus &&
                        getStatus(holdBean.paymentStatus)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.leftCell}>
                      On Hold by
                    </TableCell>
                    <TableCell>
                      {holdBean &&
                        holdBean.onHoldBy &&
                        renderString(holdBean.onHoldBy)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.leftCell}>
                      Hold Date
                    </TableCell>
                    <TableCell>
                      {holdBean && holdBean.holdDate
                        ? getDate(holdBean.holdDate)
                        : "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.leftCell}>
                      Hold Reason
                    </TableCell>
                    <TableCell>
                      {holdBean && holdBean.holdReason
                        ? renderString(holdBean.holdReason)
                        : "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.leftCell}>Comments</TableCell>
                    <TableCell>
                      {holdBean && holdBean.holdComments
                        ? renderString(holdBean.holdComments)
                        : "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.leftCell}>
                      Released by
                    </TableCell>
                    <TableCell>
                      {holdBean && holdBean.holdreleaseBy
                        ? renderString(holdBean.holdreleaseBy)
                        : "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.leftCell}>
                      Release Date
                    </TableCell>
                    <TableCell>
                      {holdBean && holdBean.holdreleaseDate
                        ? getDate(holdBean.holdreleaseDate)
                        : "-"}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}

          {status === "SOLD" && (
            <TableContainer
              component={Paper}
              style={{ width: "25rem", borderRadius: "13px" }}
            >
              <Typography variant="subtitle1" className={classes.tableHeader}>
                SOLD
              </Typography>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.leftCell}>
                      Release Status
                    </TableCell>
                    <TableCell>
                      {soldDataResponseBean &&
                        soldDataResponseBean.releaseStatus &&
                        getStatus(soldDataResponseBean.releaseStatus)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.leftCell}>
                      Marked As
                    </TableCell>
                    <TableCell>
                      {soldDataResponseBean &&
                        soldDataResponseBean.markedStatus &&
                        getStatus(soldDataResponseBean.markedStatus)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.leftCell}>Sold By</TableCell>
                    <TableCell>
                      {soldDataResponseBean && soldDataResponseBean.soldBy
                        ? renderString(
                          soldDataResponseBean && soldDataResponseBean.soldBy
                        )
                        : "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.leftCell}>
                      Sold Date
                    </TableCell>
                    <TableCell>
                      {soldDataResponseBean && soldDataResponseBean.soldDate
                        ? getDate(
                          soldDataResponseBean &&
                          soldDataResponseBean.soldDate
                        )
                        : "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.leftCell}>
                      Released By
                    </TableCell>
                    <TableCell>
                      {soldDataResponseBean && soldDataResponseBean.relasedBy
                        ? renderString(
                          soldDataResponseBean &&
                          soldDataResponseBean.relasedBy
                        )
                        : "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.leftCell}>
                      Release Date
                    </TableCell>
                    <TableCell>
                      {soldDataResponseBean && soldDataResponseBean.releasedDate
                        ? getDate(
                          soldDataResponseBean &&
                          soldDataResponseBean.releasedDate
                        )
                        : "-"}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
      </div>
      <ViewBreakup
        handleClose={handleClose}
        open={open}
        paymentList={channelPartnerResponseBean}
      />
      <ViewHistoryDrawer
        drawer={
          openRefundHistory && status === "DROP"
            ? "table-drawer_SHD openSHD_drop"
            : openRefundHistory && status === "SOLD"
              ? "table-drawer_SHD openSHD_new"
              : "table-drawer_SHD"
        }
        handleClose={handleCloseRefundHistory}
        open={openRefundHistory}
        paymentData={channelPartnerResponseBean}
        selectedData={paymentData}
      />
      {console.log(leadLifeInfoCustomer, "leadLifeInfoCustomer")}
      <ViewReason
        isOpen={isResonOpen}
        onClose={() => setIsReasonOpen(false)}
        leadData={reasonData}
        rescheduleTitle
      />

      {openTimeDialog && <RescheduleDialog
        open={openTimeDialog}
        onhandlelose={() => setOpenTimeDialog(false)}
        handleDate={(value) =>
          setRescheduleDateTime({ ...rescheduleDateTime, date: value })
        }
        handleTime={(value) =>
          setRescheduleDateTime({ ...rescheduleDateTime, time: value })
        }
        setRescheduleComments={setRescheduleComments}
        value={rescheduleComments}
        onHandleSubmitReschedule={onHandleSubmitReschedule}
        rescheduleDateTime={rescheduleDateTime}

      />}
      {openHistory && (
        <DeliveryTimeHistory openDeliveryHistory={openHistory} handleCloseDeliveryTime={onCloseHistory} leadInfo={rowInfo} timeHistoryList={historyData} />
      )}
    </>
  );
}
