import {
  Card,
  CardActions,
  CardContent,
  FormHelperText,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { renderString } from "../../../../../core/utility/stringUtility";
import { isRegistrationNumber } from "../../../../../core/utility";
import { getUserID } from "../../../../../core/services/rbacServices";
import { EditIcon, Lock } from "../../../../../core/utility/iconHelper";
import { editYearOrRegNo } from "../../../../../core/services/documentQcServices";
import { useDispatch, useSelector } from "react-redux";
import { getUserActionInfo } from "../../../../../core/services/tokenStorageServices";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
// import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CInterestedTable from "./Table"
import Filters from "./Filters"
import moment from "moment";
import isEqual from 'lodash/isEqual'
import { interestedBuyerAction } from "../../../../../redux/actions/interestedBuyersAction";
import { getBuyerStatus, postResendPayment } from "../../../../../core/services/C2cServices";
import { listCities } from "../../../../../core/services/miscServices";
import { setNotification } from "../../../../../redux/actions/notificationAction";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: "100%",
      "& .MuiAccordion-root:before":
        {height:"0px"}
     
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: "33.33%",
      flexShrink: 0,
      fontWeight:"bold",
      color:"black",
      letterSpacing:"1px",
      fontSize:"18px"
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    details:{
        overflowX:"auto",
      },
      accordionCard:{
        borderRadius:"18px",
        boxShadow:" 2px 2px 2px 0px #0000001A",
        margin: "16px 0",
        "& .MuiAccordion-root:before":
        {height:"0px"}
        
      },
     
      
  })
);
export default function InterestedBuyers({ LeadInfoCustomer, rowInfo,forInterestedTab,cityList
  ,status,
  statusList,
  setStatus,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  filters,
  setFilters,
  city,
  setCity,
  setOpenTranferDialog,mainTabStatus
 }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [enableRegNo, setEnableRegNo] = useState(false);
  const [regError, setRegError] = useState(false);
  const LeadBuyersList = useSelector((state) => state.interestedBuyersList.intrestedBuyers)
  

  const [regNo, setRegNo] = useState(
    LeadInfoCustomer &&
      LeadInfoCustomer.vehicleDetails &&
      LeadInfoCustomer.vehicleDetails.bikeRegistrationNumber &&
      LeadInfoCustomer.vehicleDetails.bikeRegistrationNumber
  );



    useEffect(() => {
      
        // getBuyerStatus()
        // .then(apiRes => {
        //     if (apiRes.isValid) {
        //         const reasonList = apiRes.buyersStatusList.map((item) => {
        //             return {
        //                 value: item.status,
        //                 label: item.status
        //             }
        //         })
        //         setStatusList(reasonList)
        //     }
        // })
      //   listCities() .then(apiRes => {
      //     if (apiRes.isValid) {
      //         const cityList = apiRes.cityList.map(status => {
      //             return {
      //                 value: status.cityId,
      //                 label: status.cityName
      //             }
      //         })
      //         setCityList(cityList)
      //         // console.log(cityList,"api city aakash")
      //     }
      // })
      
       
          },[])

  const enableRegNumber = () => {
    setEnableRegNo(true);
  };

  const handleRegNo = (e) => {
    setRegNo(e.target.value);
    let regRegex = /^[A-Z]{2}[0-9]{1,2}[A-Z]{2,3}[0-9]{4}$/;
    let R = regRegex.test(e.target.value.toString().toUpperCase());
    if (!R) setRegError(true);
    else setRegError(false);
  };

  const onInputEnterRegNo = (e) => {
    if (e.keyCode === 13) {
      const payload = {
        leadId: LeadInfoCustomer.vehicleDetails.leadId,
        updatedBy: getUserID(),
        regNumber: e.target.value,
        gatepassId:
          LeadInfoCustomer &&
          LeadInfoCustomer.vehicleDetails &&
          LeadInfoCustomer.vehicleDetails.bikeGatepassId
            .replace(
              LeadInfoCustomer &&
                LeadInfoCustomer.vehicleDetails &&
                LeadInfoCustomer.vehicleDetails.bikeRegistrationNumber,
              e.target.value
            )
            .replace(
              LeadInfoCustomer &&
                LeadInfoCustomer.vehicleDetails &&
                LeadInfoCustomer.vehicleDetails.bikeManufacturerYear,
              LeadInfoCustomer.vehicleDetails &&
                LeadInfoCustomer.vehicleDetails.bikeManufacturerYear
            ),
      };
      editYearOrRegNo(payload).then((apiResponse) => {
        if (apiResponse.isValid) {
          dispatch(setNotification("success", "Success", apiResponse.message));
        } else {
          dispatch(setNotification("danger", "Error", apiResponse.message));
        }
        setEnableRegNo(false);
      });
    }
  };

  const getStatus = (leadStatus) => {
    if (leadStatus === "AUCTION_COMPLETED") {
      return (
        <span
          style={{
            backgroundColor: "#DAF2DD",
            color: "#196834",
            padding: "1px 5px",
            borderRadius: "4px",
            width: "102px",
          }}
        >
          Auction Completed
        </span>
      );
    }
    if (leadStatus === "Central_Tagging_Inspected") {
      return (
        <span
          style={{
            backgroundColor: "#FFE1C5",
            color: "#B96716",
            padding: "1px 5px",
            borderRadius: "4px",
            width: "102px",
          }}
        >
          CT Inspected
        </span>
      );
    }
    if (leadStatus === "DROPPED") {
      return (
        <span
          style={{
            backgroundColor: "#FFD4D6",
            color: "#DA2128",
            padding: "1px 5px",
            borderRadius: "4px",
            width: "102px",
          }}
        >
          Dropped
        </span>
      );
    }
    if (leadStatus === "SELL") {
      return (
        <span
          style={{
            backgroundColor: "#DAF2DD",
            color: "#196834",
            padding: "1px 5px",
            borderRadius: "4px",
            width: "102px",
          }}
        >
          Sell
        </span>
      );
    }
    if (leadStatus === "EXCHANGE") {
      return (
        <span
          style={{
            backgroundColor: "#DAF2DD",
            color: "#196834",
            padding: "1px 5px",
            borderRadius: "4px",
            width: "102px",
          }}
        >
          Exchange
        </span>
      );
    }
    if (leadStatus === "ONGOING") {
      return (
        <span
          style={{
            backgroundColor: "#E9F4FF",
            color: "#366896",
            padding: "1px 15px",
            borderRadius: "4px",
          }}
        >
          {" "}
          Ongoing{" "}
        </span>
      );
    } else {
      return (
        <span
          style={{
            backgroundColor: "#E9F4FF",
            color: "#366896",
            padding: "1px 15px",
            borderRadius: "4px",
          }}
        >
          {" "}
          {leadStatus}{" "}
        </span>
      );
    }
  };
  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

    const getPayload = () => {
      const payload = {}
      if (endTime) {
        payload.toDate = moment(endTime).format("YYYY-MM-DD")
      }
      if (startTime) {
        payload.fromDate = moment(startTime).format("YYYY-MM-DD")
      }
      if (status) {
        payload.buyersStatus = status.value
      }
      if (city) {
        payload.cityId = city.value
      }
     
      return payload
    }

    const handleApplyFilters = (refresh) => {
      let isValid = true
      const payload = getPayload()
      if (payload.fromDate || payload.toDate) {
        if (!payload.fromDate) {
          dispatch(setNotification('danger', 'Invalid Selection', 'From Date is Required'));
          isValid = false
        }
        if (!payload.toDate) {
          dispatch(setNotification('danger', 'Invalid Selection', 'To Date is Required'));
          isValid = false
        }
        payload.dateType= "LEADCREATEDDATE";
      }
      if (isValid && (!isEqual(payload, filters ) || refresh)) {
          setFilters(payload)
          const interestedBuyerPayload={
            inventoryId: rowInfo.inventoryId,
            pageNum:1,
            ...payload
        }
          dispatch(interestedBuyerAction(interestedBuyerPayload))
          // dispatch(getC2CList({ ...payload, pageNum: 1, userId: getUserID() }))
      }
  
    }

    const handleToDateChange = (toDate) => {
      setEndTime(toDate)
    }

    const handleFromDateChange = (fromDate) => {
      setStartTime(fromDate)
    }
    const handleCityChange = (selectedCity) => {
      setCity(selectedCity)
    }

    const handleClearFilters = () => {
      // setClearFilter(clearFilter + 1)
      const { ...rest } = filters
      if (Object.keys(rest).length) {
        setFilters({})
        resetFilters()
        // const payload = {
        //   pageNumber: 1,
        //   userId: getUserID(),
        //   status: 'UNASSIGNED'
        // }
        // getC2CList(payload)
      }
    }
    const resetFilters = () => {
      const { ...rest } = filters
      setStartTime(null)
      setEndTime(null)
      setCity('')
      setFilters({})
      setStatus("")
      if (Object.keys(rest).length) {
        const payload = {
          status: status,
          pageNum: 1,
          userId: getUserID()
        }
        const interestedBuyerPayload={
          inventoryId: rowInfo.inventoryId,
          pageNum:1,
          // dateType: "LEADCREATEDDATE",
          // ...payload
      }
        dispatch(interestedBuyerAction(interestedBuyerPayload))
        // dispatch(getC2CList(payload))
      }
  
      // refreshPage()
    }

    const handleStatusChange=(value)=>{
      setStatus(value)
    }

   const getLeadCount=(listName,status)=>{
    const filteredList=Boolean(listName.length) && listName.filter((item)=>(item.buyerStatus===status))
    return filteredList.length?filteredList.length:0
   }


   const onResendPaymentLink=()=>{
    
    const dealLockedLead=LeadBuyersList.find((item)=>(item.buyerStatus==="Deal_Locked"))
    if(Object.keys(dealLockedLead).length){
        const payload={
          crmLeadId: dealLockedLead.buyerLeadId,
          userId:getUserID()
        }
        postResendPayment(payload).then((apiResponse)=>{
          if(apiResponse.isValid){
            dispatch(setNotification('success',apiResponse.message,'Success'))
          }
          else{
            dispatch(setNotification('error',apiResponse.message,'Error'))
          }
        })
    }
    
   }

  return (
    <div className={classes.root}>
          <Filters 
            onApplyFilters={handleApplyFilters}
            onEndDateChange={handleToDateChange}
            onStartDateChange={handleFromDateChange}
            onChangeCity={handleCityChange}
            onStatusChange={handleStatusChange}
            status={status}
            startTime={startTime}
            endTime={endTime}
            city={city}
            onClearFilters={handleClearFilters}
            resetFilters={resetFilters}
            cityList={cityList}
            statusTypes={statusList}
          />
          {Boolean(getLeadCount(LeadBuyersList,"Deal_Locked")) && <Typography className="bg-lock-section">
              <div className="lock-header-container">
                <div className="lock-image-container">
                    <img src={Lock} className="lock-image"/>
                    <p>Deal Locked</p>
                </div>
                  
                  <button className="resend-link-button" onClick={onResendPaymentLink}>RE-SEND PAYMENT LINK</button>
              </div>
            <CInterestedTable mainTabStatus={mainTabStatus} withHeaders className={classes.details} buyerStatus="Deal_Locked" forInterestedTab={forInterestedTab} setOpenTranferDialog={setOpenTranferDialog} statusList={statusList} rowInfo={rowInfo} refreshPage={()=>handleApplyFilters(true)}/>
          </Typography>}
      
        <Accordion
        defaultExpanded={Boolean(getLeadCount(LeadBuyersList,"new"))}
        // onChange={handleChange("panel2")}
        className={classes.accordionCard}
        >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography className={classes.heading}>Action Pending ({getLeadCount(LeadBuyersList,"new")})</Typography>
        </AccordionSummary>
        <AccordionDetails className="outer-table">
            <CInterestedTable mainTabStatus={mainTabStatus} withHeaders className={classes.details} buyerStatus="new" forInterestedTab={forInterestedTab} setOpenTranferDialog={setOpenTranferDialog} statusList={statusList} rowInfo={rowInfo} refreshPage={()=>handleApplyFilters(true)}/>
        </AccordionDetails>
      </Accordion>
      <Accordion
        defaultExpanded={Boolean(getLeadCount(LeadBuyersList,"Under_Negotitation"))}
        // onChange={handleChange("panel2")}
        className={classes.accordionCard}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography className={classes.heading}>Under Negotiation ({getLeadCount(LeadBuyersList,"Under_Negotitation")})</Typography>
        </AccordionSummary>
        <AccordionDetails className="outer-table">
            <CInterestedTable mainTabStatus={mainTabStatus} className={classes.details} buyerStatus="Under_Negotitation" forInterestedTab={forInterestedTab} setOpenTranferDialog={setOpenTranferDialog} statusList={statusList} rowInfo={rowInfo} refreshPage={()=>handleApplyFilters(true)}/>
        </AccordionDetails>
      </Accordion>
      <Accordion
        defaultExpanded={Boolean(getLeadCount(LeadBuyersList,"Dropped"))}
        // onChange={handleChange("panel3")}
        className={classes.accordionCard}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography className={classes.heading}>Dropped ({getLeadCount(LeadBuyersList,"Dropped")})</Typography>
          
        </AccordionSummary>
        <AccordionDetails className="outer-table">
            <CInterestedTable mainTabStatus={mainTabStatus} className={classes.details} buyerStatus="Dropped" forInterestedTab={forInterestedTab} setOpenTranferDialog={setOpenTranferDialog} statusList={statusList} rowInfo={rowInfo} refreshPage={()=>handleApplyFilters(true)}/>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
