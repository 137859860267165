import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import { Box, Container } from '@material-ui/core';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Header from './components/header';
import BiddingHistory from './components/BiddingHistory/index';
import LeadDetails from './components/LeadDetails/index';
import InterestedBuyers from "./components/InterestedBuyers/index"
import DocumentDetails from './components/DocumentDetails/index';
import InspectionDetails from './components/InspectionDetails/index';
import Commission from './components/Commission/index';
import CustomerBreakup from './components/CustomerBreakup/index';
import FhdStoreDetails from './components/FhdStoreDetails/index';
import CustomerDocument from './components/CustomerDocument/index';
import LeadLifecycle from './components/LeadLifeCycle/index';
import CallToCustomer from '../components/CallActionPopUp';
import { leadDetails } from '../../../redux/actions/biddingDetailsAction'
import { useDispatch, useSelector } from "react-redux"
import { getBiddingDetails, getReleaseAuth } from '../../../core/services/biddingServices'
import { leadLifecycleDetails } from '../../../redux/actions/biddingLifecycleAction'
import { getShdOrderDeduction } from '../../../redux/actions/shdOrderDeductionAction'
import BidModal from '../components/BidActionPopUp'
import HoldReleaseModal from '../components/HoldReleasePopUp'
import UpdateStatusQuote from '../components/UpdateStatusPop';
import ReleaseAuthorize from './components/Commission/ReleaseAuthorization';
import { getMobile, getUserID } from '../../../core/services/rbacServices';
import { hideLoader, showLoader } from '../../../redux/actions/loaderAction';
import { callToCareCustomer, updateStatus } from '../../../core/services/shdServices';
import { setNotification } from '../../../redux/actions/notificationAction';
import { addShdCommission } from '../../../core/services/shdServices'
import CallToCustomerDialog from '../components/CallBiddingDealer'
import CustomerEPEditDialog from '../components/EdiitPriceModal';
import ReassignModal from '../components/ReassignPopUp';
import CollectDocModel from './components/CustomerDocument/CollectDocModel.jsx'
// import ExceptionListModel from './components/CustomerDocument/ExceptionListModel.jsx'
import ExceptionModel from './components/CustomerDocument/ExceptionModel'
import { getExceptionList, getRebidReasones, listCities } from '../../../core/services/miscServices';
import RefundForm from '../components/RefundForm';
import RefundToWalletDialog from "../../QuoteConfirmation/components/RefundToWalletDialog"
import OTPPopUp from "./components/OTPPopUp"
import RefundTable from "./components/LeadLifeCycle/RefundTable"
import ViewHistoryDrawer from "./components/LeadLifeCycle/Drawer"
import "./table.scss"
import { getUserActionInfo } from '../../../core/services/tokenStorageServices';
import ConfirmationDialog from '../../../shared/components/ConfirmationPopup'
import VehicleSuggestionDialog from './components/VehicleSuggestionDialog';
import { getBuyerStatus } from '../../../core/services/C2cServices';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 2,
    backgroundColor: '#F4F7FD',
    display: 'flex',
    height: '89vh', marginTop: '5rem'
  },
  tabs: {
    // borderRight: `1px solid ${theme.palette.divider}`,
    backgroundColor: '#111328',
    color: 'white',
    width: '20rem',
    '& .MuiTab-textColorInherit': {
      opacity: '1',
      "&[aria-selected = 'true']": {
        color: 'black',
        opacity: '1',
      }
    },
    "& .MuiTab-wrapper": {
      alignItems: "flex-start",
      marginLeft: '1rem'
    },
    '& .MuiTabs-indicator': {
      left: '0',
      width: '3px'
    }
  },
  tabpanel: {
    width: '100%'
  }
}));

const slideStyles = makeStyles({
  drawer: {
    "& .MuiDrawer-paperAnchorBottom": {
      position: 'relative',
      marginTop: '3.6rem',
      borderTopLeftRadius: '1rem',
      borderTopRightRadius: '1rem',
      overflow: 'hidden',
    }
  },
  contentBox: {
    position: 'absolute',
    top: '5rem',
    left: '20rem',
    maxWidth: '76%',
    padding: '2rem',
    border: '2px solid green'
  }
});

export default function StatusSlider({ openState, close, handleValue, value, enable,leadStatus, handleEnable,Cp,Store, selectedLeadId, LeadInfo, LeadLifeInfo, orderDedctions, customerDocuments,leadDetails, rowInfo, status, leadData, onRefreshPage, onClosePushMenu, openCEDialog, onReassignUser, goToDropTab,goToFollowUpTab,goToSoldTab, handleRowChange,leadBuyers,setOpenCustInsBox }) {


  const slideClass = slideStyles();
  const classes = useStyles();
  const dispatch = useDispatch()
  const [auctionData, setAuctionData] = useState([])
  const [callPopUp, setCallPopUp] = useState(false)
  const [bidPopUp, setBidPopUp] = useState(false)
  const [holdReleasePopUp, setHoldReleasePopUp] = useState(false)
  const [resellPopUp,setResellPopUp]=useState(false)
  const [statusPopUp, setStatusPopUp] = useState(false)
  const [refundPopUp, setRefundPopUp] = useState(false)
  
  const [authorizePopup, setAuthorizePopup] = useState(false)
  // const [enable, setEnable] = useState(true)
  const [authData, setAuthData] = useState([])
  const [newVal, setNewVal] = useState('')
  const [show, setShow] = useState(false)
  const [responseMessage, setResponseMessage] = useState('')
  const [formPayload, setFormPayload] = useState({})
  const [openCallModel, setOpenCallModel] = useState(false)
  const [revAuctionData, setRevAuctionData] = useState([])
  const [bidNumIndex, setBidNumIndex] = useState()
  const [price, setPrice] = useState('')
  const [editPrice, setEditPrice] = useState(false)
  const [updateClick, setUpdateClick] = useState('1')
  const payload = useSelector((state) => state.commission);
  const [overrideColor, setOverRideColor] = useState(false)
  const [showCp, setShowCp] = useState(true)
  const [showStore, setShowStore] = useState(null)
  const [option, setOption] = useState(Store ? 'Store': 'Channnel Partner');
  const [updateComm, setUpdateComm] = useState(true)
  const [cusAmount, setCusAmount] = useState('')
  const [cpAmount, setCpAmount] = useState('')
  const [storeAmount, setStoreAmount] = useState('')
  const [leadStatusValue, setLeadStatusValue] = useState('')
  const [bidVal,setBidVal] = useState('')
  const [reassignPopUp,setReassignPopUp] = useState(false)
  const [collectModel, setCollectModel] = useState(false)
  const [exceptionModel, setExceptionModel] = useState(false)
  const [exceptionList, setExceptionList] = useState([])
  const [rebidReasonList, setRebidReasonsList] = useState([])
  const [bidderDetails, setBidderDetails] = useState({})
  const [otpPopUp,setOtpPopup]=useState(false)
  const [apiSelect,setApiSelect]=useState("")
  const [payloadNew,setPayloadNew]=useState("")
  const [dataOTP,setdataOTP]=useState(0)
  const [openModelRefund,setOpenModelRefund]=useState(false)
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false)
  const [days, setDays] = useState(0)
  const [openTranferDialog,setOpenTranferDialog]=useState(false)
  const [statusList,setStatusList]=useState("")
  const [cityList,setCityList]=useState("")
  const [filterStatus, setFilterStatus] = useState('')
  const [startTime, setStartTime] = useState(null)
  const [endTime, setEndTime] = useState(null)
  const [filters, setFilters] = useState({})
  const [city, setCity] = useState('')


  useEffect(() => {
      
    getBuyerStatus()
    .then(apiRes => {
        if (apiRes.isValid) {
            const reasonList = apiRes.buyersStatusList.map((item) => {
                return {
                    value: item.status,
                    label: item.status
                }
            })
            setStatusList(reasonList)
        }
    })
    listCities() .then(apiRes => {
      if (apiRes.isValid) {
          const cityList = apiRes.cityList.map(status => {
              return {
                  value: status.cityId,
                  label: status.cityName
              }
          })
          setCityList(cityList)
      }
  })
  
   
      },[])


  const openOTPPopPup = (apiResponse, payloadData, apiSelector) => {
    setApiSelect(apiSelector)
    setPayloadNew(payloadData)
    setdataOTP(apiResponse)
    setRefundPopUp(false)
    setOtpPopup(true)
}

  const handleCusAmt = (val) => {
    setCusAmount(val)
  }
  const handleCpAmt = (val) => {
    setCusAmount(val)
  }
  const handleStoreAmt = (val) => {
    setCusAmount(val)
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
        hidden={value !== index}
        style={{ overflow: 'scroll', paddingBottom: '2rem' }}
      >
        {
          value === index &&
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        }
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }


  const handleChange = (event, newValue) => {
    if (newValue === 'Bidding History') {
      getBiddingDetails(rowInfo.leadId).
        then(apiResponse => {
          if (apiResponse.isValid) {
            setAuctionData(apiResponse.auctionBidderInfoList ? apiResponse.auctionBidderInfoList[0] && apiResponse.auctionBidderInfoList[0].acutionList.reverse() : [])
          }else{
            setAuctionData([])
          }
        })
    }
    handleValue(newValue);
    handleEnable(true);
    setShow(false)
    setOption((orderDedctions && orderDedctions.soldTo && orderDedctions.soldTo === 'CP') ? 'Channel Partner' : 'Store')
  };
  const callCustomer = () => {
    setCallPopUp(true)
  }
  const handleCp = val => {
    setShowCp(val)
  }
  const handleStore = val => {
    setShowStore(val)
  }
  const openReassign = () => {
    setReassignPopUp(true)
  }
  const handleOption = val => {
    setOption(val)
    if (option !== val) setOverRideColor(true)
    else setOverRideColor(false)
  }
  const handleOverrideColor = val => {
    setOverRideColor(val)
  }
  const handleUpdateComm = (val) => {
    setUpdateComm(val)
  }

  const callAction = async (data, info) => {
    const payload = {
      fromNumber: getMobile(),
      toNumber: info.sellerMobileNumber,
      userId: getUserID(),
      leadId: info.leadId
    }
    dispatch(showLoader())
    const apiResponse = await callToCareCustomer(payload)
    dispatch(hideLoader())
    if (apiResponse.isValid) {
      dispatch(setNotification('success', 'Success', apiResponse.message))
      if (status === 'SOLD' || status === 'DROP') {
        // setStatusPopUp(false)
      }
      else {
        // setStatusPopUp(true)
      }
    } else {
      dispatch(setNotification('danger', 'Error', apiResponse.message));
    }
    setCallPopUp(false)
  }
  const reAssignTo = (list) => {
    const payload = {
      userId: getUserID(),
      leadId: list.leadId,
      status: 'ASSIGN',
      crmLeadId: list.crmLeadId
    }
    updateStatus(payload)
    .then(apiRes => {
      if (apiRes.isValid) {
        dispatch(hideLoader())
        dispatch(setNotification({
          type: 'success',
          message: apiRes.message,
          title: 'Success'
        }))
        setReassignPopUp(false)
        close()
        onRefreshPage()
      }
      else {
        dispatch(setNotification({
          type: 'error',
          message: apiRes.message,
          title: 'Error'
        }))
      }
    })
    
  }

  const openBid = (val) => {
    if(val === 'no bid')
    onRefreshPage()
    else{

      if(rowInfo.customerExpectedPrice >9999){
        getBiddingDetails(rowInfo.leadId).
        then(apiResponse => {
          if (apiResponse.isValid) {
            setBidderDetails(apiResponse.bidderDetails[0])
            getRebidReasones().then((apiRespon) => {
              if (apiRespon.isValid) {
                setRebidReasonsList(apiRespon.rebidReasonList);
                setBidPopUp(true)
                setBidVal(val)
              }
            });
          }else{
            setAuctionData([])
            setBidPopUp(true)
            setBidVal(val)
          }
        })
      }else{
        dispatch(setNotification( "danger", "Error", "Incorrect Customer Expected Price"))
      }
     
  
    }
  }

  const openResell = (val) => {
    if(val === 'no resell')
    onRefreshPage()
    else{
      setResellPopUp(true)
    }
  }

  const openStatus = (value, rowInfo) => {
    setLeadStatusValue(value)
    // let days = getDuration(rowInfo.leadcreatedDate)
    let days=getDuration(rowInfo && rowInfo.leadHistory && rowInfo.leadHistory.leadCreateAt)
    if(days < 15)
    setStatusPopUp(true)
    else {
      setOpenConfirmationPopup(true)
      setDays(days)
    }
  }

  const getDuration = (pickUpDate) => {
    if (pickUpDate && pickUpDate) {
        let date1 = new Date(pickUpDate)
        date1.setHours(date1.getHours());
        let pickUpTimeStamp = date1
        var d = new Date();
        var difference = d.getTime() - pickUpTimeStamp.getTime();
        let timeInMinutes = difference / 1000 / 60
        if (timeInMinutes < 60) {
            var A = new Date();
            let date2 = new Date(pickUpDate)
            let date3 = date2.setHours(date1.getHours() + 1);
            let newV = new Date().setHours(new Date().getHours())
            let sd = date3 - newV
            return ''
        }
        if (timeInMinutes > 60) {
            let hours = difference / 1000 / 60 / 60
            if (hours >= 24) {
                return `${Math.round(hours / 24)}`
            }
            return ``
        }
    }
}

  const openRefund = (value) => {
    // setLeadStatusValue(value)
    setRefundPopUp(value)
  }

  const openAuthorizeModal = (leadId) => {
    setAuthorizePopup(true)
    getReleaseAuth(leadId).
      then(apiResponse => {
        if (apiResponse.isValid) {
          setAuthData(apiResponse.releaseBreakUp)
        }
      })
  }

  const enableCommissionForm = (val) => {
    handleEnable(val)
  }

  const refreshToOld = () => {
    setAuthorizePopup(false)
    onRefreshPage()
  }
  const handleOpenCallModel = (data) => {
    setOpenCallModel(true)
    setBidNumIndex(data)
  }
  const handleCloseCallModel = (data) => {
    setOpenCallModel(false)
    setBidNumIndex('')
  }
  const onOpenEditCEP = (price, rowIndex) => {
    setEditPrice(true)
    setPrice(price)
    setBidNumIndex(rowIndex)
  }
  const onCloseEditCEP = () => {
    if (value === 'Customer Break-Up') {
      getReleaseAuth(rowInfo.leadId).
        then(apiResponse => {
          if (apiResponse.isValid) {
            setAuthData(apiResponse.releaseBreakUp)
          }
        })
    }
    setEditPrice(false)
    setBidNumIndex('')
  }

  const handleOpenCusIns=()=>{
    setOpenCustInsBox(true)
  }

  const onStatusUpdate = (val, payload) => {
    setUpdateClick('1')
    var retrievedCommission = localStorage.getItem('commissionObject');
    let finalPayload = JSON.parse(retrievedCommission)
    setNewVal(val)
    let newPayload = {
      ...finalPayload,
      leadId: rowInfo.leadId,
      loginId: getUserID(),
      userId: getUserID()
    }
    
    if(newPayload.holdReason === "0"){
      newPayload.holdReason = ''
    }
    if (finalPayload) {
      if (showCp) {
        if (newPayload.finalCusAmt < 0 || newPayload.finalCpAmt < 0) {
          console.log("1st if newPayload.finalCusAmt < 0 || newPayload.finalCpAmt < 0")
          dispatch(setNotification('danger', 'Error', 'Negative value detected for Final Amount!'))
        }else if(newPayload.finalCusAmt<newPayload.holdAmount){
          dispatch(setNotification('danger', 'Error', 'Hold amount cannot exceed than Customer final amount!'))
        }else {
          delete newPayload.finalCpAmt
          // delete newPayload.finalCusAmt
          delete newPayload.finalStoreAmt
          newPayload.buyerOfferPrice=newPayload.newPurchaseCost
          delete newPayload.newPurchaseCost
          
          addShdCommission(newPayload).
            then(apiResponse => {
              if (apiResponse.isValid) {
                dispatch(setNotification('success', 'Success', apiResponse.message));
                localStorage.removeItem("commissionObject")
                onClosePushMenu()
                handleOpenCusIns()
              }
              else {
                dispatch(setNotification('danger', 'Error', apiResponse.message));
                // localStorage.removeItem("commissionObject")
              }
            })
        }
      }
      
    } else {
      const { userDeductions } = orderDedctions
      const payload = {
        extraShdCommission: orderDedctions && orderDedctions.extraShdCommission && orderDedctions.extraShdCommission ? orderDedctions.extraShdCommission : 0,
        cityLevelCommission: orderDedctions && orderDedctions.cityLevelCommission && orderDedctions.cityLevelCommission ? orderDedctions.cityLevelCommission : 0,
        traffic_challan: userDeductions && userDeductions.userTrafficChalan && userDeductions.userTrafficChalan ? userDeductions.userTrafficChalan : 0,
        hp_rto: userDeductions && userDeductions.userHpRtoCharges && userDeductions.userHpRtoCharges ? userDeductions.userHpRtoCharges : 0,
        comments: '',
        leadId: rowInfo.leadId,
        loginId: getUserID(),
        userId: getUserID(),
        rtoCharges: userDeductions && userDeductions.userHpRtoCharges && userDeductions.userHpRtoCharges ? userDeductions.userHpRtoCharges : 0,
        challanCharges: userDeductions && userDeductions.userTrafficChalan && userDeductions.userTrafficChalan ? userDeductions.userTrafficChalan : 0,
        shdAdditionalCommission: orderDedctions && orderDedctions.extraShdCommission && orderDedctions.extraShdCommission ? orderDedctions.extraShdCommission : 0,
        newPurchaseCost: ((orderDedctions && orderDedctions.newProcurementPrice) ? orderDedctions.newProcurementPrice : ((orderDedctions && orderDedctions.storeProcuredPrice)  ? orderDedctions.storeProcuredPrice : 0)),
        leeway: orderDedctions && orderDedctions.leeway && orderDedctions.leeway ? orderDedctions.leeway : 0,
        purchaseFor: "C2C_BUYER",
        finalCusAmt: cusAmount,
        finalCpAmt: cpAmount,
        finalStoreAmt: storeAmount,
        holdComments:orderDedctions.holdComments ? orderDedctions.holdComments : '',
        holdAmount:orderDedctions.holdAmount,
        holdReason:orderDedctions.holdReason ? orderDedctions.holdReason : '',
        finalAmountWithHold:Number(orderDedctions.holdAmount)> 0 ? orderDedctions.holdAmount+cusAmount : 0
      }
      console.log("Number(orderDedctions.holdAmount)", Number(orderDedctions.holdAmount))
      if (showCp) {
        if (payload.finalCusAmt < 0 || payload.finalCpAmt < 0 || payload.finalStoreAmt < 0) {
          console.log("payload.finalCusAmt < 0 || payload.finalCpAmt < 0 || payload.finalStoreAmt < 0")
          dispatch(setNotification('danger', 'Error', 'Negative value detected for Final Amount!'))
        } else {
          delete payload.finalCpAmt
          // delete payload.finalCusAmt
          delete payload.finalStoreAmt
          newPayload.buyerOfferPrice=newPayload.newPurchaseCost
          delete newPayload.newPurchaseCost
          addShdCommission(payload).
            then(apiResponse => {
              if (apiResponse.isValid) {
                dispatch(setNotification('success', 'Success', apiResponse.message));
                localStorage.removeItem("commissionObject")
                onClosePushMenu()
                handleOpenCusIns()
              }
              else {
                dispatch(setNotification('danger', 'Error', apiResponse.message));
                localStorage.removeItem("commissionObject")
              }
            })
        }
      } else {
        if (payload.finalStoreAmt < 0) {
          dispatch(setNotification('danger', 'Error', 'Negative value detected for Final Amount!'))
        } else {
          delete payload.finalCpAmt
          // delete payload.finalCusAmt
          delete payload.finalStoreAmt
          newPayload.buyerOfferPrice=newPayload.newPurchaseCost
          delete newPayload.newPurchaseCost
          addShdCommission(payload).
            then(apiResponse => {
              if (apiResponse.isValid) {
                dispatch(setNotification('success', 'Success', apiResponse.message));
                onClosePushMenu()
                handleOpenCusIns()
              }
              else {
                dispatch(setNotification('danger', 'Error', apiResponse.message));
              }
            })
        }
      }

    }
    handleEnable(true);
  }

  const showResonseMessage = (message) => {
    setShow(true)
    setResponseMessage(message)
    setTimeout(() => {
      setShow(false)
    }, 3000)
  }

  const onUpdateStatusPayload = (payload) => {
    setFormPayload(payload)
  }

  const openCollectModel =()=> {
    setCollectModel(true)
  }

  const openExceptionModel = ()=> {

    getExceptionList().
      then(apiResponse => {
        if (apiResponse.isValid) {
          setExceptionList(apiResponse.exceptionList)
          setExceptionModel(true)
        }
        else {
          dispatch(setNotification('danger', 'Error', apiResponse.message));
          // localStorage.removeItem("commissionObject")
        }
  })
  }

  const openHoldRelModel = ()=> {
    setHoldReleasePopUp(true)
  }
  const handleCloseRefundHistory=()=>{
    setOpenModelRefund(false)
  }

  const onYesClick = () => {
    setOpenConfirmationPopup(false)
    setStatusPopUp(true)
  }
  const checkDealLocked=()=>{
    if(Boolean(leadBuyers.length)){
      const filteredItem= leadBuyers.filter((item)=>(item.buyerStatus==="Deal_Locked"))
      return Boolean(filteredItem.length)
    }
   return false
  }

  return (
    <div style={{ overflow: 'hidden' }}>
      <Drawer style={{ zIndex: '1000' }} disableEnforceFocus={true} className={slideClass.drawer} transitionDuration={{ enter: 250, exit: 250 }} anchor='bottom' open={openState} onClose={() => close()}>
        <Box
          style={{ backgroundColor: '#F4F7FD' }}
          role="presentation"
        >
          <Header close={close} status={status} callCustomer={callCustomer} openBid={openBid} openResell={openResell} enableVal={enable} onUpdateStaus={onStatusUpdate} openRefund={openRefund}
            openStatus={openStatus} LeadInfoCustomer={LeadInfo} openAuthorize={openAuthorizeModal} tabValue={value} enableEdit={enableCommissionForm}
            show={show} responseMessage={responseMessage} updateComm={updateComm} paymentStatus={orderDedctions.invPaymentStatus} shdPaymentStatus={orderDedctions.shdPaymentStatus} docStatus={orderDedctions.docQcStatus}
            holdAmount = {orderDedctions.holdAmount} formPayload={formPayload} auctionComp={leadStatus === "AUCTION_COMPLETED"} leadData = {{...leadData,leadId:leadData.sellerLeadId}} onReassign={onReassignUser} openReassign={openReassign}
            openCollectModel = {openCollectModel} openExceptionModel ={openExceptionModel} customerDocuments={customerDocuments} openHoldRelModel ={openHoldRelModel} rowInfo={{...rowInfo,leadId:rowInfo.sellerLeadId}}
            setOpenTranferDialog={setOpenTranferDialog}
          />
          <div className={classes.root}>
            <Tabs
              orientation="vertical"
              variant="standard"
              value={value}
              onChange={handleChange}
              className={classes.tabs}
            >
              <Tab label="INTERESTED BUYERS" {...a11yProps(0)} value='Lead Details' />
              {/* <Tab label="Bidding History" {...a11yProps(1)} value="Bidding History" /> */}
              <Tab label="Document Details" {...a11yProps(2)} value="Document Details" />
              <Tab label="Inspection Details" {...a11yProps(3)} value='Inspection Details' />
              {(leadStatus === 'C2C_PENDING' && checkDealLocked()) &&
                <Tab label="Commission" {...a11yProps(4)} value="Commission" />}
              {status === 'SOLD' && <Tab label="Customer Break-Up" {...a11yProps(5)} value="Customer Break-Up" />}
              {/* <Tab label="FHD Store Details" {...a11yProps(6)} value="FHD Store Details" /> */}
              <Tab label="Lead Lifecycle" {...a11yProps(7)} value="Lead Lifecycle" />
              {
                (leadStatus === 'C2C_PENDING' && checkDealLocked()) && 
                <Tab label="Customer Document" {...a11yProps(4)} value="Customer Document" />}
            </Tabs>
            <TabPanel value={value} className={classes.tabpanel} index='Lead Details'>
              <InterestedBuyers
                LeadInfoCustomer={LeadInfo}
                rowInfo={rowInfo}
                status={filterStatus}
                cityList={cityList}
                statusList={statusList}
                setStatus={setFilterStatus}
                startTime={startTime}
                setStartTime={setStartTime}
                endTime={endTime}
                setEndTime={setEndTime}
                filters={filters}
                setFilters={setFilters}
                city={city} 
                setCity={setCity}
                forInterestedTab
                setOpenTranferDialog={setOpenTranferDialog}
                mainTabStatus={status}
              />
            </TabPanel>
            {/* <TabPanel value={value} className={classes.tabpanel} index='Bidding History' >
              <BiddingHistory
                auctionData={auctionData}
                callToBidder={handleOpenCallModel}
                openEditDialog={onOpenEditCEP}
              />
            </TabPanel> */}
            <TabPanel value={value} className={classes.tabpanel} index='Document Details' >
              <DocumentDetails
                VehicleDetails={LeadInfo.vehicleDetails}
                BankDetails={LeadInfo.userBankDocs && LeadInfo.userBankDocs[0] && LeadInfo.userBankDocs[0]}
                Images={LeadInfo.imagesList && LeadInfo.imagesList}
                LeadInfo={LeadInfo}
                rowInfo={rowInfo}
              />
            </TabPanel>
            <TabPanel value={value} className={classes.tabpanel} index='Inspection Details' >
              <InspectionDetails InspectionData={LeadInfo} />
            </TabPanel>
            <TabPanel value={value} className={classes.tabpanel} index='Commission' >
              <Commission
                orderDedctions={orderDedctions}
                rowInfo={rowInfo}
                enable={enable}
                handleEnable={handleEnable}
                 enableCommissionForm={enableCommissionForm}
                newVal={newVal}
                status={status}
                leadData={leadData}
                onShowResponseMessage={showResonseMessage}
                updateClick={updateClick}
                onUpdateStatusPayload={(payload) => onUpdateStatusPayload(payload)}
                // showCp={showCp !== null ? showCp : Cp}
                showCp
                // showStore={showStore !== null ? showStore : Store}
                showStore={false}
                handleCp={handleCp}
                handleStore={handleStore}
                option={option}
                handleOption={handleOption}
                overrideColor={overrideColor}
                handleOverrideColor={handleOverrideColor}
                handleUpdateComm={handleUpdateComm}
                cusAmount={cusAmount}
                cpAmount={cpAmount}
                storeAmount={storeAmount}
                handleCpAmt={handleCpAmt}
                handleCusAmt={handleCusAmt}
                handleStoreAmt={handleStoreAmt}
                refresh = {()=>handleRowChange(leadData)}
                LeadLifeInfo={LeadLifeInfo}
                LeadInfo = {LeadInfo}
              />
            </TabPanel>
            <TabPanel value={value} className={classes.tabpanel} index='Customer Break-Up'>
              <div style={{display:"flex",justifyContent:"center",alignItems:"center",width:"100%",height:"100%"}}>
                <CustomerBreakup shdOrderInfo={orderDedctions} name="Seller’s break Up"/>
                <CustomerBreakup shdOrderInfo={orderDedctions} name="Buyer’s break Up"/>
              </div>
              
            </TabPanel>
            {/* <TabPanel value={value} className={classes.tabpanel} index='FHD Store Details'>
              <FhdStoreDetails LeadInfoCustomer={LeadInfo} />
            </TabPanel> */}
            <TabPanel value={value} className={classes.tabpanel} index='Lead Lifecycle'>
              <LeadLifecycle leadLifeInfoCustomer={LeadLifeInfo} rowInfo={rowInfo} status={status} showStore={showStore !== null ? showStore : Store} paymentData={orderDedctions}/>
            </TabPanel>
            <TabPanel value={value} className={classes.tabpanel} index='Customer Document'>
              <CustomerDocument LeadInfoCustomer={LeadInfo} 
              // collectModel = {colleOpenctModel} 
              setCollectModel ={setCollectModel}  
              customerDocuments = {customerDocuments}/>
            </TabPanel>
          </div>
        </Box>
        {callPopUp &&
          <CallToCustomer
            open={callPopUp}
            onClose={() => setCallPopUp(false)}
            onCallCustomer={callAction}
            leadData={leadData}
            rowInfo={rowInfo}
          />
        }
        {reassignPopUp &&
          <ReassignModal
            open={reassignPopUp}
            onClose={() => setReassignPopUp(false)}
            onReassignUser={reAssignTo}
            leadData={leadData}
            rowInfo={rowInfo}
          />
        }
        {
          bidPopUp &&
          <BidModal
            open={bidPopUp}
            onClose={() => setBidPopUp(false)}
            rowInfo={rowInfo}
            onRefreshPage={onRefreshPage}
            close={close}
            bidVal={bidVal}
            bidderDetails={bidderDetails}
            rebidReasonList ={rebidReasonList}
          />
        }
           {
          holdReleasePopUp &&
          < HoldReleaseModal
            open={holdReleasePopUp}
            onClose={() => setHoldReleasePopUp(false)}
            rowInfo={rowInfo}
            onRefreshPage={onRefreshPage}
            // close={close}
            // bidVal={bidVal}
            // bidderDetails={bidderDetails}
            // rebidReasonList ={rebidReasonList}
            orderDedctions={orderDedctions}
            goToSoldTab={goToSoldTab}
          />
        }
        
        {
          resellPopUp &&
          <RefundForm
            open={resellPopUp}
            onClose={() => setResellPopUp(false)}
            rowInfo={rowInfo}
            onRefreshPage={onRefreshPage}
            close={close}
            bidVal={bidVal}
            orderDedctions={orderDedctions}
            onDropRefresh={goToDropTab}
            goToSoldTab={goToSoldTab}
            LeadLifeInfo={LeadLifeInfo}
            LeadInfo = {LeadInfo}
          />
        }

        {statusPopUp &&
          <UpdateStatusQuote
            open={statusPopUp}
            onClose={() => setStatusPopUp(false)}
            leadData={leadData}
            rowInfo={rowInfo}
            tableStatus={status}
            onRefreshRow={onRefreshPage}
            orderDedctions={orderDedctions}
            customerDocuments={customerDocuments}
            close={close}
            leadStatusValue={leadStatusValue}
            onDropRefresh={goToDropTab}
            onFollowUpRefresh={goToFollowUpTab}
            onSoldRefresh={goToSoldTab}
            option={option}
            LeadLifeInfo={LeadLifeInfo}
          />
        }

{getUserActionInfo() === "WALLET_DASHBOARD" && refundPopUp &&
          <RefundToWalletDialog
            open={refundPopUp}
            onClose={() => setRefundPopUp(false)}
            openOTPPopPup={openOTPPopPup}
            leadData={LeadInfo}
            rowInfo={rowInfo}
            tableStatus={status}
            onRefreshRow={onRefreshPage}
            orderDedctions={orderDedctions}
            close={close}
            leadStatusValue={leadStatusValue}
            onDropRefresh={goToDropTab}
            onFollowUpRefresh={goToFollowUpTab}
            onSoldRefresh={goToSoldTab}
            option={option}
            paymentData={orderDedctions}
            LeadLifeInfo={LeadLifeInfo}
          />
        }

{otpPopUp && (
          <OTPPopUp
            open={otpPopUp}
              apiSelect={apiSelect}
              dataOTP={dataOTP}
              payload={payloadNew}
              orderId={orderDedctions.orderId}
              leadId={rowInfo.leadId}
              onClose={() => setOtpPopup(false)}
              setOpenModelRefund={()=> {
                dispatch(getShdOrderDeduction(rowInfo.leadId))
                setOpenModelRefund(true)
              }}
          />)}


{(openModelRefund ) &&
        <ViewHistoryDrawer
              drawer={
                openModelRefund
                  ? "table-drawer_SHD openSHD_new_live"
                  : "table-drawer_SHD"
              }
              handleClose={handleCloseRefundHistory}
              open={openModelRefund}
              paymentData={orderDedctions}
              selectedData={orderDedctions}
              
        />
                    }




        {authorizePopup &&
          <ReleaseAuthorize
            open={authorizePopup}
            onClose={() => setAuthorizePopup(false)}
            orderDedctions={orderDedctions}
            rowInfo={rowInfo}
            authData={authData}
            refreshPage={refreshToOld}
          />
        }
        {
          openCallModel &&
          <CallToCustomerDialog
            callOpen={openCallModel}
            onClose={handleCloseCallModel}
            rowData={auctionData[0].bidderInfo[bidNumIndex]}
            callerType="dealer"
            onRefreshPage={onRefreshPage}
          />
        }
         {
          collectModel &&
          <CollectDocModel
            open={collectModel}
            onClose={()=>setCollectModel(false)}
            rowInfo={rowInfo}
            orderDedctions={orderDedctions}
            // rowData={auctionData[0].bidderInfo[bidNumIndex]}
            // callerType="dealer"
            // onRefreshPage={onRefreshPage}
          />
        }
         {
          exceptionModel &&
          <ExceptionModel
            open={exceptionModel}
            onClose={()=>setExceptionModel(false)}
            rowInfo={rowInfo}
            exceptionList = {exceptionList}
            // rowData={auctionData[0].bidderInfo[bidNumIndex]}
            // callerType="dealer"
            // onRefreshPage={onRefreshPage}
          />
        }
        {
          editPrice &&
          <CustomerEPEditDialog
            tabValue={value}
            editPrice={editPrice}
            onClose={onCloseEditCEP}
            leadId={rowInfo.leadId}
            priceVal={price}
            bidData={auctionData[0]}
            rowData={auctionData[0].bidderInfo[bidNumIndex]}
            onRefreshPage={onRefreshPage}
          />
        }
        {
          <ConfirmationDialog 
          open={openConfirmationPopup}
          days={days}
          onYesClick={onYesClick}
          onClose={() => setOpenConfirmationPopup(false)}
          title='Do you want to sell the vehicle?'
          feature='quoteConfirmation'
          />
        }
        {openTranferDialog && 
        <VehicleSuggestionDialog open={openTranferDialog} setOpenTranferDialog={setOpenTranferDialog} c2cBuyerLeadId={openTranferDialog.c2cBuyerLeadId} leadData={leadData}/>
        }
      </Drawer>
    </div>
  );
}