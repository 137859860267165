import { AUTO_INSPECTION_AEs } from '../../core/constants/actions'
import { getNewState } from '../../core/utility'

const intialState = {
  allAEs:[],
  isLoading: false,
  totalInspection:0
}
export default function AutoInspectionAEsReducer(state = intialState, action) {
  switch (action.type) {
    case AUTO_INSPECTION_AEs.REQUEST:
      return getNewState(state, { isLoading: true })

    case AUTO_INSPECTION_AEs.SUCCESS:
      const { allAEs, totalInspection,pendingInspection } = action.payload;
      console.log("Reucer", allAEs)
      return getNewState(state, { allAEs, totalInspection,pendingInspection})

    case AUTO_INSPECTION_AEs.FAILURE:
      const { error } = action.payload
      return getNewState(state, { error, ...intialState })

    case AUTO_INSPECTION_AEs.RESET:
      return intialState

    default:
      return state
  }
}