import { GET_INTERESTED_BUYERS } from '../../core/constants/actions'
import { setNotification } from './notificationAction'
import { showLoader, hideLoader } from './loaderAction'
import { getInterestedDetails } from '../../core/services/C2cServices'

export function interestedBuyerAction(payload) {
  return dispatch => {
    dispatch(request())
    dispatch(showLoader())
    return getInterestedDetails(payload)
      .then(apiResponse => {
        if (apiResponse.isValid) {
          const { buyersList } = apiResponse
          dispatch(success({ intrestedBuyers:buyersList }))
        }
        else {
          dispatch(failure({ error: apiResponse.message }))
          dispatch(setNotification('danger', 'ERROR', apiResponse.message))
        }
        dispatch(hideLoader())
      })
  }
  function request() { return { type: GET_INTERESTED_BUYERS.REQUEST } }
  function success(intrestedBuyers) { return { type: GET_INTERESTED_BUYERS.SUCCESS, payload: { ...intrestedBuyers } } }
  function failure(error) { return { type: GET_INTERESTED_BUYERS.FAILURE, payload: { ...error } } }
}

export function resetBiddingLead() {
  return { type: GET_INTERESTED_BUYERS.RESET }
}