import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { showLoader, hideLoader } from '../../redux/actions/loaderAction'
import { setNotification } from '../../redux/actions/notificationAction'
import { updateCustomerExpectedPrice } from '../../core/services/miscServices'
import { useDispatch } from "react-redux"
import { updateBidAmt } from '../../core/services/biddingServices'
import { EditIcon } from '../../core/utility/iconHelper'
import { getDate, renderString, getAmount } from '../../core/utility'
import { FormHelperText } from '@material-ui/core'

const onlyContactNumber = (value) => {
    if (!value) {
        return value
    }
    let onlyContactNumbers = value.replace(/[^\d]/g, '')
    onlyContactNumbers.replace(/(?!^)+/g, '')
    return onlyContactNumbers.slice(0, 7)
}


const EditPriceModal = ({ onClose, editPrice, priceVal, onRefreshPage, leadId, tabValue, bidData, rowData, list, onAcceptOffer }) => {

    const dispatch = useDispatch()
    const [price, setPrice] = useState(list.voucherPrice)
    const [openCEP, setOpenCEP] = useState(true)
    const [shdComments, setShdComments] = useState('')
    const [commentsError, setComentsError] = useState(false)

    const handleUpdatePrice = (btnValue) => {
        const payload = {
            leadId: list.leadId,
            comments: shdComments,
            shdResponceToFranchise: btnValue === 'accpet' ? 'ACCEPTED' : 'REJECTED',
            customerExpectedPrice: price
        }
        if(btnValue === 'accpet'){
            onAcceptOffer(payload, btnValue)
        }
        else {
            if (shdComments) {
                onAcceptOffer(payload, btnValue)
            }
            else {
                if(btnValue === 'reject')
                setComentsError(true)
            }
        }
    }

    const handlePriceedit = (e) => {
        const regexp = /^[0-9\b]+$/;
        if (e.target.value === '' || regexp.test(e.target.value)) {
            let value = onlyContactNumber(e.target.value)
            setPrice(value)
        }
    }
    const onShdComments = (e) => {
        setShdComments(e.target.value)
        setComentsError(false)
    }

    const openInputForPrice = () => {
        setOpenCEP(false)
    }

    return (
        <Dialog
            open={editPrice}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
            className='dialogWrap'
        >
            <DialogContent>
                <h2 style={{ fontFamily: 'ProximaNovaSemibold', fontSize: '20px', color: '#19865f', marginBottom: '40px' }}> {tabValue === 'Bidding History' ? 'Edit Bidding Price' : 'Edit Voucher Price'} <span className="float-right" onClick={onClose} style={{ cursor: 'pointer', color: '#333' }}>&#10005;</span></h2>
                <div className="row mb-2">
                    <div className="col-6" style={{ marginTop: '6px' }}>
                        {`Store\u00a0Asking\u00a0Price`}
                    </div>
                    <div className=" col-6 customInput">
                        <input value={getAmount(list.storeExecutePrice)} style={{ border: '1px solid #f5f0f0', backgroundColor: '#fff', fontFamily: 'ProximaNovaSemibold', padding: '6px 10px' }} disabled></input>
                    </div>
                </div>
                <div className="row mt-4" style={{ flexWrap: 'nowrap' }}>
                    <div className="col-6" style={{ marginTop: '6px' }}>
                        {`Voucher\u00a0Price`}
                    </div>
                    <div className=" col-6 customInput">
                        <input value={price} style={{ border: '1px solid #f5f0f0', backgroundColor: '#fff', fontFamily: 'ProximaNovaSemibold', padding: '6px 10px' }} onChange={handlePriceedit} disabled={openCEP}></input>
                        <img
                            src={EditIcon}
                            style={{
                                marginTop: "5px",
                                marginLeft: "5px",
                                cursor: "pointer", width: "fit-content"
                            }}
                            height="15px"
                            onClick={openInputForPrice}
                        />
                    </div>
                </div>
                <div className="row mt-4" style={{ flexWrap: 'nowrap' }}>
                    <div className="col-4" style={{ marginTop: '6px' }}>
                        {`Comments`}<span style={{ color: 'red' }}>*</span>
                    </div>
                </div>
                <div className="row customInput mt-2">
                    <textarea id="bikePrice" className="customerInterstedPrice" rows="4" cols="50" style={{ width: '100%', marginLeft: '16px', maxWidth: '430px', border: 'none', borderRadius: '5px' }} value={shdComments} onChange={onShdComments}></textarea>
                </div>
                {
                    commentsError &&
                    <FormHelperText style={{ color: 'red' }}> Please enter comments</FormHelperText>
                }
                <div className="row mt-4" style={{ flexWrap: 'nowrap' }}>
                    <div className="col-4" style={{ marginTop: '6px' }}>
                        {`Recent Comments`}
                    </div>
                </div>
                <div className="row customInput mt-2">
                    <p id="bikePrice" rows="4" cols="50" style={{ marginLeft: '16px' }}>{renderString(list.leadComments)}</p>
                </div>
                <p style={{ float: 'right' }}>{getDate(list.commentsAddedOn)}</p>
            </DialogContent>
            <DialogActions>
                <button onClick={() => handleUpdatePrice('reject')} style={{ border: '1px sloid #DA2128', backgroundColor: '#DA2128', color: '#FFFFFF', padding: '8px 53px', borderRadius: '22px', marginBottom: '15px', fontFamily: 'ProximaNovaSemibold', }}>Reject Offer</button>
                <button onClick={() => handleUpdatePrice('accpet')} style={{ border: '1px sloid #47B26C', backgroundColor: '#47B26C', color: '#FFFFFF', padding: '8px 53px', borderRadius: '22px', marginBottom: '15px', fontFamily: 'ProximaNovaSemibold', }}>Accept Offer</button>
            </DialogActions>
        </Dialog>
    )
}
export default EditPriceModal
