import React,{useEffect, useState} from 'react'
import filterImg from '../../../../../shared/img/icons/filter-icon.svg'
import DropDown from '../../../../../shared/components/form/DropDown';
import { DatePicker, Loader } from 'rsuite'
import { disableDates } from '../../../../../core/utility/stringUtility'
// import DeepFilters from "./DeepFilters"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getPremiumAccess } from '../../../../../core/services/authenticationServices';
import { PriorityImage } from '../../../../../core/utility/iconHelper';

export default function Filter({
  startTime,
  endTime,
  city,
  status,
  onEndDateChange,
  onStartDateChange,
  onStatusChange,
  onChangeCity,
  onClearFilters,
  onApplyFilters,
  resetFilters,
  cityList,
  statusTypes,
}) {
// const [advancedFilter,setAdvancedFilter]=useState(false)




// useEffect(()=>{
//   onApplyFilters()
// },[])

// const dateTypesForfilter=[
//   { label: 'Lead Created Date', value: null },
//   { label: 'DocQc Approval Date', value: 'DOCQCAPPROVEDATE' },
//   { label: 'Payment Initiated Date', value: 'PAYMENTINITIATEDDATE' },
//   { label: 'Payment Completed Date', value: 'PAYMENTCOMPLETEDDATE' },
//   { label: 'Cp Payment Date', value: 'CPPPAYMENTDATE' },
//   { label: 'In Custody Completed Date', value: 'INCUSTODYDATE' },
//   {label:"Lead Sold Date",value:"SOLD DATE"}
// ]



  // const retDateType = (status)=> {
  //   if(status === 'UNASSIGNED') return unAssignedDateType
  //   if(status === 'PENDING') return pendingDateType
  //   if(status === 'FOLLOWUP') return followupDateType
  //   if(status === 'DROP') return dropDateType
  //   if(status === 'SOLD') return soldDateType
  // }



  // const getStatusValue = () => {
  //   if(status === 'PENDING' || status === 'FOLLOWUP') return true
  //   else return false 
  // }

  // const handleAdvancedFilter=()=>{
  //   setAdvancedFilter(!advancedFilter)
  // }
  


  return (
    <div className='anti-body'>
    <div className="cpp-filter-container interested-filter" style={{ flexWrap: 'nowrap'}}>
      {/* {
        isFilterLoading &&
        <Loader
          backdrop
          vertical
          content="Filter Loading..."
          style={{ zIndex: 9999 }}
          size="md"
        />
      } */}
      <div className="filter-icon">
        <img src={filterImg} alt="Filter Icon" className="filter-img" />
        <p className="filter-name">Filters</p>
      </div>
      <div className="cpp-filter-options filterOptions">
        <div className="date-filter-container">
          
          <div className="filter-container">
            <DropDown
              placeholder="Select Status"
              options={statusTypes}
              value={status}
              onChange={onStatusChange}
            />
          </div>
          
          {/* <div className="filter-container" style={{width:"200px"}} >
            <DropDown
              placeholder="Select Date Type"
              value={dateType}
              options={retDateType(status)}
              onChange={onDateTypeChange}
            />
          </div> */}
          {/* <p className="from">From</p> */}
          <div className="calendar-conatiner">
            <DatePicker
              placeholder='From Date'
              value={startTime}
              block
              format="DD-MMM-YYYY"
              cleanable={false}
              oneTap
              ranges={[]}
              onChange={onStartDateChange}
              disabledDate={date => disableDates('', endTime, date)}
            />
          </div>
          {/* <p className="to">To</p> */}
          <div className="calendar-conatiner">
            <DatePicker
              placeholder='To Date'
              value={endTime}
              block
              format="DD-MMM-YYYY"
              cleanable={false}
              oneTap
              ranges={[]}
              onChange={onEndDateChange}
              disabledDate={date => disableDates(startTime, '', date)}
            />
          </div>
          <div className="filter-container">
            <DropDown
              options={cityList}
              value={city}
              placeholder="Select City"
              onChange={onChangeCity}
            />
          </div>
          {/* <div className="filter-container">
              <DropDown
                placeholder="Select Outlet"
                value={selectedOutlet}
                options={outletList}
                onChange={setSelectedOutlet}
              />
            </div>
          {
            status && status !== 'UNASSIGNED' &&
            <div className="filter-container">
              <DropDown
                placeholder="Select Agent"
                value={agent}
                options={agentList}
                onChange={onAgentChange}
              />
            </div>
          }
          <div className="filter-container">
              <DropDown
                placeholder="Select DIY Type"
                onChange={onDiyChange}
                options={DIYOption}
                value={diy}
              />
            </div> */}
            {/* {getPremiumAccess() && 
            <div style={{display:"inline-block",marginRight:"30px"}}>
            <div className="dropdown-wraper priority-filter">
              <div>
                <img src={PriorityImage}/>
                <label>Priority Bikes</label>
              </div>
              <input type="checkbox" checked={priorityFilter} onChange={()=>{setPriorityFilter(!priorityFilter)}}/>
            </div>
            </div>} */}
          <div className="filter-container">
            <button className="font-regular" style={{ marginRight: 15, color: '#ffffff', padding: '8px 20px', borderRadius: '22px', backgroundColor: '#4DBD74', border: '1px solid #35AC5E' }} onClick={onApplyFilters}>Apply</button>
            <button className="font-regular" onClick={resetFilters} style={{ border: '#DEDEDE', backgroundColor: '1px solid #E8E8E8', color: '#333333', padding: '8px 20px', borderRadius: '22px' }}>Clear</button>
          </div>
          {/* {status && status ==='SOLD' && <div className="advanced-filter-button" style={{maxWidth: advancedFilterCount===0?"40px":"67px",cursor:"pointer"}} onClick={handleAdvancedFilter}>
            {advancedFilterCount!==0 && <button className='filter-count'>{advancedFilterCount}</button>}
            <button style={{background:"none"}}  > <img alt="advancedFilter" src={AdvancedFilterIcon}/></button>
          </div>} */}
        </div>
      </div>
      
    </div>
    
    
    </div>
  )
}
