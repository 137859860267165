import React from "react"
import {Avatar} from "../ChatBox"
import "./index.scss"
import {removeUnderscore} from "../../../core/utility/stringUtility"

const Comment = (props) => {

    const {data}=props
    const {comments,commentedDate,userType,profileImage,userName}=data

    const moment = require('moment');
    const targetDate = moment(commentedDate, 'DD-MMM-YYYY hh:mm:ss a');
    const diff = moment.duration(moment().diff(moment(targetDate)));
    const outputDateStr = moment(targetDate, 'DD-MMM-YYYY hh:mm:ss a').format('Do MMM YYYY h:mm A');

    const getTimeLapsed=(minutesLapsed)=>{
        let time=0;
        if(minutesLapsed<60){
            time=Math.floor(minutesLapsed)
            return time===0?"Just now":`${time}min ago`

        }
        else if( minutesLapsed<(24*60))
           {
                time=Math.floor(minutesLapsed/60)
                return `${time}h  ago`
            }
        else if(minutesLapsed<(30*24*60)){
            time=Math.floor(minutesLapsed/(60*24))
            return `${time} ${time<=1?"day":"days"}  ago`
        }
        else if(minutesLapsed<(30*24*60*12)){
            time=Math.floor(minutesLapsed/(60*24*30))
            return `${time} ${time<=1?"month":"months"}  ago`
        }
        else {
            time=Math.floor(minutesLapsed/(60*24*30*12))
            return `${time} ${time<=1?"year":"years"}  ago`
        }

        }

const getAvatarImage=()=>{
    if(profileImage!==null && profileImage!==undefined && profileImage!==""){
        return <img className="avatar" src={profileImage}/>
    }
    return <div className={`${Avatar(userName!==null?userName.charAt(0):"Anonymous")} avatar`}>{userName!==null?userName.charAt(0).toUpperCase():"U"}</div>

}

    return(<div className="comment-box-container">
    <div className="comment">
        {getAvatarImage(userName)}
        <div className="comment-text">
            <div className='comment-name-time-date-container'>
                <div>
                    <div className="comment-name-container">
                        <h4 className='comment-owner'>{userName!==null?(userName.charAt(0).toUpperCase() + userName.slice(1)):"Anonymous"}</h4>
                        <div className="time">&#8226; {getTimeLapsed(diff.asMinutes())}</div>
                        <div className="time">&#8226; {outputDateStr}</div>
                    </div>
                    <p className="comment-type">{userType!==null?removeUnderscore(userType):"Anonymous"}</p>
                    </div>
            </div>
            <p className='comments-text'>{comments}</p>
        </div>
    </div>



</div>)}


export default Comment