import { SIGNUP_AE_REJECT_REASONS } from '../../core/constants/actions'
import { getNewState } from '../../core/utility'

const intialState = {
    rejectReasons: [],
    isLoading: false,
}
export default function aeRejectReasonsReducer(state = intialState, action) {

    switch (action.type) {
        case SIGNUP_AE_REJECT_REASONS.REQUEST:
            return getNewState(state, { isLoading: true })

        case SIGNUP_AE_REJECT_REASONS.SUCCESS:
            const { rejectReasons } = action.payload;
            return getNewState(state, { rejectReasons })

        case SIGNUP_AE_REJECT_REASONS.FAILURE:
            const { error } = action.payload
            return getNewState(state, { error, ...intialState })

        case SIGNUP_AE_REJECT_REASONS.RESET:
            return intialState

        default:
            return state
    }
}
