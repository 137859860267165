import React, { FC, useState, useEffect, useRef } from 'react'
import { connect, ConnectedProps, useSelector } from 'react-redux'
import { renderString, getAmount, renderDate, getDate, hideEmail } from '../../../core/utility/stringUtility'
import { setNotification } from "../../../redux/actions/notificationAction"
import CallToCustomer from './CallActionPopUp'
import OTPPopup from './OTPPopup'
import { useDispatch } from "react-redux"
import { callToCareCustomer, sendOtpToCustomer, confirmOTP, getCustomerDocumentsDetails } from '../../../core/services/shdServices'
import { getMobile, getRole, getUserID } from '../../../core/services/rbacServices'
import { showLoader, hideLoader } from '../../../redux/actions/loaderAction'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableHead from '@material-ui/core/TableHead'
import { Pagination } from '@material-ui/lab';
import NoResultsFound from '../../../shared/components/NoResultFound'
import './Error.scss'
import Timer from './Timer'
import { Button } from 'rsuite'
import { makeStyles } from "@material-ui/core/styles";
import { updateStatus, getHistoryData } from '../../../core/services/shdServices'
import UpdateStatusQuote from './UpdateStatusPop'
import PendingDrawer from './PendingDrawer'
import FollowupDrawer from './FollowUpDrawer'
import UpdateDropStatus from './UpdateDropStatus'
import DropDrawer from './DropDrawer'
import SoldStatusDrawer from './SoldStatusDrawer'
import moment from 'moment'
import { getConfirmationList } from '../../../redux/actions/quoteConfirmationAction'
import EditDialog from './EditModelComponent'
import { EditIcon, PriorityImage, WhiteCallIcon } from '../../../core/utility/iconHelper'
import StatusSlider from '../StatusSlider'
import CustomerEPEditDialog from './EdiitPriceModal'
import { interestedBuyerAction } from '../../../redux/actions/interestedBuyersAction'
import { leadLifecycleDetails } from '../../../redux/actions/biddingLifecycleAction'
import { getShdOrderDeduction } from '../../../redux/actions/shdOrderDeductionAction'
import { getShdCustomerDocuments } from '../../../redux/actions/shdCustomerDocumentsAction'
import { getExceptionList } from '../../../core/services/miscServices'
import CollectDocModel from '../StatusSlider/components/CustomerDocument/CollectDocModel.jsx'
import Tooltip from "@material-ui/core/Tooltip";
import ChatBox from "../../../shared/components/ChatBox";
import PriorityLabel from "../../../shared/components/PriorityLabel";
import DIYLabel from '../../../shared/components/DIYLabel'
import EditPriceModal from '../../../shared/components/EdiitPriceModal'
import { updateFranchisePrice } from '../../../core/services/franchiseServices'
import { getPremiumAccess } from '../../../core/services/authenticationServices'
import { updateStatusC2C } from '../../../core/services/C2cServices'
import { leadDetails } from '../../../redux/actions/biddingDetailsAction'
import CustInstructionDialog from './CustInstructionDialog.jsx'
import { getStatusColor } from '../../../core/utility/colorUtility.js'
const rows = [
  { id: "prirityLabel", label: "" },
  { id: "Seller Lead ID", label: "Seller Lead ID" },
  { id: "Lead Created Date", label: "Lead Created Date" },
  { id: 'Listed Date', label: 'Listed Date' },
  { id: "Vehicle Details", label: "Vehicle Details" },
  { id: "Seller Contact Info", label: "Seller Contact Info" },
  { id: "Interested Buyers", label: "Interested Buyers" },
  // { id: "Agent Name", label: "Agent Name" },
  { id: "C2C Status", label: "C2C Status" },
  { id: "Listed Price", label: "Listed Price" },
  { id: "Seller Expected Price", label: "Seller Expected Price" },
  { id: "action", label: "Action" },
  { id: "chatBox", label: "Chat" },
];

const rowsForPending = [
  { id: "prirityLabel", label: "" },
  { id: "Seller Lead ID", label: "Seller Lead ID" },
  { id: "Lead Created Date", label: "Lead Created Date" },
  { id: 'Listed Date', label: 'Listed Date' },
  { id: "Vehicle Details", label: "Vehicle Details" },
  { id: "Seller Contact Info", label: "Seller Contact Info" },
  { id: "Interested Buyers", label: "Interested Buyers" },
  { id: "Agent Name", label: "Agent Name" },
  { id: "C2C Status", label: "C2C Status" },
  { id: "Listed Price", label: "Listed Price" },
  { id: "Seller Expected Price", label: "Seller Expected Price" },
  // { id: "action", label: "Action" },
  { id: "chatBox", label: "Chat" },
];
const rowsForSold = [
  { id: "prirityLabel", label: "" },
  { id: "Seller Lead ID", label: "Seller Lead ID" },
  { id: "Lead Created Date", label: "Lead Created Date" },
  { id: 'Listed Date', label: 'Listed Date' },
  { id: "Vehicle Details", label: "Vehicle Details" },
  { id: "Seller Contact Info", label: "Seller Contact Info" },
  { id: "Interested Buyers", label: "Interested Buyers" },
  { id: "Agent Name", label: "Agent Name" },
  { id: "C2C Status", label: "C2C Status" },
  { id: "Listed Price", label: "Listed Price" },
  { id: "Seller Expected Price", label: "Seller Expected Price" },
  { id: "chatBox", label: "Chat" },
];
const rowsForDrop = [
  { id: "prirityLabel", label: "" },
  { id: "Seller Lead ID", label: "Seller Lead ID" },
  { id: "Lead Created Date", label: "Lead Created Date" },
  { id: 'Listed Date', label: 'Listed Date' },
  { id: "Vehicle Details", label: "Vehicle Details" },
  { id: "Seller Contact Info", label: "Seller Contact Info" },
  { id: "Interested Buyers", label: "Interested Buyers" },
  { id: "Agent Name", label: "Agent Name" },
  { id: "C2C Status", label: "C2C Status" },
  { id: "Listed Price", label: "Listed Price" },
  { id: "Seller Expected Price", label: "Seller Expected Price" },
  { id: "chatBox", label: "Chat" },
];

const dummyData={}

const ConfirmationTable = ({
  onChangePage,
  status,
  handleStatusFilter,
  refreshPage,
  confirmList,
  currentPage,
  totalCount,
  onValuatorDetails,
  value,
  handleValue,
  onPendingRefresh,
  refreshDropPage,
  refreshFollowUpPage,
  refreshSoldPage,
}) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      marginTop: theme.spacing(3),
      overflowX: "auto",
    },
    table: {
      "& .MuiTableCell-body": {
        minWidth: "8rem",
      },
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor: "#FBEEED",
      },
    },
    tableCell: {
      "$selected &": {
        color: "yellow",
      },
    },
    hover: {},
    selected: {},
  }));

  const [leadData, setLeadData] = useState({});
  const [callPopUp, setCallPopUp] = useState(false);
  const [otpPopup, setOtpPopUp] = useState(false);
  const [wrongOtp, setWrongOtp] = useState(false);
  const [selectedID, setSelectedID] = useState("");
  const [drawerClass, setDrawerclass] = useState("table-shd-drawer");
  const dispatch = useDispatch();
  const [rowInfo, setRowInfo] = useState({});
  const [statusPopUp, setStatusPopup] = useState(false);
  const [historyData, setHistoryData] = useState({});
  const [historyDataFollowup, setHistoryDataFollowUp] = useState({})
  const [dropstatusPopUp, setDropStatusPopup] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [username, setUserName] = useState('')
  const [email, setEmail] = useState('')
  const [leadId, setLeadId] = useState('')
  const [openSlider, setOpenSlider] = useState(false)
  const [editPrice, setEditPrice] = useState(false)
  const [price, setPrice] = useState('')
  const classes = useStyles()
  const [leadDetailsData, setLeadDetailsData] = useState({})
  const [enable, setEnable] = useState(true)
  const [list, setList] = useState({})
  // const LeadInfo = useSelector((state) => state.interestedBuyersList.intrestedBuyers)
  const LeadInfo = useSelector((state) => state.biddingDetails.leadDetails)
  const LeadBuyersList = useSelector((state) => state.interestedBuyersList.intrestedBuyers)
  const LeadLifeInfo = useSelector((state => state.biddingListLifecycleReducer.leadDetails))
  const orderDedctions = useSelector((state) => state.orderDeductions.leadDetails)
  const customerDocuments = useSelector((state) => state.customerDocuments.customerDetails)
  const [openEdit, setOpenEdit] = useState(false)
  const [openCustBox,setOpenCustInsBox]=useState(false)

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevStatus = usePrevious(status);

  useEffect(() => {
    if (prevStatus !== status) {
      setDrawerclass("table-shd-drawer");
    }
  }, [status]);

  const handlePageChange = (event, pageNumber) => {
    if (pageNumber !== currentPage) {
      onChangePage(pageNumber);
    }
  };
  const handleRefresh = (value) => {
    setOpenSlider(false);
    refreshPage(value);
  };

  const onRefreshDrop = () => {
    setOpenSlider(false);
    refreshDropPage();
  };
  const onRefreshFollowUp = () => {
    setOpenSlider(false);
    refreshFollowUpPage();
  };
  const onRefreshSold = () => {
    setOpenSlider(false);
    refreshSoldPage();
  };

  const callToCustomer = (data) => {
    setLeadData(data);
    setCallPopUp(true);
  };
  const closeSlider = () => {
    setOpenSlider(false);
    setEnable(true);
    localStorage.removeItem("optionValue");
  };
  const handleEnable = (val) => {
    setEnable(val);
  };

  const sendOtp = async (data) => {
    setLeadData(data);
    setOtpPopUp(true);
    dispatch(showLoader());
    const apiResponse = await sendOtpToCustomer(data.leadId);
    dispatch(hideLoader());
    if (apiResponse.isValid) {
      dispatch(
        setNotification({
          type: "success",
          message: apiResponse.message,
          title: "Success",
        })
      );
    } else {
      dispatch(
        setNotification({
          type: "error",
          message: apiResponse.message,
          title: "Error",
        })
      );
    }
  };

  const callAction = async (data, info) => {
    const payload = {
      fromNumber: getMobile(),
      toNumber: data.sellerMobileNumber,
      userId: getUserID(),
      leadId: data.sellerLeadId,
    };
    dispatch(showLoader());
    const apiResponse = await callToCareCustomer(payload);
    dispatch(hideLoader());
    if (apiResponse.isValid) {
      dispatch(
        setNotification({
          type: "success",
          message: apiResponse.message,
          title: "Success",
        })
      );
      if (status === "SOLD" || status === "DROP") {
        setStatusPopup(false);
      } else {
        setStatusPopup(true);
      }
    } else {
      dispatch(
        setNotification({
          type: "error",
          message: apiResponse.message,
          title: "Error",
        })
      );
    }
    setCallPopUp(false);
  };

  const otpVerify = async (otpVal, data) => {
    dispatch(showLoader());
    const payload = {
      otp: otpVal,
      leadId: data.leadId,
    };
    const apiResponse = await confirmOTP(payload);
    dispatch(hideLoader());
    if (apiResponse.isValid) {
      dispatch(
        setNotification({
          type: "success",
          message: apiResponse.message,
          title: "Success",
        })
      );
      setOtpPopUp(false);
      setWrongOtp(false);
      window.location.reload();
    } else {
      setWrongOtp(true);
    }
  };

  const closePopUp = () => {
    setOtpPopUp(false);
    setWrongOtp(false);
  };

  const handleRowChange = (list) => {
    console.log(list,"hitted 4 apis")
    setSelectedID(list.leadId)
    setRowInfo(list)
    setLeadData(list)
    const interestedBuyerPayload={
      inventoryId: list.inventoryId,
      pageNum:1
  }
    dispatch(interestedBuyerAction(interestedBuyerPayload))
    dispatch(leadDetails(list.leadId))
    dispatch(leadLifecycleDetails(list.leadId))
    dispatch(getShdOrderDeduction(list.leadId))
    dispatch(getShdCustomerDocuments(list.leadId))
    
    setOpenSlider(true)
    handleValue('')
    // handleStatusFilter('')
  };

  const handleAssignChange = (event, list) => {
    event.stopPropagation();
    const payload = {
      userId: getUserID(),
      leadId: list.sellerLeadId,
      status: "C2C_PENDING",
      // crmLeadId: list.crmLeadId,
    };
    dispatch(showLoader());
    updateStatusC2C(payload).then((apiRes) => {
      if (apiRes.isValid) {
        dispatch(hideLoader());
        dispatch(
          setNotification({
            type: "success",
            message: apiRes.message,
            title: "Success",
          })
        );
        onPendingRefresh();
      } else {
        dispatch(
          setNotification({
            type: "error",
            message: apiRes.message,
            title: "Error",
          })
        );
      }
    });
  };

  const onRefreshRow = () => {
    setDrawerclass("table-shd-drawer");
    refreshPage();
  };

  const handleDropStatus = () => {
    setDropStatusPopup(true);
  };

  const getFinalQuote = (list) => {
    let a = Number(list.bidAmount);
    let b = Number(list.cityLevelCommission);
    return a - b;
  };

  const updateStatusForm = () => {
    setStatusPopup(true);
  };
  const openEditDialog = (e, leadId, name, email) => {
    e.stopPropagation();
    setOpenDialog(true);
    setLeadId(leadId);
    setUserName(name);
    setEmail(email);
  };
  const openCallDialog = (e, data) => {
    e.stopPropagation();
    callToCustomer(data)
  };
  const closeDialog = () => {
    setOpenDialog(false);
    setLeadId("");
    setUserName("");
    setEmail("");
  };
  const onOpenEditCEP = (e, price, leadId) => {
    e.stopPropagation();
    setEditPrice(true);
    setPrice(price);
    setLeadId(leadId);
  };
  const onOpenEditSP = (e, list) => {
    setSelectedID(list.leadId)
    e.stopPropagation();
    setList(list)
    setOpenEdit(true)
  };
  const onCloseEditSP = () => {
    setList({})
    setOpenEdit(false);
  };
  const onCloseEditCEP = () => {
    setEditPrice(false);
  };
  const closeAndRefresh = () => {
    setOpenSlider(false);
    refreshPage();
  };

  const valuatorDetails = (details) => {
    if (onValuatorDetails) {
      onValuatorDetails({
        storeId: details.storeId,
        valuatorId: details.valuatorId,
      });
    }
  };

  const getStatus = (leadStatus, time) => {
    if (leadStatus === "AUCTION_COMPLETED") {
      return (
        <span
          style={{
            backgroundColor: "#DAF2DD",
            color: "#196834",
            padding: "1px 5px",
            borderRadius: "4px",
            width: "102px",
          }}
        >
          Auction Completed
        </span>
      );
    }
    if (leadStatus === "Central_Tagging_Inspected") {
      return (
        <span
          style={{
            backgroundColor: "#FFE1C5",
            color: "#B96716",
            padding: "1px 5px",
            borderRadius: "4px",
            width: "102px",
          }}
        >
          CT Inspected
        </span>
      );
    }
    if (leadStatus === "DROPPED") {
      return (
        <span
          style={{
            backgroundColor: "#FFD4D6",
            color: "#DA2128",
            padding: "1px 5px",
            borderRadius: "4px",
            width: "102px",
          }}
        >
          Dropped
        </span>
      );
    } else {
      return (
        <>
          <span
            style={{
              backgroundColor: "#E9F4FF",
              color: "#366896",
              padding: "1px 15px",
              borderRadius: "4px",
            }}
          >
            {" "}
            Ongoing{" "}
          </span>
          <Timer time={time} />
        </>
      );
    }
  };

  const reAssignTo = (list) => {
    setOpenSlider(false);
    const payload = {
      userId: getUserID(),
      leadId: list.leadId,
      status: "ASSIGN",
      crmLeadId: list.crmLeadId,
    };
    updateStatus(payload).then((apiRes) => {
      if (apiRes.isValid) {
        dispatch(hideLoader());
        dispatch(
          setNotification({
            type: "success",
            message: apiRes.message,
            title: "Success",
          })
        );
        onPendingRefresh();
      } else {
        dispatch(
          setNotification({
            type: "error",
            message: apiRes.message,
            title: "Error",
          })
        );
      }
    });
  };

  const acceptOfferStore = (payload, type) => {
    const finalPayload = {
      ...payload,
      userId: getUserID()
    }
    updateFranchisePrice(finalPayload).
      then(apiResponse => {
        if (apiResponse.isValid) {
          dispatch(setNotification('success', 'SUCCESS', apiResponse.message))
          if (type === 'accpet') {
            handleValue('Commission')
            setOpenSlider(true)
          }
          setOpenEdit(false)
          const payload = {
            status: status,
            pageNum: 1,
            userId: getUserID()
          }
          dispatch(getConfirmationList(payload))
          dispatch(getShdOrderDeduction(selectedID))
        }
        else {
          dispatch(setNotification('danger', 'ERROR', apiResponse.message))
          setOpenEdit(false)
        }
      })
  }

  const getStoreEditModal = () => {
    if (status === 'PENDING' || status === 'FOLLOWUP' || status === 'DROP') return true
    return false
  }


  return (
    <div className="table-wraper">
      <Table size="small" className={classes.table}>
        <TableHead>
          <TableRow>
            {status === "PENDING" &&
              rowsForPending.map((row) => (
                <TableCell key={row.id}>{row.label}</TableCell>
              ))}
            {status === "SOLD" &&
              rowsForSold.map((row) => (
                <TableCell key={row.id}>{row.label}</TableCell>
              ))}
            {/* {status === "FOLLOWUP" &&
              rowsForFollowup.map((row) => (
                <TableCell key={row.id}>{row.label}</TableCell>
              ))} */}
            {status === "DROP" &&
              rowsForDrop.map((row) => (
                <TableCell key={row.id}>{row.label}</TableCell>
              ))}
            {status === "UNASSIGNED" &&
              rows.map((row) => (
                <TableCell key={row.id}>{row.label}</TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Boolean(confirmList.length) &&
            confirmList.filter((accessItem)=>((!accessItem.premiumLead) || getPremiumAccess())).map((list, index) => {
              return (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={`${list.leadId}${index}`}
                  onClick={() => handleRowChange({...list,leadId:list.sellerLeadId})}
                  selected={selectedID === list.leadId}
                  // classes={{ hover: classes.hover, selected: classes.selected }}
                  className={classes.tableRow}
                  // className={list.storeType === "INTERNAL" ? 'oemSupply' :''}
                >
                  <TableCell style={{ minWidth: "2rem" }}>
                    {list.premiumLead && <img className='priorityImage' size={16} src={PriorityImage}/>}
                  </TableCell>
                  <TableCell>
                    {list.sellerLeadId}
                    {/* <>
                      <div style={{ display: 'flex' }} className='row'>
                        <div className='col-5'>
                          {list && list.leadSource && list.leadSource.length > 8 ? (
                            <Tooltip
                              title={renderString(list.leadSource)}
                              placement="bottom"
                              style={{ backgroundColor: '#565656', color: '#FFFFFF' }}
                            >
                              <span style={{ cursor: "pointer" }}>
                                <p className="leadSource" style={{ fontSize: '8px' }}>{list.leadSource.substring(0, 8)}....</p>
                              </span>
                            </Tooltip>
                          ) : (
                            <p className="leadSource" style={{ fontSize: '8px' }}>{list.leadSource}</p>
                          )}
                        </div>
                        {
                          list.leadFranchiseStatus && list.leadFranchiseStatus === 'INTERESTED' ?
                            <div className='col-7' style={{ marginLeft: '-25px' }}>
                              <button className='storeIntersted'>STORE INTERESTED</button>
                            </div> : ''
                        }
                      </div>
                    </> */}
                    {/* {Number(list.premiumBikeFlag) === 1 && (
                      <div
                        style={{
                          backgroundColor: "#E98000",
                          color: "white",
                          padding: "4px 2px",
                          textAlign: "center",
                          width: "4rem",
                          borderRadius: "4px",
                        }}
                      >
                        Premium
                      </div>
                    )} */}
                    {/* {status === "FOLLOWUP" &&
                      (moment(getDate(list.followupDate)).diff(
                        moment(getDate(moment()))
                      ) > 0 ? (
                        <></>
                      ) : (
                        <p style={{ color: "#D92128" }}>Delayed</p>
                      ))} */}
                  </TableCell>
                  {/* <TableCell>
                    <DIYLabel status={renderString(list.diyType)} showPremium = {list.storeType === "INTERNAL"} />
                  </TableCell> */}
                  {/* <TableCell>
                    <PriorityLabel status={renderString(list.leadPriority)} />
                  </TableCell> */}
                  <TableCell style={{ minWidth: "150px" }}>
                    {getDate(list.leadCreatedDate)}
                    <p style={{ margin: "0px" }}>
                      <small
                        style={{
                          color: "gray",
                          fontSize: "9px",
                          wordBreak: "break-all",
                        }}
                      >
                        {renderString(list.cityName)}
                      </small>
                    </p>
                  </TableCell>

                  {(status === "UNASSIGNED" || status === "PENDING") && (
                    <TableCell>{getDate(list.listedDate)}</TableCell>
                  )}
                  {/* {status !== "UNASSIGNED" && (
                    <TableCell>{getDate(list.holdDate ? list.holdDate : '')}</TableCell>
                  )} */}
                  {status === "SOLD" && (
                    <TableCell>{getDate(list.soldDate)}</TableCell>
                  )}
                  {status === "FOLLOWUP" && (
                    <TableCell>{getDate(list.followupDate)}</TableCell>
                  )}

                  {status === "DROP" && (
                    <TableCell>{getDate(list.dropDate)}</TableCell>
                  )}
                  <TableCell style={{ minWidth: "140px" }}>
                    <div>
                      <p>{renderString(list.regNumber)}</p>
                      <p style={{ margin: "0px" }}>
                        <small
                          style={{
                            color: "gray",
                            fontSize: "9px",
                            wordBreak: "break-all",
                          }}
                        >
                          {/* {renderString(list.mmv)} {renderString(list.mfgYear)} */}
                          {renderString(list.mmvy)} 
                        </small>
                      </p>
                    </div>
                    {/* {
                      list.leadType.startsWith('TP') ?
                        <span style={{ border: '1px solid #C7FACB', backgroundColor: '#D9FFDC', color: '#4DBD74', padding: '1px 5px', borderRadius: '4px' }}> {list.leadType} </span> :
                        <span style={{ border: '1px solid #FFDAD6', backgroundColor: '#FCE9E7', color: '#FA5C4B', padding: '1px 5px', borderRadius: '4px' }}>{list.leadType}</span>
                    } */}
                  </TableCell>
                  <TableCell style={{ minWidth: "160px" }}>
                    <div>
                      <div style={{ display: "flex" }}>
                        <p style={{ minWidth: "120px" }}>
                          <b>{`${renderString(list.sellerName)}`}</b>
                        </p>
                        {(!Boolean(["UNASSIGNED","DROP"].includes(status))) && (
                          <img
                            src={EditIcon}
                            style={{
                              marginLeft: ".5rem",
                              marginTop: "5px",
                              cursor: "pointer",
                            }}
                            onClick={(e) =>
                              openEditDialog(
                                e,
                                list.sellerLeadId,
                                list.sellerName,
                                list.sellerEmailId
                              )
                            }
                            height="15px"
                          />
                        )}
                         {status !== "UNASSIGNED" && <img src={WhiteCallIcon}  style={{
                              marginLeft: ".5rem",
                              marginTop: "5px",
                              cursor: "pointer",width:"22px"
                            }} onClick={(e) =>
                              openCallDialog(
                                e,
                                list
                              )
                            }  alt='CallIcon' /> }
                      </div>
                      <small
                        style={{
                          color: "gray",
                          fontSize: "9px",
                          wordBreak: "break-all",
                        }}
                      >{`${renderString(hideEmail(list.sellerEmailId))}`}</small>
                    </div>
                  </TableCell>
                  
                  {/* <TableCell>
                    {renderString(list.cityName)}
                  </TableCell> */}
                  <TableCell>
                    <div className='interested-buyer-count'>
                      {list.interestedBuyers}
                      {/* <div>
                          1
                      </div> */}
                    </div>
                  </TableCell>
                   {status !== "UNASSIGNED" &&
                    <TableCell>{renderString(list.agentName) }<br/><small
                    style={{
                      color: "gray",
                      fontSize: "9px",
                      wordBreak: "break-all",
                    }}
                  >{renderString(list.agentUtName)}</small></TableCell>
                  }
                  {/* { status === 'UNASSIGNED' &&
                    <TableCell>
                      <p
                        className="link"
                        onClick={() => this.valuatorDetails(list)}
                      >
                        {list.outletName}
                      </p>
                      <p>{list.outletPhoneNumber}</p>
                    </TableCell>} */}
                  {/* {
                    status !== 'UNASSIGNED' &&
                    <TableCell>{getAmount(list.finalQuotePrice)}</TableCell>
                  } */}
                  {/* {status === "SOLD" && (
                    <TableCell>
                      {list.leadStatus === "SELL" ? (
                        <div
                          style={{
                            backgroundColor: "#DAF2DD",
                            color: "#196834",
                            padding: "1px 15px",
                            borderRadius: "4px",
                            width:"80%",
                            textAlign:"center"
                          }}
                        >
                          {" "}
                          TBD{" "}
                        </div>
                      ) : (
                        <div
                          style={{
                            backgroundColor: "#E9F4FF",
                            color: "#366896",
                            padding: "1px 15px",
                            borderRadius: "4px",
                            width:"80%",
                            textAlign:"center"
                          }}
                        >
                          {" "}
                          TBD{" "}
                        </div>
                      )}
                    </TableCell>
                  )} */}
                 
                  <TableCell>
                    <span>
                            {" "}
                            {getStatusColor(list.c2cStatus)}{" "}
                          </span>
                    </TableCell>
                  <TableCell>{getAmount(list.listedPrice)}</TableCell>
                  <TableCell>{getAmount(list.sellerExpectedPrice)}</TableCell>
                  {status === "UNASSIGNED" && (
                    <TableCell>
                      <Button
                        color="green"
                        style={{
                          fontSize: 14,
                          fontFamily: "ProximaNovaSemibold",
                        }}
                        onClick={(event) => handleAssignChange(event, list)}
                      >
                        Assign
                      </Button>
                    </TableCell>
                  )}
                  <TableCell
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    <ChatBox qclist={list} leadId={list.sellerLeadId} />
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <div className="table-paginator">
        {Boolean(confirmList.length) ? (
          <Pagination
            variant="outlined"
            style={{ float: "right" }}
            count={Math.ceil(totalCount / 10)}
            showQuickJumper
            className="float-right"
            page={currentPage}
            onChange={(event, pageNumber) =>
              handlePageChange(event, pageNumber)
            }
          />
        ) : (
          <NoResultsFound />
        )}
      </div>
      {
        <CallToCustomer
          open={callPopUp}
          onClose={() => setCallPopUp(false)}
          onCallCustomer={callAction}
          leadData={leadData}
          rowInfo={rowInfo}
        />
      }
      {statusPopUp && 
        <UpdateStatusQuote
          open={statusPopUp}
          onClose={() => setStatusPopup(false)}
          leadData={leadData}
          rowInfo={rowInfo}
          tableStatus={status}
          onRefreshRow={onRefreshRow}
        />
      }
      {
        <UpdateDropStatus
          open={dropstatusPopUp}
          onClose={() => setDropStatusPopup(false)}
          leadData={leadData}
          rowInfo={rowInfo}
          tableStatus={status}
          onRefreshRow={onRefreshRow}
        />
      }
      {
        <OTPPopup
          open={otpPopup}
          onClose={() => closePopUp()}
          onVerifyOtp={otpVerify}
          leadData={leadData}
          wrongOtp={wrongOtp}
          onClearWrongOTP={() => setWrongOtp(false)}
        />
      }
      
      {openDialog && (
        <EditDialog
          open={openDialog}
          onClose={closeDialog}
          leadId={leadId}
          name={username}
          email={email}
          refreshPage={refreshPage}
        />
      )}

      {openSlider && <StatusSlider
        openState={openSlider}
        close={closeSlider}
        selectedLeadId={selectedID}
        rowInfo={rowInfo}
        status={status}
        leadData={leadData}
        confirmList={confirmList}
        onRefreshPage={handleRefresh}
        onClosePushMenu={closeAndRefresh}
        openCEDialog={onOpenEditCEP}
        LeadInfo={LeadInfo}
        leadBuyers={LeadBuyersList}
        LeadLifeInfo={LeadLifeInfo}
        orderDedctions={orderDedctions}
        customerDocuments={customerDocuments}
        value={value}
        handleValue={handleValue}
        enable={enable}
        handleEnable={handleEnable}
        Cp={
          orderDedctions &&
          orderDedctions.soldTo &&
          orderDedctions.soldTo === "CP"
        }
        Store={
          orderDedctions &&
          orderDedctions.soldTo &&
          orderDedctions.soldTo !== "CP"
        }
        leadStatus={
          LeadInfo &&
          LeadInfo.vehicleDetails &&
          LeadInfo.vehicleDetails.leadStatus &&
          LeadInfo.vehicleDetails.leadStatus
        }
        onReassignUser={reAssignTo}
        goToDropTab={onRefreshDrop}
        goToFollowUpTab={onRefreshFollowUp}
        goToSoldTab={onRefreshSold}
        handleRowChange={(val) => {
          handleRowChange(val);
          handleValue("Commission");
        }}
        setOpenCustInsBox={setOpenCustInsBox}
      />}
      {editPrice && (
        <CustomerEPEditDialog
          editPrice={editPrice}
          onClose={onCloseEditCEP}
          leadId={leadId}
          priceVal={price}
          onRefreshPage={refreshPage}
        />
      )}
      {openCustBox && (
        <CustInstructionDialog
          openCustBox={openCustBox}
          setOpenCustInsBox={setOpenCustInsBox}
          onClose={()=>{setOpenCustInsBox(false)}}
        />
        
      )

      }
      {openEdit && (
        <EditPriceModal
          editPrice={openEdit}
          onClose={onCloseEditSP}
          leadId={leadId}
          priceVal={price}
          onRefreshPage={refreshPage}
          list={list}
          onAcceptOffer={acceptOfferStore}
        />
      )}
    </div>
  );
};

export default connect()(ConfirmationTable);
