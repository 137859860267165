import {Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import React from "react";

const CustInstructionDialog=({openCustBox,onClose})=>{
    return(
        <Dialog size="md" fullWidth open={openCustBox} onClose={onClose} >
            <DialogTitle style={{background:"#F0F5FF"}}>
                Customer Instruction Dialog
               <span className="float-right" onClick={onClose} style={{ cursor: 'pointer' }}>&#10005;</span>

            </DialogTitle>
            <DialogContent>
                <div style={{minHeight:"150px"}}>
                    <b>Please Ask Customer to Install Credr App and Do the Payment</b>
                </div>
                
            </DialogContent>
            

        </Dialog>
    )
}

export default CustInstructionDialog