import { SHOW_CALLING_STATUS, HIDE_CALLING_STATUS } from '../actions/CallingStatusAction'
import { getNewState } from '../../core/utility/getNewState'

const initialState = {
  isOnCall: false,
  customerName:'Not Available',
  leadId:'Not Available'
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SHOW_CALLING_STATUS:
      const {customerName, leadId} = action.payload
      return getNewState(state, { isOnCall: true, customerName, leadId })
    case HIDE_CALLING_STATUS:
      return getNewState(state, { isOnCall: false })
    default:
      return state
  }
}
