import { getCustomerDocumentsDetails } from '../../core/services/shdServices'
import { SHD_CUSTOMER_DOCUMENNTS } from '../../core/constants/actions'
import { setNotification } from './notificationAction'
import { showLoader, hideLoader } from './loaderAction'

export function getShdCustomerDocuments(leadId) {
  return dispatch => {
    dispatch(request())
    dispatch(showLoader())
    return getCustomerDocumentsDetails(leadId)
      .then(apiResponse => {
        if (apiResponse.isValid) {
          const customerDocumentsResponse = apiResponse
          dispatch(success({ customerDocumentsResponse }))
        }
        else {
          dispatch(failure({ error: apiResponse.message }))
          // dispatch(setNotification('danger', 'ERROR', apiResponse.message))
        }
        dispatch(hideLoader())
      })
  }
  function request() { return { type: SHD_CUSTOMER_DOCUMENNTS.REQUEST } }
  function success(customerDocumentsResponse) { return { type: SHD_CUSTOMER_DOCUMENNTS.SUCCESS, payload: { ...customerDocumentsResponse } } }
  function failure(error) { return { type: SHD_CUSTOMER_DOCUMENNTS.FAILURE, payload: { ...error } } }
}

export function resetBiddingLead() {
  return { type: SHD_CUSTOMER_DOCUMENNTS.RESET }
}