import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContentText from '@material-ui/core/DialogContentText'
import call from '../../../shared/img/icons/call.svg'
import Divider from '@material-ui/core/Divider';
import { getMobile, getUserID } from '../../../core/services/rbacServices'
import { showLoader, hideLoader } from '../../../redux/actions/loaderAction'
import { setNotification } from '../../../redux/actions/notificationAction'
import { updateCustomerExpectedPrice } from '../../../core/services/miscServices'
import { useDispatch } from "react-redux"
// import { updateBidAmt } from '../../../core/services/biddingServices'

const onlyContactNumber = (value) => {
    if (!value) {
        return value
    }
    let onlyContactNumbers = value.replace(/[^\d]/g, '')
    onlyContactNumbers.replace(/(?!^)+/g, '')
    return onlyContactNumbers.slice(0, 7)
}


const EditBidPrice = ({biddingPrice, onClose, editPrice, priceVal, onRefreshPage, leadId
    , tabValue, bidData, rowData }) => {
      

    const dispatch = useDispatch()
    const [price, setPrice] = useState(priceVal)
    const [bidPrice,setbidPrice] = useState(biddingPrice)
    const [bidpriceErr, setbidPriceErr] = useState(false)
    const [priceErr, setPriceErr] = useState(false)
    const [priceConditionError, setPriceConditionError] = useState(false)
    const [bidpriceConditionError, setbidPriceConditionError] = useState(false)

    const handleUpdatePrice = (tabValue) => {
            const payload = {
                leadId: leadId,
                leadCustomerExpectedPrice: `${price}`,
                biddingPrice:`${bidPrice}`

            }
            let seventyPercent = 0.7 * priceVal
            if (price && bidPrice) {
                if (price >= seventyPercent && bidPrice > price) {
                    setPriceErr(false);
                    setbidPriceErr(false);
                    setPriceConditionError(false);
                    setbidPriceConditionError(false);
            
                    dispatch(showLoader());
            
                    updateCustomerExpectedPrice(payload)
                        .then(apiResponse => {
                            if (apiResponse.isValid) {
                                dispatch(hideLoader());
                                onClose();
                                onRefreshPage();
                                dispatch(setNotification('success', 'SUCCESS', apiResponse.message));
                            } else {
                                dispatch(hideLoader());
                                onClose();
                                dispatch(setNotification('danger', 'ERROR', apiResponse.message));
                            }
                        });
                } else {
                    if (price < seventyPercent) {
                        setPriceErr(false);
                        setbidPriceErr(false);
                        setPriceConditionError(true);
                        setbidPriceConditionError(false);
                    } else if (bidPrice < price) {
                        setPriceErr(false);
                        setbidPriceErr(false);
                        setPriceConditionError(false);
                        setbidPriceConditionError(true);
                    }else if( (bidPrice ===price )){
                        setPriceErr(false);
                        setbidPriceErr(false);
                        setPriceConditionError(false);
                        setbidPriceConditionError(true);
                    }
                     else {
                        setPriceErr(true);
                        setbidPriceErr(true);
                        setPriceConditionError(false);
                        setbidPriceConditionError(false);
                    }
                }
            } else {
                setPriceErr(!price);
                setbidPriceErr(!bidPrice);
                setPriceConditionError(false);
                setbidPriceConditionError(false);
            }
        
    }

    const handlePriceedit = (e) => {
        setPriceConditionError(false)
        const regexp = /^[0-9\b]+$/;
        if (e.target.value === '' || regexp.test(e.target.value)) {
            let value = onlyContactNumber(e.target.value)
            setPrice(value)
            setPriceErr(false)
        }
        else {
            setPriceErr(true)
        }
    }
    const handleBidPriceedit = (e) => {
        setbidPriceConditionError(false)
        const regexp = /^[0-9\b]+$/;
        if (e.target.value === '' || regexp.test(e.target.value)) {
            let value = onlyContactNumber(e.target.value)
            setbidPrice(value)
            setbidPriceErr(false)
        }
        else {
            setbidPriceErr(true)
        }
    }

    const getSeventyPercent = (amount) => {
        return 0.7 * amount
    }

    return (
        <Dialog
            open={editPrice}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
            className='dialogWrap'
        >
            <DialogContent>
                <h2 style={{ fontFamily: 'ProximaNovaSemibold', fontSize: '20px', color: '#19865f', marginBottom: '40px' }}> {tabValue === 'Bidding History' ? 'Edit Bidding Price' : 'Edit Customer Expected Price'} <span className="float-right" onClick={onClose} style={{ cursor: 'pointer', color: '#333' }}>&#10005;</span></h2>
                <div className="row" style={{marginBottom:'24px'}}>
                    <div className='col-6'>
                        <p style={{ fontFamily: 'ProximaNovaSemibold', fontSize: '16px' }}>{tabValue === 'Bidding History' ? 'Update Bidding Price :' : 'Customer Expected Price :'}</p>
                    </div>
                    <div className='col-6'>
                        <input autoFocus value={price} style={{ border: '1px solid #f5f0f0', backgroundColor: '#fff', fontFamily: 'ProximaNovaSemibold', padding: '6px 10px', width: '100%' }} onChange={handlePriceedit}></input>
                        {
                            priceErr && <span style={{ color: '#da2128', fontFamily: 'ProximaNovaRegular', fontSize: '12px' }}>{tabValue === 'Bidding History' ? '* Please Enter Bidding Price' : '* Please Enter Customer Expected Price'}</span>
                        }
                        {
                            priceConditionError && <span style={{ color: '#da2128', fontFamily: 'ProximaNovaRegular', fontSize: '12px' }}>{`Price Shouldn't be less than ${getSeventyPercent(priceVal)}`}</span>
                        }
                    </div>
                </div>

                <div className="row">
                    <div className='col-6'>
                        <p style={{ fontFamily: 'ProximaNovaSemibold', fontSize: '16px' }}>{tabValue === 'Bidding History' ? 'Update Bidding Price :' : 'Bidding Price:'}</p>
                    </div>
                    <div className='col-6'>
                        <input autoFocus value={bidPrice} style={{ border: '1px solid #f5f0f0', backgroundColor: '#fff', fontFamily: 'ProximaNovaSemibold', padding: '6px 10px', width: '100%' }} onChange={handleBidPriceedit}></input>
                        {
                            bidpriceErr && <span style={{ color: '#da2128', fontFamily: 'ProximaNovaRegular', fontSize: '12px' }}>{tabValue === 'Bidding History' ? '* Please Enter Bidding Price' : '* Please Enter Bidding Price'}</span>
                        }
                        {
                            bidpriceConditionError && <span style={{ color: '#da2128', fontFamily: 'ProximaNovaRegular', fontSize: '12px' }}>{`Bidding Price Should be greater than customer expected price`}</span>
                        }
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <button onClick={() => handleUpdatePrice(tabValue)} style={{ border: '1px sloid #70bbfd', backgroundColor: '#70bbfd', color: '#333333', padding: '8px 53px', borderRadius: '6px', marginBottom: '15px', fontFamily: 'ProximaNovaSemibold', }}>Update</button>
            </DialogActions>
        </Dialog>
    )
}

export default EditBidPrice