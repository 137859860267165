import { SIGNUP_AE_APPROVE_REJECT } from '../../core/constants/actions'
import { getNewState } from '../../core/utility'

const intialState = {
    aeVerificationStatus: [],
    isLoading: false,
}
export default function aePersonalDetailsVerificationReducer(state = intialState, action) {

    switch (action.type) {
        case SIGNUP_AE_APPROVE_REJECT.REQUEST:
            return getNewState(state, { isLoading: true })

        case SIGNUP_AE_APPROVE_REJECT.SUCCESS:
            const { aeVerificationStatus } = action.payload;
            return getNewState(state, { aeVerificationStatus })

        case SIGNUP_AE_APPROVE_REJECT.FAILURE:
            const { error } = action.payload
            return getNewState(state, { error, ...intialState })

        case SIGNUP_AE_APPROVE_REJECT.RESET:
            return intialState

        default:
            return state
    }
}