import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import React, { useState} from 'react'
import { Modal, Grid, Button } from 'rsuite'
import { getUserID } from '../../../../../core/services/rbacServices';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../../../../../redux/actions/loaderAction';
import { setNotification } from '../../../../../redux/actions/notificationAction';
import { addLeadException } from '../../../../../core/services/miscServices';
import { getShdCustomerDocuments } from '../../../../../redux/actions/shdCustomerDocumentsAction';


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


const top100Films = [
    { title: 'The Shawshank Redemption', year: 1994 },
    { title: 'The Godfather', year: 1972 },
    { title: 'The Godfather: Part II', year: 1974 },
    { title: 'The Dark Knight', year: 2008 },
    { title: '12 Angry Men', year: 1957 },
    { title: "Schindler's List", year: 1993 },
    { title: 'Pulp Fiction', year: 1994 },
    {
      title: 'The Lord of the Rings: The Return of the King',
      year: 2003,
    },
]

export default function ExceptionModel({open, onClose, exceptionList,rowInfo}){
    
    const [exceptionSelected, setExceptionSelected] = useState([]);
    const [comments, setComments] = useState("")

    const dispatch =  useDispatch()

    const handleComments = (e) => {
      setComments(e.target.value)
    }

    const handleSubmit =()=> {
      const payload = {
       leadId: rowInfo.leadId,
        leadException: exceptionSelected.map(item => item.exception),
        comments: comments,
        createdBy: getUserID()
    }

      dispatch(showLoader)
      addLeadException(payload).
      then(apiResponse =>{
          if(apiResponse.isValid){
              dispatch(hideLoader)
              dispatch(setNotification('success', 'SUCCESS', apiResponse.message))
              dispatch(getShdCustomerDocuments(rowInfo.leadId))
              onClose()
          }else{
              dispatch(hideLoader)
              dispatch(setNotification('danger', 'ERROR', apiResponse.message))
                  onClose()
              }
          }
      )
    }

    return(
             <Modal onHide={onClose} show={open} size="sm" className='diyWrap' style={{zIndex:'1000'}}>
            <Modal.Header closeButton>
                <Modal.Title>
                <p
                style={{
                  fontSize: "18px",
                  fontFamily: "ProximaNovaSemibold",
                  color: "#333333",
                  // textAlign: "center",
                }}
              >{`Provide Exception`}</p>
                  </Modal.Title>
            </Modal.Header>
           <Modal.Body>
        
        <div style={{display:"flex", marginTop:'50px', justifyContent:'flex-start', alignItems:'center'}}>

            <span style={{fontSize: "18px", fontWeight:'600'}}>
            {`Choose\u00a0Exception`}
            </span>
        <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      style={{width:'300px', marginLeft:'50px'}}
      options={exceptionList}
      disableCloseOnSelect
      // onChange={handleChange}
      onChange={(e, val) => setExceptionSelected(val)}
      getOptionLabel={(option) => option.exception}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.exception}
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label="Selected Exception" placeholder="Search Exception" />
      )}
    />
        </div>

        <div>
        <div style={{fontSize: "18px", fontWeight:'600', marginTop:'50px'}}>
            {`Comments`}
        </div>
        <br />
       <textarea className="textareaException" placeholder="Enter comments here"
							value={comments}
							onChange={handleComments}
							required
						></textarea>
        </div>



    </Modal.Body> 
          <br />
       <Modal.Footer style={{textAlign: 'center'}}>
          <button 
              onClick={handleSubmit} 
              disabled ={exceptionSelected && comments ? false : true}
              style={{ marginRight: 15, color: '#ffffff', padding: '8px 20px', borderRadius: '22px', backgroundColor: '#4DBD74', border: '1px solid #35AC5E'  }}>
              Submit
             </button>
          </Modal.Footer>
        </Modal>
    )
}