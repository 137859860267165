import React from "react";
import { Modal, Grid } from "rsuite";

export default function UpdateCPPModal({
  onClose,
  onYesClick,
  open,
  days,
  title,
  feature,
  rowInfo
}) {

  const onYes = () => {
    onYesClick(rowInfo)
  }

  return (
    <Modal
      show={open}
      size="xs"
      className="diyWrap"
      style={{
        zIndex: "2000",
        position: "fixed",
        left: "40%",
        right: "50%",
        top: "41%"
      }}
    >
      {
        feature === 'quoteConfirmation' &&
        <Modal.Header closeButton style={{ textAlign: "center" }} onHide={onClose}>
          <Modal.Title style={{ textAlign: 'left' }}>Confirmation</Modal.Title>
          <hr />
        </Modal.Header>
      }
      <Modal.Body>
        <Grid fluid>
          <p
            style={{
              fontSize: "16px",
              fontFamily: "ProximaNovaSemibold",
              color: "#333333",
              textAlign: "center",
            }}
          >
            {title}
          </p>
          {
            feature === 'quoteConfirmation' &&
            <p style={{ textAlign: 'center' }}>
              Inspection Report is{" "}
              <span style={{ color: "red", fontWeight: 'bolder' }}>{`${days} days old`}</span>
            </p>
          }
        </Grid>
      </Modal.Body>
      <br />
      <Modal.Footer style={{ textAlign: "center" }}>
        <button
          onClick={onClose}
          style={{
            border: "#DEDEDE",
            backgroundColor: "1px solid #E8E8E8",
            color: "#333333",
            padding: "8px 20px",
            borderRadius: "22px",
            marginRight: "10px",
            width: '100%',
            maxWidth: '150px'
          }}
        >
          No
        </button>
        <button
          onClick={onYes}
          style={{
            // marginRight: 15,
            color: "#ffffff",
            padding: "8px 20px",
            borderRadius: "22px",
            backgroundColor: feature === 'Wishlist-Reject' ? "#F4364C" : "#4DBD74",
            border: feature === 'Wishlist-Reject' ? "1px solid #F4364C" : 'Wishlist-Retrive' ? "1px solid #6DBB7B" : "1px solid #35AC5E",
            width: '100%',
            maxWidth: '150px',
          }}
        >
          Yes
        </button>
      </Modal.Footer>
    </Modal>
  );
}
