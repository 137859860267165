import {
  FormControl,
  InputBase,
  InputLabel,
  withStyles,
  makeStyles,
  NativeSelect,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Button,
  Chip,
  MenuItem,
} from "@material-ui/core";

import Select from "react-select";

import { useDispatch } from "react-redux";
import { Modal, Grid, SelectPicker, Placeholder } from "rsuite";
import {
  directResell,
  getCpList,
  getLeadDetails,
  rebid,
  shdRebid,
} from "../../../core/services/biddingServices";
import { hideLoader, showLoader } from "../../../redux/actions/loaderAction";
import { setNotification } from "../../../redux/actions/notificationAction";
import Commission from "../StatusSlider/components/Commission/index";
// import RefundForm from "./RefundForm";

import React, { useState, useEffect,useRef } from "react";
import { Form } from "rsuite";
import FormHelperText from "@material-ui/core/FormHelperText";
import SelectField from "@material-ui/core/Select";
// import { Dropdown, Schema } from "rsuite";
import { Schema } from "rsuite";
import DropDown from "../../../shared/components/form/DropDown";
import { getAmount } from "../../../core/utility/stringUtility";
import { getUserData } from "../../../core/services/rbacServices";
import {
  getUserActionInfo,
  getUserInfo,
} from "../../../core/services/tokenStorageServices";
import "./resellStyle.scss";
import { StepButton } from "@material-ui/core";
import { getToken } from "../../../core/services/tokenStorageServices";
import { login } from "../../../core/services/authenticationServices";
import { GET_LEAD_DETAILS } from "../../../core/constants/actions";
import { getUserID } from "../../../core/services/rbacServices";
import { AlertIconRed } from "../../../core/utility/iconHelper";

const nameRule = Schema.Types.StringType().isRequired(
  "This field is required."
);

const useStyles = makeStyles((theme) => ({
  table: {
    "& .MuiTableCell-root": {
      borderBottom: "none",
      fontWeight: "500",
      height: "2rem",
      padding: "5px 0 5px 10px",
    },
    "& .MuiInputBase-root": {
      height: "2rem",
      width: "7rem",
      marginTop: "0",
      borderRadius: "5px",
    },
    "& .MuiButton-contained": {
      height: "2rem",
      width: "7rem",
      color: "black",
      justifyContent: "flex-start",
    },
    "& .MuiFilledInput-root": {
      paddingBottom: "15px",
      color: "black",
    },
  },
  commentInput: {
    "& .MuiInputBase-root": {
      minWidth: "235%",
      height: "auto",
    },
  },
  selectInput: {
    "& .MuiInputBase-root": {
      minWidth: "16.4rem",
      // height: "auto",
    },
  },
  selectOption: {
    '&:hover': {
      cursor: 'pointer',
      background: "#479dff",
   },
},
  commentHoldInput: {
    "& .MuiInputBase-root": {
      minWidth: "180%",
      height: "auto",
    },
  },
  tableHeader: {
    height: "2rem",
    backgroundColor: "#333333",
    color: "white",
    fontWeight: "500",
    paddingLeft: "1rem",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  margin: {
    margin: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "2rem",
  },
}));

function ConfirmationDialog(props) {
  const handleCloseConfirm = () => {
    const { setOpenConfirmDialog } = props;
    setOpenConfirmDialog(false);
  };

  const handleConfirm = () => {
    props.onConfirm();
  };

  return (
    // <div className="bg-container-confirmation">
    //   <Modal
    //     size={"lg"}
    //     open={props.openConfirmDialog}
    //     onClose={handleCloseConfirm}
    //     style={{ zIndex: `${2000} !important`, position: "absolute" }}
    //     classPrefix="my-modal"
    //   >
    //     <Modal.Header>
    //       <Modal.Title>Modal Title</Modal.Title>
    //     </Modal.Header>
    //     <Modal.Body>
    //       <Placeholder.Paragraph>
    //         Are you sure you want to Re-sell vehicle?
    //       </Placeholder.Paragraph>
    //     </Modal.Body>
    //     <Modal.Footer>
    //       <Button onClick={handleConfirm} appearance="primary">
    //         Confirm
    //       </Button>
    //       <Button onClick={handleCloseConfirm} appearance="subtle">
    //         Cancel
    //       </Button>
    //     </Modal.Footer>
    //   </Modal>
    // </div>
    <div className="bg-container-confirmation">
      <div className="resell-confirmation">
        <div>
          <p className="resell-confirmation-para">Are you sure you want to Re-sell vehicle??</p>
        </div>
        <div>
          
          <button  className="c-button"
            onClick={handleConfirm}
          >
            Confirm
          </button>
          <button className="c-button cancel-button" onClick={handleCloseConfirm}>Cancel</button>
        </div>
      </div>
    </div>
  );
}

const RefundForm = ({
  onClose,
  open,
  close,
  leadData,
  rowInfo,
  onRefreshPage,
  bidVal,
  orderDedctions,onDropRefresh,goToSoldTab,LeadLifeInfo,
  LeadInfo,
}) => {

  const {payoutDetailsResponseBean,channelPartnerResponseBean}=LeadLifeInfo
  const [resellButton, setResellButton] = useState(true);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [listOpen, selectListOpen] = useState(false);
  // const [comments,setComments]=useState("")
  const [enable, setEnable] = useState(false);
  const classes = useStyles();
  const [leeway, setLeeway] = useState(
    orderDedctions && orderDedctions.leeway && orderDedctions.leeway
      ? orderDedctions.leeway
      : 0
  );
  const [extraCommission, setExtraCommission] = useState(
    orderDedctions &&
      orderDedctions.extraShdCommission &&
      orderDedctions.extraShdCommission
      ? orderDedctions.extraShdCommission
      : 0
  );
  // const [leeway, setLeeway] = useState(orderDedctions && orderDedctions.leeway && orderDedctions.leeway ? orderDedctions.leeway : 0)
  const [trafficChallan, setTrafficChallan] = useState(
    orderDedctions &&
      orderDedctions.userDeductions &&
      orderDedctions.userDeductions.userTrafficChalan &&
      orderDedctions.userDeductions.userTrafficChalan &&
      orderDedctions.userDeductions.userTrafficChalan
      ? orderDedctions.userDeductions.userTrafficChalan
      : 0
  );
  const [hpRto, setHpRto] = useState(
    orderDedctions &&
      orderDedctions.userDeductions &&
      orderDedctions.userDeductions.userHpRtoCharges &&
      orderDedctions.userDeductions.userHpRtoCharges &&
      orderDedctions.userDeductions.userHpRtoCharges
      ? orderDedctions.userDeductions.userHpRtoCharges
      : 0
  );
  const [docQc2Charges, setDocQc2Charges] = useState(
    orderDedctions &&
      orderDedctions.docQc2Deductions &&
      orderDedctions.docQc2Deductions
      ? orderDedctions.docQc2Deductions
      : 0
  );
  const [comments, setComments] = useState(
    orderDedctions &&
      orderDedctions.comments &&
      orderDedctions.comments &&
      orderDedctions.comments
  );

  const [holdAmount, setHoldAmount] = useState(
    orderDedctions &&
      orderDedctions.holdAmount &&
      orderDedctions.holdAmount
  );

  const [holdReason, setHoldReason] = useState(
    orderDedctions &&
      orderDedctions.holdReason 
      ? orderDedctions.holdReason 
      :'0'
  );

  const [holdComments, setHoldComments] = useState(
    orderDedctions &&
      orderDedctions.holdComments 
      ? orderDedctions.holdComments
      : ''
  );

  const [newCommission, setNewCommission] = useState("");
  const [showNewCommissionError, setShowNewCommissionError] = useState(false);
  const [cpHighestBid, setCpHighestBid] = useState(
    orderDedctions && orderDedctions.highestBid
      ? Number(orderDedctions.highestBid)
      : Number(0)
  );

  const [cityCommission, setCityCommission] = useState(
    orderDedctions.cityLevelCommission
  );

  const [wordsLeft, setWordsLeft] = useState(100);
  const [wordsHoldLeft, setWordsHoldLeft] = useState(100);
  const [cpList, setCpList] = useState([]);
  const [cpSelected, setCpSelected] = useState("Enter Partner Name");

  const [searchQuery, setSearchQuery] = useState("");

  const [searchValue, setSearchValue] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedStore, setSelectedStore] = useState(null);

  const [ErrorForCpSelection, setErrorForCpSelection] = useState(false);
  const [minBidValue,setMinBidValue]=useState(0)
  const [paymentError,setPaymentError]=useState("")

  useEffect(() => {
    if (getUserActionInfo() === "WALLET_DASHBOARD") {
      setResellButton(0.9 * Number(finalAmtCustomer()) >= Number(finalAmtCP()));
    } else {
      setResellButton(Number(finalAmtCustomer()) >= Number(finalAmtCP()));
    }
    
  }, [cpHighestBid]);

  useEffect(() => {
    getCpList(rowInfo.cityId).then((apiResponse) => {
      if (apiResponse.isValid) {
        setCpList(apiResponse.shdDetails);

        // dispatch(
        //   setNotification("success", "SUCCESS", apiResponse.message)
        // );
        // dispatch(hideLoader());
        // onClose();
        // close();
        // onRefreshPage("bidAction");
      } else {
        // dispatch(setNotification("danger", "Error", apiResponse.message));
      }
    });
  }, []);

  const filteredData = cpList.filter((item) =>
    item.storeName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSearchCp = (event) => {
    setSearchQuery(event.target.value);
  };

  const calculateMinBidValue = () => {
    if (getUserActionInfo() === "WALLET_DASHBOARD") {
      return getAmount(Math.floor((finalAmtCustomer()) * 0.9 + totalDeductionCP()));
    } else {
      return getAmount(Math.floor((finalAmtCustomer()) + totalDeductionCP()));
    }
  };


  const onCommentsChange = (event) => {
    setComments(event.target.value);
    const inputValue = event.target.value;
    const words = inputValue.length;
    setWordsLeft(100 - words);
  };

  const onLeewayChange = (event) => {
    setLeeway(event.target.value);
  };

  const onChangeChallan = (event) => {
    setTrafficChallan(event.target.value);
  };

  const onChangeRto = (event) => {
    setHpRto(event.target.value);
  };

  const onChangeDocQc2 = (event) => {
    setDocQc2Charges(event.target.value);
  };

  const onChangeCityCommission = (event) => {
    setCityCommission(event.target.value);
  };

  const onHoldAmountChange = (event) => {
    const regex = /^[0-9\b]+$/;
    if (event.target.value === "" || regex.test(event.target.value)) {
      setHoldAmount(event.target.value); 
    }

  }

  const onHoldReasonChange = (event) => {
      setHoldReason(event.target.value);    
  }

  const onHoldCommentsChange = (event) => {
      setHoldComments(event.target.value);
      const inputValue = event.target.value;
      const words = inputValue.length;
      setWordsHoldLeft(100 - words);
};

  const handleCPHighestBid = (event) => {
    setCpHighestBid(event.target.value);
    setPaymentError("")
  };

  const finalAmtCustomer = () => {
    if (orderDedctions) {
      return Number(cpHighestBid) - totalDeductionCustomer();
    }
  };

  const checkPriceMargin = () => {
    if (getUserActionInfo() === "WALLET_DASHBOARD") {
      return 0.9 * Number(finalAmtCustomer()) <= Number(finalAmtCP());
      
    }
    return Number(finalAmtCustomer()) <= Number(finalAmtCP());
  };

  const finalAmtCP = () => {
    if (orderDedctions) {
      return Number(cpHighestBid) - totalDeductionCP();
    }
  };

  const totalDeductionCP = () => {
    let totalDeductionCP = 0;
    // if (leeway) totalDeductionCP += Number(leeway);
    if (trafficChallan) totalDeductionCP += Number(trafficChallan);
    if (hpRto) totalDeductionCP += Number(hpRto);
    if (docQc2Charges) totalDeductionCP += Number(docQc2Charges);
    return totalDeductionCP;
  };

  const totalDeductionCustomer = () => {
    // const { cityLevelCommission } = orderDedctions;
    let totalDeductionCustomer = 0;
    if (extraCommission) totalDeductionCustomer += Number(extraCommission);
    if (cityCommission) totalDeductionCustomer += Number(cityCommission);
    if (leeway) totalDeductionCustomer += Number(leeway);
    if (trafficChallan) totalDeductionCustomer += Number(trafficChallan);
    if (hpRto) totalDeductionCustomer += Number(hpRto);
    if (docQc2Charges) totalDeductionCustomer += Number(docQc2Charges);
    return totalDeductionCustomer;
  };
  const onCommissionChange = (event) => {
    setExtraCommission(event.target.value);
    // if (regex.test(event.target.value) && Number(event.target.value) < 10000000) {
    //     setExtraCommission(event.target.value)
    //     const payload = {
    //         cityLevelCommission: orderDedctions && orderDedctions.cityLevelCommission && orderDedctions.cityLevelCommission ? orderDedctions.cityLevelCommission : 0,
    //         comments: comments,
    //         leadId: rowInfo.leadId,
    //         loginId: getUserID(),
    //         userId: getUserID(),
    //         rtoCharges: hpRto,
    //         challanCharges: trafficChallan,
    //         shdAdditionalCommission: event.target.value,
    //         newPurchaseCost: newCommission ? newCommission : ((orderDedctions && orderDedctions.newProcurementPrice) ? orderDedctions.newProcurementPrice : orderDedctions.storeProcuredPrice),
    //         leeway: leeway,
    //         finalCusAmt:finalAmtCustomer(),
    //         finalCpAmt: finalAmtCP(),
    //         finalStoreAmt: finalAmtStore(),
    //         purchaseFor: showStore ? 'STORE' : 'CP'
    //     }
    //     localStorage.setItem('commissionObject', JSON.stringify(payload));
    // }
  };

  // console.log(getUserData(),"getUserData")
  // if(getUserActionInfo()==="WALLET_DASHBOARD")

  const siblingStyles = {
    opacity: listOpen ? 0.2 : 1,
    transition: listOpen ? "" : "opacity 0.5s ease-in-out",
  };

  const toggleDropdown = () => {
    selectListOpen(!listOpen);
  };

  const dispatch = useDispatch();

  const postApiForResell = () => {
    dispatch(showLoader());
    const myToken = getToken();
    const payload = {
      cityLevelCommission: cityCommission,
      comments: comments,
      leadId: rowInfo.leadId,
      loginId: getUserID(),
      rtoCharges: hpRto,
      challanCharges: trafficChallan,
      shdAdditionalCommission: extraCommission,
      leeway: leeway,
      highestBid: String(cpHighestBid),
      shdUserId: Number(selectedStore.userId),
      shdStoreId: Number(selectedStore.storeId),
      holdComments:holdComments,
      holdAmount:holdAmount,
      holdReason:holdReason === '0' ? '' : holdReason,
      finalAmountWithHold:Number(holdAmount) > 0 ? finalAmtCustomer()-holdAmount : 0
    };

    directResell(payload).then((apiResponse) => {
      if (apiResponse.isValid) {
        dispatch(setNotification("success", "SUCCESS", apiResponse.message));
        dispatch(hideLoader());
        onClose();
        close();
        goToSoldTab()
        
        // onRefreshPage("bidAction");
      } else {
        dispatch(setNotification("danger", "Error", apiResponse.message));
        dispatch(hideLoader());
        onClose();
        close();
        onRefreshPage();
        
      }
    });
  };

  const onConfirm = () => {
    if (selectedStore !== null) {
      postApiForResell();
    } 
  };

  const handleAcceptBtnClick = () => {
    if (selectedStore !== null) {
      if(orderDedctions.invPaymentStatus==="COMPLETED" || orderDedctions.invPaymentStatus==="PROCESSING" || orderDedctions.invPaymentStatus==="PARTIAL"){
        if (getUserActionInfo() === "WALLET_DASHBOARD") {
          if(0.9*orderDedctions.invPaymentAmount>finalAmtCP()){
            setOpenConfirmDialog(false);
            setPaymentError(`Final CP Amount Cannot be less than 10% of ${getAmount(orderDedctions.invPaymentAmount)} = ${getAmount(0.9*orderDedctions.invPaymentAmount)}`)
          }
          else{
            setOpenConfirmDialog(true);
          }
        }
        else{
          if(orderDedctions.invPaymentAmount>finalAmtCustomer()){
            setOpenConfirmDialog(false);
            setPaymentError(`Final Customer Amount Cannot be less than ${Math.ceil(getAmount(orderDedctions.invPaymentAmount))}`)
          }
          else{
            setOpenConfirmDialog(true);
          }
  
        }
        
      }
      else{
        setOpenConfirmDialog(true);
      }
    } else {
      setErrorForCpSelection(true);
      setOpenConfirmDialog(false)
    }
    
  };

  

  const cpListData = cpList.map((item) => ({
    storeId: item.storeId,
    label: item.shdName,
    storeName: item.storeName,
    value: item.shdName,
    userId: item.userId,
  }));

  const filteredDataNew = cpListData.filter((item) =>
    item.label.toLowerCase().includes(searchValue.toLowerCase())
  );

  const renderMenuItem = (label, item) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "29px",
          lineHeight: "15px",
        }}
        key={item.storeId}
      >
        <div>
          <p style={{ fontWeight: "bold", margin: "0px", fontSize: "14px" }}>
            {label}
          </p>
          <p style={{ margin: "0px", fontSize: "10px" }}>{item.storeName}</p>
        </div>
        <p style={{ margin: "0px", fontSize: "14px" }}>
          Store ID: <b>{item.storeId}</b>
        </p>
      </div>
    );
  };


  const getStatus = (lifecycleStatus) => {
    if (lifecycleStatus === "APPROVED") {
      return (
        <span
          style={{
            backgroundColor: "#DAF2DD",
            color: "#196834",
            padding: "1px 15px",
            borderRadius: "4px",
            fontWeight: "500",
          }}
        >
          Approved
        </span>
      );
    }
    if (lifecycleStatus === "PENDING") {
      return (
        <span
          style={{
            backgroundColor: "#FFE1C5",
            color: "#B96716",
            padding: "1px 15px",
            borderRadius: "4px",
            fontWeight: "500",
          }}
        >
          Pending
        </span>
      );
    }
    if (lifecycleStatus === "REJECTED") {
      return (
        <span
          style={{
            backgroundColor: "#FFD4D6",
            color: "#DA2128",
            padding: "1px 15px",
            borderRadius: "4px",
            fontWeight: "500",
          }}
        >
          Rejected
        </span>
      );
    }
    if (lifecycleStatus === "SELL") {
      return (
        <span
          style={{
            backgroundColor: "#DAF2DD",
            color: "#196834",
            padding: "1px 15px",
            borderRadius: "4px",
            fontWeight: "500",
          }}
        >
          Sell
        </span>
      );
    }
    if (lifecycleStatus === "EXCHANGE") {
      return (
        <span
          style={{
            backgroundColor: "#DAF2DD",
            color: "#196834",
            padding: "1px 15px",
            borderRadius: "4px",
            fontWeight: "500",
          }}
        >
          Exchange
        </span>
      );
    }
    if (lifecycleStatus === "ONGOING") {
      return (
        <span
          style={{
            backgroundColor: "#E9F4FF",
            color: "#366896",
            padding: "1px 15px",
            borderRadius: "4px",
            fontWeight: "500",
          }}
        >
          {" "}
          Ongoing{" "}
        </span>
      );
    } else {
      return (
        <span
          style={{
            backgroundColor: "#E9F4FF",
            color: "#366896",
            padding: "1px 15px",
            borderRadius: "4px",
            fontWeight: "500",
          }}
        >
          {" "}
          {lifecycleStatus}{" "}
        </span>
      );
    }
  };
 
  const searchByFunction=(keyword,label,item)=>{
    if(item && item.storeName && item.label){
        return (item.label.toLowerCase().includes(keyword.toLowerCase()) || item.storeName.toLowerCase().includes(keyword.toLowerCase())) 
    }
    else if (item && item.storeName){
        return item.storeName.toLowerCase().includes(keyword.toLowerCase())
    }
    else if (item && item.label){
      return item.label.toLowerCase().includes(keyword.toLowerCase())
    }
    else {
      return false
    }
    
  }

  console.log("leadInfo", LeadInfo)
  return (
    <Modal
      onHide={onClose}
      show={open}
      // open={open}
      // onClose={onClose}
      size="full"
      className="diyWrap bg-container-resell"
      style={{maxHeight: "45rem", marginTop: "2rem",overflow:"auto"}}
    >
      <Modal.Header closeButton style={{ textAlign: "center"}}>
      <Modal.Body style={{paddingBottom:"0px"}}>
        {!openConfirmDialog && (
          <div
            style={{
              display: "flex",
              flexFlow: "row wrap",
              justifyContent: "flex-start",
              gap: "1rem 2rem",
              width: "71rem",
            }}
          >
            <div style={{ width: "33rem",overflow:'auto'}}>
              <label
                style={{
                  textAlign: "left",
                  width: "100%",
                  marginBottom: "10px",
                  fontWeight: "bold",
                 fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                 fontSize:"16px",
                fontWeight:"500",color:"#000000"
                }}
                htmlFor="cpHighestBid"
              >
                Choose Channel Partner
              </label>
              <br />

              <SelectPicker
                searchable
                cleanable={false}
                
                placeholder="Enter Partner Name"
                value={selectedValue}
                data={cpListData}
                // onSearch={(value) => {
                //   setSearchValue(value);
                // }}
                onSelect={(value, store) => {
                  setSelectedValue(value);
                  setSelectedStore(store);
                  selectListOpen(false);
                  setErrorForCpSelection(false)
                }}
                renderMenuItem={renderMenuItem}
                className="drop-down-refund"
                onClick={() => {
                  selectListOpen(!listOpen);
                }}
                onClose={()=>{selectListOpen(false)}}
                searchBy={searchByFunction}
              />
                <p style={{ color: "red",margin:"0px",marginBottom:"10px",textAlign:"left",minHeight:"16px"}}><b>
                 {ErrorForCpSelection? "Please select the Channel partner to proceed":""}
                 </b>
                </p>

                <div style={{overflow:'auto'}}>
              <div style={siblingStyles} >
                <TableContainer
                  component={Paper}
                  style={{
                    width: "33rem",
                    borderRadius: "13px",
                    height: "9rem",
                    marginBottom: "0rem",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    className={classes.tableHeader}
                  >
                    Add Commission
                  </Typography>
                  <Table
                    size="medium"
                    className={classes.table}
                    style={{ marginTop: "1rem" }}
                  >
                    <TableBody>
                      <TableRow>
                        <TableCell>Extra Commission</TableCell>
                        <TableCell>
                          <TextField
                            InputProps={{
                              disableUnderline: true,
                              inputProps: { min: 0 },
                              type: "number",
                            }}
                            variant="outlined"
                            disabled={false}
                            value={extraCommission}
                            onChange={onCommissionChange}
                            onKeyPress={(event) => {
                              if (event.key === '-' || event.key === '+') {
                                event.preventDefault();
                              }
                            }}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>City Commission</TableCell>
                        <TableCell>
                          <TextField
                            variant="outlined"
                            InputProps={{
                              disableUnderline: true,
                              inputProps: { min: 0 },
                              type: "number",
                            }}
                            
                            disabled={false}
                            value={cityCommission}
                            onChange={onChangeCityCommission}
                            onKeyPress={(event) => {
                              if (event.key === '-' || event.key === '+') {
                                event.preventDefault();
                              }
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>

              <br />

              <div style={siblingStyles}>
                <TableContainer
                  component={Paper}
                  style={{
                    marginBottom: "2rem",
                    width: "33rem",
                    borderRadius: "13px",
                    paddingBottom: "1rem",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    className={classes.tableHeader}
                  >
                    {" "}
                    Add Deductions{" "}
                  </Typography>
                  <Table size="small" className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Customer</TableCell>
                        <TableCell>Channel Partner</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>Leeway</TableCell>
                        <TableCell>
                          <TextField
                            colSpan={5}
                            variant="outlined"
                            onChange={onLeewayChange}
                            value={leeway}
                            disabled={false}
                            InputProps={{
                              disableUnderline: true,
                              inputProps: { min: 0 },
                              type: "number",
                            }}
                            onKeyPress={(event) => {
                              if (event.key === '-' || event.key === '+') {
                                event.preventDefault();
                              }
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            variant="filled"
                            InputProps={{ disableUnderline: true }}
                            disabled
                            value={orderDedctions && orderDedctions.leeway && orderDedctions.leeway
                              ? orderDedctions.leeway
                              : 0}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Traffic Challan</TableCell>
                        <TableCell>
                          <TextField
                            variant="outlined"
                            onChange={onChangeChallan}
                            value={trafficChallan}
                            disabled={false}
                            InputProps={{
                              disableUnderline: true,
                              inputProps: { min: 0 },
                              type: "number",
                            }}
                            onKeyPress={(event) => {
                              if (event.key === '-' || event.key === '+') {
                                event.preventDefault();
                              }
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            variant="filled"
                            InputProps={{ disableUnderline: true }}
                            disabled={true}
                            value={orderDedctions &&
                              orderDedctions.userDeductions &&
                              orderDedctions.userDeductions.userTrafficChalan
                              ? orderDedctions.userDeductions.userTrafficChalan
                              : 0}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>HP/RTO Charges</TableCell>
                        <TableCell>
                          <TextField
                            variant="outlined"
                            onChange={onChangeRto}
                            value={hpRto}
                            disabled={false}
                            InputProps={{
                              disableUnderline: true,
                              inputProps: { min: 0 },
                              type: "number",
                            }}
                            onKeyPress={(event) => {
                              if (event.key === '-' || event.key === '+') {
                                event.preventDefault();
                              }
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            variant="filled"
                            InputProps={{ disableUnderline: true }}
                            disabled
                            value={orderDedctions &&
                              orderDedctions.userDeductions &&
                              orderDedctions.userDeductions.userHpRtoCharges
                              ? orderDedctions.userDeductions.userHpRtoCharges
                              : 0}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Doc QC2 Charges</TableCell>
                        <TableCell>
                          <TextField
                            variant="filled"
                            InputProps={{
                              disableUnderline: true,
                              inputProps: { min: 0 },
                              type: "number",
                            }}
                            disabled={true}
                            onChange={onChangeDocQc2}
                            value={docQc2Charges}
                            onKeyPress={(event) => {
                              if (event.key === '-' || event.key === '+') {
                                event.preventDefault();
                              }
                            }}
                          />
                          {/* <TextField variant='outlined' value={docQc2Charges} onChange={oDocQc2ChargesChange} disabled={true} /> */}
                        </TableCell>
                        <TableCell>
                          <TextField
                            variant="filled"
                            InputProps={{ disableUnderline: true }}
                            disabled
                            value={
                              orderDedctions &&
                              orderDedctions.docQc2Deductions &&
                              orderDedctions.docQc2Deductions
                                ? orderDedctions.docQc2Deductions
                                : 0
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Comments</TableCell>
                        <TableCell colspan={2} rowspan={2}>
                          <TextField
                            multiline
                            variant="outlined"
                            InputProps={{ inputProps: { maxLength: 100 } }}
                            className={classes.commentInput}
                            placeholder="Enter Comments"
                            value={comments}
                            onChange={onCommentsChange}
                            disabled={enable}
                          />
                          {wordsLeft < 100 && (
                            <Typography
                              variant="caption"
                              style={{ color: "red" }}
                            >
                              {wordsLeft} word(s) left
                            </Typography>
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              {/* <br /> */}
              {true &&
              <div style={siblingStyles}>
              <TableContainer
                component={Paper}
                style={{
                  marginBottom: "2rem",
                  width: "33rem",
                  borderRadius: "13px",
                  paddingBottom: "1rem",
                }}
              >
                <Typography variant="subtitle1" className={classes.tableHeader}>
                  {" "}
                  Add Hold Amount{" "}
                </Typography>
                <Table size="small" className={classes.table}>
                  <TableBody>
                  <TableRow>
                      <TableCell>Hold Amount</TableCell>
                      <TableCell>
                        <TextField
                          variant="outlined"
                          disabled={orderDedctions.invPaymentStatus !== "PENDING"}
                          className={classes.selectInput}
                          value={holdAmount}
                          onChange={onHoldAmountChange}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Reason for Hold</TableCell>
                      <TableCell>
                      <TextField
                          select
                          variant="outlined"
                          className={classes.selectInput}
                          value={holdReason}
                          onChange={onHoldReasonChange }
                          disabled={orderDedctions.invPaymentStatus !== "PENDING"}
                        >
                          <option value="0" disabled>Select Reason</option>
                          {
                            LeadInfo && LeadInfo.holdReasons &&
                            LeadInfo.holdReasons.map(reason =>{
                              return <option className={classes.selectOption} key = {reason.reason} value={reason.reason}> {reason.reason}</option>
                            })
                          }
                          </TextField>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Comments</TableCell>
                      <TableCell colSpan={2} rowSpan={2}>
                        <TextField
                          multiline
                          variant="outlined"
                          InputProps={{ inputProps: { maxLength: 100 } }}
                          className={classes.commentHoldInput}
                          placeholder="Enter Comments"
                          value={holdComments}
                          onChange={onHoldCommentsChange}
                          disabled={orderDedctions.invPaymentStatus !== "PENDING"}
                        />
                                                  {wordsHoldLeft < 100 && (
                            <Typography
                              variant="caption"
                              style={{ color: "red" }}
                            >
                              {wordsHoldLeft} word(s) left
                            </Typography>
                          )}
                      </TableCell>
                    </TableRow>
                    {/* <TableRow>
                        <TableCell>Comments</TableCell>
                        <TableCell colspan={2} rowspan={2}>
                          <TextField
                            multiline
                            variant="outlined"

                            className={classes.commentInput}
                            placeholder="Enter Comments"
                            value={comments}
                            onChange={onCommentsChange}
                            disabled={enable}
                          />

                        </TableCell>
                      </TableRow> */}
                  </TableBody>
                </Table>
              </TableContainer>
              </div>
              }
              </div>
            </div>
            <div style={siblingStyles}>
              <div style={{ width: "33rem" }}>
                <label
                  style={{
                    textAlign: "left",
                    width: "100%",
                    marginBottom: "10px",
                    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                    fontSize:"16px",
                   fontWeight:"500",color:"#000000"
                  }}
                  htmlFor="cpHighestBid"
                >
                  CP Highest Bid
                </label>
                <br />
                <input
                  style={{
                    textAlign: "left",
                    width: "100%",
                    marginBottom: "30px",
                    height: "36px",
              fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                fontSize:"16px",
                 color:"#000000",
                  backgroundColor:"white",padding:"1px 10px",border:"none"
                  }}
                  id="cpHighestBid"
                  type="number"
                  onChange={handleCPHighestBid}
                  value={cpHighestBid}
                  onKeyPress={(event) => {
                    if (event.key === '-' || event.key === '+' || event.key === '.') {
                      event.preventDefault();
                    }
                  }}
                />

                <TableContainer
                  component={Paper}
                  style={{
                    width: "33rem",
                    borderRadius: "13px",
                    paddingBottom: "1rem",
                    marginBottom: "2rem",
                    minHeight: "15rem",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    className={classes.tableHeader}
                  >
                    Totals
                  </Typography>
                  <Table size="small" className={classes.table}>
                    {true && (
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell>Customer</TableCell>
                          <TableCell>Channel Partner</TableCell>
                        </TableRow>
                      </TableHead>
                    )}
                    <TableBody>
                      <TableRow>
                        <TableCell>Highest Bid</TableCell>
                        <TableCell>
                          <TextField
                            variant="filled"
                            InputProps={{ disableUnderline: true }}
                            // disabled
                            value={
                              getAmount(cpHighestBid)
                              // orderDedctions && orderDedctions.highestBid
                              //   ? getAmount(orderDedctions.highestBid)
                              //   : getAmount(0)
                            }
                          />
                        </TableCell>
                        {true && (
                          <TableCell>
                            <TextField
                              variant="filled"
                              InputProps={{ disableUnderline: true }}
                              disabled
                              value={getAmount(cpHighestBid)}
                            />
                          </TableCell>
                        )}
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Total Deductions
                          <Chip
                            size="small"
                            label="-"
                            style={{
                              backgroundColor: "#DA2128",
                              color: "white",
                              position: "relative",
                              right: "-1.6rem",
                              fontSize: "x-large",
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            variant="filled"
                            InputProps={{ disableUnderline: true }}
                            disabled
                            value={getAmount(totalDeductionCustomer())}
                          />
                        </TableCell>
                        {true && (
                          <TableCell>
                            <TextField
                              variant="filled"
                              InputProps={{ disableUnderline: true }}
                              disabled
                              value={getAmount(totalDeductionCP())}
                            />
                          </TableCell>
                        )}
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Final Amount
                          <Chip
                            size="small"
                            label="="
                            style={{
                              backgroundColor: "#47B26C",
                              color: "white",
                              position: "relative",
                              right: "-2.9rem",
                              fontSize: "large",
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            variant="filled"
                            InputProps={{ disableUnderline: true }}
                            style={{ backgroundColor: "#93CC99" }}
                            disabled
                            value={getAmount(finalAmtCustomer())}
                          />
                        </TableCell>
                        {true && (
                          <TableCell>
                            <TextField
                              variant="filled"
                              InputProps={{ disableUnderline: true }}
                              style={{ backgroundColor: "#93CC99" }}
                              disabled
                              value={getAmount(finalAmtCP())}
                            />
                          </TableCell>
                        )}
                      </TableRow>
                      { 
                      Number(holdAmount) >0 &&
                    <TableRow>
                      <TableCell>
                        Final Amount With Hold
                      </TableCell>
                      <TableCell>
                        <TextField
                          variant="filled"
                          InputProps={{ disableUnderline: true }}
                          disabled
                          value={getAmount(finalAmtCustomer()-holdAmount)}
                        />
                      </TableCell>
                    </TableRow>
                   } 
                    </TableBody>
                  </Table>
                  
                  {paymentError && <div className="payment-error-container"><img alt="alert-image" className="alert-image"  src={AlertIconRed}/> <FormHelperText
                      style={{
                        color: "red",
                        marginLeft: "0.25rem",
                        textAlign: "start",fontWeight:"bold"
                      }}
                    > {paymentError}</FormHelperText></div>}
                  {/* {!checkPriceMargin() && (
                    <FormHelperText
                      style={{
                        color: "red",
                        marginRight: "1rem",
                        textAlign: "end",
                      }}
                    >
                      
                      <b>CP Highest bid amount cannot be less than {" "}
                      {calculateMinBidValue()}
                      /-</b>
                      <br />
                    </FormHelperText>
                  )} */}
                </TableContainer>
                <TableContainer
                    component={Paper}
                    style={{
                      width: "33rem",
                      borderRadius: "13px",
                      paddingBottom: "1rem",
                      marginBottom: "2rem",
                      height: "10rem",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      className={classes.tableHeader}
                    >
                      Payment Status
                    </Typography>
                    <Table size="small" className={classes.table}>
                      { 
                        <TableHead>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Customer</TableCell>
                            <TableCell>Channel Partner</TableCell>
                          </TableRow>
                        </TableHead>
                      }
                      <TableBody>
                        <TableRow>
                          <TableCell>Payment Status</TableCell>
                          <TableCell>
                          {orderDedctions &&
                                  orderDedctions.shdPaymentStatus
                                    ? getStatus(orderDedctions.invPaymentStatus)
                                    : "-"}
                            </TableCell>
                            <TableCell>
                               {orderDedctions &&
                                  orderDedctions.shdPaymentStatus
                                    ? getStatus(orderDedctions.shdPaymentStatus)
                                    : "-"}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Paid Amount</TableCell>
                          
                          <TableCell>
                          <TextField
                          inputProps={{ style: { color: "#366896" } }}
                          InputProps={{ disableUnderline: true }}
                          disabled
                          style={{
                            backgroundColor: "#E9F4FF",
                            color: "#366896",
                            padding: "1px 15px",
                            borderRadius: "4px",
                            fontWeight: "500",
                          }}
                          
                          value={
                            orderDedctions && orderDedctions.invPaymentAmount
                              ? getAmount(orderDedctions.invPaymentAmount)
                              : "-"
                          }
                          />
                            </TableCell>
                            <TableCell>
                            <TextField
                                inputProps={{ style: { color: "#366896" } }}
                                InputProps={{ disableUnderline: true }}
                                disabled
                                style={{
                                  backgroundColor: "#E9F4FF",
                                  color: "#366896",
                                  padding: "1px 15px",
                                  borderRadius: "4px",
                                  fontWeight: "500",
                                }}
                                
                                value={
                                  channelPartnerResponseBean && channelPartnerResponseBean.amount
                              ? getAmount(channelPartnerResponseBean.amount)
                              : "-"
                                }
                          />
                            </TableCell>
                        </TableRow>

                      </TableBody>
                    </Table>
                  </TableContainer>
              </div>
            </div>
          </div>
        )}
        {openConfirmDialog && (
          <ConfirmationDialog
            setOpenConfirmDialog={setOpenConfirmDialog}
            onConfirm={onConfirm}
            openConfirmDialog={openConfirmDialog}
          />
        )}
      </Modal.Body>

      </Modal.Header>
      

      
      {!openConfirmDialog && <Modal.Footer style={{ textAlign: "center" }}>
        {/* <button onClick={discardBid} style={{ border: '#DEDEDE', backgroundColor: '#E59342', color: '#333333', padding: '8px 20px', borderRadius: '22px', marginRight: '10px' }}>Cancel</button> */}
        <button
          onClick={handleAcceptBtnClick}
          style={{
            // marginRight: 15,
            color: "#ffffff",
            padding: "8px 20px",
            borderRadius: "22px",
            backgroundColor: "#4DBD74",
            border: "1px solid #35AC5E",cursor: 'pointer'
          }}
          // disabled={resellButton !== true}
        >
          Re-Sell to CP
        </button>
      </Modal.Footer>}
    </Modal>
  );
};
export default RefundForm;
