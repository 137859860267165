import { AE_TASKS } from '../../core/constants/actions'
import { getNewState } from '../../core/utility'

const intialState = {
  taskList: [],
  isLoading: false,
  pendingCount: 0,
  confirmedCount:0,
  pageNum: 1
}

export default function aeTaskList(state = intialState, action) {

  switch (action.type) {
    case AE_TASKS.REQUEST:
      return getNewState(state, { isLoading: true })

    case AE_TASKS.SUCCESS:
      const { taskList, pageNum, pendingCount, confirmedCount} = action.payload;
      return getNewState(state, { taskList, isLoading: false, pageNum, pendingCount, confirmedCount})

    case AE_TASKS.FAILURE:
      const { error } = action.payload
      return getNewState(state, { error, ...intialState })

    default:
      return state
  }
}