import React, { FC, useState, useEffect, useRef } from 'react'
import { connect, ConnectedProps, useSelector } from 'react-redux'
import { renderString, getAmount, renderDate, getDate, hideEmail } from '../../../../../core/utility/stringUtility'
import { setNotification } from "../../../../../redux/actions/notificationAction"
import CallToCustomer from '../../../components/CallActionPopUp'
import OTPPopup from '../../../components/OTPPopup'
import { useDispatch } from "react-redux"
import { callToCareCustomer, sendOtpToCustomer, confirmOTP, getCustomerDocumentsDetails } from '../../../../../core/services/shdServices'
import { getMobile, getRole, getUserID } from '../../../../../core/services/rbacServices'
import { showLoader, hideLoader } from '../../../../../redux/actions/loaderAction'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableHead from '@material-ui/core/TableHead'
import { Pagination } from '@material-ui/lab';
import NoResultsFound from '../../../../../shared/components/NoResultFound'
import "../../../components/Error.scss"
import Timer from '../../../components/Timer'
import { Button } from 'rsuite'
import { makeStyles } from "@material-ui/core/styles";
import { updateStatus, getHistoryData } from '../../../../../core/services/shdServices'
import UpdateStatusQuote from '../../../components/UpdateStatusPop'
// import PendingDrawer from './PendingDrawer'
// import FollowupDrawer from './FollowUpDrawer'
import UpdateDropStatus from './UpdateDropStatus'
// import SoldStatusDrawer from '../../../components/SoldStatusDrawer'
import moment from 'moment'
import { getConfirmationList } from '../../../../../redux/actions/quoteConfirmationAction'
import EditDialog from '../../../components/EditModelComponent'
import { EditIcon, NotesIcon, NotesIconNew, PriorityImage, WhiteCallIcon } from '../../../../../core/utility/iconHelper'
import StatusSlider from '../../../StatusSlider'
import CustomerEPEditDialog from '../../../components/EdiitPriceModal'
import { leadDetails } from '../../../../../redux/actions/biddingDetailsAction'
import { leadLifecycleDetails } from '../../../../../redux/actions/biddingLifecycleAction'
import { getShdOrderDeduction } from '../../../../../redux/actions/shdOrderDeductionAction'
import { getShdCustomerDocuments } from '../../../../../redux/actions/shdCustomerDocumentsAction'
import { getExceptionList } from '../../../../../core/services/miscServices'
import CollectDocModel from '../../../StatusSlider/components/CustomerDocument/CollectDocModel.jsx'
import Tooltip from "@material-ui/core/Tooltip";
import ChatBox from "../../../../../shared/components/ChatBox";
import PriorityLabel from "../../../../../shared/components/PriorityLabel";
import DIYLabel from '../../../../../shared/components/DIYLabel'
import EditPriceModal from '../../../../../shared/components/EdiitPriceModal'
import { updateFranchisePrice } from '../../../../../core/services/franchiseServices'
import { getPremiumAccess } from '../../../../../core/services/authenticationServices'
import { getStatusColor } from '../../../../../core/utility/colorUtility.js'

// const rows = [
//   // { id: "prirityLabel", label: "" },
//   { id: "Seller Lead ID", label: "Seller Lead ID" },
//   { id: "Lead Created Date", label: "Lead Created Date" },
//   { id: 'Listed Date', label: 'Listed Date' },
//   { id: "Vehicle Details", label: "Vehicle Details" },
//   { id: "Seller Contact Info", label: "Seller Contact Info" },
//   { id: "Interested Buyers", label: "Interested Buyers" },
//   // { id: "Agent Name", label: "Agent Name" },
//   { id: "C2C Status", label: "C2C Status" },
//   { id: "Listed Price", label: "Listed Price" },
//   { id: "Seller Expected Price", label: "Seller Expected Price" },
//   { id: "chatBox", label: "Chat" },
// ];

const rowsForPending = [
  // { id: "prirityLabel", label: "" },
  { id: "Buyer Lead ID", label: "Buyer Lead ID" },
  { id: "Lead Created Date", label: "Lead Created Date" },
  { id: 'Buyers Details', label: 'Buyers Details' },
  { id: "Pin Code", label: "Pin Code" },
  { id: "Interested Price", label: "Interested Price" },
  { id: "Buyers Status", label: "Buyers Status" },
  // { id: "Agent Name", label: "Agent Name" },
  { id: "Actions", label: "Actions" },
  { id: "notes", label: "Notes" },
];
const rowsForUnassigned = [
  // { id: "prirityLabel", label: "" },
  { id: "Buyer Lead ID", label: "Buyer Lead ID" },
  { id: "Lead Created Date", label: "Lead Created Date" },
  { id: 'Buyers Details', label: 'Buyers Details' },
  { id: "Pin Code", label: "Pin Code" },
  { id: "Interested Price", label: "Interested Price" },
  { id: "Buyers Status", label: "Buyers Status" },
  // { id: "Agent Name", label: "Agent Name" },
  // { id: "Actions", label: "Actions" },
  { id: "notes", label: "Notes" },
];
// const rowsForSold = [
//   // { id: "prirityLabel", label: "" },
//   { id: "Seller Lead ID", label: "Seller Lead ID" },
//   { id: "Lead Created Date", label: "Lead Created Date" },
//   { id: 'Listed Date', label: 'Listed Date' },
//   { id: "Vehicle Details", label: "Vehicle Details" },
//   { id: "Seller Contact Info", label: "Seller Contact Info" },
//   { id: "Interested Buyers", label: "Interested Buyers" },
//   // { id: "Agent Name", label: "Agent Name" },
//   { id: "C2C Status", label: "C2C Status" },
//   { id: "Listed Price", label: "Listed Price" },
//   { id: "Seller Expected Price", label: "Seller Expected Price" },
//   { id: "chatBox", label: "Chat" },
// ];
// const rowsForDrop = [
//   // { id: "prirityLabel", label: "" },
//   { id: "Seller Lead ID", label: "Seller Lead ID" },
//   { id: "Lead Created Date", label: "Lead Created Date" },
//   { id: 'Listed Date', label: 'Listed Date' },
//   { id: "Vehicle Details", label: "Vehicle Details" },
//   { id: "Seller Contact Info", label: "Seller Contact Info" },
//   { id: "Interested Buyers", label: "Interested Buyers" },
//   // { id: "Agent Name", label: "Agent Name" },
//   { id: "C2C Status", label: "C2C Status" },
//   { id: "Listed Price", label: "Listed Price" },
//   { id: "Seller Expected Price", label: "Seller Expected Price" },
//   { id: "chatBox", label: "Chat" },
// ];

// // const rowsForFollowup = [
// //   { id: "prirityLabel", label: "" },
// //   { id: "leadId", label: "Lead\u00a0Id" },
// //   { id: "diyType", label: "DIY\u00a0Type" },
// //   { id: 'leadPriority', label: 'Lead Priority' },
// //   { id: "leadcreatedDate", label: "Lead\u00a0Created Date" },
// //   { id: "auctionCompletedDate", label: "Auction\u00a0Date" },
// //   { id: "holdDate", label: "Hold\u00a0Date" },
// //   { id: "followupDate", label: "Followup\u00a0Date" },
// //   { id: "customerName", label: "Customer\u00a0Details" },
// //   { id: "regNumber", label: "Vehicle Details" },
// //   // { id: 'cityName', label: 'City Name' },
// //   { id: "agentName", label: "Agent\u00a0Name" },
// //   { id: "reason", label: "Followup\u00a0Reason" },
// //   // { id: 'outletDetails', label: 'Outlet\u00a0Details', },
// //   { id: "highestBid", label: "Highest Bid" },
// //   { id: "customerExpectedPrice", label: " Customer Expected Price" },
// //   { id: "leadSQI", label: "Lead SQI" },
// //   // { id: 'totalCommission', label: 'Commission' },
// //   { id: "averageCpPrice", label: "CP Procurement Price" },
// //   { id: "storeProcPrice", label: `Store\u00a0Procurement\u00a0Price` },
// //   { id: "cpMaxProcurementPrice", label: "Procurement Price" },
// //   { id: "auctionStatus", label: "Auction Status" },
// //   { id: "chatBox", label: "Chat" },
// // ];

const dummyData={}

const CInterestedTable = ({
  onChangePage,
  status,
  handleStatusFilter,
  refreshPage,
  confirmList=[{}],
  currentPage,
  totalCount,
  onValuatorDetails,
  value,
  handleValue,
  onPendingRefresh,
  refreshDropPage,
  refreshFollowUpPage,
  refreshSoldPage,
  withHeaders,
  buyerStatus,
  forInterestedTab,
  setOpenTranferDialog,
  statusList,
  rowInfo,
  mainTabStatus
}) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      marginTop: theme.spacing(3),
      overflowX: "auto",
    },
    table: {
      "& .MuiTableCell-body": {
        minWidth: "8rem",
      },
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor: "#FBEEED",
      },
    },
    tableCell: {
      "$selected &": {
        color: "yellow",
      },
    },
    hover: {},
    selected: {},
  }));

  const [leadData, setLeadData] = useState({});
  const [callPopUp, setCallPopUp] = useState(false);
  const [otpPopup, setOtpPopUp] = useState(false);
  const [wrongOtp, setWrongOtp] = useState(false);
  const [selectedID, setSelectedID] = useState("");
  const [drawerClass, setDrawerclass] = useState("table-shd-drawer");
  const dispatch = useDispatch();
  // const [rowInfo, setRowInfo] = useState({});
  const [statusPopUp, setStatusPopup] = useState(false);
  const [historyData, setHistoryData] = useState({});
  const [historyDataFollowup, setHistoryDataFollowUp] = useState({})
  const [dropstatusPopUp, setDropStatusPopup] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [username, setUserName] = useState('')
  const [email, setEmail] = useState('')
  const [leadId, setLeadId] = useState('')
  const [openSlider, setOpenSlider] = useState(false)
  const [editPrice, setEditPrice] = useState(false)
  const [price, setPrice] = useState('')
  const classes = useStyles()
  const [leadDetailsData, setLeadDetailsData] = useState({})
  const [enable, setEnable] = useState(true)
  const [list, setList] = useState({})
  const LeadBuyersList = useSelector((state) => state.interestedBuyersList.intrestedBuyers)
  const LeadLifeInfo = useSelector((state => state.biddingListLifecycleReducer.leadDetails))
  const orderDedctions = useSelector((state) => state.orderDeductions.leadDetails)
  const customerDocuments = useSelector((state) => state.customerDocuments.customerDetails)
  const [openEdit, setOpenEdit] = useState(false)
  

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevStatus = usePrevious(status);

  useEffect(() => {
    if (prevStatus !== status) {
      setDrawerclass("table-shd-drawer");
    }
  }, [status]);

  const handlePageChange = (event, pageNumber) => {
    if (pageNumber !== currentPage) {
      onChangePage(pageNumber);
    }
  };
  const handleRefresh = (value) => {
    // setOpenSlider(false);
    refreshPage(value);
  };

  const onRefreshDrop = () => {
    // setOpenSlider(false);
    refreshDropPage();
  };
  const onRefreshFollowUp = () => {
    // setOpenSlider(false);
    refreshFollowUpPage();
  };
  const onRefreshSold = () => {
    // setOpenSlider(false);
    refreshSoldPage();
  };

  const callToCustomer = (data) => {
    setLeadData(data);
    setCallPopUp(true);
  };
  const closeSlider = () => {
    // setOpenSlider(false);
    setEnable(true);
    localStorage.removeItem("optionValue");
  };
  const handleEnable = (val) => {
    setEnable(val);
  };

  const sendOtp = async (data) => {
    setLeadData(data);
    setOtpPopUp(true);
    dispatch(showLoader());
    const apiResponse = await sendOtpToCustomer(data.buyerLeadId);
    dispatch(hideLoader());
    if (apiResponse.isValid) {
      dispatch(setNotification("success",apiResponse.message,"Success"));
    } else {
      dispatch(setNotification("error",apiResponse.message,"Error"));
    }
  };

  const callAction = async (data, info) => {
    const payload = {
      fromNumber: getMobile(),
      // toNumber: data.userMobileNumber,
      toNumber:data.buyerMobileNumber,
      userId: getUserID(),
      leadId: data.buyerLeadId,
    };
    dispatch(showLoader());
    const apiResponse = await callToCareCustomer(payload);
    dispatch(hideLoader());
    if (apiResponse.isValid) {
      dispatch(setNotification("success",apiResponse.message,"Success"));
      // if (status === "SOLD" || status === "DROP") {
      //   setStatusPopup(false);
      // } else {
      //   setStatusPopup(true);
      // }
    } else {
      dispatch(setNotification("error",apiResponse.message,"Error"));
    }
    setCallPopUp(false);
  };

  const otpVerify = async (otpVal, data) => {
    dispatch(showLoader());
    const payload = {
      otp: otpVal,
      leadId: data.buyerLeadId,
    };
    const apiResponse = await confirmOTP(payload);
    dispatch(hideLoader());
    if (apiResponse.isValid) {
      dispatch(setNotification("success",apiResponse.message,"Success"));
      setOtpPopUp(false);
      setWrongOtp(false);
      window.location.reload();
    } else {
      setWrongOtp(true);
    }
  };

  const closePopUp = () => {
    setOtpPopUp(false);
    setWrongOtp(false);
  };

  // const handleRowChange = (list) => {
  //   // if (drawerClass === 'table-shd-drawer') {
  //   //   setDrawerclass('table-shd-drawer open')
  //   //   setSelectedID(list.leadId)
  //   //   if (status !== 'UNASSIGNED' && status !== 'PENDING') {
  //   //     if (status === 'FOLLOWUP') {
  //   //       status = 'FOLLOW UP'
  //   //       getHistoryData(list.leadId, status)
  //   //         .then(apiResponse => {
  //   //           if (apiResponse.isValid) {
  //   //             setHistoryData(apiResponse.diyCallHistoryResponse)
  //   //           }
  //   //         })
  //   //     }
  //   //     else {
  //   //       getHistoryData(list.leadId, status)
  //   //         .then(apiResponse => {
  //   //           if (apiResponse.isValid) {
  //   //             setHistoryData(apiResponse.diyCallHistoryResponse)
  //   //           }
  //   //         })
  //   //     }
  //   //   }
  //   // }
  //   // else {
  //   //   setDrawerclass('table-shd-drawer')
  //   //   setSelectedID(list.leadId)
  //   // }
  //   setSelectedID(list.buyerLeadId)
  //   setRowInfo(list)
  //   setLeadData(list)
  //   dispatch(leadDetails(list.buyerLeadId))
  //   dispatch(leadLifecycleDetails(list.buyerLeadId))
  //   dispatch(getShdOrderDeduction(list.buyerLeadId))
  //   // if(status === "PENDING") {
  //   dispatch(getShdCustomerDocuments(list.buyerLeadId))
  //   // }
  //   // 933140, 
  //   // setOpenSlider(true)
  //   handleValue('')
  //   // handleStatusFilter('')
  // };

  const handleAssignChange = (event, list) => {
    event.stopPropagation();
    setDropStatusPopup(true)
    setList(list)
  };

  const onRefreshRow = () => {
    setDrawerclass("table-shd-drawer");
    refreshPage();
  };

  const handleDropStatus = () => {
    setDropStatusPopup(true);
  };

  const getFinalQuote = (list) => {
    let a = Number(list.bidAmount);
    let b = Number(list.cityLevelCommission);
    return a - b;
  };

  const updateStatusForm = () => {
    setStatusPopup(true);
  };
  const openEditDialog = (e, leadId, name, email) => {
    e.stopPropagation();
    setOpenDialog(true);
    setLeadId(leadId);
    setUserName(name);
    setEmail(email);
  };
  const closeDialog = () => {
    setOpenDialog(false);
    setLeadId("");
    setUserName("");
    setEmail("");
  };
  const onOpenEditCEP = (e, price, leadId) => {
    e.stopPropagation();
    setEditPrice(true);
    setPrice(price);
    setLeadId(leadId);
  };
  const onOpenEditSP = (e, list) => {
    setSelectedID(list.leadId)
    e.stopPropagation();
    setList(list)
    setOpenEdit(true)
  };
  const onCloseEditSP = () => {
    setList({})
    setOpenEdit(false);
  };
  const onCloseEditCEP = () => {
    setEditPrice(false);
  };
  const closeAndRefresh = () => {
    // setOpenSlider(false);
    refreshPage();
  };

  const valuatorDetails = (details) => {
    if (onValuatorDetails) {
      onValuatorDetails({
        storeId: details.storeId,
        valuatorId: details.valuatorId,
      });
    }
  };

  
  const getStatus = (leadStatus, time) => {
    if (leadStatus === "AUCTION_COMPLETED") {
      return (
        <span
          style={{
            backgroundColor: "#DAF2DD",
            color: "#196834",
            padding: "1px 5px",
            borderRadius: "4px",
            width: "102px",
          }}
        >
          Auction Completed
        </span>
      );
    }
    if (leadStatus === "Central_Tagging_Inspected") {
      return (
        <span
          style={{
            backgroundColor: "#FFE1C5",
            color: "#B96716",
            padding: "1px 5px",
            borderRadius: "4px",
            width: "102px",
          }}
        >
          CT Inspected
        </span>
      );
    }
    if (leadStatus === "DROPPED") {
      return (
        <span
          style={{
            backgroundColor: "#FFD4D6",
            color: "#DA2128",
            padding: "1px 5px",
            borderRadius: "4px",
            width: "102px",
          }}
        >
          Dropped
        </span>
      );
    } else {
      return (
        <>
          <span
            style={{
              backgroundColor: "#E9F4FF",
              color: "#366896",
              padding: "1px 15px",
              borderRadius: "4px",
            }}
          >
            {" "}
            Ongoing{" "}
          </span>
          <Timer time={time} />
        </>
      );
    }
  };

  const reAssignTo = (list) => {
    // setOpenSlider(false);
    const payload = {
      userId: getUserID(),
      leadId: list.buyerLeadId,
      status: "ASSIGN",
      crmLeadId: list.crmLeadId,
    };
    updateStatus(payload).then((apiRes) => {
      if (apiRes.isValid) {
        dispatch(hideLoader());
        dispatch(setNotification("success",apiRes.message,"Success"));
        onPendingRefresh();
      } else {
        dispatch(setNotification("error",apiRes.message,"Error"));
      }
    });
  };

  const acceptOfferStore = (payload, type) => {
    const finalPayload = {
      ...payload,
      userId: getUserID()
    }
    updateFranchisePrice(finalPayload).
      then(apiResponse => {
        if (apiResponse.isValid) {
          dispatch(setNotification('success', 'SUCCESS', apiResponse.message))
          if (type === 'accpet') {
            handleValue('Commission')
            // setOpenSlider(true)
          }
          setOpenEdit(false)
          const payload = {
            status: status,
            pageNum: 1,
            userId: getUserID()
          }
          dispatch(getConfirmationList(payload))
          dispatch(getShdOrderDeduction(selectedID))
        }
        else {
          dispatch(setNotification('danger', 'ERROR', apiResponse.message))
          setOpenEdit(false)
        }
      })
  }

  const getStoreEditModal = () => {
    if (status === 'PENDING' || status === 'FOLLOWUP' || status === 'DROP') return true
    return false
  }


  const getLockedAndPaymentDone=()=>{
    return(buyerStatus==="Deal_Locked" && LeadLifeInfo && LeadLifeInfo.buyersPaymentDetails && LeadLifeInfo.buyersPaymentDetails.paymentStatus==="COMPLETED")
  }
  return (
    <div className="table-wraper">
      <Table size="small" className={classes.table}>
        <TableHead>
          { <TableRow>
            {mainTabStatus !== "UNASSIGNED"  &&
              rowsForPending.map((row) => (
                <TableCell key={row.id}>{row.label}</TableCell>
              ))}
            {/* {status === "SOLD" &&
              rowsForSold.map((row) => (
                <TableCell key={row.id}>{row.label}</TableCell>
              ))} */}
            {/* {status === "FOLLOWUP" &&
              rowsForFollowup.map((row) => (
                <TableCell key={row.id}>{row.label}</TableCell>
              ))} */}
            {/* {status === "DROP" &&
              rowsForDrop.map((row) => (
                <TableCell key={row.id}>{row.label}</TableCell>
              ))} */}
            {mainTabStatus === "UNASSIGNED" &&
              rowsForUnassigned.map((row) => (
                <TableCell key={row.id}>{row.label}</TableCell>
              ))}
          </TableRow>}
        </TableHead>
        <TableBody>
          {Boolean(LeadBuyersList.length) &&
            LeadBuyersList.filter((accessItem)=>((!accessItem.premiumLead) || getPremiumAccess()) && accessItem.buyerStatus===buyerStatus).map((list, index) => {
              return (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={`${list.buyerLeadId}${index}`}
                  // onClick={() => handleRowChange(list)}
                  selected={selectedID === list.buyerLeadId}
                  // classes={{ hover: classes.hover, selected: classes.selected }}
                  // className={classes.tableRow}
                  className={list.storeType === "INTERNAL" ? 'oemSupply' :''}
                >
                  {/* <TableCell style={{ minWidth: "2rem" }}>
                    {list.premiumLead && <img className='priorityImage' size={16} src={PriorityImage}/>}
                  </TableCell> */}

                  {/* "buyerLeadId": 5217,
                  "leadCreatedDate": "2023-11-28 17:13:41.0",
                  "cityName": "Bangalore",
                  "cityId": 1,
                  "buyerName": "manish",
                  "buyerMobileNumber": "8292724298",
                  "buyerEmailId": "abc@gmail.com",
                  "pincode": 560071,
                  "buyerIntrestedPrice": null,
                  "buyerStatus": "new",
                  "chatCount": 0 */}
                  <TableCell>
                    {list.buyerLeadId}
                    {/* <>
                      <div style={{ display: 'flex' }} className='row'>
                        <div className='col-5'>
                          {list && list.leadSource && list.leadSource.length > 8 ? (
                            <Tooltip
                              title={renderString(list.leadSource)}
                              placement="bottom"
                              style={{ backgroundColor: '#565656', color: '#FFFFFF' }}
                            >
                              <span style={{ cursor: "pointer" }}>
                                <p className="leadSource" style={{ fontSize: '8px' }}>{list.leadSource.substring(0, 8)}....</p>
                              </span>
                            </Tooltip>
                          ) : (
                            <p className="leadSource" style={{ fontSize: '8px' }}>{list.leadSource}</p>
                          )}
                        </div>
                        {
                          list.leadFranchiseStatus && list.leadFranchiseStatus === 'INTERESTED' ?
                            <div className='col-7' style={{ marginLeft: '-25px' }}>
                              <button className='storeIntersted'>STORE INTERESTED</button>
                            </div> : ''
                        }
                      </div>
                    </> */}
                    {/* {Number(list.premiumBikeFlag) === 1 && (
                      <div
                        style={{
                          backgroundColor: "#E98000",
                          color: "white",
                          padding: "4px 2px",
                          textAlign: "center",
                          width: "4rem",
                          borderRadius: "4px",
                        }}
                      >
                        Premium
                      </div>
                    )} */}
                    {/* {status === "FOLLOWUP" &&
                      (moment(getDate(list.followupDate)).diff(
                        moment(getDate(moment()))
                      ) > 0 ? (
                        <></>
                      ) : (
                        <p style={{ color: "#D92128" }}>Delayed</p>
                      ))} */}
                  </TableCell>
                  {/* <TableCell>
                    <DIYLabel status={renderString(list.diyType)} showPremium = {list.storeType === "INTERNAL"} />
                  </TableCell> */}
                  {/* <TableCell>
                    <PriorityLabel status={renderString(list.leadPriority)} />
                  </TableCell> */}
                  <TableCell style={{ minWidth: "120px" }}>
                    {getDate(list.leadCreatedDate)}
                    <p style={{ margin: "0px" }}>
                      <small
                        style={{
                          color: "gray",
                          fontSize: "9px",
                          wordBreak: "break-all",
                        }}
                      >
                        {renderString(list.cityName)}
                      </small>
                    </p>
                  </TableCell>

                  {/* {status !== "UNASSIGNED" && (
                    <TableCell>{getDate(list.auctionCompletedDate)}</TableCell>
                  )} */}
                  {/* {status !== "UNASSIGNED" && (
                    <TableCell>{getDate(list.holdDate ? list.holdDate : '')}</TableCell>
                  )} */}
                  {status === "SOLD" && (
                    <TableCell>{getDate(list.soldDate)}</TableCell>
                  )}
                  {status === "FOLLOWUP" && (
                    <TableCell>{getDate(list.followupDate)}</TableCell>
                  )}

                  {status === "DROP" && (
                    <TableCell>{getDate(list.dropDate)}</TableCell>
                  )}
                  {/* <TableCell style={{ minWidth: "120px" }}>
                    <div>
                      <p>{renderString(list.regNumber)}</p>
                      <p style={{ margin: "0px" }}>
                        <small
                          style={{
                            color: "gray",
                            fontSize: "9px",
                            wordBreak: "break-all",
                          }}
                        >
                          {renderString(list.mmv)} {renderString(list.mfgYear)}
                        </small>
                      </p>
                    </div> */}
                    {/* {
                      list.leadType.startsWith('TP') ?
                        <span style={{ border: '1px solid #C7FACB', backgroundColor: '#D9FFDC', color: '#4DBD74', padding: '1px 5px', borderRadius: '4px' }}> {list.leadType} </span> :
                        <span style={{ border: '1px solid #FFDAD6', backgroundColor: '#FCE9E7', color: '#FA5C4B', padding: '1px 5px', borderRadius: '4px' }}>{list.leadType}</span>
                    } */}
                  {/* </TableCell> */}
                  <TableCell style={{ minWidth: "140px" }}>
                    <div>
                      <div style={{ display: "flex" }}>
                        <p style={{ minWidth: "120px" }}>
                          <b>{`${renderString(list.buyerName)}`}</b>
                        </p>
                        {(status !== "DROP") && (
                          <img
                            src={EditIcon}
                            style={{
                              marginLeft: ".5rem",
                              marginTop: "5px",
                              cursor: "pointer",
                              height:"20px"
                            }}
                            onClick={(e) =>
                              openEditDialog(
                                e,
                                list.buyerLeadId,
                                list.buyerName,
                                list.buyerEmailId
                              )
                            }
                            height="15px"
                          />
                        )}
                        {<p><img src={WhiteCallIcon} style={{ width: '31px',height:"20px", margin: '5px 0px', cursor: 'pointer' }} onClick={()=>callToCustomer(list)} alt='CallIcon' />  </p>}
                      </div>
                      <small
                        style={{
                          color: "gray",
                          fontSize: "9px",
                          wordBreak: "break-all",
                        }}
                      >{`${renderString(hideEmail(list.buyerEmailId))}`}</small>
                    </div>
                  </TableCell>
                  
                  {/* <TableCell>
                    {renderString(list.cityName)}
                  </TableCell> */}
                  {/* <TableCell>
                    <div className='interested-buyer-count'>
                      2
                      <div>
                          1
                      </div>
                    </div>
                  </TableCell> */}
                   {
                    // <TableCell>{renderString(list.agentName)}</TableCell>
                  }
                  {/* { status === 'UNASSIGNED' &&
                    <TableCell>
                      <p
                        className="link"
                        onClick={() => this.valuatorDetails(list)}
                      >
                        {list.outletName}
                      </p>
                      <p>{list.outletPhoneNumber}</p>
                    </TableCell>} */}
                  {/* {
                    status !== 'UNASSIGNED' &&
                    <TableCell>{getAmount(list.finalQuotePrice)}</TableCell>
                  } */}
                  {/* {status === "SOLD" && (
                    <TableCell>
                      {list.leadStatus === "SELL" ? (
                        <div
                          style={{
                            backgroundColor: "#DAF2DD",
                            color: "#196834",
                            padding: "1px 15px",
                            borderRadius: "4px",
                            width:"80%",
                            textAlign:"center"
                          }}
                        >
                          {" "}
                          TBD{" "}
                        </div>
                      ) : (
                        <div
                          style={{
                            backgroundColor: "#E9F4FF",
                            color: "#366896",
                            padding: "1px 15px",
                            borderRadius: "4px",
                            width:"80%",
                            textAlign:"center"
                          }}
                        >
                          {" "}
                          TBD{" "}
                        </div>
                      )}
                    </TableCell>
                  )} */}
                  {/* <TableCell>TBD</TableCell> */}
                  {/* {status === "FOLLOWUP" && (
                    <TableCell>{renderString(list.reason)}</TableCell>
                  )}
                  {status !== "UNASSIGNED" && (
                    <TableCell>{getAmount(list.bidAmount)}</TableCell>
                  )} */}
                  {/* <TableCell>
                    {getAmount(list.customerExpectedPrice)}
                    {(status !== "SOLD" && status !== "DROP" && list.storeType !== "INTERNAL") && (
                      <img
                        src={EditIcon}
                        style={{
                          marginLeft: "1.5rem",
                          marginTop: "-2rem",
                          cursor: "pointer",
                        }}
                        onClick={(e) =>
                          onOpenEditCEP(
                            e,
                            list.customerExpectedPrice,
                            list.leadId
                          )
                        }
                        height="15px"
                      />
                    )}
                  </TableCell> */}
                  {/* <TableCell>{list.leadSqi}</TableCell> */}
                  {/* {
                    status === 'FOLLOWUP' &&
                    <TableCell>
                      {renderDate(list.followupDate)}
                    </TableCell>
                  } */}
                  {/* {status === "UNASSIGNED" && (
                    <TableCell>
                      {list.leadStatus === "AUCTION_COMPLETED" ? (
                        <span
                          style={{
                            backgroundColor: "#DAF2DD",
                            color: "#196834",
                            padding: "5px",
                            borderRadius: "8px",
                            width: "102px",
                          }}
                        >
                          Auction Completed
                        </span>
                      ) : (
                        <>
                          <span
                            style={{
                              backgroundColor: "#FFE1C5",
                              color: "#B96716",
                              padding: "5px",
                              borderRadius: "8px",
                              width: "102px",
                            }}
                          >
                            {" "}
                            CT Inspected{" "}
                          </span>
                        </>
                      )}
                    </TableCell>
                  )} */}
                  <TableCell>{list.pincode}</TableCell>
                  {/* {
                    status !== 'SOLD' &&
                    <TableCell>{getAmount(list.storeExecutePrice)}
                      {
                        list.leadFranchiseStatus && list.leadFranchiseStatus === 'INTERESTED' && getStoreEditModal() ?
                          <img
                            src={EditIcon}
                            style={{
                              marginLeft: "1.5rem",
                              marginTop: "-2rem",
                              cursor: "pointer",
                            }}
                            onClick={(e) => onOpenEditSP(e, list)}
                            height="15px"
                          />
                          : ''
                      }
                    </TableCell>
                  } */}
                  <TableCell>{getAmount(list.buyerIntrestedPrice)}
                  {!getLockedAndPaymentDone() && <Button style={{
                          marginLeft: "1.5rem",
                          cursor: "pointer",
                          background:"none"
                        }}>
                  <img
                        src={EditIcon}
                        
                        onClick={(e) =>
                          onOpenEditCEP(
                            e,
                            list.buyerIntrestedPrice,
                            list.buyerLeadId
                          )
                        }
                        height="15px"
                      />
                      </Button>}
                      </TableCell>
                  {/* <TableCell>
                       {getAmount(list.totalCommission)} 
                    </TableCell> */}
                  {/* {(status === "FOLLOWUP" || status === "PENDING") && (
                    <TableCell style={{ minWidth: "9rem" }}>
                      {getStatus(list.leadStatus, list.auctionCompletedDate)}
                    </TableCell>
                  )} */}
                  <TableCell>{getStatusColor(list.buyerStatus)}</TableCell>
                  

                  
                {mainTabStatus!=="UNASSIGNED" && <TableCell>
                  <Button
                    color="green"
                    style={{
                      fontSize: 14,
                      fontFamily: "ProximaNovaSemibold",
                    }}
                    onClick={(event) => handleAssignChange(event, list)}
                    disabled={getLockedAndPaymentDone()}
                  >
                    Update
                  </Button>
                </TableCell>}
                  
                  <TableCell
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    
                  <ChatBox qclist={{...list,registrationNum:rowInfo.regNumber}} leadId={list.buyerLeadId} onCRM medium newIcon={NotesIcon}/>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      {/* <div className="table-paginator">
        {Boolean(confirmList.length) ? (
          <Pagination
            variant="outlined"
            style={{ float: "right" }}
            count={Math.ceil(totalCount / 10)}
            showQuickJumper
            className="float-right"
            page={currentPage}
            onChange={(event, pageNumber) =>
              handlePageChange(event, pageNumber)
            }
          />
        ) : (
          <NoResultsFound />
        )}
      </div> */}
      {callPopUp && 
        <CallToCustomer
          open={callPopUp}
          onClose={() => setCallPopUp(false)}
          onCallCustomer={callAction}
          leadData={leadData}
          rowInfo={rowInfo}
        />
      }
      {statusPopUp && forInterestedTab &&
        <UpdateStatusQuote
          open={statusPopUp}
          onClose={() => setStatusPopup(false)}
          leadData={leadData}
          rowInfo={rowInfo}
          tableStatus={status}
          onRefreshRow={onRefreshRow}
        />
      }
      {/* {dropstatusPopUp && forInterestedTab &&
        <UpdateDropStatus
          open={dropstatusPopUp}
          onClose={() => setDropStatusPopup(false)}
          leadData={leadData}
          rowInfo={rowInfo}
          tableStatus={status}
          onRefreshRow={onRefreshRow}
          forInterestedTab={forInterestedTab}
        />
      } */}
      {
        <OTPPopup
          open={otpPopup}
          onClose={() => closePopUp()}
          onVerifyOtp={otpVerify}
          leadData={leadData}
          wrongOtp={wrongOtp}
          onClearWrongOTP={() => setWrongOtp(false)}
        />
      }
      {/* {
          collectModel &&
          <CollectDocModel
            open={collectModel}
            onClose={()=>setCollectModel(false)}
            // rowData={auctionData[0].bidderInfo[bidNumIndex]}
            // callerType="dealer"
            // onRefreshPage={onRefreshPage}
          /> */}
      {/* {
        status === 'PENDING' &&
        <PendingDrawer
          drawer={Boolean(confirmList.length) ? drawerClass : 'table-shd-drawer'}
          open={callToCustomer}
          confirmList={confirmList}
          rowInfo={rowInfo}
          openForm={updateStatusForm}
        />
      } */}
      {/* {
        status === 'FOLLOWUP' &&
        <FollowupDrawer
          drawer={Boolean(confirmList.length) ? drawerClass : 'table-shd-drawer'}
          open={callToCustomer}
          confirmList={confirmList}
          rowInfo={rowInfo}
          historyData={historyData}
          handleDropStatus={handleDropStatus}
          openForm={updateStatusForm}
        />
      } */}
      {/* {
        status === 'DROP' &&
        <DropDrawer
          drawer={Boolean(confirmList.length) ? drawerClass : 'table-shd-drawer'}
          open={callToCustomer}
          confirmList={confirmList}
          rowInfo={rowInfo}
          historyData={historyData}
          handleDropStatus={handleDropStatus}
          status={status}
        />
      } */}
      {/* {
        status === 'SOLD' &&
        <SoldStatusDrawer
          drawer={Boolean(confirmList.length) ? drawerClass : 'table-shd-drawer'}
          open={callToCustomer}
          confirmList={confirmList}
          rowInfo={rowInfo}
          historyData={historyData}
          handleDropStatus={handleDropStatus}
        />
      } */}
      {openDialog && (
        <EditDialog
          open={openDialog}
          onClose={closeDialog}
          leadId={leadId}
          name={username}
          email={email}
          refreshPage={refreshPage}
          onCrmLeadId
        />
      )}

      {/* <StatusSlider
        openState={openSlider}
        close={closeSlider}
        selectedLeadId={selectedID}
        rowInfo={rowInfo}
        status={status}
        leadData={leadData}
        confirmList={confirmList}
        onRefreshPage={handleRefresh}
        onClosePushMenu={closeAndRefresh}
        openCEDialog={onOpenEditCEP}
        LeadInfo={LeadInfo}
        LeadLifeInfo={LeadLifeInfo}
        orderDedctions={orderDedctions}
        customerDocuments={customerDocuments}
        value={value}
        handleValue={handleValue}
        enable={enable}
        handleEnable={handleEnable}
        Cp={
          orderDedctions &&
          orderDedctions.soldTo &&
          orderDedctions.soldTo === "CP"
        }
        Store={
          orderDedctions &&
          orderDedctions.soldTo &&
          orderDedctions.soldTo !== "CP"
        }
        leadStatus={
          LeadInfo &&
          LeadInfo.vehicleDetails &&
          LeadInfo.vehicleDetails.leadStatus &&
          LeadInfo.vehicleDetails.leadStatus
        }
        onReassignUser={reAssignTo}
        goToDropTab={onRefreshDrop}
        goToFollowUpTab={onRefreshFollowUp}
        goToSoldTab={onRefreshSold}
        handleRowChange={(val) => {
          handleRowChange(val);
          handleValue("Commission");
        }} 
      // setCollectModel ={setCollectModel}
      // setExceptionModel ={setExceptionModel}
      // openCollectModel ={openCollectModel}
      // openExceptionModel = {openExceptionModel}
      />*/}
      {editPrice && (
        <CustomerEPEditDialog
          editPrice={editPrice}
          onClose={onCloseEditCEP}
          leadId={leadId}
          priceVal={price}
          onRefreshPage={refreshPage}
          onCrmLeadId
        />
      )}
      {openEdit && (
        <EditPriceModal
          editPrice={openEdit}
          onClose={onCloseEditSP}
          leadId={leadId}
          priceVal={price}
          onRefreshPage={refreshPage}
          list={list}
          onAcceptOffer={acceptOfferStore}
        />
      )}
       
        {dropstatusPopUp && forInterestedTab && (
        <UpdateDropStatus
          open={dropstatusPopUp}
          onClose={() => setDropStatusPopup(false)}
          leadData={list}
          rowInfo={rowInfo}
          forInterestedTab={UpdateDropStatus}
          setOpenTranferDialog={setOpenTranferDialog}
          statusList={statusList}
          // tableStatus={status}
          // onRefreshRow={onRefreshRow}
        />)}
      
    </div>
  );
};

export default connect()(CInterestedTable);
