import { SET_LOADER } from "../../core/constants/actions";
export const SHOW_CALLING_STATUS = 'SHOW_CALLING_STATUS';
export const HIDE_CALLING_STATUS = 'HIDE_CALLING_STATUS';

export function showCallingStatus(customerName, leadId) {
  return {
    type: SHOW_CALLING_STATUS,
    payload: {customerName, leadId}
  }
}

export function hideCallingStatus() {
  return {
    type: HIDE_CALLING_STATUS
  }
}

// export function setLoader(show) {
//   return {
//     type: SET_LOADER,
//     payload: { show }
//   }
// }