import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Modal,
  Grid,
  Button,
  SelectPicker,
  Dropdown,
  ErrorMessage,
} from "rsuite";
import {
  callToCustomer,
  rebid,
  shdRebid,
} from "../../../core/services/biddingServices";
import { hideLoader, showLoader } from "../../../redux/actions/loaderAction";
import { setNotification } from "../../../redux/actions/notificationAction";
import InputLabel from "@material-ui/core/InputLabel";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import { getRebidReasones } from "../../../core/services/miscServices";
import {
  CallIcon,
  CallIconGreen,
  ThumbUpIcon,
  ThumbDownIcon,
} from "../../../core/utility/iconHelper";
import "./HoldReleasePopUp.scss";
import { getComaSeparated } from "../../../core/utility";
import { Input } from "@material-ui/core";
import {
  callToCareCustomer,
  releaseHoldAmount,
} from "../../../core/services/shdServices";
import { getMobile, getUserID } from "../../../core/services/rbacServices";
import { callingPtAgent } from "../../../core/services/paperTransferService";

export default function HoldReleaseModal({
  onClose,
  open,
  orderDedctions,
  rowInfo,goToSoldTab
}) {
  const [isLimitError, setIsLimitError] = useState(false);
  const [errorMessages, setErrorMessage] = useState("");
  const [releaseAmount, setReleaseAmount] = useState("");
  const [holdDeduction, setHoldDeduction] = useState("");
  const [newFinalAmount, setNewFinalAmount] = useState("");
  const [holdReason, setHoldReason] = useState("");
  const [releaseType, setReleaseType] = useState("");

  const dispatch = useDispatch();

  const handleChange = (e, subReasons) => {
    //
  };

  const handleLimit = (e) => {
    setIsLimitError(false);
    // setRebidValue((e.target.value))
    // if (Number(e.target.value) < bidderDetails.minAmount) {
    setIsLimitError(true);
    // }
  };

  const handleChangeHold = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setIsLimitError(false);
      setReleaseAmount(e.target.value);
      setHoldDeduction(orderDedctions.holdAmount - e.target.value);
      setNewFinalAmount(
        Number(orderDedctions.invPaymentAmount) -
          Number(orderDedctions.holdAmount) +
          Number(e.target.value)
      );
      if (e.target.value > orderDedctions.holdAmount && releaseType==="FULL") {
        setIsLimitError(true);
        setErrorMessage("Release Amount Cannot Be More Than Hold Amount");
      }
      if((e.target.value >= orderDedctions.holdAmount && releaseType==="PARTIAL")){
        setIsLimitError(true);
        setErrorMessage("Hold After Deduction should be > 0");
      }
    }
  };

  const handleChangeReasone = (e) => {
    setIsLimitError(false);
    const regex = /^([a-zA-Z0-9 -]+)$/;
    if (e.target.value.length < 255) {
      if (e.target.value === "" || regex.test(e.target.value))
        setHoldReason(e.target.value);
    } else {
      setIsLimitError(true);
      setErrorMessage("Reasone should be less than 255 character");
    }
  };

  const handleReleaseAmount = () => {
    if(releaseType===""){
      setIsLimitError(true);
      setErrorMessage("Please select Release Type");
    }
    else if (releaseAmount === "") {
      setIsLimitError(true);
      setErrorMessage("Please Input Release Amount");
    } else if (holdReason.length < 5) {
      setIsLimitError(true);
      setErrorMessage("Please Provide Proper Reason");
    } else {
      const payload = {
        leadId: rowInfo.leadId,
        type: orderDedctions.soldTo,
        releaseAmount: releaseAmount,
        holdDeductions: holdDeduction,
        newFinalAmount: newFinalAmount,
        holdReleaseBy: getUserID(),
        holdComments: holdReason,
        releaseType:releaseType
      };
      dispatch(showLoader());
      releaseHoldAmount(payload).then((apiResponse) => {
        if (apiResponse.isValid) {
          dispatch(hideLoader());
          dispatch(setNotification("success", "SUCCESS", apiResponse.message));
          onClose();
          goToSoldTab()
        } else {
          dispatch(hideLoader());
          dispatch(setNotification("danger", "Error", apiResponse.message));
          onClose();
          goToSoldTab()
        }
      });
    }
  };

  const onChangeReleaseType=(e)=>{
      setReleaseType(e.target.value);
      setErrorMessage("")
      setIsLimitError(false);
      setReleaseAmount("")

  }

  return (
    <div>
      {/* {(rebidReasonList && rebidReasonList.length > 0) ? ( */}
      <Modal
        // onHide={onClose}
        show={open}
        size="sm"
        className="diyWrap"
        style={{
          zIndex: "1000",
          // height: "500px",
          width: "450px",
          margin: "auto auto",
          marginTop: "10%",
        }}
      >
        <Modal.Header
          closeButton
          onHide={onClose}
          // style={{ textAlign: "center" }}
        >
          <Modal.Title>
            <p
              style={{
                fontSize: "16px",
                fontFamily: "ProximaNovaSemibold",
                color: "#196834",
                //   textAlign: "center",
              }}
            >{`Hold Release`}</p>{" "}
          </Modal.Title>
          {/* <hr /> */}
        </Modal.Header>
        <Modal.Body>
          <br />

          <div className="inputRowRazor holdAmount">
            <label>Final Amount</label>
            <div>
              <span>₹</span>
              <input
                type="text"
                className="inputDisburse"
                placeholder="Enter Amount"
                value={getComaSeparated(
                  orderDedctions.invPaymentAmount
                    ? orderDedctions.invPaymentAmount
                    : 0
                )}
                disabled
              />
            </div>
          </div>

          <div className="inputRowRazor holdAmount">
            <label>Already paid</label>
            <div>
              <span>₹</span>
              <input
                type="text"
                className="inputDisburse"
                placeholder="Enter Amount"
                value={getComaSeparated(
                  orderDedctions.alreadypaid ? orderDedctions.alreadypaid : 0
                )}
                disabled
              />
            </div>
          </div>

          <div className="inputRowRazor holdAmount">
            <label>Hold Amount</label>
            <div>
              <span>₹</span>
              <input
                type="text"
                className="inputDisburse"
                placeholder="Enter Amount"
                value={getComaSeparated(
                  orderDedctions.holdAmount ? orderDedctions.holdAmount : 0
                )}
                disabled
              />
            </div>
          </div>
          <div className="inputRowRazor" style={{ border: "none" }}>
            <label>Release Type</label>
            <div className="release-type">
              
              <Select
                value={releaseType}
                displayEmpty
                onChange={onChangeReleaseType}
                label="Release Type"
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem style={{ fontSize: "9px" }} value="" disabled>
                  Release Type
                </MenuItem>
                <MenuItem value="PARTIAL">Partial</MenuItem>
                <MenuItem value="FULL">Full</MenuItem>
              </Select>
            </div>
          </div>
          {releaseType && <div className="inputRowRazor">
            <label>Release Amount</label>
            <div>
              <span>₹</span>
              <input
                type="text"
                className="inputDisburse"
                placeholder="Enter Amount"
                value={releaseAmount}
                onChange={(e) => handleChangeHold(e)}
              />
            </div>
          </div>}

         
          {releaseType && <div className="inputRowRazor holdAmount">
            <label>Hold After Deduction</label>
            <div>
              <span>₹</span>
              <input
                type="text"
                className="inputDisburse"
                placeholder="Enter Amount"
                value={getComaSeparated(
                  holdDeduction !== ""
                    ? holdDeduction
                    : orderDedctions.holdAmount
                )}
                disabled
              />
            </div>
          </div>}

          {releaseType==="FULL" && <div className="inputRowRazor totalAmount">
            <label>New Final Amount</label>
            <div>
              <span>₹</span>
              <input
                type="text"
                className="inputDisburse"
                placeholder="New Amount"
                value={getComaSeparated(
                  releaseAmount !== "" ? newFinalAmount : ""
                )}
                disabled
              />
            </div>
          </div>}

          {releaseType && <div className="inputRowRazor" style={{ alignItems: "flex-start" }}>
            <label>Reason For Hold Deduction</label>
            <textarea
              type="textarea"
              className="inputDisburse"
              // placeholder="Enter Amount"
              value={holdReason}
              rows="3"
              onChange={(e) => handleChangeReasone(e)}
              style={{ width: "350px", fontSize: "12px" }}
            />
          </div>}

          {isLimitError && (
            <p
              style={{
                color: "#DA2128",
                fontSize: "12px",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              {errorMessages}
            </p>
          )}
          {/* {isPartialLimitError && (
            <p
              style={{
                color: "#DA2128",
                fontSize: "12px",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              "Partial Release Amount Cannot Be Equal or More Than Hold Amount"

            </p>
          )} */}
        </Modal.Body>
        <Modal.Footer style={{ textAlign: "center" }}>
          <button
            onClick={() => handleReleaseAmount()}
            disabled={isLimitError ? true : false}
            style={{
              marginRight: 15,
              color: "#ffffff",
              padding: "8px 20px",
              borderRadius: "22px",
              backgroundColor: "#4DBD74",
              border: "1px solid #35AC5E",
            }}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
      {/* ):} */}
    </div>
  );
}
