import { SHD_CUSTOMER_DOCUMENNTS } from '../../core/constants/actions'
import { getNewState } from '../../core/utility'

const intialState = {
    leadDetails: {}
  }

export default function shdCustomerDocumentsReducer(state = intialState, action) {

    switch (action.type) {
        case SHD_CUSTOMER_DOCUMENNTS.REQUEST:
          return getNewState(state, { isLoading: true })
    
        case SHD_CUSTOMER_DOCUMENNTS.SUCCESS:
          const { customerDocumentsResponse: customerDetails } = action.payload
          return getNewState(state, { customerDetails, isLoading: false })
    
        case SHD_CUSTOMER_DOCUMENNTS.FAILURE:
          const { error } = action.payload
          return getNewState(state, { error,customerDetails:[], ...intialState })
    
        case SHD_CUSTOMER_DOCUMENNTS.RESET:
          return intialState
    
        default:
          return state
      }

}