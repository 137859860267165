import React,{useState,useEffect} from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import { CloseIconBlack } from "../../../../core/utility/iconHelper";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,FormControlLabel, Radio, RadioGroup
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EachFilter from "./EachFilter";
import { getCpList } from "../../../../core/services/biddingServices";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import "./index.scss"
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { getUserCityList } from "../../../../core/services/userInfoStorageServices";
import { getCityID } from "../../../../core/services/rbacServices";




export default function DeepFilters({ advancedFilter, handleAdvancedFilter,onSelect,logisticsStatus,setLogisticsStatus,
  docQcStatus,setDocQcStatus,
  customerPayoutStatus,setCustomerPayoutStatus,
  cpPaymentStatus,setCpPaymentStatus,
  inCustodyStatus,setInCustodyStatus,
  cpVsStore,setCpVsStore,
  dateTypeFilter,setDateTypeFilter,
  cpDrop,setCpDrop,
  logisticsStatusDrop,setLogisticsStatusDrop,
  docQcStatusDrop,setDocQcStatusDrop,
  customerPayoutStatusDrop,setCustomerPayoutStatusDrop,
  cpPaymentStatusDrop,setCpPaymentStatusDrop,
  inCustodyStatusDrop,setInCustodyStatusDrop,
  cpVsStoreDrop,setCpVsStoreDrop,
  dateTypeFilterDrop,setDateTypeFilterDrop,
  cpList, setCpList,
  selectedOption, setSelectedOption,
  startDate, setStartDate,
  endDate, setEndDate,onApplyFilters
 }) {
  
  const [clearFilterCount,setSetClearFilterCount]=useState(0)

  const drawerWidth = 400;
  const theme = useTheme();

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex"
    },
    appBar: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: drawerWidth,
    },
    title: {
      flexGrow: 1,
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      borderRadius: "18px 0px 0px 18px",
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "space-between",
      backgroundColor: "#F0F7FE",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginRight: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    },
    mainHeading: {
      fontFamily: "ProximaNovaRegular",
      fontWeight: "600",
      fontSize: "16px",
      color: "#333333",
      marginLeft:"10px"
    },
    typography:{
      fontFamily:"ProximaNovaRegular",
      fontWeight: "500",fontSize: "16px",color: "#6D6D6D",width:"100%"
    },
    radioGroup:{display: 'flex',flexDirection:"row-reverse",justifyContent: 'space-between',alignItems: 'center', width: '338px',margin:"0px"
    },
    dropdown: {
      backgroundColor:"#F5F6F6" 
    },
  }));

  const classes = useStyles();
  

  useEffect(() => {
    const singleCity=getCityID()
    const userCity=getUserCityList().trim('"').split(",")

    if(!userCity.includes(singleCity)){
        userCity.push(singleCity)
    }
    const finalCityList=userCity.join(",")

    getCpList(`${finalCityList}`).then((apiResponse) => {
      if (apiResponse.isValid) {
        setCpList(apiResponse.shdDetails);

      } else {
        // dispatch(setNotification("danger", "Error", apiResponse.message));
      }
    });
  }, []);

  useEffect(()=>{
    onApplyFilters()
  },[clearFilterCount])

  // useEffect(() => {
  //   if (advancedFilter) {
  //     document.body.style.setProperty("fontFamily", "inherit !important");
  //     document.body.style.setProperty("letterSpacing", "inherit !important");
  //   } else {
  //     document.body.style.fontFamily = "inherit";
  //     document.body.style.letterSpacing = "inherit";
  //   }
  // }, [advancedFilter]);

  const handleDrawerClose = () => {
    handleAdvancedFilter(false);
  };


  const cpListData = cpList.map((item) => ({
    storeId: item.storeId,
    label: item.shdName,
    storeName: item.storeName,
    value: item.storeId,
    userId: item.userId,
    cityName:item.cityName
  }));


const renderMenuItemNew=(item,selected ) => {
  const {inputValue}=selected
  const {storeName,cityName,storeId,label,value}=item
  return(
    <div
        style={{
          height: "45px",
          lineHeight: "15px",width:"100%",padding:"5px"
        }}
        key={storeId}
      >
        <div style={{display:"flex",width:"100%",justifyContent:"space-between"}}>
          <p style={{ fontWeight: "bold", margin: "0px", fontSize: "16px",color:"#333333"}}>
            {label}
          </p>
          <Radio
          checked={inputValue===(label + ". Store Id-"+ value)?true:false}
          color="primary"
          // onChange={() => handleOptionChange(item)}
          style={{marginTop:"-8px",fontSize: "16px",paddingBottom:"0px"}}
        />
         </div>
          <div style={{display:"flex",color:"#8C8C8C",fontFamily:"proximaNovaRegular",alignItems:"center"}}>
              <p style={{ margin: "0px", fontSize: "14px" ,fontWeight:"500"}}>{storeName}</p>
              <p style={{ margin: "0px", fontSize: "14px",minWidth:"30px" ,fontWeight:"500",textAlign:"center"}}><FiberManualRecordIcon style={{height:"7px",color:"#8C8C8C"}}/></p>
              <p style={{ margin: "0px", fontSize: "14px" ,fontWeight:"500"}}>{cityName}</p>
              <p style={{ margin: "0px", fontSize: "14px",minWidth:"30px" ,fontWeight:"500",textAlign:"center"}}><FiberManualRecordIcon style={{height:"7px",color:"#8C8C8C"}}/></p>
              <p style={{ margin: "0px", fontSize: "14px",textAlign:"left",fontWeight:"500"  }}>
                Store ID: {storeId}
              </p>
          </div>
      </div>

  )}

  const handleOptionChange = (event, newValue) => {
    setSelectedOption(newValue);
    if (onSelect) {
      onSelect(newValue);
    }
  };


  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const customFilter = (options, state) => {
    const searchInput = state.inputValue.toLowerCase();
    
    return options.filter(option =>
      (option.storeName && option.storeName.toLowerCase().includes(searchInput)) || (option.label && option.label.toLowerCase().includes(searchInput)) || (option.storeId && option.storeId.toLowerCase().includes(searchInput)))
    
  };

  const onClickClear=()=>{
    setSelectedOption(null);
    setLogisticsStatus(null);
    setDocQcStatus(null);
    setCustomerPayoutStatus(null);
    setCpPaymentStatus(null);
    setInCustodyStatus(null);
    setCpVsStore(null);
    setDateTypeFilter(null);
    setLogisticsStatusDrop(null);
    setDocQcStatusDrop(null);
    setCustomerPayoutStatusDrop(null);
    setCpPaymentStatusDrop(null);
    setInCustodyStatusDrop(null);
    setCpVsStoreDrop(null);
    setDateTypeFilterDrop(null);
    setSetClearFilterCount(clearFilterCount+1)
    

  }

  const logisticsStatusValues=[
    { label: 'Unassigned', value: 'UNASSIGNED' },
    { label: 'Assigned', value: 'ASSIGNED' },
    { label: 'Pending', value: 'PENDING' },
    { label: 'Intransit', value: 'INTRANSIT' },
    { label: 'Delivered', value: 'DELIVERED' },
    { label: 'Dispute', value: 'DISPUTE' },
    { label: 'Rejected', value: 'REJECTED' },
    { label: 'Delivered Confirm Pending', value: 'DELIVERED_CONFIRM_PENDING' }
  ]
  
  const docQcValues=[
    { label: 'Pending', value: 'PENDING' },
    { label: 'Conditional Approved', value: 'COND_APPROVED' },
    { label: 'Approved', value: 'APPROVED' },
    { label: 'Dispute', value: 'DISPUTE' },
    { label: 'Rejected', value: 'REJECTED' }
  ]
  
  const customerPayoutStatusValues=[
    { label: 'Pending', value: 'PENDING' },
    { label: 'Partial', value: 'PARTIAL' },
    { label: 'Completed', value: 'COMPLETED' }
  ]
  
  const cpPaymentStatusValues=[
    { label: 'Pending', value: 'PENDING' },
    { label: 'Completed', value: 'COMPLETED' },
    // { label: 'Approval Pending', value: 'APPROVAL_PENDING' },
    // { label: 'Requested Pre approval', value: 'REQUESTED_PREAPPOVAL' },
    // { label: 'Pre approved', value: 'PRE_APPROVED' }
  ]
  const inCustodyStatusValues=[{ label: 'Pending', value: 'PENDING' },{ label: 'Completed', value: 'COMPLETED' }]
  const cpVsStoreValues=[{label:"Store",value:"STORE"},{label:"CP",value:"CP"}]
  

 

  return (
    <div>
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={advancedFilter}
        classes={{
          paper: classes.drawerPaper,
        }}
        disableScrollLock={true}
        inputProps={{MenuProps: {disableScrollLock: true}}}
      >
        <div className={classes.drawerHeader}>
          <h1 className={classes.mainHeading}>Additional Filters</h1>
          <IconButton style={{marginRight:"10px"}} onClick={handleDrawerClose}>
            <img height={14} src={CloseIconBlack} />
          </IconButton>
        </div>
        <Divider />

        <List className="bg-container-advanced-filter">

              
              <Typography style={{color:"#317A9A",fontSize:"12px",color:"#979797",marginLeft:"3px",fontFamily:"proximaNovaRegular"}}>{selectedOption?"CP Name":""}</Typography>
              <Accordion className="advanced-dropdown"  style={{width:"100%",backgroundColor:cpDrop || selectedOption?"#F5F6F6":"",borderRadius:"10px"}} expanded={cpDrop}  onClick={()=>{setCpDrop(!cpDrop)}}>
                <AccordionSummary expandIcon={!(selectedOption && selectedOption.label) || cpDrop?<ExpandMoreIcon style={{width:"36px"}}/>:null} style={{width:"348px",height:"48px"}} >
                  <Typography  className={classes.typography}>
                    <div style={{ display: 'flex', alignItems: 'center',width:"100%",justifyContent:"space-between" }}>
                      <Typography style={{color:selectedOption && selectedOption.label?"#317A9A":"",maxWidth:"220px",whiteSpace:"nowrap",overflow:"hidden",textOverflow:"ellipsis"}}>{selectedOption && selectedOption.label?selectedOption.label:"CP Name List"}</Typography>
                      <div style={{display:"flex",marginRight:"-5px"}}>
                      {selectedOption && selectedOption.label && 
                      <>
                      <IconButton  aria-label="Delete" onClick={(event)=>{event.stopPropagation();setSelectedOption(null);setCpDrop(false)}}>
                        <DeleteOutlineIcon />
                      </IconButton>
                      {!cpDrop  && <IconButton  aria-label="Delete" onClick={(event)=>{event.stopPropagation();setCpDrop(!cpDrop)}}>
                        <EditIcon style={{ color: '#2076DC' }}/>
                      </IconButton>}
                      </>
                      }
                      </div>
                    </div> 
                  </Typography>
                </AccordionSummary>
              </Accordion>

              

             <EachFilter optionList={logisticsStatusValues} value={logisticsStatus} valueDrop={logisticsStatusDrop} setValue={setLogisticsStatus} setValueDrop={setLogisticsStatusDrop} label={"Logistics Status"} />
             <EachFilter optionList={docQcValues} value={docQcStatus} valueDrop={docQcStatusDrop} setValue={setDocQcStatus} setValueDrop={setDocQcStatusDrop} label={"Doc QC"}/>
             <EachFilter optionList={customerPayoutStatusValues} value={customerPayoutStatus} valueDrop={customerPayoutStatusDrop} setValue={setCustomerPayoutStatus} setValueDrop={setCustomerPayoutStatusDrop} label={"Customer Payout Status"}/>
             <EachFilter optionList={cpPaymentStatusValues} value={cpPaymentStatus} valueDrop={cpPaymentStatusDrop} setValue={setCpPaymentStatus} setValueDrop={setCpPaymentStatusDrop} label={"CP Payment Status"}/>
             <EachFilter optionList={inCustodyStatusValues} value={inCustodyStatus} valueDrop={inCustodyStatusDrop} setValue={setInCustodyStatus} setValueDrop={setInCustodyStatusDrop} label={"In-Custody status"}/>
             <EachFilter optionList={cpVsStoreValues} value={cpVsStore} valueDrop={cpVsStoreDrop} setValue={setCpVsStore} setValueDrop={setCpVsStoreDrop} label={"CP vs Store sell Status"}/>
             {/* <EachFilter optionList={["Lead Created Date","DocQc Approval Date","Payment Initiated Date","Payment Completed Date","Cp Payment Date","In Custody Completed Date","Lead Sold Date"]} value={dateTypeFilter} valueDrop={dateTypeFilterDrop} setValue={setDateTypeFilter} setValueDrop={setDateTypeFilterDrop} label={"Date Type"}/> */}
        </List>
       {/* {dateTypeFilter && <DateRangeSelector startDate={startDate} handleStartDateChange={handleStartDateChange} endDate={endDate} handleEndDateChange={handleEndDateChange} dateTypeFilter={dateTypeFilter}/>} */}
       
        <div className="filter-container in-advanced">
          <button
            className="font-regular"
            style={{
              marginRight: 15,
              color: "#ffffff",
              padding: "8px 20px",
              borderRadius: "22px",
              backgroundColor: "#4DBD74",
              border: "1px solid #35AC5E",
            }}
            onClick={onApplyFilters}
          >
            Apply
          </button>
          <button
            className="font-regular"
            style={{
              border: "#DEDEDE",
              backgroundColor: "1px solid #E8E8E8",
              color: "#333333",
              padding: "8px 20px",
              borderRadius: "22px",
            }} onClick={onClickClear}
          >
            Clear
          </button>
        </div>
        </Drawer>
    </div>
      <Dialog open={cpDrop} onClose={()=>{setCpDrop(false)}}  fullWidth disableScrollLock={true}
        maxWidth='sm' className="simple-model">
          <div style={{display:"flex",justifyContent:"space-between",width:"100%"}}>
        <DialogTitle>CP Name List</DialogTitle>
        <IconButton aria-label="close" onClick={()=>{setCpDrop(false)}}>
          <CloseIcon />
        </IconButton>
        </div>
        <DialogContent >
            <Autocomplete 
              options={cpListData}
              open={cpDrop}
              getOptionLabel={(option) => (option.label + ". Store Id-"+ option.value)}
              value={selectedOption}
              onChange={handleOptionChange}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
              renderOption={renderMenuItemNew}
              filterOptions={customFilter}
              classes={{
                paper: classes.dropdown,
              }}
             />
        </DialogContent>
      </Dialog>
    </div>
  );
}
