import React from "react"
import "./index.scss"

const PriorityLabel=(props)=>{
    const {status}=props
    const randomStatus=["hot","warm","cold"][Math.floor(Math.random()*3)]
    return(
        <p className={`priority-tag ${status}`}>{status}</p>
    )
}

export default PriorityLabel