import { C2C } from '../../core/constants/actions'
import { getNewState } from '../../core/utility'

const intialState = {
  c2cList: [],
  count: 0,
  isLoading: false,
  pageNum: 1,
}

export default function c2cReducer(state = intialState, action) {

  switch (action.type) {
    case C2C.REQUEST:
      return getNewState(state, { isLoading: true })

    case C2C.SUCCESS:
      const { c2cList, count, pageNum } = action.payload;
      return getNewState(state, { c2cList, count, isLoading: false, pageNum })

    case C2C.FAILURE:
      const { error } = action.payload
      return getNewState(state, { error, ...intialState })

    case C2C.RESET:
      return intialState

    default:
      return state
  }
}