import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { ChatIcon } from '../../../core/utility/iconHelper';
import { submitComments } from '../../../core/services/miscServices'
import { getComments } from '../../../core/services/miscServices'
import { getTwoLetterS } from "../../../core/utility/stringUtility"
import { removeUnderscore } from "../../../core/utility/stringUtility"
import Comment from "../Comment"

import { getRole, getUserID, getUserName } from '../../../core/services/rbacServices'
import "./index.scss"

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const Avatar = (name) => {
    const letter = name.charAt(0).toUpperCase();
    const order = letter.charCodeAt(0);
    let className;
    const lightColors = {
        0: 'avatar--light-0',
        1: 'avatar--light-1',
        2: 'avatar--light-2',
        3: 'avatar--light-3',
        4: 'avatar--light-4',
        5: 'avatar--light-5',
        6: 'avatar--light-6',
        7: 'avatar--light-unknown',

    };
    if (name !== "Anonymous") 
    className = lightColors[(order - 65) % 7];
    else className = lightColors[7];
    return className
}


export default function FullScreenDialog({ qclist, leadId, onCRM, chatCount, extend,medium,newIcon }) {

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [comment, setComment] = React.useState('');
    const [commentList, setCommentList] = React.useState([])
    const [LengthError, setLengthError] = React.useState(false)

    let payload = {
        leadId: leadId ? leadId : qclist.leadID
    }
    let crmPayload = {
        crmLeadId: leadId ? leadId : qclist.leadID
    }

    const handleClickOpen = () => {
        getComments(onCRM ? crmPayload : payload).
            then(apiResponse => {
                if (apiResponse.isValid) {
                    setCommentList(apiResponse.data)
                }
                setOpen(true);
            })
    }

    const handleClose = () => {
        setOpen(false);
    };

    function handleInputChange(event) {
        setComment(event.target.value);
        if (event.target.value.length >= 15) {
            setLengthError(false)
        }
    }

    const postComments = () => {
        if (comment.length < 15) {
            setLengthError(true)
        }
        else {
            const payload = {
                leadId: leadId ? leadId : qclist.leadID,
                userId: getUserID(),
                comments: comment
            }
            let crmPayload = {
                crmLeadId: leadId ? leadId : qclist.leadID,
                userId: getUserID(),
                comments: comment
            }
            submitComments(onCRM ? crmPayload : payload).
                then(apiResponse => {
                    if (apiResponse.valid) {
                        getComments(onCRM ? crmPayload : payload).
                            then(apiResponse => {
                                if (apiResponse.isValid) {
                                    setCommentList(apiResponse.data)
                                }
                            })
                    }
                })
            setComment("")
        }
    }

    const getVehicleNumber = () => (
        qclist.bikeRegistrationNumber ? qclist.bikeRegistrationNumber : qclist.registrationNum ? qclist.registrationNum : qclist.regNumber ? qclist.regNumber : qclist.registrationNumber
    )

    return (
        <div >
            <button className={`chat-button${extend ? " extend" : ""}`} onClick={handleClickOpen}>
                <div className="chat-image-container">
                    <img className="chat-image" src={newIcon ?newIcon:ChatIcon} alt='chat' />
                    {Boolean(qclist && qclist.chatCount && qclist.chatCount != "0" && qclist.chatCount != 0) && <div className="bubble">{qclist && qclist.chatCount}</div>}
                    {Boolean(qclist && !qclist.chatCount && chatCount && chatCount !== "0" && qclist.chatCount != 0) && <div className="bubble">{chatCount}</div>}
                </div>
            </button>
            {open &&
                <Dialog BackdropProps={{ invisible: true }} className="bg-container-chat" maxWidth={medium?"lg":""} fullScreen={!medium} fullWidth={medium} open={open} onClose={handleClose} TransitionComponent={Transition}>
                    <Toolbar className='close-button-container'>
                        <p className='vehicle-number'>Comment for  Vehicle no: {getVehicleNumber()}</p>
                        <IconButton className='close-button' edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                    <div>
                        <div id="comment-box-container">
                            <div className={`${Avatar(`${getUserName()}`)} avatar`}>{getTwoLetterS(getUserName())}</div>
                            <div className={`comment-box-and-post `}>
                                <div>
                                    <h4 className='comment-owner'>{getUserName()}</h4>
                                    <p className="comment-type">{removeUnderscore(getRole())}</p>
                                </div>
                                <textarea maxLength="500" value={comment} id="comment-box" placeholder="Add a comment..." onChange={handleInputChange}></textarea>
                                {LengthError && <p className="error-comment">Minimum 15 character required</p>}
                                <button type="button" id="post-button" onClick={postComments}>Post</button>
                            </div>
                        </div>
                        {commentList.map((item, index) => (<Comment key={index} data={item} />))}
                    </div>
                </Dialog>
            }
        </div>
    );
}
