import { RUNNER_ACTIVE_STATUS } from '../../core/constants/actions'
import { getNewState } from '../../core/utility'

const intialState = {
  isLoading: false,
}

export default function runnerActiveStatusReducer(state = intialState, action) {

  switch (action.type) {
    case RUNNER_ACTIVE_STATUS.REQUEST:
      return getNewState(state, { isLoading: true })

    case RUNNER_ACTIVE_STATUS.SUCCESS:
      const { activeRunners } = action.payload;
      console.log("In reducer", activeRunners)
      return getNewState(state, { activeRunners})

    case RUNNER_ACTIVE_STATUS.FAILURE:
      const { error } = action.payload
      return getNewState(state, { error, ...intialState })

    case RUNNER_ACTIVE_STATUS.RESET:
      return intialState

    default:
      return state
  }
}