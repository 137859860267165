import {

	Dialog,
	DialogContent,
	DialogTitle,

} from "@material-ui/core";
import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import 'react-picky/dist/picky.css'
import { useDispatch } from "react-redux";
import { AlertIconRed, InfoOutlinedIconBank } from "../../../../core/utility/iconHelper";
import './refundToWallet.scss';
import { CommentOutlined } from "@material-ui/icons";
import { getAmount, getComaSeparated, getTitleCase } from "../../../../core/utility/stringUtility";
// import { getUserID } from "../../../../core/services/authenticationServices";
import { generateWalletOTP } from "../../../../core/services/shdServices";
import { setNotification } from "../../../../redux/actions/notificationAction";
import { hideLoader, showLoader } from "../../../../redux/actions/loaderAction";
import { getUserID } from "../../../../core/services/rbacServices";
import { getUserActionInfo } from "../../../../core/services/tokenStorageServices";

const useStyles = makeStyles((theme) => ({

}));

export default function ModelComponent({
	open,
	onClose,
	leadData,
	walletBalance,
	openOTPPopPup,
	paymentData,LeadLifeInfo
}) {
	const dispatch = useDispatch();

	const [refundAmount, setRefundAmount] = useState('')
	const [comments, setComments] = useState("")
	const [isAmountError, setIsAmountError] = useState(false)
	const [amountErrorMessage, setAmountErrorMessage] = useState('')
	const {channelPartnerResponseBean}=LeadLifeInfo






	const userID = getUserID()

	const payload = {
		orderId: paymentData.orderId,
		refundAmount: paymentData.shdClearanceStatus === "PENDING" ? paymentData.shdClearancePrice : refundAmount,
		walletId: paymentData.walletId,
		registrationNumber: paymentData.registrationNumber,
		loginUserId: userID,
		comments: comments,
		otp: '',
		otpPrimaryKey: '',
		shdPrimaryKey:paymentData.shdPrimaryKey
	}



	const handleLimit = (e) => {
		setIsAmountError(false)
		setRefundAmount(e.target.value)
		if (e.target.value > (paymentData.amountAfterRefund)) {
			setIsAmountError(true)
			setAmountErrorMessage(`Refund amount cannot be more than ${getAmount(paymentData.amountAfterRefund)}`)
		}
		if (e.target.value && e.target.value < 1) {
			setIsAmountError(true)
			setAmountErrorMessage("Refund amount cannot be less than one")
		}
	}

	const handleComments = (e) => {
		setComments(e.target.value)
	}

	const handleGenerateOTP = () => {
		// openOTPPopPup()
		dispatch(showLoader())
		generateWalletOTP(userID).
			then(apiResponse => {
				if (apiResponse.isValid) {
					dispatch(hideLoader())
					dispatch(setNotification("success", "Success", `OTP sent to ${apiResponse.otpSentTo}`))
					payload.otpPrimaryKey = apiResponse.otpPrimaryKey
					openOTPPopPup(apiResponse, payload, 'refundToWallet')
				}
			})
	}


	

	return (
		<div>
			<Dialog open={open} className="dialogMain">
				<DialogTitle>
					{"Refund on "}
					<span
						style={{ color: 'red' }}
					>
						{/* {leadData.vehicleDetails.bikeRegistrationNumber} */}
					</span>
					{' '}
					<span
						className="float-right"
						onClick={onClose}
						style={{ cursor: 'pointer' }}
					>
						&#10005;
					</span>
				</DialogTitle>
				<DialogContent className="dialogContent">
					<div className="editCardMain">
					<div className="inputRow">
							<label>CP Name</label>
							<span>
								<input
									type="text"
									disabled
									className="inputBalance availableBalance"
									value={channelPartnerResponseBean && channelPartnerResponseBean.shdName? channelPartnerResponseBean.shdName :"-"}
									
								/>
								
							</span>

						</div>
						<div className="inputRow">
							<label>CP Store Name</label>
							<span>
								<input
									type="text"
									disabled
									className="inputBalance availableBalance"
									value={channelPartnerResponseBean && channelPartnerResponseBean.shdStoreName? channelPartnerResponseBean.shdStoreName :"-"}
									
								/>
								<br />
							</span>

						</div>
						<div className="inputRow">
							<label>Payout Amount</label>
							<span>₹
								<input
									type="text"
									disabled
									className="inputBalance availableBalance"
									value={getComaSeparated(paymentData.amountAfterRefund)}
									
								/>
								<br />
								{
									paymentData.shdClearanceStatus === "PENDING" &&
									<span style={{ marginLeft: '25px', fontSize: "12px", color: "#0057D9", fontWeight: "bold" }}>Refund Deduction is Included </span>
								}
							</span>

						</div>
						{
							paymentData.shdClearanceStatus === "PENDING" ? <div className="inputRow">
								<label>Refund Clearance</label>
								<span>₹
									<input
										type="text"
										disabled
										className="inputBalance availableBalance"
										value={getComaSeparated(paymentData.shdClearancePrice)}

									/>
								</span>

							</div>
								:
								<div className="inputRow">
									<label>Enter Refund Amount </label>
									<span>₹
										<input
											disabled={paymentData.amountAfterRefund <= 0 ? true : false}
											type="number"
											className="inputBalance"
											placeholder="Enter Amount"
											value={refundAmount}
											onChange={handleLimit}
										/>
									</span>
								</div>

						}

						{isAmountError && (
							<div className="inputRow">
								<span></span>
								<div className="limiterrorDiv">
									<p>
										{amountErrorMessage}
									</p>
								</div>
							</div>
						)}
						<CommentOutlined /> <label>Comments</label>
						<textarea className="textarea" placeholder="Enter comments here"
							value={comments}
							onChange={handleComments}
							required
						></textarea>
						<p style={{ fontSize: '18px',marginTop:"10px"}}>Wallet Details</p>
						<div className="walletDetailsContainer">
							<div>
								<span> Wallet ID</span> <span>{paymentData.walletId}</span>
							</div>
							<div>
								<span> Wallet Status</span>
								<span
									className={paymentData.walletStatus === "ACTIVE" ? "walletStatus walletActive" : "walletStatus walletInactive"}
								>{paymentData.walletStatus && getTitleCase(paymentData.walletStatus)}</span>
							</div>

						</div>
						<div style={{ textAlign: "center", padding: '10px',marginTop:"20px" }}>

							{paymentData.docQcStatus !== "APPROVED" && <div className={"infoBoxDocQC"}>
								<img className="btn-icon-square" src={AlertIconRed} alt="Info" /> <span>DOC QC is not approved. Please verify total amount before issuing refund</span>
							</div>
							}
							<div className={"infoBox"}>
								<img className="btn-icon-square" src={InfoOutlinedIconBank} alt="Info" /> <span>Amount will be refunded to wallet</span>
							</div>
							<button
								disabled={comments && (refundAmount || paymentData.shdClearanceStatus === "PENDING") && !isAmountError ? false : true}
								className="refundButton"
								onClick={() => handleGenerateOTP()}

							>
								Refund In Wallet
							</button>
						</div>
					</div>
				</DialogContent>
			</Dialog>

		</div >
	);
}
