import { Input, TableCell,TableHead,TableContainer,Table, TableRow, DialogTitle, DialogContent, Checkbox} from '@material-ui/core'
// import { CheckBox,} from '@material-ui/icons'
import React, { useState, FC, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { Modal, Grid, Button } from 'rsuite'
import { requestColletDocument } from '../../../../../core/services/miscServices'
import { getUserID } from '../../../../../core/services/rbacServices';
import {  AlertIconRed } from '../../../../../core/utility/iconHelper';
import { hideLoader, showLoader } from '../../../../../redux/actions/loaderAction';
import { setNotification } from '../../../../../redux/actions/notificationAction';
import { getShdCustomerDocuments } from '../../../../../redux/actions/shdCustomerDocumentsAction';
// import call from '../../../shared/img/icons/call.svg'
import { useStyles } from "../LeadDetails";

export default function ReassignModal  ({ onClose, open, rowInfo, orderDedctions})  {

    const classes = useStyles();
    const dispatch =  useDispatch()


    const [docNoc, setDocNoc] = useState(false)
    const [docInsurance, setDocInsurance] = useState(false)
    const [docSignatureProof, setDocSignatureProof] = useState(false)
    const [openShdErr, setOpenShdErr] = useState(false);

    useEffect(() => {
      if(rowInfo && !rowInfo.commissionAddedFlag
    ) {
      setOpenShdErr(true);
    }
    },[]);

   

    const handleSubmit = () => {
        const payload = {
            leadId:rowInfo.leadId,
            userId:getUserID(),
            noc: docNoc,
            insurance: docInsurance,
            signatureProof: docSignatureProof
        }
       dispatch(showLoader)
        requestColletDocument(payload).
        then(apiResponse =>{
            if(apiResponse.isValid){
                dispatch(hideLoader)
                dispatch(setNotification('success', 'SUCCESS', apiResponse.message))
                dispatch(getShdCustomerDocuments(rowInfo.leadId))
                onClose()
            }else{
                dispatch(hideLoader)
                dispatch(setNotification('danger', 'ERROR', apiResponse.message))
                    onClose()
                }
            }
        )
    }


    return (
        <Modal
        // onHide={onClose}
        show={open}
        size="xs"
        className="diyWrap"
        style={{ zIndex: "2000"}}
      >
        <Modal.Header closeButton={false} style={{ textAlign: "center" }}>
          <Modal.Title>
              <p
                style={{
                  fontSize: "16px",
                  fontFamily: "ProximaNovaSemibold",
                  color: "#333333",
                  textAlign: "center",
                }}
              >{`Do you want to request below document?`}</p>
          </Modal.Title>
          <hr />
        </Modal.Header>
        <Modal.Body>
        <div style={{
            display:'flex', justifyContent:'space-between'
        }}>
        <span>NOC</span>
        <Checkbox sx={{ color: '#DA2128','&.Mui-checked': {color: '#DA2128'}}}
        onChange={()=> setDocNoc(!docNoc)}
        />
        </div>
        <div style={{
            display:'flex', justifyContent:'space-between'
        }}>
        <span>Insurance</span>
        <Checkbox sx={{ color: '#DA2128','&.Mui-checked': {color: '#DA2128'}}} 
        onChange={()=> setDocInsurance(!docInsurance)}
        />
        </div>
        <div style={{
            display:'flex', justifyContent:'space-between'
        }}>
        <span>Signature Proof</span>
        <Checkbox sx={{ color: '#DA2128','&.Mui-checked': {color: '#DA2128'}}} 
        onChange={()=> setDocSignatureProof(!docSignatureProof)}
        />
        </div>

        { !rowInfo.userEmail && <div style={{color:'#DA2128', display:'flex'}}>
            <img src={AlertIconRed} alt="" style={{width:'14px', marginRight:'10px'}}/>
            <span>Please Provide Email ID</span>
        </div>
      }
        { openShdErr && <div style={{color:'#DA2128', display:'flex'}}>
            <img src={AlertIconRed} alt="" style={{width:'14px', marginRight:'10px'}}/>
            <span>Please add commission</span>
        </div>
      }

        </Modal.Body>
        <br />
        <Modal.Footer style={{ textAlign: "center" }}>
          <button 
          onClick={onClose}
           style={{ border: '#DEDEDE', backgroundColor: '#BDBDBD', color: '#ffffff', padding: '8px 20px', borderRadius: '22px', marginRight: '10px' }}>Cancel</button>
          <button
            onClick={handleSubmit}
            disabled={rowInfo.userEmail && !openShdErr && (docNoc || docInsurance || docSignatureProof) ? false : true}
            style={{
              marginRight: 15,
              color: "#ffffff",
              padding: "8px 20px",
              borderRadius: "22px",
              backgroundColor: "#4DBD74",
              border: "1px solid #35AC5E",
            }}
          >
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
    )
}
