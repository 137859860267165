import React, { Component } from 'react'
import './table.scss'
import PdfIcon from '../../../../../core/utility/iconHelper'
import Divider from '@material-ui/core/Divider';
import { renderString, getMmvYear, getMmv, getAmount, getDate } from '../../../../../core/utility';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import { withStyles } from "@material-ui/core/styles";
import blue from '@material-ui/core/colors/blue'
// import { getUtrHistory } from '../../../../../core/services/shdPaymentServices';
// import ImageViewer from '../../../../SHD Payments/Awaiting Approval/Components/PaymentModal/ImageViewer/index'
import { lightBlue, red } from '@material-ui/core/colors';
import RefundTable from './RefundTable'
import RefundToWallet from '../../../components/RefundToWalletDialog/index'
// import OTPPopUp from '../../../../WalletBalanceCp/components/OTPPopUp/index'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@material-ui/core';

const ImageSearchIconBlue = withStyles({
    root: {
        // color: "blue"
    },
    icon: {
        color: 'red',
    }
})(ImageSearchIcon);



class DrawerComplete extends Component {
    state = {
        checked: false,
        isTogglePay: false,
        leadId: "",
        isTogglePayDetails: true,
        open: false,
        setOpen: false,
        setClose: false,
        data: {},
        outlet: undefined,
        paymentAmount: "",
        pay: "0",
        orderId: "",
        drawerClass: 'table-drawer_SHD',
        exchangeDate: "",
        transactionType: null,
        paymentNote: "",
        value: null,
        imageURL: null,
        openImageViewer: false,
        isOpenModelRefund: false,
        isOTPPopUp: false,
        apiSelect: '',
        payload: {},
        refreshDrawer: this.props.refresh,
        dataOTP: []
    }
   

    openImage = (imgUrl) => {
        window.open(imgUrl)
    }

    styles = theme => ({
        root: {
            //   width: '100%',
            //   maxWidth: 360
        },
        icon: {
            color: 'blue !important',
        }
    });


    approvePendingLeads = (data, status, totalAmount) => {
        const { onApprovePayment } = this.props
        onApprovePayment(data, status, totalAmount)
    }

    getBoolValue = (status) => {
        if (status === 'REQUESTED_PREAPPOVAL' || status === 'PENDING' || status === 'APPROVAL_PENDING') return true
        return false
    }

    showAmount = (status) => {
        if (status === 'APPROVAL_PENDING' || status === 'COMPLETED') return true
        return false
    }

    calTotal = (paymentDetails) => {
        let amount = 0
        if (paymentDetails && Boolean(paymentDetails.length)) {
            amount = paymentDetails.map(pd => pd.amount)
        }
        if (amount)
            return amount.reduce((a, b) => Number(a) + Number(b), 0)
        else return 0
    }

    openImage = (url) => {
        this.setState({ imageURL: url, openImageViewer: true })
        const { imageURL, openImageViewer } = this.state

    }

    handleOpenModel = () => {
        this.setState({ isOpenModelRefund: true })
    }

    openOTPPopPup = (apiResponse, payloadData, apiSelector) => {
        this.setState({ apiSelect: apiSelector })
        this.setState({ payload: payloadData })
        this.setState({ dataOTP: apiResponse })
        this.setState({ isOpenModelRefund: false })
        this.setState({ isOTPPopUp: true })
    }
    
   
    render() {
        let { drawer, selectedData,paymentData, updateTabledata, refresh, openRefundModel, closeRefundModel, onClose,open,handleClose,paymentList} = this.props
        const { imageURL, openImageViewer, isOpenModelRefund, isOTPPopUp, apiSelect, payload, dataOTP } = this.state
        // selectedData={}
        // selectedData.paymentType = 'WALLET'
        // selectedData.shdPaymentStatus = 'COMPLETED'
        
       
   
        return (
            <div className={drawer} >
                <div className="col-md-12 pt-0 pl-0 pr-0" style={{ minWidth: '600px !important' }}>
                    {
                       selectedData.paymentMethod === 'WALLET' ?
                            <>
                                <h4 align="left" className='headingDrawerSHD'>Payment Details - {renderString(selectedData.registrationNumber)} {' '}
                                    <span
                                        className="float-right"
                                        onClick={handleClose}
                                        style={{ cursor: 'pointer', marginRight: '20px' }}
                                    >
                                        &#10005;
                                    </span></h4>
                                <div className="row mt-2" style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                                    <div className="col-sm-4" style={{ fontFamily: 'ProximaNovaSemibold', fontSize: '14px', fontWeight: 'bold' }}>
                                        Order ID
                                    </div>
                                    <div className="col-sm-8 mb-2" style={{ fontFamily: 'ProximaNovaSemibold', fontSize: '14px', fontWeight: 'bold' }}>
                                        {renderString(selectedData.orderId)}
                                    </div>
                                    <div className="col-sm-4" style={{ fontFamily: 'ProximaNovaSemibold' }}>
                                        Payment Method
                                    </div>
                                    <div className="col-sm-8 mb-2">
                                        {renderString(selectedData.paymentMethod)}
                                    </div>
                                    <div className="col-sm-4" style={{ fontFamily: 'ProximaNovaSemibold' }}>
                                        Wallet Tx ID
                                    </div>
                                    <div className="col-sm-8 mb-2">
                                        {renderString(selectedData.paymentTxnId)}
                                    </div>
                                    <div className="col-sm-4" style={{ fontFamily: 'ProximaNovaSemibold' }}>
                                        Virtual A/C ID
                                    </div>
                                    <div className="col-sm-8 mb-2">
                                        {renderString(selectedData.virtualAccountId)}
                                    </div>
                                    <div className="col-sm-4" style={{ fontFamily: 'ProximaNovaSemibold' }}>
                                        Wallet ID
                                    </div>
                                    <div className="col-sm-8 mb-2">
                                        {renderString(selectedData.walletId)}
                                    </div>
                                    <div className="col-sm-4" style={{ fontFamily: 'ProximaNovaSemibold' }}>
                                        Store ID
                                    </div>
                                    <div className="col-sm-8 mb-2">
                                        {renderString(selectedData.storeId)}
                                    </div>
                                    <div className="col-sm-4" style={{ fontFamily: 'ProximaNovaSemibold' }}>
                                        Date & Time
                                    </div>
                                    <div className="col-sm-8 mb-2">
                                        {getDate(selectedData.paymentDate)}
                                    </div>
                                </div>
                            </> :
                            <>
                                <h4 align="left" className='headingDrawerSHD'>Payment Details - {renderString(selectedData.registrationNumber)}
                                    <span
                                        className="float-right"
                                        onClick={handleClose}
                                        style={{ cursor: 'pointer', marginRight: '20px' }}
                                    >
                                        &#10005;
                                    </span>
                                </h4>
                                <div className="row mt-2" style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                                    {/* <div className="col-sm-12 mb-1" > */}
                                    <div className="col-sm-4" style={{ fontFamily: 'ProximaNovaSemibold', fontSize: '14px', fontWeight: 'bold' }}>
                                        Order ID
                                    </div>
                                    <div className="col-sm-8 mb-2" style={{ fontFamily: 'ProximaNovaSemibold', fontSize: '14px', fontWeight: 'bold' }}>
                                        {renderString(selectedData.orderId)}
                                    </div>
                                    <div className="col-sm-4" style={{ fontFamily: 'ProximaNovaSemibold' }}>
                                        Payment Method
                                    </div>
                                    <div className="col-sm-8 mb-2">
                                        {renderString(selectedData.paymentMethod)}
                                    </div>
                                    {
                                        selectedData.finVerification === "APPROVED" && <>
                                            <div className="col-sm-4" style={{ fontFamily: 'ProximaNovaSemibold' }}>
                                                Payment Recipt
                                            </div>
                                            <div className="col-sm-8 mb-2">
                                                <button
                                                    style={{ border: 'none', color: 'blue', backgroundColor: 'white' }}
                                                    onClick={() => this.openImage(selectedData.paymentUrl)}>View Receipt</button>
                                            </div>
                                        </>
                                    }
                                    {
                                        this.showAmount(selectedData.shdPaymentStatus) &&
                                        <>
                                            <div className="col-sm-4" style={{ fontFamily: 'ProximaNovaSemibold' }}>
                                                Payment Date
                                            </div>
                                            <div className="col-sm-8 mb-2">
                                                {getDate(selectedData.paymentDate)}
                                            </div>
                                        </>
                                    }
                                    {
                                        selectedData.paymentReceipts && Boolean(selectedData.paymentReceipts.length) &&
                                        selectedData.paymentReceipts.map(receipt => {
                                            return (
                                                <>
                                                    <Divider style={{ width: '100%', border: '1px  #0000', marginBottom: '5px' }} />
                                                    <div className="col-sm-4" style={{ fontFamily: 'ProximaNovaSemibold' }}>
                                                        UTR Number
                                                    </div>
                                                    <div className="col-sm-8 mb-1" >
                                                        {renderString(receipt.utrNum)}
                                                    </div>
                                                    <div className="col-sm-4" style={{ fontFamily: 'ProximaNovaSemibold' }}>
                                                        Payment Method
                                                    </div>
                                                    <div className="col-sm-8 mb-2">
                                                        {renderString(selectedData.paymentMethod)}
                                                    </div>
                                                    <div className="col-sm-4" style={{ fontFamily: 'ProximaNovaSemibold' }}>
                                                        Amount
                                                    </div>
                                                    <div className="col-sm-4 mb-1" >
                                                        {getAmount(receipt.amount)}
                                                    </div>
                                                    <div className="col-sm-12" style={{ marginBottom: '5px' }}>
                                                        <button className="buttonImage" onClick={() => this.openImage(receipt.receiptUrl)}>
                                                            <ImageSearchIcon alt="Download" style={{ maxWidth: '70px', height: '30px', cursor: 'pointer', width: '25px', marginRight: '10px', color: blue }} />
                                                            Receipt - {receipt.utrNum}</button>
                                                    </div>
                                                </>
                                            )
                                        })
                                    }
                                    {
                                        this.getBoolValue(selectedData.shdPaymentStatus) &&
                                        <>
                                            <Divider style={{ width: '100%', border: '1px  #0000', marginBottom: '5px' }} />
                                            <div style={{ marginTop: '8px', fontSize: '16px', marginLeft: '90%' }}>
                                                <button className="rounded no-margin btn btn-success" onClick={() => this.approvePendingLeads(selectedData, selectedData.shdPaymentStatus, this.calTotal(selectedData.paymentReceipts))}> Approve </button>
                                            </div>
                                        </>
                                    }
                                    {
                                        this.showAmount(selectedData.shdPaymentStatus) &&
                                        <>
                                            {
                                                selectedData.shdPaymentStatus === 'COMPLETED' && <Divider style={{ width: '100%', border: '1px  #0000', marginBottom: '5px' }} />
                                            }
                                            <div className="col-sm-6" style={{ marginBottom: '5px', marginTop: selectedData.shdPaymentStatus === 'APPROVAL_PENDING' ? '-23px' : '5px', fontWeight: 'bolder', fontSize: '14px' }}>
                                                Total Amount: {getAmount(selectedData.amountTobePaid)}
                                            </div>
                                        </>
                                    }
                                </div>
                            </>
                    }
                    <br />
                    {
                        (selectedData.shdPaymentStatus === 'COMPLETED' && selectedData.paymentMethod === "WALLET") &&

                        <RefundTable
                            handleOpenModel={this.handleOpenModel}
                            tableData={selectedData.shdRefundDetails}
                            refresh={refresh}
                        />
                    }
                    {(isOpenModelRefund || openRefundModel ) &&
                        <RefundToWallet
                            open={isOpenModelRefund || openRefundModel}
                            paymentData={selectedData}
                            onClose={() => {

                                this.setState({ isOpenModelRefund: false, })
                                closeRefundModel()
                            }
                            }
                            // openOTPPopPup={this.openOTPPopPup}
                        />
                    }
                    {/* {isOTPPopUp && (
                        <OTPPopUp
                            apiSelect={apiSelect}
                            dataOTP={dataOTP}
                            payload={payload}
                            open={isOTPPopUp}
                            onClose={() => this.setState({ isOTPPopUp: false, isOpenModelRefund: false })}
                            openModel={(leadId) => updateTabledata(leadId)}
                        />)} */}

                </div>

                {/* {openImageViewer &&
                    <ImageViewer
                        images={[{ src: imageURL }]}
                        isOpen={openImageViewer}
                        currentIndex={0}
                        onClose={() => this.setState({ imageURL: null, openImageViewer: false })}
                    />
                } */}
            </div >
        )
    }
}


export default DrawerComplete