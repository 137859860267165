import React from "react";
import { Component } from "react";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Stepper, Step, StepLabel, Typography,
  makeStyles,
  withStyles,
  StepConnector,
  styled,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { getLogisticsHistory } from "../../../../core/services/logisticServices";
import { setNotification } from "../../../../redux/actions/notificationAction";
import { hideLoader } from "../../../../redux/actions/loaderAction";
import moment from "moment";
import { capaitalize, renderDate, renderString, capitalizeFirstLetter, removeUnderscore } from "../../../../core/utility";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';


const CustomStepper = styled(Stepper)(() => ({
  height: '8vh',
  '& .MuiStepConnector-root .MuiStepConnector-line': {
    height: '100%',
  },
  '& .MuiPaper-root': {
    backgroundColor: 'none'
  }
}));

const ActiveStepperIcon = styled(RadioButtonUncheckedIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  height: '16px',
  '& .MuiPaper-root': {
    backgroundColor: 'none'
  }
}));


const InactiveStepperIcon = styled(FiberManualRecordIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  height: '16px'
}))

const StyledTableRow = withStyles((theme) => ({
  root: {
    // backgroundColor: "white",
    borderRadius: "10px",
    color: "#000000",
    background: 'none'
  },
}))(TableRow);

const getStatusColor = (statusValue) => {
  switch (statusValue) {
    case "REJECTED":
      return "status-rejected-color";
      break;
    case "RESCHEDULED":
      return "status-rescheduled-color";
      break;
    case "RE_ASSIGNED":
      return "status-reassigned-color";
      break;
    case "ASSIGNED":
      return "status-assigned-color";
      break;
    default:
      return "";
      break;
  }
};

class DeliveryTimeHistory extends Component {

  state = { activeStep: [0] };

  checkStatusForTime = (tempStatus) => {
    const statusValues = ['ASSIGNED', 'RE_ASSIGNED', 'REJECTED', 'RESCHEDULED']
    return statusValues.includes(tempStatus)
  }

  getButtonColor = () => {
    const getRandomCoolColor = () => {
      const randomColorComponent = () => Math.floor(Math.random() * 128) + 128;
      const color = `rgb(${randomColorComponent()}, ${randomColorComponent()}, ${randomColorComponent()})`;
      return color;
    };

    const randomColor = getRandomCoolColor();

    return {
      color: '#000000',
      backgroundColor: randomColor,
      width: 'auto',
      minWidth: '100px',
      padding: '3px',
      border: '1px solid #EAFFFA',
      borderRadius: '8px',
      textTransform: 'capitalize',
      fontWeight: 'bold'
    };
  }

  getDuration = (data) => {
    if (data && data.intransitDate) {
      let date1 = new Date(data.pickupDate);
      date1.setHours(date1.getHours());
      let pickUpTimeStamp = date1;
      var d = new Date(data.intransitDate);
      var difference = d.getTime() - pickUpTimeStamp.getTime();
      let timeInMinutes = difference / 1000 / 60;
      console.log(timeInMinutes, 'time')
      // if (timeInMinutes < 60 && timeInMinutes > 0) {
      //   return `${Math.round(timeInMinutes)}m delay`;
      // }
      if (timeInMinutes >= 60) {
        let hours = difference / 1000 / 60 / 60;
        if (hours >= 24) {
          let days = Math.floor(hours / 24);
          let remainingHours = hours % 24;

          if (remainingHours > 0) {
            return `Delayed ${days}d ${Math.round(remainingHours)}h`;
          } else {
            return `Delayed ${days}d`;
          }
        } else {
          let remainingMinutes = timeInMinutes % 60;

          if (remainingMinutes > 0) {
            return `Delayed ${Math.floor(hours)}h ${Math.round(remainingMinutes)}m`;
          } else {
            return `Delayed ${Math.floor(hours)}h`;
          }
        }
      }
      else{
        return `${Math.round(timeInMinutes)}m delay`;
      }
    }
    else {
      if (data && data.pickupDate) {
        let date1 = new Date(data.pickupDate)
        date1.setHours(date1.getHours());
        let pickUpTimeStamp = date1
        var d = new Date();
        var difference = d.getTime() - pickUpTimeStamp.getTime()
        let timeInMinutes = difference / 1000 / 60
        if (timeInMinutes < 60) {
          return `${Math.round(timeInMinutes)}m delay`;
        }
        if (timeInMinutes >= 60) {
          let hours = difference / 1000 / 60 / 60;
          if (hours >= 24) {
            let days = Math.floor(hours / 24);
            let remainingHours = hours % 24;

            if (remainingHours > 0) {
              return `Delayed ${days}d ${Math.round(remainingHours)}h`;
            } else {
              return `Delayed ${days}d`;
            }
          } else {
            let remainingMinutes = timeInMinutes % 60;

            if (remainingMinutes > 0) {
              return `Delayed ${Math.floor(hours)}h ${Math.round(remainingMinutes)}m`;
            } else {
              return `Delayed ${Math.floor(hours)}h`;
            }
          }
        }
      }
    }
  }

  render() {
    const { openDeliveryHistory, handleCloseDeliveryTime, leadInfo, timeHistoryList } =
      this.props;
    const { activeStep } = this.state;
    return (
      <Dialog
        maxWidth="lg"
        fullWidth
        disableScrollLock
        open={openDeliveryHistory}
        PaperProps={{
          style: {
            backgroundColor: "#F5F5F5",
          },
        }}
      >
        <DialogTitle>
          Logistics History
          <IconButton
            style={{ float: "right" }}
            edge="end"
            color="inherit"
            onClick={handleCloseDeliveryTime}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <div className="table-container">
            <Table>
              <TableHead style={{ backgroundColor: "#DDEBF9" }}>
                <TableCell className="delivery-table-head"></TableCell>
                <TableCell className="delivery-table-head">Date</TableCell>
                <TableCell className="delivery-table-head">Action By</TableCell>
                <TableCell className="delivery-table-head">
                  Status
                </TableCell>
                <TableCell className="delivery-table-head">Pick Up</TableCell>
                <TableCell className="delivery-table-head">Delayed By</TableCell>
                <TableCell className="delivery-table-head">Comments</TableCell>
              </TableHead>
              <TableBody className="delivery-history-body">
                <br />
                {
                  timeHistoryList.map((item) => {
                    let reachByDate = "---";
                    let reachByTime = "---";
                    let reachOnDate = "---";
                    let reachOnTime = "---";
                    let startTime = moment("", "DD-MMM-YYYY hh:mm A");
                    let endTime = moment("", "DD-MMM-YYYY hh:mm A");
                    let onTime = null;
                    let finalDuration = "---";
                    if (item && item.reachedBy) {
                      const reachByArray = item.reachedBy.split(" ");
                      reachByDate = reachByArray[0];
                      reachByTime = reachByArray[1] + " " + reachByArray[2];
                      startTime = moment(item.reachedBy, "DD-MMM-YYYY hh:mm A");
                    }

                    if (item && item.reachedOn) {
                      const reachOnArray = item.reachedOn.split(" ");
                      reachOnDate = reachOnArray[0];
                      reachOnTime = reachOnArray[1] + " " + reachOnArray[2];
                      endTime = moment(item.reachedOn, "DD-MMM-YYYY hh:mm A");
                    }

                    if (endTime.isBefore(startTime)) {
                      const duration = moment.duration(endTime.diff(startTime));
                      const days = duration.days()
                      const hours = duration.hours();
                      const minutes = duration.minutes();
                      finalDuration = "On Time";
                      onTime = "onTime";
                    } else if (endTime.isAfter(startTime)) {
                      const duration = moment.duration(startTime.diff(endTime));
                      // const months=duration.months()
                      const days = Math.floor(duration.asDays())
                      const hours = duration.hours();
                      const minutes = duration.minutes();
                      finalDuration = `Delayed by ${days * -1}d ${hours * -1}h ${minutes * -1}m`;
                      onTime = "delayed";
                    } else {
                      finalDuration = "---";
                      onTime = null;
                    }

                    return (
                      <>
                        <StyledTableRow className="delivery-history-row-new">
                          {/* <CustomStepper orientation="vertical">
                            <Step key={item.leadId}>
                              <StepLabel
                                StepIconComponent={
                                  activeStep === item.leadId ? ActiveStepperIcon : InactiveStepperIcon
                                }
                                onClick={() => this.setState({item})}
                                sx={{ ':hover': { cursor: 'pointer' } }}
                              >
                                {item.title}
                              </StepLabel>
                            </Step>
                          </CustomStepper> */}
                          <TableCell className="noneBorder">

                          </TableCell>
                          <TableCell className="table-cell-leftmost noneBorder">
                            {renderDate(item.date)}
                          </TableCell>
                          <TableCell className="noneBorder">
                            {renderString(item.actionByName)}
                            <p>{capitalizeFirstLetter(removeUnderscore(renderString(item.actionByUtName)))}</p>
                          </TableCell>
                          <TableCell className="noneBorder">
                            <button style={this.getButtonColor()}>{capitalizeFirstLetter(renderString(item.status))}</button>
                          </TableCell>

                          <TableCell className="noneBorder">
                            {renderDate(item.pickupDate)}
                          </TableCell>
                          <TableCell style={{ maxWidth: "130px", color: '#EA0000' }} className="noneBorder">
                            {this.getDuration(item)}
                          </TableCell>
                          <TableCell style={{ maxWidth: "130px" }} className="noneBorder">
                            <p>{capitalizeFirstLetter(removeUnderscore(renderString(item.comments)))}</p>
                          </TableCell>
                        </StyledTableRow>
                        <br />
                      </>
                    );
                  })}
              </TableBody>
            </Table>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}
DeliveryTimeHistory = connect()(DeliveryTimeHistory);

export default DeliveryTimeHistory;
