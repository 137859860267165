import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Modal, Dropdown } from "rsuite";
import { rebid, shdRebid } from "../../../core/services/biddingServices";
import { hideLoader, showLoader } from "../../../redux/actions/loaderAction";
import { setNotification } from "../../../redux/actions/notificationAction";
import Checkbox from "@material-ui/core/Checkbox";
import {
  CallIcon,
  CallIconGreen,
  ThumbUpIcon,
  ThumbDownIcon,
} from "../../../core/utility/iconHelper";
import "./BidActionPopUp.scss";
import { getAmount } from "../../../core/utility";
import { getMobile, getUserID } from "../../../core/services/rbacServices";
import { callingPtAgent } from "../../../core/services/paperTransferService";
import { useSelector } from 'react-redux'
import { getCityData } from '../../../core/services/rbacServices'
import { userTypeDetails } from '../../../core/services/authenticationServices'

export default function BidModal({
  onClose,
  open,
  close,
  bidderDetails,
  rowInfo,
  onRefreshPage,
  bidVal,
  rebidReasonList,
  leadData,
}) {
  const [rebidReasonsList, setRebidReasonsList] = useState(rebidReasonList);
  const [showBidInput, setShowBidInput] = useState(false);
  const [showCall, setShowCall] = useState(false);
  const [openDropDown, setOpenDropDown] = useState(false);
  const [selectText, setSelectText] = useState("Select Reason for Re-bid");
  const [rebidReasonId, setRebidReasonId] = useState();
  const [openCondtion, SetOpenCondtion] = useState({});
  const [isLimitError, setIsLimitError] = useState(false);
  const [rebidValue, setRebidValue] = useState("")
  const [rating, setRating] = useState("")
  const [selectCity, setSelectCity] = useState('')
  const [isChecked, setIsChecked] = useState(false);
  const dispatch = useDispatch();
  const [cityIdList, setCityIdList] = useState([])
  const [userList, setUserList] = useState([])
  const [showOnClick, setShowOnclick] = useState(false)

  const cities = useSelector((state) => state.cities.cityList)

  const usersCity = getCityData().split(',').map((val) => val)

  const finalCityList = cities.filter(item => usersCity.includes(item.cityId));

  const cityFuncList = finalCityList.map(city => {
    return {
      cityId: city.cityId,
      cityName: city.cityName,
      isChecked: false
    }
  })

  const updatedCityList = [{
    cityId: 'all',
    cityName: 'Select All',
    isChecked: false
  }, ...cityFuncList];

  const [cityList, setCityList] = useState(updatedCityList);

  useEffect(() => {
    const userTypeDetailsData = userTypeDetails();
    const listOfFeatureNames = userTypeDetailsData && userTypeDetailsData.userType && userTypeDetailsData.userType.listOfFeatureNames;

    setUserList(listOfFeatureNames && listOfFeatureNames.map((feature) => {
      return feature.ufac_feature_name;
    }))
  }, []);

  const continueBid = () => {
    if ((userList) && (userList.includes("multi_city_bidding")) && cityIdList.length === 0) {
      dispatch(
        setNotification("danger", "Error", "Please select at least one city.")
      );
      return;
    }
    else {
      dispatch(showLoader());
      if (Number(rowInfo.customerExpectedPrice) === 0) {
        dispatch(
          setNotification(
            "danger",
            "Error",
            "Please enter customer expected price"
          )
        );
        onClose();
        close();
        onRefreshPage();
      } else {
        if (
          rowInfo.leadType === "NTP" ||
          rowInfo.leadType === "TP-R" ||
          rowInfo.leadType === "TP-CP" || 
          rowInfo.leadType === 'PUBLISHING_LEAD'
        ) {
          const payload = {
            auctionTransactionId: rowInfo.transactionId,
            gatePassId: rowInfo.gatepassId,
            leadId: rowInfo.leadId,
            type: "Continue",
            rebidReasonId: 0,
            cityList: cityIdList && Boolean(cityIdList.length) ? [...cityIdList] : [rowInfo.cityId]
          };
          shdRebid(payload).then((apiResponse) => {
            if (apiResponse.isValid) {
              dispatch(
                setNotification("success", "SUCCESS", apiResponse.message)
              );
              dispatch(hideLoader());
              onClose();
              close();
              onRefreshPage("bidAction");
            } else {
              dispatch(hideLoader());
              dispatch(setNotification("danger", "Error", apiResponse.message));
            }
          });
        } 
        else {
          const payload = {
            auctionTransactionId: rowInfo.transactionId,
            gatePassId: rowInfo.gatepassId,
            leadId: rowInfo.leadId,
            type: "Continue",
            cityList: cityIdList && Boolean(cityIdList.length) ? [...cityIdList] : [rowInfo.cityId]
          };
          rebid(payload).then((apiResponse) => {
            if (apiResponse.isValid) {
              dispatch(
                setNotification("success", "SUCCESS", apiResponse.message)
              );
              dispatch(hideLoader());
              onClose();
              close();
              onRefreshPage("bidAction");
            } else {
              dispatch(hideLoader());
              dispatch(setNotification("danger", "Error", apiResponse.message));
            }
          });
        }
      }
    }
  };

  const discardBid = () => {
    onClose();
  };

  const handleChange = (e, subReasons) => {
    if (e.target.checked) {
      setShowOnclick(true)
      SetOpenCondtion({
        open: false,
      });
      setShowCall(true);
      setSelectText(subReasons.reasons);
      setRebidReasonId(subReasons.reasonId);
    } else {
      setShowOnclick(false)
      setSelectText("Select Reason for Re-bid");
      setShowCall(false);
    }
  };

  const handleCityChange = (e, city) => {
    let selectedCityIds = [];

    if (city.cityId === 'all') {
      const allChecked = e.target.checked;
      const updatedCities = cityList.map(cityItem => ({
        ...cityItem,
        isChecked: allChecked
      }));
      setCityList(updatedCities);
      if (!allChecked) {
        setIsChecked(false);
      } else {
        selectedCityIds = updatedCities.slice(1).map(cityItem => cityItem.cityId);
      }
    } else {
      const updatedCities = cityList.map(cityItem => {
        if (cityItem.cityId === city.cityId) {
          return { ...cityItem, isChecked: e.target.checked };
        } else if (cityItem.cityId === 'all' && !e.target.checked) {
          return { ...cityItem, isChecked: false };
        } else {
          return cityItem;
        }
      });
      setCityList(updatedCities);
      const isAnyUnchecked = updatedCities.slice(1).some(cityItem => !cityItem.isChecked);
      setIsChecked(!isAnyUnchecked && updatedCities.length > 1);

      selectedCityIds = updatedCities.slice(1).filter(cityItem => cityItem.isChecked).map(cityItem => cityItem.cityId);
    }
    setCityIdList(selectedCityIds)
  };


  const callHandler = () => {
    const payload = {
      fromNumber: getMobile(),
      toNumber: bidderDetails ? bidderDetails.higgestBidderMobileNumber : 'NA',
      userId: getUserID(),
    }
    dispatch(showLoader())
    callingPtAgent(payload).then(apiResponse => {
      dispatch(hideLoader())
      if (apiResponse.isValid) {
        dispatch(setNotification('success', 'Success', apiResponse.message))

      } else {
        dispatch(setNotification('danger', 'Error', apiResponse.message));
      }
    })
  }

  const rebidHandler = () => {
    if ((userList) && (userList.includes("multi_city_bidding")) && cityIdList.length === 0) {
      dispatch(
        setNotification("danger", "Error", "Please select at least one city.")
      );
      return;
    }
    else {
      const payload = {
        auctionTransactionId: rowInfo.transactionId,
        gatePassId: rowInfo.gatepassId,
        leadId: rowInfo.leadId,
        type: "Continue",
        rebidReasonId: rebidReasonId,
        rating: rating,
        resetAmount: rebidValue,
        bidderUserId: (rating === "BAD" || rating === "GOOD") ? (bidderDetails ? bidderDetails.higgestBidderUserId : null) : null,
        loginUserId: getUserID(),
        cityList: cityIdList && Boolean(cityIdList.length) ? [...cityIdList] : [rowInfo.cityId]
      };
      dispatch(showLoader)
      shdRebid(payload).then((apiResponse) => {
        if (apiResponse.isValid) {
          dispatch(setNotification("success", "SUCCESS", apiResponse.message));
          dispatch(hideLoader());
          onClose();
          close();
          onRefreshPage("bidAction");
        } else {
          dispatch(hideLoader());
          dispatch(setNotification("danger", "Error", apiResponse.message));
        }
      });
    }
  }

  const handleLimit = (e) => {
    setIsLimitError(false)
    setRebidValue((e.target.value))
    if (Number(e.target.value) < bidderDetails.minAmount) {
      setIsLimitError(true)
    }

  }

  return (
    <div>
      {(rebidReasonList && rebidReasonList.length > 0) ? (
        <Modal
          // onHide={onClose}
          show={open}
          size="sm"
          className="diyWrap"
          style={{
            zIndex: "1000",
            // height: "500px",
            width: "450px",
            margin: "auto auto",
            marginTop: '10%'
          }}
        >
          <Modal.Header
            closeButton
            onHide={onClose}
          // style={{ textAlign: "center" }}
          >
            <Modal.Title>
              <p
                style={{
                  fontSize: "16px",
                  fontFamily: "ProximaNovaSemibold",
                  color: "#000000",
                  //   textAlign: "center",
                }}
              >{`Select the Re-Bid Reason ?`}</p>{" "}
            </Modal.Title>
            {/* <hr /> */}
          </Modal.Header>
          <Modal.Body style={{ maxHeight: '900px', overflowY: 'auto' }}>
            {rebidReasonsList.length > 0 && (
              <div className="rebidReasonSelector">
                <Dropdown
                  {...openCondtion}
                  title={selectText}
                  onClick={() => {
                    SetOpenCondtion({});
                  }}
                  className="main-dropDown-bid"
                >
                  {rebidReasonsList.map((reason) => {
                    if (!reason.subReasonList) {
                      return (
                        <Dropdown.Item
                          onClick={() => {
                            setSelectText(reason.reasons);
                            setRebidReasonId(reason.reasonId)
                            setOpenDropDown(false);

                          }}
                        >
                          {reason.reasons}
                        </Dropdown.Item>
                      );
                    } else {
                      return (
                        <Dropdown
                          // open={openSubDropDown}
                          title={reason.reasons}
                          // onClick={()=> setOpenSubDropDown(!openSubDropDown)}
                          className="sub-dropDown-bid"
                        >
                          {reason.subReasonList.map((subReason) => {
                            return (
                              <Dropdown.Item style={{ marginLeft: "20px" }} disabled>
                                {subReason.reasons}
                                <Checkbox
                                  checked={
                                    subReason.reasons === selectText
                                      ? true
                                      : false
                                  }
                                  onChange={(e) =>
                                    handleChange(e, subReason)
                                  }
                                  inputProps={{
                                    "aria-label": "primary checkbox",
                                  }}
                                  style={{ padding: "2px" }}
                                />
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown>
                      );
                    }
                  })}
                </Dropdown>
              </div>
            )}

            {
              showOnClick &&
              <div style={{ visibility: showCall ? "visible" : "hidden" }}>
                <div className="boxContainer">
                  <p className="titleBox">Confirm the reason for back out</p>
                  <div className="insideBoxContainer">
                    <span>{bidderDetails ? bidderDetails.higgestBidderName : 'NA'}</span>
                    <img src={CallIconGreen} alt="" onClick={callHandler} />
                  </div>
                </div>

                {!showBidInput && (
                  <div className="boxContainer">
                    <p className="titleBox">Rate Channel Partner?</p>
                    <div className="insideBoxContainer">
                      <div
                        style={{
                          border: "1px solid #47B26C",
                          color: "#47B26C",
                        }}
                        onClick={() => {
                          setShowBidInput(true)
                          setRating("GOOD")
                        }}
                      >
                        <img src={ThumbUpIcon} alt="" />
                        <span>{bidderDetails ? bidderDetails.thumsUpCount : 'NA'}</span>
                      </div>
                      <div
                        style={{
                          border: "1px solid #DA2128",
                          color: "#DA2128",
                        }}
                        onClick={() => {
                          setShowBidInput(true)
                          setRating("BAD")
                        }}
                      >
                        <img src={ThumbDownIcon} alt="" />
                        <span>{bidderDetails ? bidderDetails.thumsDownCount : 'NA'}</span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            }
            {showBidInput && (
              <div style={{ marginTop: "40px", marginBottom: "10px" }}>
                <p
                  style={{
                    fontSize: "16px",
                    fontFamily: "ProximaNovaSemibold",
                    color: "#000000",
                  }}
                >
                  Set Minimum bid Limit
                </p>
                ₹{" "}
                <input
                  type="number"
                  style={{
                    width: "116px",
                    height: "38px",
                    borderRadius: "8px",
                    border: "1px solid #D2D2D2",
                    color: "#9D5501",
                    fontWeight: 500,
                  }}
                  placeholder="Enter Amount"
                  value={rebidValue}
                  onChange={handleLimit}
                />{" "}
                <span
                  style={{
                    width: "103px",
                    height: "16px",
                    borderRadius: "2px",
                    background: "#FFEFDD",
                    // fontSize:''
                    padding: "4px 8px",
                    color: "#9D5501",
                    fontSize: "10px",
                    fontWeight: 400,
                    marginLeft: "8px",
                  }}
                >
                  Min. Limit: {getAmount(bidderDetails ? bidderDetails.minAmount : 0)}
                </span>
              </div>
            )}
            {isLimitError && (
              <div className="inputRow">
                <span></span>
                <div className="limiterrorDiv">
                  <br />
                  <p style={{ color: 'red', fontSize: '9px', float: 'left' }}>
                    {'Bid Can Not Be Lower Then Minimum Limit'}
                  </p>
                </div>
              </div>
            )}
            <div style={{ width: '80%', marginTop: '10px' }}>
              {
                (userList) && (userList.includes('multi_city_bidding')) &&
                <>
                  <p style={{ fontWeight: 'bold', fontSize: '15px', marginTop: '25px' }}>Multi City Bidding</p>
                  <p style={{ fontWeight: 'bold', fontSize: '15px', marginTop: '20px' }}>Select City</p>
                </>
              }
              {
                (userList) && (userList.includes('multi_city_bidding')) && cityList && Boolean(cityList.length) && cityList.map((city) => {
                  return (
                    <div
                      key={city.cityId}
                      className={`row ${city.isChecked || isChecked ? 'selectedRow' : ''} rounded-div`}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginTop: '5px',
                        marginLeft: '10px',
                        cursor: 'default',
                        padding: '5px 10px'
                      }}
                    >
                      <div style={{ flex: 1 }}>
                        {city.cityName}
                      </div>
                      <div style={{ flex: '0 0 auto' }}>
                        <Checkbox
                          checked={city.isChecked || isChecked}
                          onChange={(e) => handleCityChange(e, city)}
                          inputProps={{
                            "aria-label": "primary checkbox",
                          }}
                          style={{
                            color: city.isChecked || isChecked ? '#0A7AFF' : 'initial', // Change color when checked
                            padding: '6px',        // Add padding
                            borderRadius: '10px',   // Add border radius
                            fontSize: '24px',
                            borderColor: '#C4C4C4'
                          }}
                          id="checkBoxColor"
                        />
                      </div>
                    </div>
                  );
                })
              }
            </div>
          </Modal.Body>
          <Modal.Footer style={{ textAlign: "center", marginTop: '10px' }}>
            <button
              onClick={rebidHandler}
              disabled={isLimitError ? true : false}
              style={{
                color: "#ffffff",
                padding: "8px 20px",
                borderRadius: "22px",
                backgroundColor: "#4DBD74",
                border: "1px solid #35AC5E",
              }}
            >
              Submit
            </button>
          </Modal.Footer>
        </Modal>
      ) :
        (userList) && !(userList.includes("multi_city_bidding")) ?
          (
            <Modal
              onHide={onClose}
              show={open}
              size="sm"
              className="diyWrap"
              style={{ zIndex: "2000" }}
            >
              <Modal.Header closeButton style={{ textAlign: "center" }}>
                <Modal.Title>
                  {bidVal === "bid" ? (
                    <p
                      style={{
                        fontSize: "22px",
                        fontFamily: "ProximaNovaSemibold",
                        color: "#333333",
                        textAlign: "center",
                      }}
                    >{`Are you sure you want to Bid ?`}</p>
                  ) : (
                    <p
                      style={{
                        fontSize: "22px",
                        fontFamily: "ProximaNovaSemibold",
                        color: "#333333",
                        textAlign: "center",
                      }}
                    >{`This will start the bid and re-assign lead to you.`}</p>
                  )}
                </Modal.Title>
                <hr />
              </Modal.Header>
              {/* <Modal.Body>
                <Grid fluid>
                    {bidVal === 'bid' ?
                        <p style={{ fontSize: '16px', fontFamily: 'ProximaNovaSemibold', color: '#333333', textAlign: 'center' }}>{`Are you sure you want to Bid ?`}</p> :
                        <p style={{ fontSize: '16px', fontFamily: 'ProximaNovaSemibold', color: '#333333', textAlign: 'center' }}>{`This will start the bid and re-assign lead to you.`}</p>}
                </Grid>
            </Modal.Body> */}
              <br />
              <Modal.Footer style={{ textAlign: "center" }}>
                {/* <button onClick={discardBid} style={{ border: '#DEDEDE', backgroundColor: '#E59342', color: '#333333', padding: '8px 20px', borderRadius: '22px', marginRight: '10px' }}>Cancel</button> */}
                <button
                  onClick={continueBid}
                  style={{
                    marginRight: 15,
                    color: "#ffffff",
                    padding: "8px 20px",
                    borderRadius: "22px",
                    backgroundColor: "#4DBD74",
                    border: "1px solid #35AC5E",
                  }}
                >
                  Confirm
                </button>
              </Modal.Footer>
            </Modal>
          )
          :
          (<Modal
            onHide={onClose}
            show={open}
            size="sm"
            className="diyWrap"
            style={{ zIndex: "2000" }}
          >
            <Modal.Header closeButton style={{ textAlign: "center" }}>
              <Modal.Title>
                Multi City Bidding
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div style={{ width: '80%', marginTop: '10px' }}>
                <p style={{ fontWeight: 'bold', fontSize: '15px', marginTop: '20px' }}>Select City</p>
                {
                  cityList && Boolean(cityList.length) && cityList.map((city) => {
                    return (
                      <div
                        key={city.cityId}
                        className={`row ${city.isChecked || isChecked ? 'selectedRow' : ''} rounded-div`}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginTop: '5px',
                          marginLeft: '10px',
                          cursor: 'default',
                          padding: '5px 10px'
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          {city.cityName}
                        </div>
                        <div style={{ flex: '0 0 auto' }}>
                          <Checkbox
                            checked={city.isChecked || isChecked}
                            onChange={(e) => handleCityChange(e, city)}
                            inputProps={{
                              "aria-label": "primary checkbox",
                            }}
                            style={{
                              color: city.isChecked || isChecked ? '#0A7AFF' : 'initial', // Change color when checked
                              padding: '6px',        // Add padding
                              borderRadius: '10px',   // Add border radius
                              fontSize: '24px',
                              borderColor: '#C4C4C4'
                            }}
                            id="checkBoxColor"
                          />
                        </div>
                      </div>
                    );
                  })
                }
              </div>
            </Modal.Body>
            <br />
            <Modal.Footer style={{ textAlign: "center" }}>
              {/* <button onClick={discardBid} style={{ border: '#DEDEDE', backgroundColor: '#E59342', color: '#333333', padding: '8px 20px', borderRadius: '22px', marginRight: '10px' }}>Cancel</button> */}
              <button
                onClick={continueBid}
                style={{
                  marginRight: 15,
                  color: "#ffffff",
                  padding: "8px 20px",
                  borderRadius: "22px",
                  backgroundColor: "#4DBD74",
                  border: "1px solid #35AC5E",
                }}
              >
                Confirm
              </button>
            </Modal.Footer>
          </Modal>
          )
      }
    </div>
  );
}
