export const LIST_INVENTORY_ACTION = {
	REQUEST: 'LIST_INVENTORY_REQUEST',
	SUCCESS: 'LIST_INVENTORY_SUCCESS',
	FAILURE: 'LIST_INVENTORY_FAILURE',
	RESET: 'LIST_INVENTORY_RESET'
};

export const LIST_INVENTORY_SHD_ACTION = {
	REQUEST: 'LIST_INVENTORY_SHD_REQUEST',
	SUCCESS: 'LIST_INVENTORY_SHD_SUCCESS',
	FAILURE: 'LIST_INVENTORY_SHD_FAILURE',
	RESET: 'LIST_INVENTORY_SHD_RESET'
};

export const FHD_SHD_STORES_ACTION = {
	REQUEST: 'FHD_SHD_STORES_ACTION_REQUEST',
	SUCCESS: 'FHD_SHD_STORES_ACTION_SUCCESS',
	FAILURE: 'FHD_SHD_STORES_ACTION_FAILURE',
	RESET: 'FHD_SHD_STORES_ACTION_RESET'
};

export const GET_LEAD_DETAILS = {
	REQUEST: 'GET_LEAD_DETAILS_REQUEST',
	SUCCESS: 'GET_LEAD_DETAILS_SUCCESS',
	FAILURE: 'GET_LEAD_DETAILS_FAILURE',
	RESET: 'GET_LEAD_DETAILS_RESET'
};
export const GET_INTERESTED_BUYERS = {
	REQUEST: 'GET_INTERESTED_BUYERS_REQUEST',
	SUCCESS: 'GET_INTERESTED_BUYERS_SUCCESS',
	FAILURE: 'GET_INTERESTED_BUYERS_FAILURE',
	RESET: 'GET_INTERESTED_BUYERS_RESET'
};

export const GET_LEAD_LIFECYCLE_DETAILS = {
	REQUEST: 'GET_LEAD_LIFECYCLE_DETAILS_REQUEST',
	SUCCESS: 'GET_LEAD_LIFECYCLE_DETAILS_SUCCESS',
	FAILURE: 'GET_LEAD_LIFECYCLE_DETAILS_FAILURE',
	RESET: 'GET_LEAD_LIFECYCLE_DETAILS_RESET'
}

export const GET_BIDDING_LIST = {
	REQUEST: 'GET_BIDDING_LIST_REQUEST',
	SUCCESS: 'GET_BIDDING_LIST_SUCCESS',
	FAILURE: 'GET_BIDDING_LIST_FAILURE',
	RESET: 'GET_BIDDING_LIST_RESET'
};

export const RUNNER_ACTIVE_STATUS = {
	REQUEST: 'RUNNER_ACTIVE_STATUS_REQUEST',
	SUCCESS: 'RUNNER_ACTIVE_STATUS_SUCCESS',
	FAILURE: 'RUNNER_ACTIVE_STATUS_FAILURE',
	RESET: 'RUNNER_ACTIVE_STATUS_RESET'
};

export const TRACK_RUNNER_LOCATION = {
	REQUEST: 'TRACK_RUNNER_LOCATION_REQUEST',
	SUCCESS: 'TRACK_RUNNER_LOCATION_SUCCESS',
	FAILURE: 'TRACK_RUNNER_LOCATION_FAILURE',
	RESET: 'TRACK_RUNNER_LOCATION_RESET'
};

export const ASSIGN_RUNNER_ACTION = {
	REQUEST: 'ASSIGN_RUNNER_REQUEST',
	SUCCESS: 'ASSIGN_RUNNER_SUCCESS',
	FAILURE: 'ASSIGN_RUNNER_FAILURE',
	RESET: 'ASSIGN_RUNNER_RESET'
};

export const VEHICLE_STATUS_ACTION = {
	REQUEST: 'VEHICLE_STATUS_REQUEST',
	SUCCESS: 'VEHICLE_STATUS_SUCCESS',
	FAILURE: 'VEHICLE_STATUS_FAILURE',
	RESET: 'VEHICLE_STATUS_RESET'
};

export const WAREHOUSE_DELIVERY_ACTION = {
	REQUEST: 'WAREHOUSE_DELIVERY_REQUEST',
	SUCCESS: 'WAREHOUSE_DELIVERY_SUCCESS',
	FAILURE: 'WAREHOUSE_DELIVERY_FAILURE',
	RESET: 'WAREHOUSE_DELIVERY_RESET'
};

export const CITIES_ACTION = {
	REQUEST: 'CITIES_REQUEST',
	SUCCESS: 'CITIES_SUCCESS',
	FAILURE: 'CITIES_FAILURE'
};

export const LOGISTICS_CITY_LIST = {
	REQUEST: 'LOGISTICS_CITY_LIST_REQUEST',
	SUCCESS: 'LOGISTICS_CITY_LIST_SUCCESS',
	FAILURE: 'LOGISTICS_CITY_LIST_FAILURE'
};

export const STORE_LIST_ACTION = {
	REQUEST: 'STORE_LIST_REQUEST',
	SUCCESS: 'STORE_LIST_SUCCESS',
	FAILURE: 'STORE_LIST_FAILURE',
	RESET: 'STORE_LIST_RESET'
};

export const INSPECTOR_LIST_ACTION = {
	REQUEST: 'INSPECTOR_LIST_REQUEST',
	SUCCESS: 'INSPECTOR_LIST_SUCCESS',
	FAILURE: 'INSPECTOR_LIST_FAILURE',
	RESET: 'INSPECTOR_LIST_RESET'
};

export const PUBLISH_INVENTORY_LIST_ACTION = {
	REQUEST: 'PUBLISH_INVENTORY_LIST_REQUEST',
	SUCCESS: 'PUBLISH_INVENTORY_LIST_SUCCESS',
	FAILURE: 'PUBLISH_INVENTORY_LIST_FAILURE',
	RESET: 'PUBLISH_INVENTORY_LIST_RESET'
};

export const NOTIFICATION_ACTION = {
	SET: 'NOTIFICATION_SET',
	CLEAR: 'NOTIFICATION_CLEAR'
};

export const STORES_ACTION = {
	REQUEST: 'STORES_ACTION_REQUEST',
	SUCCESS: 'STORES_ACTION_SUCCESS',
	FAILURE: 'STORES_ACTION_FAILURE',
	RESET: 'STORES_ACTION_RESET'
};

export const FHD_LIST_ACTION = {
	REQUEST: 'FHD_LIST_ACTION_REQUEST',
	SUCCESS: 'FHD_LIST_ACTION_SUCCESS',
	FAILURE: 'FHD_LIST_ACTION_FAILURE',
	RESET: 'FHD_LIST_ACTION_RESET'
};

export const FETCH_FRANCHISE_PROFILE = {
	BANNER_REQUEST: 'FETCH_FRANCHISE_PROFILE_BANNER_REQUEST',
	BANNER_SUCCESS: 'FETCH_FRANCHISE_PROFILE_BANNER_SUCCESS',
	AVATAR_SUCCESS: 'FETCH_FRANCHISE_PROFILE_AVATAR_SUCCESS',
	BANNER_FAILURE: 'FETCH_FRANCHISE_PROFILE_BANNER_FAILURE',

	FETCH_PROFILE_REQUEST: 'FETCH_FRANCHISE_PROFILE_FETCH_PROFILE_REQUEST',
	FETCH_PROFILE_SUCCESS: 'FETCH_FRANCHISE_PROFILE_FETCH_PROFILE_SUCCESS',
	FETCH_PROFILE_FAILURE: 'FETCH_FRANCHISE_PROFILE_FETCH_PROFILE_FAILURE',

	UPDATE_PROFILE_REQUEST: 'FETCH_FRANCHISE_PROFILE_UPDATE_PROFILE_REQUEST',
	UPDATE_PROFILE_SUCCESS: 'FETCH_FRANCHISE_PROFILE_UPDATE_PROFILE_SUCCESS',
	UPDATE_PROFILE_FAILURE: 'FETCH_FRANCHISE_PROFILE_UPDATE_PROFILE_FAILURE',

	UPDATE_PROFILE_IMAGES_REQUEST: 'FETCH_FRANCHISE_PROFILE_UPDATE_PROFILE_IMAGES_REQUEST',
	UPDATE_PROFILE_IMAGES_SUCCESS: 'FETCH_FRANCHISE_PROFILE_UPDATE_PROFILE_IMAGES_SUCCESS',
	UPDATE_PROFILE_IMAGES_FAILURE: 'FETCH_FRANCHISE_PROFILE_UPDATE_PROFILE_IMAGES_FAILURE',
}

export const VALUATOR_LIST_ACTION = {
	REQUEST: 'VALUATOR_LIST_REQUEST',
	SUCCESS: 'VALUATOR_LIST_SUCCESS',
	FAILURE: 'VALUATOR_LIST_FAILURE',
	RESET: 'VALUATOR_LIST_RESET'
};

export const GET_SHD_LIST = {
	REQUEST: 'GET_SHD_LIST_REQUEST',
	SUCCESS: 'GET_SHD_LIST_SUCCESS',
	FAILURE: 'GET_SHD_LIST_FAILURE',
	RESET: 'GET_SHD_LIST_RESET'
};
export const GET_DOCUMENTQC_LIST = {
	REQUEST: 'GET_DOCUMENTQC_LIST_REQUEST',
	SUCCESS: 'GET_DOCUMENTQC_LIST_SUCCESS',
	FAILURE: 'GET_DOCUMENTQC_LIST_FAILURE',
	RESET: 'GET_DOCUMENTQC_LIST_RESET'
};
export const GET_DOCUMENTQC_BIKEDOCS_LIST = {
	REQUEST: 'GET_DOCUMENTQC_BIKEDOCS_LIST_REQUEST',
	SUCCESS: 'GET_DOCUMENTQC_BIKEDOCS_LIST_SUCCESS',
	FAILURE: 'GET_DOCUMENTQC_BIKEDOCS_LIST_FAILURE',
	RESET: 'GET_DOCUMENTQC_BIKEDOCS_LIST_RESET'
};

export const FRANCHISE_LIVE_INVENTORY = {
	REQUEST: 'FRANCHISE_LIVE_INVENTORY_REQUEST',
	SUCCESS: 'FRANCHISE_LIVE_INVENTORY_SUCCESS',
	FAILURE: 'FRANCHISE_LIVE_INVENTORY_FAILURE',
	RESET: 'FRANCHISE_LIVE_INVENTORY_RESET'
};

export const PENDING_ASSIGN_INVENTORY = {
	REQUEST: 'PENDING_ASSIGN_INVENTORY_REQUEST',
	SUCCESS: 'PENDING_ASSIGN_INVENTORY_SUCCESS',
	FAILURE: 'PENDING_ASSIGN_INVENTORY_FAILURE',
	RESET: 'PENDING_ASSIGN_INVENTORY_RESET'
};
export const PINCODE_MASTER_DATA = {
	REQUEST: 'PINCODE_DATA_REQUEST',
	SUCCESS: 'PINCODE_DATA_SUCCESS',
	FAILURE: 'PINCODE_DATA_FAILURE',
	RESET: 'PINCODE_DATA_RESET'
};
export const PINCODE_GET_CITIES_MASTER_DATA = {
	REQUEST: 'PINCODE_GET_CITIES_DATA_REQUEST',
	SUCCESS: 'PINCODE_GET_CITIES_DATA_SUCCESS',
	FAILURE: 'PINCODE_GET_CITIES_DATA_FAILURE',
	RESET: 'PINCODE_GET_CITIES_DATA_RESET'
};
export const PINCODE_GET_UPDATE_HISTORY_MASTER_DATA = {
	REQUEST: 'PINCODE_GET_UPDATE_DATA_REQUEST',
	SUCCESS: 'PINCODE_GET_UPDATE_DATA_SUCCESS',
	FAILURE: 'PINCODE_GET_UPDATE_DATA_FAILURE',
	RESET: 'PINCODE_GET_UPDATE_DATA_RESET'
};

export const FRANCHISE_STORES = {
	REQUEST: 'FRANCHISE_STORES_REQUEST',
	SUCCESS: 'FRANCHISE_STORES_SUCCESS',
	FAILURE: 'FRANCHISE_STORES_FAILURE',
	RESET: 'FRANCHISE_STORES_RESET'
};

export const FRANCHISE_CITY_LIST = {
	REQUEST: 'FRANCHISE_CITY_LIST_REQUEST',
	SUCCESS: 'FRANCHISE_CITY_LIST_SUCCESS',
	FAILURE: 'FRANCHISE_CITY_LIST_FAILURE',
	RESET: 'FRANCHISE_CITY_LIST_RESET'
};

export const FRANCHISE_STORE_LEADS = {
	REQUEST: 'FRANCHISE_STORE_LEADS_REQUEST',
	SUCCESS: 'FRANCHISE_STORE_LEADS_SUCCESS',
	FAILURE: 'FRANCHISE_STORE_LEADS_FAILURE',
	RESET: 'FRANCHISE_STORE_LEADS_RESET'
};

export const FRANCHISE_SALES = {
	REQUEST: 'FRANCHISE_SALES_REQUEST',
	SUCCESS: 'FRANCHISE_SALES_SUCCESS',
	FAILURE: 'FRANCHISE_SALES_FAILURE',
	RESET: 'FRANCHISE_SALES_RESET'
};

export const POSTSALES_DOCUMENTATION_LEADS = {
	REQUEST: 'POSTSALES_DOCUMENTATION_LEADS_REQUEST',
	SUCCESS: 'POSTSALES_DOCUMENTATION_LEADS_SUCCESS',
	FAILURE: 'POSTSALES_DOCUMENTATION_LEADS_FAILURE',
	RESET: 'POSTSALES_DOCUMENTATION_LEADS_RESET'
};

export const FRANCHISE_ASSIGN = {
	REQUEST: 'FRANCHISE_ASSIGN_REQUEST',
	SUCCESS: 'FRANCHISE_ASSIGN_SUCCESS',
	FAILURE: 'FRANCHISE_ASSIGN_FAILURE',
	RESET: 'FRANCHISE_ASSIGN_RESET'
};

export const FRANCHISE_PAYMENT_LIST = {
	REQUEST: 'FRANCHISE_PAYMENT_LIST_REQUEST',
	SUCCESS: 'FRANCHISE_PAYMENT_LIST_SUCCESS',
	FAILURE: 'FRANCHISE_PAYMENT_LIST_FAILURE',
	RESET: 'FRANCHISE_PAYMENT_LIST_RESET'
};

export const CONVERSIONAL_TAT_RESULT = {
	REQUEST: 'CONVERSIONAL_TAT_RESULT_REQUEST',
	SUCCESS: 'CONVERSIONAL_TAT_RESULT_SUCCESS',
	FAILURE: 'CONVERSIONAL_TAT_RESULT_FAILURE'
};

export const PRICING_RESULT = {
	REQUEST: 'PRICING_RESULT_REQUEST',
	SUCCESS: 'PRICING_RESULT_SUCCESS',
	FAILURE: 'PRICING_RESULT_FAILURE'
};

export const OUTLET_RESULT = {
	REQUEST: 'OUTLET_RESULT_REQUEST',
	SUCCESS: 'OUTLET_RESULT_SUCCESS',
	FAILURE: 'OUTLET_RESULT_FAILURE'
};

export const VALUATOR_RESULT = {
	REQUEST: 'VALUATOR_RESULT_REQUEST',
	SUCCESS: 'VALUATOR_RESULT_SUCCESS',
	FAILURE: 'VALUATOR_RESULT_FAILURE'
};

export const VALUATOR_LIST = {
	REQUEST: 'VALUATOR_LIST_PD_REQUEST',
	SUCCESS: 'VALUATOR_LIST_PD_SUCCESS',
	FAILURE: 'VALUATOR_LIST_PD_FAILURE'
};
export const VALUATOR_DATA = {
	REQUEST: 'VALUATOR_DATA_PD_REQUEST',
	SUCCESS: 'VALUATOR_DATA_PD_SUCCESS',
	FAILURE: 'VALUATOR_DATA_PD_FAILURE'
};

export const FRANCHISE_ASSIGN_RUNNER_LIST_ACTION = {
	REQUEST: 'FRANCHISE_ASSIGN_RUNNER_LIST_REQUEST',
	SUCCESS: 'FRANCHISE_ASSIGN_RUNNER_LIST_SUCCESS',
	FAILURE: 'FRANCHISE_ASSIGN_RUNNER_LIST_FAILURE',
	RESET: 'FRANCHISE_ASSIGN_RUNNER_LIST_RESET'
};

export const FRANCHISE_WAREHOUSE_DELIVERY_ACTION = {
	REQUEST: 'FRANCHISE_WAREHOUSE_DELIVERY_ACTION_REQUEST',
	SUCCESS: 'FRANCHISE_WAREHOUSE_DELIVERY_ACTION_SUCCESS',
	FAILURE: 'FRANCHISE_WAREHOUSE_DELIVERY_ACTION_FAILURE',
	RESET: 'FRANCHISE_WAREHOUSE_DELIVERY_ACTION_RESET'
};

export const GET_SPM_DATA = {
	REQUEST: 'GET_SPM_DATA_REQUEST',
	SUCCESS: 'GET_SPM_DATA_SUCCESS',
	FAILURE: 'GET_SPM_DATA_FAILURE'
};

export const GET_ALL_MMV = {
	REQUEST: 'GET_ALL_MMV_REQUEST',
	SUCCESS: 'GET_ALL_MMV_SUCCESS',
	FAILURE: 'GET_ALL_MMV_FAILURE'
};

export const GET_LIVE_INVENTORY_UNIT = {
	REQUEST: 'LIVE_INVENTORY_UNIT_REQUEST',
	SUCCESS: 'LIVE_INVENTORY_UNIT_SUCCESS',
	FAILURE: 'LIVE_INVENTORY_UNIT_FAILURE'
};

export const GET_INVENTORY_AGGREGATE = {
	REQUEST: 'GET_INVENTORY_REQUEST',
	SUCCESS: 'GET_INVENTORY_SUCCESS',
	FAILURE: 'GET_INVENTORY_FAILURE'
};

export const GET_SPAREPARTS_ASSIGNMENT = {
	REQUEST: 'GET_ASSIGNMENT_REQUEST',
	SUCCESS: 'GET_ASSIGNMENT_SUCCESS',
	FAILURE: 'GET_ASSIGNMENT_FAILURE',

	SET_WAREHOUSE_ID: 'SET_WAREHOUSE_ID',
	SET_SERVICE_CENTER_NAME: 'SET_SERVICE_CENTER_NAME'
};

export const FRANCHISE_VEHICLE_STATUS_ACTION = {
	REQUEST: 'FRANCHISE_VEHICLE_STATUS_ACTION_REQUEST',
	SUCCESS: 'FRANCHISE_VEHICLE_STATUS_ACTION_SUCCESS',
	FAILURE: 'FRANCHISE_VEHICLE_STATUS_ACTION_FAILURE',
	RESET: 'FRANCHISE_VEHICLE_STATUS_ACTION_RESET'
};

export const GET_PARTS_REQUIREMENT = {
	REQUEST: 'GET_PARTS_REQUEST',
	SUCCESS: 'GET_PARTS_SUCCESS',
	FAILURE: 'GET_PARTS_FAILURE'
};

export const GET_REQUISITION_AGGREGATE = {
	REQUEST: 'GET_AGGREGATE_REQUEST',
	SUCCESS: 'GET_AGGREGATE_SUCCESS',
	FAILURE: 'GET_AGGREGATE_FAILURE'
};

export const GET_PARTS_ORDER_HISTORY = {
	REQUEST: 'GET_ORDER_HISTORY_REQUEST',
	SUCCESS: 'GET_ORDER_HISTORY_SUCCESS',
	FAILURE: 'GET_ORDER_HISTORY_FAILURE'
};

export const REFURB_DATA = {
	REQUEST: 'REFURB_DATA_PD_REQUEST',
	SUCCESS: 'REFURB_DATA_PD_SUCCESS',
	FAILURE: 'REFURB_DATA_PD_FAILURE'
};

export const GET_VENDOR_MANAGEMENT = {
	REQUEST: 'GET_VENDOR_REQUEST',
	SUCCESS: 'GET_VENDOR_SUCCESS',
	FAILURE: 'GET_VENDOR_FAILURE'
};

export const GET_PAYMENT_SPM = {
	REQUEST: 'GET_PAYMENT_SPM_REQUEST',
	SUCCESS: 'GET_PAYMENT_SPM_SUCCESS',
	FAILURE: 'GET_PAYMENT_SPM_FAILURE'
};

export const GET_BIKE_PRIORITY = {
	REQUEST: 'GET_BIKE_PRIORITY_REQUEST',
	SUCCESS: 'GET_BIKE_PRIORITY_SUCCESS',
	FAILURE: 'GET_BIKE_PRIORITY_FAILURE'
};

export const GET_BIKE_PRIORITY_BY_FILTER = {
	REQUEST: 'GET_BIKE_PRIORITY_BY_FILTER_REQUEST',
	SUCCESS: 'GET_BIKE_PRIORITY_BY_FILTER_SUCCESS',
	FAILURE: 'GET_BIKE_PRIORITY_BY_FILTER_FAILURE'
};

export const VALUATOR_DASHBOARD = {
	REQUEST: 'VALUATOR_DASHBOARD_REQUEST',
	SUCCESS: 'VALUATOR_DASHBOARD_SUCCESS',
	FAILURE: 'VALUATOR_DASHBOARD_FAILURE'
};

export const GET_BUFFER_PRICE_ONLINE = {
	REQUEST: 'GET_BUFFER_PRICE_REQUEST',
	SUCCESS: 'GET_BUFFER_PRICE_SUCCESS',
	FAILURE: 'GET_BUFFER_PRICE_FAILURE',
	RESET: 'GET_BUFFER_PRICE_RESET'
};

// SALE STORE LEADS DASHBOARD ACTIONS

// Upcoming sales
export const GET_VISIT_LEADS_BY_STORE_ID = {
	REQUEST: 'GET_VISIT_LEADS_BY_STORE_ID_REQUEST',
	SUCCESS: 'GET_VISIT_LEADS_BY_STORE_ID_SUCCESS',
	FAILURE: 'GET_VISIT_LEADS_BY_STORE_ID_FAILURE',
	RESET: 'GET_VISIT_LEADS_BY_STORE_ID_RESET'
};

export const GET_PAPER_TRANSFER = {
	REQUEST: 'GET_PAPER_TRANSFER_REQUEST',
	SUCCESS: 'GET_PAPER_TRANSFER_SUCCESS',
	FAILURE: 'GET_PAPER_TRANSFER_FAILURE',
	RESET: 'GET_PAPER_TRANSFER_RESET'
};

// FSM - FRANCHISE_STORE_MANAGER
export const FETCH_FSM_RETURNS = {
	REQUEST: 'FETCH_FSM_RETURNS_REQUEST',
	SUCCESS: 'FETCH_FSM_RETURNS_SUCCESS',
	FAILURE: 'FETCH_FSM_RETURNS_FAILURE',

	CREATE_RETURN_REQ_SUCCESS: 'CREATE_RETURN_REQ_SUCCESS',

	RESET: 'FETCH_FSM_RETURNS_RESET'
};

export const FETCH_FSM_PENDING_RETURNS = {
	REQUEST: 'FETCH_FSM_PENDING_RETURNS_REQUEST',
	SUCCESS: 'FETCH_FSM_PENDING_RETURNS_SUCCESS',
	FAILURE: 'FETCH_FSM_PENDING_RETURNS_FAILURE',

	UPDATE_RETURN_REQUEST: 'UPDATE_RETURN_REQUEST',

	RESET: 'FETCH_FSM_PENDING_RETURNS_RESET'
};

export const SHD_COMMISSION = {
	REQUEST: 'SHD_COMMISSION_REQUEST',
	SUCCESS: 'SHD_COMMISSION_SUCCESS',
	FAILURE: 'SHD_COMMISSION_FAILURE',
	RESET: 'SHD_COMMISSION_RESET'
};

export const SHD_ORDER_DEDUCTIONS = {
	REQUEST: 'SHD_ORDER_DEDUCTIONS_REQUEST',
	SUCCESS: 'SHD_ORDER_DEDUCTIONS_SUCCESS',
	FAILURE: 'SHD_ORDER_DEDUCTIONS_FAILURE',
	RESET: 'SHD_ORDER_DEDUCTIONS_RESET'
}

export const SHD_CUSTOMER_DOCUMENNTS = {
	REQUEST: 'SHD_CUSTOMER_DOCUMENNTS_REQUEST',
	SUCCESS: 'SHD_CUSTOMER_DOCUMENNTS_SUCCESS',
	FAILURE: 'SHD_CUSTOMER_DOCUMENNTS_FAILURE',
	RESET: 'SHD_CUSTOMER_DOCUMENNTS_RESET'
}

export const SHOWROOM_LEADS = {
	REQUEST: 'SHOWROOM_LEADS_REQUEST',
	SUCCESS: 'SHOWROOM_LEADS_SUCCESS',
	FAILURE: 'SHOWROOM_LEADS_FAILURE',
	RESET: 'SHOWROOM_LEADS_RESET'
};

export const SHOWROOM_SALES = {
	REQUEST: 'SHOWROOM_SALES_REQUEST',
	SUCCESS: 'SHOWROOM_SALES_SUCCESS',
	FAILURE: 'SHOWROOM_SALES_FAILURE',
	RESET: 'SHOWROOM_SALES_RESET'
};

export const PAYMENTS_HISTORY = {
	REQUEST: 'PAYMENTS_HISTORY_REQUEST',
	SUCCESS: 'PAYMENTS_HISTORY_SUCCESS',
	FAILURE: 'PAYMENTS_HISTORY_FAILURE',
	RESET: 'PAYMENTS_HISTORY_RESET'
}

export const SHOWROOM_PAYMENTS_HISTORY = {
	REQUEST: 'SHOWROOM_PAYMENTS_HISTORY_REQUEST',
	SUCCESS: 'SHOWROOM_PAYMENTS_HISTORY_SUCCESS',
	FAILURE: 'SHOWROOM_PAYMENTS_HISTORY_FAILURE',
	RESET: 'SHOWROOM_PAYMENTS_HISTORY_RESET'
};

export const FETCH_FRANCHISE_RETURNS_LIST = {
	REQUEST: 'FETCH_FRANCHISE_RETURNS_LIST_REQUEST',
	SUCCESS: 'FETCH_FRANCHISE_RETURNS_LIST_SUCCESS',

  APPROVE_RETURN: 'FETCH_FRANCHISE_APPROVE_RETURN',
  REJECT_RETURN: 'FETCH_FRANCHISE_REJECT_RETURN',

	FAILURE: 'FETCH_FRANCHISE_RETURNS_LIST_FAILURE',
};

export const ASSIGN_RUNNER_NEW = {
	GET_ALL_VEHICLES_REQUEST: 'GET_ALL_VEHICLES_REQUEST',
	GET_ALL_VEHICLES_SUCCESS: 'GET_ALL_VEHICLES_SUCCESS',
	GET_ALL_VEHICLES_FAILURE: 'GET_ALL_VEHICLES_FAILURE',
}

export const PENDING_RETURN_INVENTORY = {
	REQUEST: 'PENDING_RETURN_INVENTORY_REQUEST',
	SUCCESS: 'PENDING_RETURN_INVENTORY_SUCCESS',
	FAILURE: 'PENDING_RETURN_INVENTORY_FAILURE',
	RESET: 'PENDING_RETURN_INVENTORY_RESET'
};

export const PRIMARY_SALES_ACTION_CMS = {
	REQUEST: 'PRIMARY_SALES_ACTION_CMS_REQUEST',
	SUCCESS: 'PRIMARY_SALES_ACTION_CMS_SUCCESS',
	FAILURE: 'PRIMARY_SALES_ACTION_CMS_FAILURE',
	RESET: 'PRIMARY_SALES_ACTION_CMS_RESET'
};

export const PRIMARY_SALES_ACTION_FRANCHISE = {
	REQUEST: 'PRIMARY_SALES_ACTION_FRANCHISE_REQUEST',
	SUCCESS: 'PRIMARY_SALES_ACTION_FRANCHISE_SUCCESS',
	FAILURE: 'PRIMARY_SALES_ACTION_FRANCHISE_FAILURE',
	RESET: 'PRIMARY_SALES_ACTION_FRANCHISE_RESET'
};

export const PRIMARY_SALES_ACTION_STORE = {
	REQUEST: 'PRIMARY_SALES_ACTION_STORE_REQUEST',
	SUCCESS: 'PRIMARY_SALES_ACTION_STORE_SUCCESS',
	FAILURE: 'PRIMARY_SALES_ACTION_STORE_FAILURE',
	RESET: 'PRIMARY_SALES_ACTION_STORE_RESET'
};

export const SECONDARY_SALES_FRANCHISE = {
	REQUEST: 'SECONDARY_SALES_FRANCHISE_REQUEST',
	SUCCESS: 'SECONDARY_SALES_FRANCHISE_SUCCESS',
	FAILURE: 'SECONDARY_SALES_FRANCHISE_FAILURE',
	RESET: 'SECONDARY_SALES_FRANCHISE_RESET'
};


export const QUOTE_CONFIRMATION = {
	REQUEST: 'QUOTE_CONFIRMATION_REQUEST',
	SUCCESS: 'QUOTE_CONFIRMATION_SUCCESS',
	FAILURE: 'QUOTE_CONFIRMATION_FAILURE',
	RESET: 'QUOTE_CONFIRMATION_RESET'
};
export const C2C = {
	REQUEST: 'C2C_REQUEST',
	SUCCESS: 'C2C_SUCCESS',
	FAILURE: 'C2C_FAILURE',
	RESET: 'C2C_RESET'
};

export const LOGISTICS_IN_CUSTODY = {
	REQUEST: 'LOGISTICS_IN_CUSTODY_REQUEST',
	SUCCESS: 'LOGISTICS_IN_CUSTODY_SUCCESS',
	FAILURE: 'LOGISTICS_IN_CUSTODY_FAILURE',
	RESET: 'LOGISTICS_IN_CUSTODY_RESET'
}

export const COMMISSION_DATA = {
	SET: 'COMMISSION_DATA_SET',
	CLEAR: 'COMMISSION_DATA_CLEAR'
}

export const STORE_WALLET_LIST = {
	REQUEST: 'STORE_WALLET_LIST_REQUEST',
	SUCCESS: 'STORE_WALLET_LIST_SUCCESS',
	FAILURE: 'STORE_WALLET_LIST_FAILURE',
	RESET: 'STORE_WALLET_LIST_RESET'
  }

  export const SHD_TRANSACTIONS_LIST = {
	REQUEST: 'SHD_TRANSACTIONS_LIST_REQUEST',
	SUCCESS: 'SHD_TRANSACTIONS_LIST_SUCCESS',
	FAILURE: 'SHD_TRANSACTIONS_LIST_FAILURE',
	RESET: 'SHD_TRANSACTIONS_LIST_RESET'
  };

  export const ROLE_LIST = {
	REQUEST: 'ROLE_LIST_REQUEST',
	SUCCESS: 'ROLE_LIST_SUCCESS',
	FAILURE: 'ROLE_LIST_FAILURE',
	RESET: 'ROLE_LIST_RESET'
  };

  export const DOC_COLLECTOR_LIST = {
	REQUEST: 'DOC_COLLECTOR_LIST_REQUEST',
	SUCCESS: 'DOC_COLLECTOR_LIST_SUCCESS',
	FAILURE: 'DOC_COLLECTOR_LIST_FAILURE',
	RESET: 'DOC_COLLECTOR_LIST_RESET'
}
  export const FRANCHISE_WISH_LIST = {
	REQUEST: 'FRANCHISE_WISH_LIST_REQUEST',
	SUCCESS: 'FRANCHISE_WISH_LIST_SUCCESS',
	FAILURE: 'FRANCHISE_WISH_LIST_FAILURE',
	RESET: 'FRANCHISE_WISH_LIST_RESET'
  };

  export const AUTO_INSPECTION_AEs = {
	REQUEST: 'AUTO_INSPECTION_AEs_REQUEST',
	SUCCESS: 'AUTO_INSPECTION_AEs_SUCCESS',
	FAILURE: 'AUTO_INSPECTION_AEs_FAILURE',
	RESET: 'AUTO_INSPECTION_AEs_RESET'
};

export const LOGISTICS_RUNNER_DETAILS = {
	REQUEST: 'LOGISTICS_RUNNER_DETAILS_REQUEST',
	SUCCESS: 'LOGISTICS_RUNNER_DETAILS_SUCCESS',
	FAILURE: 'LOGISTICS_RUNNER_DETAILS_FAILURE',
	RESET: 'LOGISTICS_RUNNER_DETAILS_RESET'
};


export const AE_TASKS = {
	REQUEST: 'AE_TASKS_REQUEST',
	SUCCESS: 'AE_TASKS_SUCCESS',
	FAILURE: 'AE_TASKS_FAILURE'
};

export const SIGNUP_AE_DETAILS = {
	REQUEST: 'SIGNUP_AE_DETAILS_REQUEST',
	SUCCESS: 'SIGNUP_AE_DETAILS_SUCCESS',
	FAILURE: 'SIGNUP_AE_DETAILS_FAILURE',
	RESET: 'SIGNUP_AE_DETAILS_RESET'
};
export const AE_USER_MGMT_DETAILS= {
	REQUEST: 'AE_USER_MGMT_DETAILS_REQUEST',
	SUCCESS: 'AE_USER_MGMT_DETAILS_SUCCESS',
	FAILURE: 'AE_USER_MGMT_DETAILS_FAILURE',
	RESET: 'AE_USER_MGMT_DETAILS_RESET'
};
export const BAN_UNBAN_AE_DETAILS= {
	REQUEST: 'BAN_UNBAN_AE_DETAILS_REQUEST',
	SUCCESS: 'BAN_UNBAN_AE_DETAILS_SUCCESS',
	FAILURE: 'BAN_UNBAN_AE_DETAILS_FAILURE',
	RESET: 'BAN_UNBAN_AE_DETAILS_RESET'
};

export const AE_OFFER_DETAILS= {
	REQUEST: 'AE_OFFER_DETAILS_REQUEST',
	SUCCESS: 'AE_OFFER_DETAILS_DETAILS_SUCCESS',
	FAILURE: 'AE_OFFER_DETAILS_FAILURE',
	RESET: 'AE_OFFER_DETAILS_RESET'
};

export const SIGNUP_AE_APPROVE_REJECT = {
	REQUEST: 'SIGNUP_AE_APPROVE_REJECT_REQUEST',
	SUCCESS: 'SIGNUP_AE_APPROVE_REJECT_SUCCESS',
	FAILURE: 'SIGNUP_AE_APPROVE_REJECT_FAILURE',
	RESET: 'SIGNUP_AE_APPROVE_REJECT_RESET'
};

export const SIGNUP_AE_REJECT_REASONS = {
	REQUEST: 'SIGNUP_AE_REJECT_REASONS_REQUEST',
	SUCCESS: 'SIGNUP_AE_REJECT_REASONS_SUCCESS',
	FAILURE: 'SIGNUP_AE_REJECT_REASONS_FAILURE',
	RESET: 'SIGNUP_AE_REJECT_REASONS_RESET'
};

export const PINCODE_WISE_VIEW = {
	REQUEST: 'PINCODE_WISE_VIEW_REQUEST',
	SUCCESS: 'PINCODE_WISE_VIEW_SUCCESS',
	FAILURE: 'PINCODE_WISE_VIEW_FAILURE',
	RESET: 'PINCODE_WISE_VIEW_RESET'
};

export const SET_LOADER = 'SET_LOADER'
