import React from "react"
import "./index.scss"

const DIYLabel=(props)=>{
    const {status, showPremium}=props
    // const randomStatus=["DIY","ADIY","OEM"][Math.floor(Math.random()*3)]
    return(
        <>
        {
            status === 'OEM_ADIY' ? 
            <p className={`diy-tag ${status==="-"?"random":status}`}>{status === 'CP_DIY_PUBLISH' ? 'B2B' : status}</p>
           : 'NA'
        }
        {
            showPremium && <p className="statusPremium"> CredR Premium</p>
        } 
    </>)
}

export default DIYLabel