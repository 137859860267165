// export function getDate(date) {
//     if (date && moment(date).isValid()) {
//       const time = moment(date).format('hh:mm A')
//       const datefield = moment(date).format('DD-MMM-YYYY')
//       return `${datefield}\n${time}`
//     }
//     return '-'
//   }
import React from "react";

export const getStatusColor = (value) => {
    let bgColor="";
    let fontColor="";
    switch (value) {
        case "Under_Negotitation":
            bgColor="#FFE1C5";
            fontColor="#B96716";
            break;
        case "Deal_Locked":
            bgColor="#DAF2DD";
            fontColor="#196834";
            break;
        case "Dropped":
            bgColor="#FFD4D6";
            fontColor="#DA2128";
            break;
        case "C2C_Transfer":
            bgColor="#E9F4FF";
            fontColor="#366896";
            break; 
        case "Store_Transfer":
            bgColor="#E9F4FF";
            fontColor="#366896";
            break;
        case "new":
            bgColor="#fcfbf7";
            fontColor="#e8a813";
            break;
        default:
            bgColor="#FFE1C5";
            fontColor="#B96716";
            break;
    }

    
        return (
            <span
              style={{
                backgroundColor: bgColor,
                color: fontColor,
                padding: "1px 15px",
                borderRadius: "4px",
                fontWeight: "500",
              }}
            >
              {value}
            </span>
          );
    
  };


export const getPaymentStatusColor = (lifecycleStatus) => {
    if (lifecycleStatus === "APPROVED" || lifecycleStatus === "COMPLETED") {
      return (
        <span
          style={{
            backgroundColor: "#DAF2DD",
            color: "#196834",
            padding: "1px 15px",
            borderRadius: "4px",
            fontWeight: "500",
          }}
        >
          Approved
        </span>
      );
    }
    if (lifecycleStatus === "PENDING") {
      return (
        <span
          style={{
            backgroundColor: "#FFE1C5",
            color: "#B96716",
            padding: "1px 15px",
            borderRadius: "4px",
            fontWeight: "500",
          }}
        >
          Pending
        </span>
      );
    }
    if (lifecycleStatus === "REJECTED") {
      return (
        <span
          style={{
            backgroundColor: "#FFD4D6",
            color: "#DA2128",
            padding: "1px 15px",
            borderRadius: "4px",
            fontWeight: "500",
          }}
        >
          Rejected
        </span>
      );
    }
    if (lifecycleStatus === "SELL") {
      return (
        <span
          style={{
            backgroundColor: "#DAF2DD",
            color: "#196834",
            padding: "1px 15px",
            borderRadius: "4px",
            fontWeight: "500",
          }}
        >
          Sell
        </span>
      );
    }
    if (lifecycleStatus === "EXCHANGE") {
      return (
        <span
          style={{
            backgroundColor: "#DAF2DD",
            color: "#196834",
            padding: "1px 15px",
            borderRadius: "4px",
            fontWeight: "500",
          }}
        >
          Exchange
        </span>
      );
    }
    if (lifecycleStatus === "ONGOING") {
      return (
        <span
          style={{
            backgroundColor: "#E9F4FF",
            color: "#366896",
            padding: "1px 15px",
            borderRadius: "4px",
            fontWeight: "500",
          }}
        >
          {" "}
          Ongoing{" "}
        </span>
      );
    } else {
      return (
        <span
          style={{
            backgroundColor: "#E9F4FF",
            color: "#366896",
            padding: "1px 15px",
            borderRadius: "4px",
            fontWeight: "500",
          }}
        >
          {" "}
          {lifecycleStatus}{" "}
        </span>
      );
    }
  };
