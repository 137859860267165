import React, { useEffect, Fragment, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import ConfirmationTable from './components/Table'
import { getC2CList } from '../../redux/actions/c2cAction'
import { getUserID } from '../../core/services/rbacServices'
import { getAgentsList } from '../../core/services/shdServices'
import Filter from './components/Filters'
import moment from 'moment'
import { setNotification } from '../../redux/actions/notificationAction'
import isEqual from 'lodash/isEqual'
import Header from './components/Header'
import { useSelector } from 'react-redux'
import { getCityData } from '../../core/services/rbacServices'
import { getValuatorDetails } from '../../core/services/biddingServices'
import { hideLoader, showLoader } from '../../redux/actions/loaderAction'
import { getAmount } from '../../core/utility'
import { getExceptionList, listStoresFhdShd } from '../../core/services/miscServices'
import { CallIconGreen } from '../../core/utility/iconHelper'
import { getBuyerStatus } from '../../core/services/C2cServices'
const C2C = () => {

  const [status, setStatus] = useState('UNASSIGNED')
  const [startTime, setStartTime] = useState(null)
  const [endTime, setEndTime] = useState(null)
  const [filters, setFilters] = useState({})
  const [city, setCity] = useState('')
  const [isFilterLoading, setIsFilterLoading] = useState(false)
  const [searchText, setSearchtext] = useState('')
  const [isResetRequired, setIsResetRequired] = useState(false)
  const [isRegistrationSearch, setIsRegistrationSearch] = useState(false)
  const currentPage = useSelector((state) => state.c2cList.pageNum)
  const confirmList = useSelector((state) => state.c2cList.c2cList)
  const totalCount = useSelector((state) => state.c2cList.count)
  const [dateType, setDateType] = useState('')
  const [agent, setAgent] = useState('')
  const [agentList, setAgentList] = useState([])
  const [appliedAgent, setAppliedAgent] = useState(false)
  const [onGoingStatus, setOnGoingStatus] = useState(false)
  const [filterStatus, setFilterStatus] = useState('')
  const [onGoingStatusType, setOnGoingStatustype] = useState('')
  const [clearFilter, setClearFilter] = useState(0)

  const [cp, setCp] = useState(null)
  const [logisticsStatus, setLogisticsStatus] = useState(null)
  const [docQcStatus, setDocQcStatus] = useState(null)
  const [customerPayoutStatus, setCustomerPayoutStatus] = useState(null)
  const [cpPaymentStatus, setCpPaymentStatus] = useState(null)
  const [inCustodyStatus, setInCustodyStatus] = useState(null)
  const [cpVsStore, setCpVsStore] = useState(null)
  const [dateTypeFilter, setDateTypeFilter] = useState(null)

  const [cpDrop, setCpDrop] = useState(false)
  const [logisticsStatusDrop, setLogisticsStatusDrop] = useState(false)
  const [docQcStatusDrop, setDocQcStatusDrop] = useState(false)
  const [customerPayoutStatusDrop, setCustomerPayoutStatusDrop] = useState(false)
  const [cpPaymentStatusDrop, setCpPaymentStatusDrop] = useState(false)
  const [inCustodyStatusDrop, setInCustodyStatusDrop] = useState(false)
  const [cpVsStoreDrop, setCpVsStoreDrop] = useState(false)
  const [dateTypeFilterDrop, setDateTypeFilterDrop] = useState(false)

  const [cpList, setCpList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [advancedFilterCount, setAdvancedFilterCount] = useState(0)
  const [diy, setDiy] = useState(null)
  const [outletList, setOutletList] = useState([])
  const [selectedOutlet, setSelectedOutlet] = useState(null)
  const [statusList,setStatusList]=useState([])
  const [priorityFilter,setPriorityFilter]=useState(false)
  
  const getTabValue = (stat) => {
    if (stat === 'SOLD') return 'Commission'
    else return 'Lead Details'
  }
  const tabValue = getTabValue(status)
  const [value, setValue] = useState('')

  const handleValue = (newVal) => {
    setValue(newVal)
  }
  const dispatch = useDispatch()

  const cities = useSelector((state) => state.cities.cityList)

  const usersCity = getCityData().split(',').map((val) => val)

  const finalCityList = cities.filter(item => usersCity.includes(item.cityId));

  const cityList = finalCityList.map(city => {
    return {
      value: city.cityId,
      label: city.cityName
    }
  })

  const handlePageChange = (pageNumber) => {
    let filPayload = {}
    if (searchText) {
      if (filterStatus) {
        filPayload.filterType = filterStatus.value
      }
      if (agent) {
        dispatch(getC2CList({ ...filters, pageNum: pageNumber, userId: agent.value, status, searchString: searchText, ...filPayload }))
      }
      else
        dispatch(getC2CList({ ...filters, pageNum: pageNumber, userId: getUserID(), status, searchString: searchText, ...filPayload }))
    }
    else {
      if (filterStatus) {
        filPayload.filterType = filterStatus.value
      }
      if (agent) {
        dispatch(getC2CList({ ...filters, pageNum: pageNumber, userId: agent.value, status, ...filPayload }))
      }
      else
        dispatch(getC2CList({ ...filters, pageNum: pageNumber, userId: getUserID(), status, ...filPayload }))
    }
  }

  useEffect(() => {
    setAdvancedFilterCount(getAdvancedFilterCount())
  }, [selectedOption, logisticsStatus, docQcStatus, customerPayoutStatus, cpPaymentStatus, inCustodyStatus, cpVsStore])

  //   useEffect(()=>{
  //     refreshPage()
  // },[filters])


  useEffect(() => {
    const payload = {
      status: 'UNASSIGNED',
      pageNum: 1,
      userId: getUserID()
    }
    dispatch(getC2CList(payload))
    getAgentsList().
      then(agentListResponse => {
        if (agentListResponse.isValid) {
          let agents = agentListResponse.commissionUsers.map(agt => {
            return {
              value: agt.agentId,
              label: agt.agentName
            }
          })
          setAgentList(agents)
        }
      })

      getBuyerStatus()
      .then(apiRes => {
        if (apiRes.isValid) {
            const reasonList = apiRes.buyersStatusList.map((item) => {
                return {
                    value: item.status,
                    label: item.status
                }
            })
            setStatusList(reasonList)
        }
    })

  }, [])

  const getAdvancedFilterCount = () => {
    const filteritems = [selectedOption, logisticsStatus, docQcStatus, customerPayoutStatus, cpPaymentStatus, inCustodyStatus, cpVsStore]
    return filteritems.filter((item) => (item)).length
  }

  const getPayload = () => {
    const payload = {}
    if (dateType) {
      payload.dateType = dateType.value
    }
    if (endTime) {
      payload.toDate = moment(endTime).format("YYYY-MM-DD")
    }
    if (startTime) {
      payload.fromDate = moment(startTime).format("YYYY-MM-DD")
    }
    if (status) {
      payload.status = status
    }
    if (city) {
      payload.cityId = city.value
    }
    if (agent) {
      payload.userId = agent.value
    }
    if (filterStatus)
      payload.filterType = filterStatus.value

    if (agent)
      payload.userId = agent.value

    if (logisticsStatus)
      payload.logisticStatus = logisticsStatus

    if (docQcStatus)
      payload.docQc = docQcStatus

    if (customerPayoutStatus)
      payload.cusPayoutStatus = customerPayoutStatus

    if (cpPaymentStatus)
      payload.cpPaymentStatus = cpPaymentStatus

    if (inCustodyStatus)
      payload.inCusStatus = inCustodyStatus

    if (cpVsStore)
      payload.cpAndStoreSellStatus = cpVsStore
    if (selectedOption)
      payload.storeId = selectedOption.storeId
    if (diy) {
      payload.diyType = diy.value
    }
    if (selectedOutlet) {
      payload.storeId = selectedOutlet.value
    }
    if(priorityFilter)
      payload.premiumAccess=priorityFilter

    return payload
  }

  const resetFilters = () => {
    const { ...rest } = filters
    setStartTime(null)
    setEndTime(null)
    setCity('')
    setDateType('')
    setFilters({})
    setAgent('')
    setAppliedAgent(false)
    setFilterStatus('')
    setLogisticsStatus(null)
    setDocQcStatus(null)
    setCustomerPayoutStatus(null)
    setCpPaymentStatus(null)
    setInCustodyStatus(null)
    setCpVsStore(null)
    setDateTypeFilter(null)
    setSelectedOption(null)
    setStartDate(null)
    setEndDate(null)
    setAdvancedFilterCount(0)
    setDiy(null)
    setSelectedOutlet(null)
    setPriorityFilter(false)

    if (Object.keys(rest).length) {
      const payload = {
        status: status,
        pageNum: 1,
        userId: getUserID()
      }
      dispatch(getC2CList(payload))
    }

    // refreshPage()
  }

  const handleClearFilters = () => {
    setClearFilter(clearFilter + 1)
    const { ...rest } = filters
    if (Object.keys(rest).length) {
      setFilters({})
      resetFilters()
      // const payload = {
      //   pageNumber: 1,
      //   userId: getUserID(),
      //   status: 'UNASSIGNED'
      // }
      // getC2CList(payload)
    }
  }
  const onGoingStatVal = [
    { label: 'Ongoing', value: 'Ongoing' },
    { label: 'Auction Completed', value: 'Completed' },
  ]

  // const statusTypes = [
  //   {
  //     value: 'ONGOING',
  //     label: 'Ongoing'
  //   },
  //   {
  //     value: 'Completed',
  //     label: 'Auction Completed'
  //   },
  //   {
  //     value: 'CentralTaggingInspected',
  //     label: 'Auction Not Started'
  //   }
  // ]

  const pendingDateType = [
    {
      value: 'AUCTIONDATE',
      label: 'Auction Date'
    },
    {
      value: 'LEADCREATEDDATE',
      label: 'Lead Created Date'
    }
  ]
  const unAssignedDateType = [
    {
      value: 'LEADCREATEDDATE',
      label: 'Lead Created Date'
    }
  ]

  const dropDateType = [
    {
      value: 'AUCTIONDATE',
      label: 'Auction Date'
    },
    {
      value: 'LEADCREATEDDATE',
      label: 'Lead Created Date'
    },
    {
      value: 'DROPDATE',
      label: 'Dropped Date'
    }
  ]
  const soldDateType = [
    {
      value: 'AUCTIONDATE',
      label: 'Auction Date'
    },
    {
      value: 'LEADCREATEDDATE',
      label: 'Lead Created Date'
    },
    {
      value: 'SOLDDATE',
      label: 'Sold Date'
    },
    { label: 'DocQc Approval Date', value: 'DOCQCAPPROVEDATE' },
    { label: 'Payment Initiated Date', value: 'PAYMENTINITIATEDDATE' },
    { label: 'Payment Completed Date', value: 'PAYMENTCOMPLETEDDATE' },
    { label: 'CP Payment Date', value: 'CPPPAYMENTDATE' },
    { label: 'In-Custody Completed Date', value: 'INCUSTODYDATE' },
  ]
  const followupDateType = [
    {
      value: 'AUCTIONDATE',
      label: 'Auction Date'
    },
    {
      value: 'LEADCREATEDDATE',
      label: 'Lead Created Date'
    },
    {
      value: 'FOLLOWUPDATE',
      label: 'Follow Up Date'
    }
  ]

  const handleFromDateChange = (fromDate) => {
    setStartTime(fromDate)
  }

  const handleCityChange = (selectedCity) => {
    setCity(selectedCity)
    setOutletList([])
    setSelectedOutlet(null)
    if (selectedCity && selectedCity.value) {
      listStoresFhdShd({
        storeTypeId: 3,
        cityId: selectedCity.value
      })
        .then(apiResponse => {
          if (apiResponse.isValid) {
            const stores = apiResponse.storeListByCityId.map(store => ({
              value: store.storeId,
              label: store.storeName
            }))
            setOutletList(stores)
          }
        })
    }

  }

 

  const handleToDateChange = (toDate) => {
    setEndTime(toDate)
  }

  const handleDateType = (dateType) => {
    setDateType(dateType)
  }

  const handleStatusChange = (status) => {
    if (searchText) {
      setSearchtext(searchText)
      dispatch(getC2CList({ ...filters, userId: getUserID(), status, searchString: searchText, pageNum: 1 }))
    }
    else {
      setStartTime(null)
      setEndTime(null)
      setCity('')
      setDateType('')
      setFilters({})
      setAgent('')
      setFilterStatus('')
      setOnGoingStatus(!onGoingStatus)
      dispatch(getC2CList({ userId: getUserID(), status, pageNum: 1 }))
    }
    setStatus(status)
    setIsRegistrationSearch(false)
    setValue('')
  }

  const handleOnGoingFilter = (filterVal) => {
    setOnGoingStatustype(filterVal)
  }

  // const applyOnGoingFilter = () => {
  //   if(onGoingStatusType.value){
  //     dispatch(getC2CList({ userId: getUserID(), status, pageNum: 1 ,filterType: onGoingStatusType.value}))
  //   }else{
  //     dispatch(setNotification('danger', 'Invalid Selection', 'Status is Required.'));
  //     isValid = false
  //   }
  // }

  const handleApplyFilters = () => {
    let isValid = true
    const payload = getPayload()
    if (payload.dateType || payload.fromDate || payload.toDate) {
      if (!payload.dateType) {
        dispatch(setNotification('danger', 'Invalid Selection', 'Date Type is Required.'));
        isValid = false
      }
      if (!payload.fromDate) {
        dispatch(setNotification('danger', 'Invalid Selection', 'From Date is Required'));
        isValid = false
      }
      if (!payload.toDate) {
        dispatch(setNotification('danger', 'Invalid Selection', 'To Date is Required'));
        isValid = false
      }
    }
    if (isValid && !isEqual(payload, filters)) {
      if (appliedAgent) {
        setFilters(payload)
        dispatch(getC2CList({ ...payload, pageNum: 1 }))
      }
      else {
        setFilters(payload)
        dispatch(getC2CList({ ...payload, pageNum: 1, userId: getUserID() }))
      }
    }
    if (onGoingStatusType.value) {
      if (onGoingStatusType) {
        dispatch(getC2CList({ userId: getUserID(), status, pageNum: 1, filterType: onGoingStatusType.value }))
      } else {
        dispatch(setNotification('danger', 'Invalid Selection', 'Status is Required.'));
        isValid = false
      }
    }
  }

  const handleSearch = (search) => {
    const searchUpper = search.toUpperCase()
    setSearchtext(searchUpper)
  }

  const searchRegKeyWord = () => {
    const searchREgex = /^[a-z0-9]+$/i
    if (searchREgex.test(searchText) && searchText.length && searchText.length <= 12) {
      dispatch(getC2CList({ ...filters, userId: getUserID(), status, searchString: searchText, pageNum: 1 }))
    }
    else {
      dispatch(setNotification('danger', 'Error', 'Please Enter Valid Lead Id or Registration Number'));
    }
  }

  const handleClearSearch = () => {
    setSearchtext('')
    dispatch(getC2CList({ ...filters, userId: getUserID(), status, pageNum: 1 }))
  }

  const refreshPage = (value) => {
    if (value === 'bidAction') {
      dispatch(getC2CList({ ...filters, filterType: 'ONGOING', pageNum: 1, userId: getUserID(), status: "PENDING" }))
      setFilterStatus({
        value: 'ONGOING',
        label: 'Ongoing'
      })
      setStatus("PENDING")
    }
    else {
      dispatch(getC2CList({ ...filters, pageNum: 1, userId: getUserID(), status: status }))
    }
  }

  const pendingRefresh = () => {
    setStatus('PENDING')
    setFilterStatus({
      value: 'CentralTaggingInspected',
      label: 'Auction Not Started'
    })
    if (value === 'bidAction') {
      dispatch(getC2CList({ ...filters, filterType: 'CentralTaggingInspected', pageNum: 1, userId: getUserID(), status: 'PENDING' }))
    } else {
      dispatch(getC2CList({ ...filters, pageNum: 1, userId: getUserID(), status: 'PENDING', filterType: 'CentralTaggingInspected' }))
    }
  }

  const onDropRefresh = () => {
    setStatus('DROP')
    dispatch(getC2CList({ ...filters, pageNum: 1, userId: getUserID(), status: "DROP" }))
  }
  const onFollowUpRefresh = () => {
    setStatus('FOLLOWUP')
    dispatch(getC2CList({ ...filters, pageNum: 1, userId: getUserID(), status: "FOLLOWUP" }))
  }
  const onSoldRefresh = () => {
    setStatus('SOLD')
    dispatch(getC2CList({ ...filters, pageNum: 1, userId: getUserID(), status: "SOLD" }))
  }

  const handleStatusFilter = (val) => {
    setFilterStatus(val)
  }

  const handleAgentChange = (agentData) => {
    setAppliedAgent(true)
    setAgent(agentData)
  }
  const showValuatorDetails = valuator => {
    dispatch(showLoader())
    const columns = [
      { id: 'storeName', label: 'Outlet Name' },
      { id: 'storeLocation', label: 'Outlet Location' },
      { id: 'storeContactNumber', label: 'Outlet Number' },
      { id: 'storeType', label: 'Outlet Type' },
      { id: 'storeIncentive', label: 'Store Incentive' }
    ]
    const { storeId, valuatorId } = valuator
    getValuatorDetails(storeId, valuatorId)
      .then(apiResponse => {
        if (apiResponse.isValid) {
          const storeData = {
            ...apiResponse.userInfo,
            storeIncentive: getAmount(apiResponse.userInfo.storeIncentive)
          }
          this.setState({
            data: storeData,
            columns,
            isArray: false,
            title: 'Outlet Details',
            isOpen: true,
            cta: 'Close',
            check: "valuator"
          })
        } else {
          dispatch(setNotification(
            'danger',
            'Error',
            apiResponse.message
          ))
        }
        dispatch(hideLoader())
      })
  }

  const onStatusChange = (status) => {
    setFilterStatus(status)
  }
  const onDiyChange = (diydata) => {
    setDiy(diydata)
  }

  return (
    <div className='anti-body'>
      <div className="cpp-header" style={{ fontFamily: "Roboto", letterSpacing: "0px" }}>
        <h3> C2C Leads </h3>
      </div>
      <Header
        onChangeStatus={handleStatusChange}
        status={status}
        searchText={searchText}
        onSearch={handleSearch}
        onClearSearch={handleClearSearch}
        onSearchOfKeyword={searchRegKeyWord}
      />
      <Filter
        onGoingStatVal={onGoingStatVal}
        onGoingStatusType={onGoingStatusType}
        onApplyOnGoing={handleOnGoingFilter}
        onApplyFilters={handleApplyFilters}
        onEndDateChange={handleToDateChange}
        onStartDateChange={handleFromDateChange}
        onChangeCity={handleCityChange}
        onDateTypeChange={handleDateType}
        endTime={endTime}
        startTime={startTime}
        dateType={dateType}
        onClearFilters={handleClearFilters}
        isFilterLoading={isFilterLoading}
        status={status}
        city={city}
        resetFilters={resetFilters}
        pendingDateType={pendingDateType}
        followupDateType={followupDateType}
        unAssignedDateType={unAssignedDateType}
        dropDateType={dropDateType}
        soldDateType={soldDateType}
        cityList={cityList}
        onAgentChange={handleAgentChange}
        agentList={agentList}
        agent={agent}
        statusTypes={statusList}
        onStatusChange={onStatusChange}
        filterStatus={filterStatus}
        onValuatorDetails={showValuatorDetails}
        logisticsStatus={logisticsStatus}
        setLogisticsStatus={setLogisticsStatus}
        docQcStatus={docQcStatus}
        setDocQcStatus={setDocQcStatus}
        customerPayoutStatus={customerPayoutStatus}
        setCustomerPayoutStatus={setCustomerPayoutStatus}
        cpPaymentStatus={cpPaymentStatus}
        setCpPaymentStatus={setCpPaymentStatus}
        inCustodyStatus={inCustodyStatus}
        setInCustodyStatus={setInCustodyStatus}
        cpVsStore={cpVsStore}
        setCpVsStore={setCpVsStore}
        dateTypeFilter={dateTypeFilter}
        setDateTypeFilter={setDateTypeFilter}
        cpDrop={cpDrop}
        setCpDrop={setCpDrop}
        logisticsStatusDrop={logisticsStatusDrop}
        setLogisticsStatusDrop={setLogisticsStatusDrop}
        docQcStatusDrop={docQcStatusDrop}
        setDocQcStatusDrop={setDocQcStatusDrop}
        customerPayoutStatusDrop={customerPayoutStatusDrop}
        setCustomerPayoutStatusDrop={setCustomerPayoutStatusDrop}
        cpPaymentStatusDrop={cpPaymentStatusDrop}
        setCpPaymentStatusDrop={setCpPaymentStatusDrop}
        inCustodyStatusDrop={inCustodyStatusDrop}
        setInCustodyStatusDrop={setInCustodyStatusDrop}
        cpVsStoreDrop={cpVsStoreDrop}
        setCpVsStoreDrop={setCpVsStoreDrop}
        dateTypeFilterDrop={dateTypeFilterDrop}
        setDateTypeFilterDrop={setDateTypeFilterDrop}
        cpList={cpList}
        setCpList={setCpList}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        advancedFilterCount={advancedFilterCount}
        filters={filters}
        onDiyChange={onDiyChange}
        diy={diy}
        outletList={outletList}
        selectedOutlet={selectedOutlet}
        setSelectedOutlet={setSelectedOutlet}
        priorityFilter={priorityFilter}
        setPriorityFilter={setPriorityFilter}

      />
      <ConfirmationTable
        onChangePage={handlePageChange}
        status={status}
        refreshPage={refreshPage}
        currentPage={currentPage}
        confirmList={confirmList}
        totalCount={totalCount}
        value={value ? value : tabValue}
        handleValue={handleValue}
        handleStatusFilter={handleStatusFilter}
        onPendingRefresh={pendingRefresh}
        refreshDropPage={onDropRefresh}
        refreshFollowUpPage={onFollowUpRefresh}
        refreshSoldPage={onSoldRefresh}
        statusList={statusList}
      />
    </div>
  )
}

export default connect()(C2C)
