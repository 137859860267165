import { GET_INTERESTED_BUYERS } from '../../core/constants/actions'
import { getNewState } from '../../core/utility'

const intialState = {
  intrestedBuyers: {}
}

export default function interestedBuyersReducer(state = intialState, action) {

  switch (action.type) {
    case GET_INTERESTED_BUYERS.REQUEST:
      return getNewState(state, { isLoading: true })

    case GET_INTERESTED_BUYERS.SUCCESS:
      const { intrestedBuyers } = action.payload
      return getNewState(state, { intrestedBuyers, isLoading: false })

    case GET_INTERESTED_BUYERS.FAILURE:
      const { error } = action.payload
      return getNewState(state, { error, ...intialState })

    case GET_INTERESTED_BUYERS.RESET:
      return intialState

    default:
      return state
  }
}   