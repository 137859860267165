
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Tooltip,
} from "@material-ui/core";
import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import './refundTable.scss'
import { getAmount, getDate } from "../../../../../core/utility";


const useStyles = makeStyles((theme) => ({


    tableContainer: {
        margin: 20,
        maxHeight: "350px",
        maxWidth: '97%',
    },
    table: {
        borderRadius: 6,
    },

    tableHeaderCell: {
        fontWeight: 'bold',
        backgroundColor: "#F5F7FD",
        color: '#000000',

    },
    cellPaddingLight: {
        minWidth: '200px !important'
    }

}));

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: theme.palette.common.black,
    },
    tooltip: {
        backgroundColor: theme.palette.common.black,
        maxWidth: '175px',
        marginRight: '20px'
    },
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();

    return <Tooltip arrow classes={classes} {...props} />;
}



export default function RefundTable({open,
    tableData,
    handleOpenModel,

}) {
    const classes = useStyles();



    return (
        <div>
            <h4 align="left" className='headingDrawerSHD'>Refund Details</h4>
            {(tableData!==undefined) &&
                <TableContainer
                    className={classes.tableContainer} component={Paper}
                >
                    <Table
                        className={classes.table}
                        stickyHeader
                        aria-label="sticky table">

                        <TableHead sx={{ bgColor: "green" }}>
                            <TableRow								>
                                <TableCell style={{ borderBottom: "none" }}
                                    className={`${classes.tableHeaderCell} ${classes.cellPaddingLight}`}
                                >{`Date\u00a0&\u00a0Time`}</TableCell>
                                <TableCell style={{ borderBottom: "none" }}
                                    className={`${classes.tableHeaderCell}`} align="left"
                                >{`Refund\u00a0ID`}</TableCell>
                                <TableCell style={{ borderBottom: "none" }}
                                    className={`${classes.tableHeaderCell}`} align="left"
                                >{`Refund\u00a0Amount`}</TableCell>
                                <TableCell style={{ borderBottom: "none" }}
                                    className={`${classes.tableHeaderCell}`} align="left"
                                >{`Payout\u00a0Amount\u00a0(After)`}</TableCell>
                                <TableCell style={{ borderBottom: "none" }}
                                    className={`${classes.tableHeaderCell}`} align="left"
                                >{`Payout\u00a0Amount\u00a0(Before)`}</TableCell>
                                <TableCell style={{ borderBottom: "none" }}
                                    className={`${classes.tableHeaderCell}`} align="left"
                                >{`Comments`}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableData && tableData.map((rowData, i) => (
                                <TableRow
                                    key={rowData.refundId}
                                >
                                    <TableCell style={{ borderBottom: "none" }} className={i % 2 === 0 ? classes.tableBodyCellDark : classes.tableBodyCellLight} component="th" scope="row">{getDate(rowData.refundedDate)}</TableCell>
                                    <TableCell style={{ borderBottom: "none" }} className={i % 2 === 0 ? classes.tableBodyCellDark : classes.tableBodyCellLight} sx={{}} align="left">{rowData.refundId}</TableCell>
                                    <TableCell style={{ borderBottom: "none" }} className={i % 2 === 0 ? classes.tableBodyCellDark : classes.tableBodyCellLight} align="left">{getAmount(rowData.amountRefuned)}</TableCell>
                                    <TableCell style={{ borderBottom: "none" }} className={i % 2 === 0 ? classes.tableBodyCellDark : classes.tableBodyCellLight} sx={{}} align="left">{getAmount(rowData.shdAmountAfterRefund)}</TableCell>
                                    <TableCell style={{ borderBottom: "none" }} className={i % 2 === 0 ? classes.tableBodyCellDark : classes.tableBodyCellLight} align="left">{getAmount(rowData.shdAmountBeforeRefund)}</TableCell>
                                    <TableCell style={{ borderBottom: "none" }} className={i % 2 === 0 ? classes.tableBodyCellDark : classes.tableBodyCellLight} align="left">
                                        <BootstrapTooltip title={rowData.comments}>
                                            <span>{"View Comments"}</span>
                                        </BootstrapTooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            }

        </div>


    );
}









