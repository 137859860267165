import { AE_OFFER_DETAILS } from '../../core/constants/actions'
import { getNewState } from '../../core/utility'

const intialState = {
    allAEs: [],
    isLoading: false,
}
export default function aeOfferListReducer(state = intialState, action) {

    switch (action.type) {
        case AE_OFFER_DETAILS.REQUEST:
            return getNewState(state, { isLoading: true })

        case AE_OFFER_DETAILS.SUCCESS:
            const { allAEs,aesCount } = action.payload;
            return getNewState(state, { allAEs,aesCount })

        case AE_OFFER_DETAILS.FAILURE:
            const { error } = action.payload
            return getNewState(state, { error, ...intialState })

        case AE_OFFER_DETAILS.RESET:
            return intialState

        default:
            return state
    }
}
